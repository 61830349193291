import React, { useEffect, useState } from "react";

const ButtonWithicon = ({
  color,
  children,
  text,
  className,
  onPressingButton,
}) => {
  const [settings, setSettings] = useState({
    bg: "bg-white",
    font: "text-slate-600 font-semibold",
    outline: "outline outline-slate-300",
  });
  useEffect(() => {
    if (color == "green") {
      setSettings({
        bg: "bg-emerald-500",
        font: "text-white font-semibold",
        outline: "outline outline-emerald-500",
      });
    } else if (color == "red") {
      setSettings({
        bg: "bg-white",
        font: "text-red-500 font-semibold",
        outline: "outline outline-red-500",
      });
    } else if (color == "greenOutline") {
      setSettings({
        bg: "bg-white",
        font: "text-emerald-500 font-semibold",
        outline: "outline outline-emerald-500",
      });
    }
  }, []);

  return (
    <button
      onClick={onPressingButton}
      className={`flex ${settings.bg} ${settings.font} ${settings.outline} rounded-lg px-1 py-1.5 inline mx-1 ${className}`}
    >
      <div className="flex">
        <div className="mx-1 self-center">{children}</div>
        <span className="mr-1 ">{text}</span>
      </div>
    </button>
  );
};

export default ButtonWithicon;
