import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../components/alert/alert";
import Title from "../../components/title/title";
import "../../index.css"
import ButtonChild from "../../components/buttons/UI_Buttons_2/buttonChild";
import FillButtonV2 from "../../components/buttons/UI_Buttons_2/fillButton";
import InfoBox from "../../components/inputfields/UI_Inputs/infoBox";
import TextInput from "../../components/inputfields/UI_Inputs/textInput";
import InputFieldComp from "../../components/inputfields/UI_Inputs/InputFieldComp";
import Loader from "../../components/Loader/loader";
import { browserName, isMobileOnly } from "react-device-detect";
import moment from "moment";
import { Mixpanel } from "../../services/mixpanel";

export function ResetPasswordOTP() {
  const navigator = useNavigate();
  const [form, setForm] = useState({ otp: "", password1: "" });
  const [counter, setCounter] = useState(30);
  const [timeoutId, setTimeoutid] = useState(null);
  const [otpVerified, setOTPVerified] = useState(false);
  const [alertData, setAlertData] = React.useState(null);
  const [isValidOtp, setIsValidOtp] = useState(false);
  const closeAlert = () => setAlertData(null);
  const [error, setError]= useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const authToken = localStorage.getItem("auth")
  // console.log(authToken)
  const isEmail = /\S+@\S+\.\S+/.test(authToken);
  
  
  const onFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (isEmail) {
      // Remove non-digits and keep only the first 10 digits
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
      setForm({ ...form, [name]: sanitizedValue });
    } else if(!isEmail){
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 4);
      setForm({ ...form, [name]: sanitizedValue });
    } else {
      setForm({ ...form, [name]: value });
    }
    // Add your automatic verification logic here
    
  };
  const timer = () => {
    // console.log(10);
    setCounter((prev) => {
      if (prev == 1) clearInterval(timeoutId);
      return --prev;
    });
  };

  useEffect(() => {
    setTimeoutid(setInterval(timer, 1000));
  }, []);

  const onPasswordChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }
  

  const test = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/otp/resend`;
  
    try {
      const response = await axios.post(url, {
        identifier: localStorage.getItem("forgotPassIdentifier"),
      });
  
      setCounter(30);
      setTimeoutid(setInterval(timer, 1000));
  
      // You might want to log the response data if needed
      console.log(response?.data);
    } catch (error) {
      console.error(error);
  
      if (error.response && error.response?.data.message) {
        setAlertData(error.response?.data.message);
        setError(error.response?.data.message)
      }
    }
  };
  



  const verifyMobOTP = async (e) => {

    
  if(form.otp.length == 4){
    setIsLoading(true)
    const url = `${process.env.REACT_APP_baseURL}/recruiter/verify_otp`;
  
    try {
      const response = await axios.post(url, {
        identifier: localStorage.getItem('forgotPassIdentifier'),
        OTP: form.otp,
      });
      setError(false)
      // Verification success
      setTimeout(() => {
        setIsValidOtp(true);
        
        setIsLoading(false)
        
        
      }, 1000);
      
      setTimeout(() => {
        setOTPVerified(true);
        Mixpanel.track("Phone_OTP_verified", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          recruiter_id: localStorage.getItem("userId"),
          browser: browserName,
          mobile_device: isMobileOnly,
        });
        // setIsLoading(false)
      }, 3000);
  
      console.log(response); // Move this line here
    } 
    
    catch (error) {
      // console.log(error);
 
       if (error.response && error.response?.data.message) {
       
          setError(error.response?.data.message);
          setOTPVerified(false)
          setIsValidOtp(false);
          setIsLoading(true)
        
       }
    }
  }
}
  
const verifyEmailOTP = async (e) => {

    
  if(form.otp.length == 6){
    setIsLoading(true)
    const url = `${process.env.REACT_APP_baseURL}/recruiter/verify_otp`;
  
    try {
      const response = await axios.post(url, {
        identifier: localStorage.getItem('forgotPassIdentifier'),
        OTP: form.otp,
      });
      
      setError(false)
      // Verification success
      setTimeout(() => {
        setIsValidOtp(true);
       
        setIsLoading(false)
       
        
      }, 1000);
      
      setTimeout(() => {
        setOTPVerified(true);
        Mixpanel.track("Email_OTP_verified", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          recruiter_id: localStorage.getItem("userId"),
          browser: browserName,
          mobile_device: isMobileOnly,
        });
        // setIsLoading(false)
      }, 3000);
  
      console.log(response); // Move this line here
    } 
    
    catch (error) {
      // console.log(error);
 
       if (error.response && error.response?.data.message) {
       
          setError(error.response?.data.message);
          setOTPVerified(false)
          setIsValidOtp(false);

          setIsLoading(true)
          
        
       }
    }
  }
}
  
useEffect(() => {
  const isEmail = /\S+@\S+\.\S+/.test(authToken); // Check if the input resembles an email
  
  if (form.otp.length === 4 && !isEmail && !otpVerified) {
    verifyMobOTP();
  } else if (form.otp.length === 6 && isEmail && !otpVerified) {
    verifyEmailOTP();
  }
}, [form.otp, otpVerified]);

useEffect(() => {
  const timer = setInterval(() => {
    if (counter > 0 && !isValidOtp && !otpVerified && !isLoading && !error) {
      setCounter((prevCounter) => prevCounter - 1);
    }
  }, 1000);

  return () => clearInterval(timer);
}, [counter, isValidOtp, otpVerified, isLoading, error]);

 
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     if (counter > 0 && !isValidOtp && !otpVerified && !isLoading && !error) {
  //       setCounter((prevCounter) => prevCounter - 1);
  //     }
  //   }, 1000);
  
  //   const isEmail = /\S+@\S+\.\S+/.test(authToken); // Check if the input resembles an email
  //   if (form.otp.length === 4 && !isEmail && !otpVerified) {
  //     // If it's a mobile number and 4 characters, send 4-digit OTP
  //     verifyMobOTP();
  //   } else if (form.otp.length === 6 && isEmail && !otpVerified) {
  //     // If it looks like an email and 6 characters, send 6-digit OTP
  //     verifyEmailOTP();
  //   }
  //   return () => clearInterval(timer);
  // }, [form.otp,counter, isValidOtp, otpVerified, isLoading, error])



  const changePass = async () => {
    setIsLoading(true);
    console.log(form.password1.length, "length");
    if (form.password1.length < 8 && form.password1 != "") {
      // setAlertData("Password must contain atleast 8 characters. ");
      setError("Password must contain atleast 8 characters.")
      setIsLoading(false);
     
    } else if(form.password1.length == ""){
      setError("Password is required.")
      setIsLoading(false);
    }

    // if (form.password1 != form.password2) {
    //   setAlertData("Password mismatch");
    //   return;
    // }
    if (!isValidOtp) {
      setAlertData("Please verify the OTP first");
      setIsLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_baseURL}/recruiter/change_password`;
    const response = await axios
      .post(url, {
        identifier: localStorage.getItem("forgotPassIdentifier"),
        OTP: form.otp,
        password: form.password1,
      })
      .then(() => {
        Mixpanel.track("Password_reset_Successfull", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          recruiter_id: localStorage.getItem("userId"),
          browser: browserName,
          mobile_device: isMobileOnly,
        });
        localStorage.removeItem("forgotPassIdentifier");
        navigator("/signin");
      })
      .catch((error) => {
        
        console.log(error);
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
        setError(error.response?.data.message)
        setIsLoading(false);
      }
      );
      // console.log(response?.data);
  
  };


  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = async () => {
    setIsPasswordVisible((prevState) => !prevState);
  }

  const [Loading, setLoading] = useState(true); // State to track loading

// Your existing state and useEffect logic...

useEffect(() => {
  // Simulating an asynchronous action
  const fakeAsyncAction = async () => {
    try {
      // Simulate an API call or any asynchronous action
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      // Set isLoading to false once the asynchronous action is done
      setLoading(false);
    }
  };

  // Call the fakeAsyncAction when the component mounts
  fakeAsyncAction();
}, []); // Empty dependency array means this effect runs once on mount

// Render the loader if isLoading is true
if (Loading) {
  return <Loader />;
}
  return (
    <>
<div
        className="flex flex-col w-screen overflow-auto no-scrollbar gradient sm:py-[64px] min-h-screen
                   xl:flex-row xl:h-[100vh] xl:p-0 xl:m-0  ">
        <div className="relative Graphics max-w-full xl:w-full  xl:h-[100vh] mb-16 xl:mb-0">
          <img
            className="object-cover absolute Gfx xl:w-full xl:h-full 2xl:w-full 2xl:h-full 
            xl1:w-full xl1:h-full left-0 top-0 bg-fixed bg-cover h-full"
            src="/gfx.png"
          />
          <Title />
        </div>
        <div className="form p-0 m-0 w-100% flex flex-nowrap h-auto sm:m-16 overflow-scroll no-scrollbar
        md:mx-[97px] md:mt-[32px] lg:mx-[192px] lg:mt-[64px] xl:m-0 xl:w-[105vh] ">

          <div className="relative bg-white mt-[161px] border-none shadow-none w-full h-max xl:mt-0 p-[32px] 
          sm:border sm:border-white sm:p-spaceSLarge sm:self-stretch sm:rounded-[28px] sm:shadow-white-xl
          md:py-[64px] md:w-full lg:w-full h-fit  xl:h-full 2xl:h-auto sm:mt-[209px] md:mt-[230px] lg:mt-[211px] xl:border-none xl:shadow-none xl:rounded-none xl:m-0
          xl:w-full xl:p-[64px]">
           
            <div className="mb-spaceBase">
            <Link
              to="/signIn"
            >
              <ButtonChild
                text="Back to Sign In"
                textColor="green"
                leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                <path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="currentColor"/>
              </svg>}
              />
            </Link>
            </div>


            <div className="text-xl font-bold my-spaceBase">
              {otpVerified ? 
              <div className="w-max text-teal-950 text-[32px] font-black font-['Satoshi Variable'] leading-[38.40px]">
                Reset Password
                </div> 
                : 
              <div className="w-max text-teal-950 text-[32px] font-black font-['Satoshi Variable'] leading-[38.40px]">
                Verify Your Account
                </div>}
            </div>
            
            {!otpVerified && (
              <>
              <div className="flex flex-col">
            <div className="mb-spaceSLarge">
            <InfoBox 
            variant="standard"
            infoText={
            <div className="">
              <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight">A unique OTP has been sent to your <br/></span>
              {authToken ?
              <div>
                
              {/* <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight"> &nbsp; &#8226; your registered mobile or Email </span> */}
              <span className="text-emerald-500 text-sm font-medium font-satoshi leading-tight">{`${authToken}`}<br/></span>
             
            </div>
             : 
             <div>
              <span className="text-emerald-500 text-sm font-medium font-satoshi leading-tight">email....@gmail.com<br/></span>
              </div>
             }
            <div>
            {/* <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight"> &nbsp; &#8226; your Mobile </span>
              <span className="text-emerald-500 text-sm font-medium font-satoshi leading-tight">'+91 98765 43210'</span> */}
            </div>
            </div>
              }

              buttonLeftIcon={<div onClick={()=>navigator('/password/verifyaccount')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M17.7586 5.73203L14.268 2.24062C14.1519 2.12452 14.0141 2.03242 13.8624 1.96958C13.7107 1.90675 13.5482 1.8744 13.384 1.8744C13.2198 1.8744 13.0572 1.90675 12.9056 1.96958C12.7539 2.03242 12.6161 2.12452 12.5 2.24062L2.86641 11.875C2.74983 11.9907 2.65741 12.1283 2.59451 12.28C2.5316 12.4317 2.49948 12.5944 2.50001 12.7586V16.25C2.50001 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41849 17.5 3.75001 17.5H7.24141C7.40563 17.5005 7.5683 17.4684 7.71999 17.4055C7.87168 17.3426 8.00935 17.2502 8.12501 17.1336L17.7586 7.5C17.8747 7.38392 17.9668 7.24611 18.0296 7.09443C18.0925 6.94276 18.1248 6.78019 18.1248 6.61601C18.1248 6.45184 18.0925 6.28927 18.0296 6.13759C17.9668 5.98592 17.8747 5.84811 17.7586 5.73203ZM15 8.49062L11.5086 5L13.3836 3.125L16.875 6.61562L15 8.49062Z" fill="#16C083"/>
            </svg> 
            </div>}
            />
           </div>
<div className="mb-spaceBase">
           <TextInput 
              htmlFor='otp'
              labelText="Enter OTP sent on Email/Mobile"
              textColor="gray"
              textType="input"
               type="text"
               placeClass={`tracking-[10px] ${isLoading ? "" : ""}`}
              placeholder={`${isEmail ? "000000" : "0000"}`}
              name='otp'
              value={form.otp}
              hasError={error}
              errorMsg={error}
              onChange={onFormChange}
              rightTextClass={`${( isValidOtp || otpVerified ) ? "hidden": ""}`}
              rightText={
<div >
       {(!isValidOtp && !isLoading && !otpVerified || error ) &&
                <div>
        {counter < 1 ? (
          <div  onClick={test} className={``}>
              <ButtonChild
              className={`w-max`}
              text="Resend OTP"
              textColor="green"
              />
              </div>
                  ) : (
                    <div className={` w-max font-bold text-gray-300 text-sm `}>
                      {" "}
                      Resend OTP in <span className="p-1 w-4">{counter}</span>
                    </div>
                  )}
              
                </div>
              }
               

</div>
              }
              rightIcon={
                       <div> 
                 
                {(!isValidOtp && !error && !otpVerified) ? 

                
                <div><ButtonChild 
                textColor="green"
               //  text="Verified"
                leftIcon={<div></div>}
                isLoading={isLoading}
                />
                </div>
                : isValidOtp?
                <div>
<ButtonChild 
textColor="green"
text="Verified"
// leftIcon={<div></div>}
// isLoading={isLoading}
/>
                </div> 
                : 
                ""
                //  : 
                //  <div><ButtonChild 
                //  textColor="green"
                // //  text="Verified"
                //  leftIcon={<div></div>}
                //  isLoading={isLoading}
                //  />
                //  </div>
              }
                </div>
         
              }
              />
              </div>
              <div className=" inline-flex justify-end">
                {error ?
              <ButtonChild
              
              text="Invalid OTP"
              textColor="red"
              />
              :""
                }
                  </div>
                  </div>
                  </>
            )}  
                     
     

            {otpVerified && (
              <>
                <div className="flex flex-col my-8">
                 <InputFieldComp 
                 labelText="New Password"
                 type={isPasswordVisible ? "text" : "password"}
                  name="password1"
                  placeholder="Enter new password"
                  value={form.password1}
                  onChange={onPasswordChange}
                  hasError={error}
                  errorMsg={error}
                  rightIcon={
                    <button
                      onClick={togglePasswordVisibility}
                    >
                      {isPasswordVisible ? (
                        <svg

                          xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                          <path d="M19.3211 9.74688C19.2937 9.68516 18.632 8.21719 17.1609 6.74609C15.2008 4.78594 12.725 3.75 9.99999 3.75C7.27499 3.75 4.79921 4.78594 2.83905 6.74609C1.36796 8.21719 0.703118 9.6875 0.678899 9.74688C0.643362 9.82681 0.625 9.91331 0.625 10.0008C0.625 10.0883 0.643362 10.1748 0.678899 10.2547C0.706243 10.3164 1.36796 11.7836 2.83905 13.2547C4.79921 15.2141 7.27499 16.25 9.99999 16.25C12.725 16.25 15.2008 15.2141 17.1609 13.2547C18.632 11.7836 19.2937 10.3164 19.3211 10.2547C19.3566 10.1748 19.375 10.0883 19.375 10.0008C19.375 9.91331 19.3566 9.82681 19.3211 9.74688ZM9.99999 13.125C9.38193 13.125 8.77774 12.9417 8.26383 12.5983C7.74993 12.255 7.34939 11.7669 7.11287 11.1959C6.87634 10.6249 6.81446 9.99653 6.93504 9.39034C7.05562 8.78415 7.35324 8.22733 7.79028 7.79029C8.22732 7.35325 8.78414 7.05562 9.39033 6.93505C9.99652 6.81447 10.6249 6.87635 11.1959 7.11288C11.7669 7.3494 12.255 7.74994 12.5983 8.26384C12.9417 8.77775 13.125 9.38193 13.125 10C13.125 10.8288 12.7958 11.6237 12.2097 12.2097C11.6236 12.7958 10.8288 13.125 9.99999 13.125Z"
                            fill="currentColor" />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M18.0417 12.8125C18.1246 12.9564 18.1469 13.1273 18.1038 13.2877C18.0606 13.448 17.9556 13.5847 17.8117 13.6676C17.7404 13.7086 17.6618 13.7352 17.5802 13.7459C17.4987 13.7565 17.4159 13.751 17.3365 13.7296C17.1761 13.6865 17.0395 13.5814 16.9566 13.4375L15.4722 10.8438C14.6099 11.427 13.6588 11.8666 12.6558 12.1453L13.1144 14.8969C13.1279 14.9779 13.1254 15.0608 13.1068 15.1408C13.0883 15.2208 13.0542 15.2964 13.0065 15.3632C12.9587 15.43 12.8983 15.4868 12.8286 15.5302C12.7589 15.5737 12.6813 15.603 12.6003 15.6164C12.567 15.6219 12.5333 15.6247 12.4995 15.625C12.3517 15.6248 12.2087 15.5721 12.096 15.4765C11.9833 15.3808 11.9081 15.2482 11.8839 15.1023L11.4331 12.4008C10.4825 12.5331 9.51814 12.5331 8.56751 12.4008L8.11673 15.1023C8.09247 15.2485 8.01711 15.3812 7.90407 15.477C7.79103 15.5727 7.64767 15.6251 7.49954 15.625C7.46499 15.6249 7.43051 15.622 7.39642 15.6164C7.3154 15.603 7.23783 15.5737 7.16814 15.5302C7.09845 15.4868 7.03801 15.43 6.99027 15.3632C6.94253 15.2964 6.90842 15.2208 6.88991 15.1408C6.87139 15.0608 6.86882 14.9779 6.88235 14.8969L7.34329 12.1453C6.33988 11.8659 5.38845 11.4256 4.5261 10.8414L3.04642 13.4375C2.96354 13.5819 2.82668 13.6875 2.66596 13.731C2.50523 13.7745 2.3338 13.7524 2.18939 13.6695C2.04497 13.5867 1.93939 13.4498 1.89587 13.2891C1.85236 13.1283 1.87447 12.9569 1.95735 12.8125L3.51985 10.0781C2.97083 9.60418 2.46614 9.08118 2.01204 8.51562C1.92211 8.4044 1.87305 8.26569 1.87305 8.12266C1.87305 7.97962 1.92211 7.84091 2.01204 7.72969C3.48079 5.91563 6.05814 3.75 9.99954 3.75C13.9409 3.75 16.5183 5.91562 17.9855 7.73438C18.0754 7.8456 18.1245 7.98431 18.1245 8.12734C18.1245 8.27038 18.0754 8.40909 17.9855 8.52031C17.5314 9.08587 17.0267 9.60887 16.4777 10.0828L18.0417 12.8125Z" 
  fill="currentColor"/>
</svg>
                         )}
                    </button>
                  }
                 />
                  
                </div>
                
                <FillButtonV2 
                onClick={changePass}
                textColor="white"
                color="green"
                text="Complete Reset"
                isLoading={isLoading}
                />
              
              </>
            )}
           
          </div>
          
   
        </div>
        {alertData && (
          <Alert text={alertData} alertClose={closeAlert} time={2} />
        )}
      </div>
    </>
  );
}
