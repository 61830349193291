import axios from "axios";
import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import Alert from "../../../components/alert/alert";
import otpVerify from "../modals/forgotpassOTPverify";
import BeepNavbar from "../../../components/navbar/navbar";
import ProfileHeading from "./heading";
import BorderButton from "../../../components/buttons/UI_Buttons_2/borderButton";
import FillButtonV2 from "../../../components/buttons/UI_Buttons_2/fillButton";
import TextInput from "../../../components/inputfields/UI_Inputs/textInput";
import ButtonChild from "../../../components/buttons/UI_Buttons_2/buttonChild";
import OnSaveChangeToggle from "../modals/onSaveChangesToggle";
import Loader from "../../../components/Loader/loader";
import { Mixpanel } from "../../../services/mixpanel";
import moment from "moment";
import { browserName, isMobileOnly } from "react-device-detect";


//Reset user password from user profile

const ResetPass = ({setActiveSection, showDiscard, setShowDiscard, setIsChanged}) => {
  const [formData, setFormData] = useState({
    oldPass: "",
    newPass: "",
  });

  const [showChangesSaved, setShowChangesSaved] = useState(false); //small text near save button

  const [alertData, setAlert] = useState(null); // Show Alert if Any
  const [changed, setChanged] = useState(false)

  //Form state update
  const onFormChange = (e) => {
    setFormData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
    setIsChanged(true)
    setChanged(true)
  };

  const resetPass = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/change/password`;

    const response = await axios
      .patch(url, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => {
        console.log(error?.response?.data.code);
        if (error?.response?.data?.code) {
          if (error.response?.data.code == "INVALID_CREDENTIALS")
            setAlert("Invalid password");
          else setAlert(error.response?.data.code);
        } else setAlert("Something went wrong");
      });

    if (response?.data) {
      Mixpanel.track("change_password_from_my_account_Page_success", {
        date: moment().format(),
        browser: browserName,
        mobile_device: isMobileOnly,
        recruiter_id: localStorage.getItem("userId"),
        distinct_id: localStorage.getItem("userId"),
        isPremium: localStorage.getItem("isPremium"),
      });
      setShowChangesSaved(true);
      setTimeout(() => setShowChangesSaved(false), 3000); // show small text for 3 seconds near save button
      //Reset state to start
      setFormData({
        oldPass: "",
        newPass: "",
      });
      if(showDiscard){
        setActiveSection(showDiscard); 
        setShowDiscard(false)
        }
    }
  };

  const cancelReset = () => {
    // Reset the form or perform any other actions needed when the user cancels
    setFormData({
      oldPass: "",
      newPass: "",
    });
    
  };

  const [isOldPasswordVisible, setIsOldPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);


  // const togglePasswordVisibility = async () => {
  //   setIsPasswordVisible((prevState) => !prevState);
  // }

  const toggleOldPasswordVisibility = () => {
    setIsOldPasswordVisible(!isOldPasswordVisible);
  };

  const toggleNewPasswordVisibility = () => {
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };
  
  
  const [isLoading, setIsLoading] = useState(true); // State to track loading

// Your existing state and useEffect logic...

useEffect(() => {
  // Simulating an asynchronous action
  const fakeAsyncAction = async () => {
    try {
      // Simulate an API call or any asynchronous action
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      // Set isLoading to false once the asynchronous action is done
      setIsLoading(false);
    }
  };

  // Call the fakeAsyncAction when the component mounts
  fakeAsyncAction();
}, []); // Empty dependency array means this effect runs once on mount

// Render the loader if isLoading is true
// if (isLoading) {
//   return <Loader />;
// }
  return (
    <>
    <div className="overflow-auto no-scrollbar">
    {/* <div className="sm:hidden">
      <BeepNavbar/>
     
     
     </div> */}
    <div className="Menu w-full sm:w-[75vw] border-l border-gray-900 border-opacity-10 min-h-screen xl:w-[50vw] sm:pt-[3rem] sm:m-0 mb-8 overflow-scroll no-scrollbar grow shrink basis-0 self-stretch p-spaceBase sm:px-spaceSLarge sm:py-spaceLarge bg-white flex-col justify-start items-start inline-flex">
    {/* <Link to="/profile">
          <div className="sm:hidden mb-4  flex flex-row ">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5.80781 10.4427L12.0578 16.6927C12.1452 16.7802 12.2566 16.8398 12.3779 16.8639C12.4992 16.8881 12.625 16.8757 12.7392 16.8284C12.8535 16.781 12.9512 16.7009 13.0198 16.598C13.0885 16.4951 13.1251 16.3742 13.125 16.2505V3.75049C13.1251 3.62681 13.0885 3.50587 13.0198 3.403C12.9512 3.30013 12.8535 3.21995 12.7392 3.1726C12.625 3.12526 12.4992 3.11288 12.3779 3.13704C12.2566 3.16119 12.1452 3.2208 12.0578 3.3083L5.80781 9.5583C5.7497 9.61635 5.7036 9.68528 5.67215 9.76115C5.6407 9.83703 5.62451 9.91836 5.62451 10.0005C5.62451 10.0826 5.6407 10.164 5.67215 10.2398C5.7036 10.3157 5.7497 10.3846 5.80781 10.4427Z" fill="#002032" fill-opacity="0.6" />
            </svg>
            </div>
            <span className='text-gray-500 font-bold leading-tight'>Go Back</span>
          </div>
        </Link> */}
         {isLoading && (
          <>
        
            <div className=" m-auto"><Loader /></div>
          </>
        )}
          {!isLoading && 
          <>
      <div className="ResetPassword  self-stretch h-auto flex-col justify-start items-start gap-spaceSLarge flex">
      <div className="w-auto text-teal-950 text-[32px] font-black font-['Satoshi Variable'] leading-[28.80px]">Reset Password</div>
        <div className="Row w-full flex-col sm:flex-row self-stretch justify-start items-start gap-8 inline-flex">
          
          <div className="Type grow w-full shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
          <div className="w-full">
          
          <TextInput 
          textType="input"
          textColor="gray"
          type={isOldPasswordVisible ? "text" : "password"}
          name="oldPass"
          value={formData.oldPass}
          onChange={onFormChange}
          labelText="Old Password"
          placeholder="Enter Old Password"
          rightIcon={
            <button
            onClick={toggleOldPasswordVisibility}
          >
            {isOldPasswordVisible ? (
              <svg
    
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M19.3211 9.74688C19.2937 9.68516 18.632 8.21719 17.1609 6.74609C15.2008 4.78594 12.725 3.75 9.99999 3.75C7.27499 3.75 4.79921 4.78594 2.83905 6.74609C1.36796 8.21719 0.703118 9.6875 0.678899 9.74688C0.643362 9.82681 0.625 9.91331 0.625 10.0008C0.625 10.0883 0.643362 10.1748 0.678899 10.2547C0.706243 10.3164 1.36796 11.7836 2.83905 13.2547C4.79921 15.2141 7.27499 16.25 9.99999 16.25C12.725 16.25 15.2008 15.2141 17.1609 13.2547C18.632 11.7836 19.2937 10.3164 19.3211 10.2547C19.3566 10.1748 19.375 10.0883 19.375 10.0008C19.375 9.91331 19.3566 9.82681 19.3211 9.74688ZM9.99999 13.125C9.38193 13.125 8.77774 12.9417 8.26383 12.5983C7.74993 12.255 7.34939 11.7669 7.11287 11.1959C6.87634 10.6249 6.81446 9.99653 6.93504 9.39034C7.05562 8.78415 7.35324 8.22733 7.79028 7.79029C8.22732 7.35325 8.78414 7.05562 9.39033 6.93505C9.99652 6.81447 10.6249 6.87635 11.1959 7.11288C11.7669 7.3494 12.255 7.74994 12.5983 8.26384C12.9417 8.77775 13.125 9.38193 13.125 10C13.125 10.8288 12.7958 11.6237 12.2097 12.2097C11.6236 12.7958 10.8288 13.125 9.99999 13.125Z"
                  fill="currentColor" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.0417 12.8125C18.1246 12.9564 18.1469 13.1273 18.1038 13.2877C18.0606 13.448 17.9556 13.5847 17.8117 13.6676C17.7404 13.7086 17.6618 13.7352 17.5802 13.7459C17.4987 13.7565 17.4159 13.751 17.3365 13.7296C17.1761 13.6865 17.0395 13.5814 16.9566 13.4375L15.4722 10.8438C14.6099 11.427 13.6588 11.8666 12.6558 12.1453L13.1144 14.8969C13.1279 14.9779 13.1254 15.0608 13.1068 15.1408C13.0883 15.2208 13.0542 15.2964 13.0065 15.3632C12.9587 15.43 12.8983 15.4868 12.8286 15.5302C12.7589 15.5737 12.6813 15.603 12.6003 15.6164C12.567 15.6219 12.5333 15.6247 12.4995 15.625C12.3517 15.6248 12.2087 15.5721 12.096 15.4765C11.9833 15.3808 11.9081 15.2482 11.8839 15.1023L11.4331 12.4008C10.4825 12.5331 9.51814 12.5331 8.56751 12.4008L8.11673 15.1023C8.09247 15.2485 8.01711 15.3812 7.90407 15.477C7.79103 15.5727 7.64767 15.6251 7.49954 15.625C7.46499 15.6249 7.43051 15.622 7.39642 15.6164C7.3154 15.603 7.23783 15.5737 7.16814 15.5302C7.09845 15.4868 7.03801 15.43 6.99027 15.3632C6.94253 15.2964 6.90842 15.2208 6.88991 15.1408C6.87139 15.0608 6.86882 14.9779 6.88235 14.8969L7.34329 12.1453C6.33988 11.8659 5.38845 11.4256 4.5261 10.8414L3.04642 13.4375C2.96354 13.5819 2.82668 13.6875 2.66596 13.731C2.50523 13.7745 2.3338 13.7524 2.18939 13.6695C2.04497 13.5867 1.93939 13.4498 1.89587 13.2891C1.85236 13.1283 1.87447 12.9569 1.95735 12.8125L3.51985 10.0781C2.97083 9.60418 2.46614 9.08118 2.01204 8.51562C1.92211 8.4044 1.87305 8.26569 1.87305 8.12266C1.87305 7.97962 1.92211 7.84091 2.01204 7.72969C3.48079 5.91563 6.05814 3.75 9.99954 3.75C13.9409 3.75 16.5183 5.91562 17.9855 7.73438C18.0754 7.8456 18.1245 7.98431 18.1245 8.12734C18.1245 8.27038 18.0754 8.40909 17.9855 8.52031C17.5314 9.08587 17.0267 9.60887 16.4777 10.0828L18.0417 12.8125Z" 
    fill="currentColor"/>
    </svg>
               )}
          </button>
    //       <div
    //         onClick={toggleOldPasswordVisibility}
    //       >
    //         {isOldPasswordVisible ? (
    //           <svg
    
    //             xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    //             <path d="M19.3211 9.74688C19.2937 9.68516 18.632 8.21719 17.1609 6.74609C15.2008 4.78594 12.725 3.75 9.99999 3.75C7.27499 3.75 4.79921 4.78594 2.83905 6.74609C1.36796 8.21719 0.703118 9.6875 0.678899 9.74688C0.643362 9.82681 0.625 9.91331 0.625 10.0008C0.625 10.0883 0.643362 10.1748 0.678899 10.2547C0.706243 10.3164 1.36796 11.7836 2.83905 13.2547C4.79921 15.2141 7.27499 16.25 9.99999 16.25C12.725 16.25 15.2008 15.2141 17.1609 13.2547C18.632 11.7836 19.2937 10.3164 19.3211 10.2547C19.3566 10.1748 19.375 10.0883 19.375 10.0008C19.375 9.91331 19.3566 9.82681 19.3211 9.74688ZM9.99999 13.125C9.38193 13.125 8.77774 12.9417 8.26383 12.5983C7.74993 12.255 7.34939 11.7669 7.11287 11.1959C6.87634 10.6249 6.81446 9.99653 6.93504 9.39034C7.05562 8.78415 7.35324 8.22733 7.79028 7.79029C8.22732 7.35325 8.78414 7.05562 9.39033 6.93505C9.99652 6.81447 10.6249 6.87635 11.1959 7.11288C11.7669 7.3494 12.255 7.74994 12.5983 8.26384C12.9417 8.77775 13.125 9.38193 13.125 10C13.125 10.8288 12.7958 11.6237 12.2097 12.2097C11.6236 12.7958 10.8288 13.125 9.99999 13.125Z"
    //               fill="currentColor" />
    //           </svg>
    //         ) : (
    //           <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    // <path d="M18.0417 12.8125C18.1246 12.9564 18.1469 13.1273 18.1038 13.2877C18.0606 13.448 17.9556 13.5847 17.8117 13.6676C17.7404 13.7086 17.6618 13.7352 17.5802 13.7459C17.4987 13.7565 17.4159 13.751 17.3365 13.7296C17.1761 13.6865 17.0395 13.5814 16.9566 13.4375L15.4722 10.8438C14.6099 11.427 13.6588 11.8666 12.6558 12.1453L13.1144 14.8969C13.1279 14.9779 13.1254 15.0608 13.1068 15.1408C13.0883 15.2208 13.0542 15.2964 13.0065 15.3632C12.9587 15.43 12.8983 15.4868 12.8286 15.5302C12.7589 15.5737 12.6813 15.603 12.6003 15.6164C12.567 15.6219 12.5333 15.6247 12.4995 15.625C12.3517 15.6248 12.2087 15.5721 12.096 15.4765C11.9833 15.3808 11.9081 15.2482 11.8839 15.1023L11.4331 12.4008C10.4825 12.5331 9.51814 12.5331 8.56751 12.4008L8.11673 15.1023C8.09247 15.2485 8.01711 15.3812 7.90407 15.477C7.79103 15.5727 7.64767 15.6251 7.49954 15.625C7.46499 15.6249 7.43051 15.622 7.39642 15.6164C7.3154 15.603 7.23783 15.5737 7.16814 15.5302C7.09845 15.4868 7.03801 15.43 6.99027 15.3632C6.94253 15.2964 6.90842 15.2208 6.88991 15.1408C6.87139 15.0608 6.86882 14.9779 6.88235 14.8969L7.34329 12.1453C6.33988 11.8659 5.38845 11.4256 4.5261 10.8414L3.04642 13.4375C2.96354 13.5819 2.82668 13.6875 2.66596 13.731C2.50523 13.7745 2.3338 13.7524 2.18939 13.6695C2.04497 13.5867 1.93939 13.4498 1.89587 13.2891C1.85236 13.1283 1.87447 12.9569 1.95735 12.8125L3.51985 10.0781C2.97083 9.60418 2.46614 9.08118 2.01204 8.51562C1.92211 8.4044 1.87305 8.26569 1.87305 8.12266C1.87305 7.97962 1.92211 7.84091 2.01204 7.72969C3.48079 5.91563 6.05814 3.75 9.99954 3.75C13.9409 3.75 16.5183 5.91562 17.9855 7.73438C18.0754 7.8456 18.1245 7.98431 18.1245 8.12734C18.1245 8.27038 18.0754 8.40909 17.9855 8.52031C17.5314 9.08587 17.0267 9.60887 16.4777 10.0828L18.0417 12.8125Z" 
    // fill="currentColor"/>
    // </svg>
    //            )}
    //       </div>
          }
          />
          </div>
          
          
          </div>
          <div className="Type grow w-full shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
          <TextInput 
          textType="input"
          type={isNewPasswordVisible ? "text" : "password"}
          textColor="gray"
          name="newPass"
          value={formData.newPass}
          onChange={onFormChange}
          labelText="New Password"
          placeholder="Enter New Password"
          rightIcon={<button
            onClick={toggleNewPasswordVisibility}
          >
            {isNewPasswordVisible ? (
              <svg
    
                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M19.3211 9.74688C19.2937 9.68516 18.632 8.21719 17.1609 6.74609C15.2008 4.78594 12.725 3.75 9.99999 3.75C7.27499 3.75 4.79921 4.78594 2.83905 6.74609C1.36796 8.21719 0.703118 9.6875 0.678899 9.74688C0.643362 9.82681 0.625 9.91331 0.625 10.0008C0.625 10.0883 0.643362 10.1748 0.678899 10.2547C0.706243 10.3164 1.36796 11.7836 2.83905 13.2547C4.79921 15.2141 7.27499 16.25 9.99999 16.25C12.725 16.25 15.2008 15.2141 17.1609 13.2547C18.632 11.7836 19.2937 10.3164 19.3211 10.2547C19.3566 10.1748 19.375 10.0883 19.375 10.0008C19.375 9.91331 19.3566 9.82681 19.3211 9.74688ZM9.99999 13.125C9.38193 13.125 8.77774 12.9417 8.26383 12.5983C7.74993 12.255 7.34939 11.7669 7.11287 11.1959C6.87634 10.6249 6.81446 9.99653 6.93504 9.39034C7.05562 8.78415 7.35324 8.22733 7.79028 7.79029C8.22732 7.35325 8.78414 7.05562 9.39033 6.93505C9.99652 6.81447 10.6249 6.87635 11.1959 7.11288C11.7669 7.3494 12.255 7.74994 12.5983 8.26384C12.9417 8.77775 13.125 9.38193 13.125 10C13.125 10.8288 12.7958 11.6237 12.2097 12.2097C11.6236 12.7958 10.8288 13.125 9.99999 13.125Z"
                  fill="currentColor" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M18.0417 12.8125C18.1246 12.9564 18.1469 13.1273 18.1038 13.2877C18.0606 13.448 17.9556 13.5847 17.8117 13.6676C17.7404 13.7086 17.6618 13.7352 17.5802 13.7459C17.4987 13.7565 17.4159 13.751 17.3365 13.7296C17.1761 13.6865 17.0395 13.5814 16.9566 13.4375L15.4722 10.8438C14.6099 11.427 13.6588 11.8666 12.6558 12.1453L13.1144 14.8969C13.1279 14.9779 13.1254 15.0608 13.1068 15.1408C13.0883 15.2208 13.0542 15.2964 13.0065 15.3632C12.9587 15.43 12.8983 15.4868 12.8286 15.5302C12.7589 15.5737 12.6813 15.603 12.6003 15.6164C12.567 15.6219 12.5333 15.6247 12.4995 15.625C12.3517 15.6248 12.2087 15.5721 12.096 15.4765C11.9833 15.3808 11.9081 15.2482 11.8839 15.1023L11.4331 12.4008C10.4825 12.5331 9.51814 12.5331 8.56751 12.4008L8.11673 15.1023C8.09247 15.2485 8.01711 15.3812 7.90407 15.477C7.79103 15.5727 7.64767 15.6251 7.49954 15.625C7.46499 15.6249 7.43051 15.622 7.39642 15.6164C7.3154 15.603 7.23783 15.5737 7.16814 15.5302C7.09845 15.4868 7.03801 15.43 6.99027 15.3632C6.94253 15.2964 6.90842 15.2208 6.88991 15.1408C6.87139 15.0608 6.86882 14.9779 6.88235 14.8969L7.34329 12.1453C6.33988 11.8659 5.38845 11.4256 4.5261 10.8414L3.04642 13.4375C2.96354 13.5819 2.82668 13.6875 2.66596 13.731C2.50523 13.7745 2.3338 13.7524 2.18939 13.6695C2.04497 13.5867 1.93939 13.4498 1.89587 13.2891C1.85236 13.1283 1.87447 12.9569 1.95735 12.8125L3.51985 10.0781C2.97083 9.60418 2.46614 9.08118 2.01204 8.51562C1.92211 8.4044 1.87305 8.26569 1.87305 8.12266C1.87305 7.97962 1.92211 7.84091 2.01204 7.72969C3.48079 5.91563 6.05814 3.75 9.99954 3.75C13.9409 3.75 16.5183 5.91562 17.9855 7.73438C18.0754 7.8456 18.1245 7.98431 18.1245 8.12734C18.1245 8.27038 18.0754 8.40909 17.9855 8.52031C17.5314 9.08587 17.0267 9.60887 16.4777 10.0828L18.0417 12.8125Z" 
    fill="currentColor"/>
    </svg>
               )}
          </button>}
          />
           
          </div>
        </div>
        <div className="Cta w-full sm:w-max flex-col sm:flex-row justify-start items-start gap-4 inline-flex">
       <BorderButton 
       className="w-full"
      onClick={cancelReset}
       text="Cancel"
       textColor="gray"
       disabled={changed ? false : true}
       leftIcon={<svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z"
          fill="currentColor"
          fill-opacity="0.6"
        />
      </svg>}
       />
      
        <BorderButton 
        className="w-full"
        onClick={resetPass}
        text="Save New Password"
        color="green"
        textColor="green"
        disabled={changed ? false : true}
        leftIcon={<svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
            fill="currentColor"
          />
        </svg>}
        />
       

        {showChangesSaved && (
          <div className="py-4 flex px-4">
            <div className="Checkcircle  w-5 h-5  text-emerald-500 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="SaveChanges text-emerald-500 text-sm font-bold leading-tight px-1 py-0.5">
              Changed
            </div>
          </div>
        )}
      </div>
      </div>
      </>
}
      {showDiscard && <OnSaveChangeToggle close={()=>{setShowDiscard(false)}} onSave={resetPass}/>}
     
      <otpVerify close={() => {}} addNote={() => {}} />
      {alertData && (
        <Alert alertClose={() => setAlert(null)} time={3} text={alertData} />
      )}
    </div>
    </div>
    </>
  );
};

export default ResetPass;
