import axios from "axios";

export const getChatToken = async () => {
  console.log("get token");
  const url = `${process.env.REACT_APP_baseURL}/recruiter/chat_token`;
  console.log(url)
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("auth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    localStorage.setItem("chatAuth", response?.data.token);
    // localStorage.setItem("test", "yes");
    console.log(response?.data.token);
    return response?.data.token;
  }
};

export const blockUser = async (userId) => {
  const url = `${process.env.REACT_APP_baseURL}/recruiter/block_user/${userId}`;
  const response = await axios
    .post(
      url,
      {
        email: form.email,
        password: form.password,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    console.log(response?.data.token);
  }
};

export const unblockUser = async (userId) => {
  const url = `${process.env.REACT_APP_baseURL}/recruiter/unblock_user/${userId}`;
  const response = await axios
    .post(
      url,
      {
        email: form.email,
        password: form.password,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    console.log(response?.data.token);
  }
};

export const getAllConversations = async () => {
  const url = `${process.env.REACT_APP_chatURL}/conversation`;
  let params = {
    skip: 0,
    limit: 100,
  };
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
      params,
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    return response.data;
  }
};

export const getAConversation = async (conversationId) => {
  const url = `${process.env.REACT_APP_chatURL}/conversation/${conversationId}`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    if (response?.data.messages)
      response.data.messages = response?.data.messages.reverse();
    return response?.data;
  }
};

export const readConversation = async (convoId, messageId) => {
  const url = `${process.env.REACT_APP_chatURL}/message/read`;
  const response = await axios
    .post(
      url,
      {
        conversationId: convoId,
        messageId: messageId,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("chatAuth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
  }
};

export const sendMessage = async (messageObj) => {
  const url = `${process.env.REACT_APP_chatURL}/message/private`;
  const response = await axios
    .post(url, messageObj, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
    });

  if (response?.data) {
    console.log(response?.data);
  }
};

export const getConversationId = async (userId) => {
  const url = `${process.env.REACT_APP_chatURL}/conversation/id/private/${userId}`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("chatAuth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
      return null;
    });

  if (response?.data) {
    console.log(response?.data, "get convo id");
    return response?.data;
  }
};

export const getPresignedUrl = async (messageType, mimeType, extenstion) => {
  const url = `${process.env.REACT_APP_chatURL}/message/uploadUrl`;
  const response = await axios
    .post(
      url,
      {
        messageType: messageType,
        mimeType: mimeType,
        extension: `.${extenstion}`,
      },
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("chatAuth"),
        },
      }
    )
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
        // setAlertData(error.response?.data.message);
        console.log(error.response?.data.message);
      return null;
    });

  if (response?.data) {
    console.log(response?.data, "get convo id");
    return response?.data;
  }
};

export const putObjectInUrl = async (url, file) => {
  console.log(url);

  const response = await axios.put(url, file, {
    headers: {
      "x-ms-blob-type": "BlockBlob",
    },
  }).catch((error) => {
    console.log(error.response?.data);
    if (error.response && error.response?.data.message)
      // setAlertData(error.response?.data.message);
      console.log(error.response?.data.message);
    return null;
  });

  if (response?.data) {
    console.log(response?.data, "get convo id");
    return response?.data;
  }
};

export const getFileSizeText = (size) => {
  let sizeunit = ["B", "KB", "MB", "GB"];

  let count = 0;

  while (size > 900) {
    size /= 1024;
    count++;
  }

  return `${Math.round(size * 100) / 100} ${sizeunit[count]}`;
};

export const trimFileName = (name) => {
  if (name.length < 30) return name;
  return `${name.slice(0, 10)}...${name.slice(name.length - 10, name.length)}`;
};
