import React, { useState, useEffect, useRef } from "react";

const DropDownMenu = ({ className, icon, children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click event from propagating to window
    toggleDropdown();
  };

  // Function to close the menu when a click occurs outside of it
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("click", handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const childrenWithClose = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        onClick: () => {
          toggleDropdown();
          if (child.props.onClick) {
            child.props.onClick();
          }
        },
      });
    }
    return child;
  });
  return (
    <div className={` ${className}`} {...props}>
      <button
        className={`bg-white text-white border border-gray rounded-md border border-teal-950 border-opacity-10 dropdown-toggle ${className}`}
        onClick={handleButtonClick}
      >
        {icon}
      </button>
      <div className="relative bg-white shadow rounded-xl" ref={menuRef}>
        {isOpen && (
          <div className={`absolute text-gray-900 text-opacity-70 text-[14px] font-bold leading-tight w-max mt-[-12rem] ml-[2.8rem] ${className}`}>
            {childrenWithClose}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDownMenu;
