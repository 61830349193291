import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Login } from "./auth/login";
import { deRegisterToken } from "../services/firebase";

//This is home page url
// User logged in and company created successfylly - redirect ot dashboard
// user logged in but company not created then redirect to ('/create/selectcompany')
// User not logged in - redirect ot signin
const RedirectSignIn = () => {
  const navigator = useNavigate();
  useEffect(() => {
    console.log("navigator");

        localStorage.removeItem("auth");
        navigator("/login");
    
        deRegisterToken();

    //  navigator("/login");
  }, []);

  return <div>...</div>;
};

export default RedirectSignIn;
