import axios from "axios";
import moment from "moment/moment";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getJobStatusColor, getJobStatusText } from "../../services/job";
import BeepNavbar from "../../components/navbar/navbar";
import NavigationGreenButtonWithIcon from "../../components/buttons/navigationButtonGreenIcon";
import GrayOutlineButton from "../../components/buttons/buttonWithGrayOutline";
import GrayTextInput from "../../components/inputfields/grayTextInputOuline";
import InfiniteScroll from "react-infinite-scroll-component";
import { dateCustom } from "../../services/date";
import { Mixpanel } from "../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import SearchIconButton from "../../components/inputfields/searchInputAnimated";
import "../../index.css";
import BorderButton from "../../components/buttons/UI_Buttons_2/borderButton";
import FillButtonV2 from "../../components/buttons/UI_Buttons_2/fillButton";
import Loader from "../../components/Loader/loader";

const JobDashboard = ({ navigation }) => {
  const [title, setTitle] = useState([
    { name: "Openings Title", isSortable: false },
    { name: "Actions", isSortable: false },
    { name: "Status", isSortable: false },
    { name: "Closing Date", isSortable: false },
    { name: "Type", isSortable: false },
    { name: "Date Posted", isSortable: false },
    { name: "Positions", isSortable: false },
    // { name: "Applicants", isSortable: true },
    // { name: "Views", isSortable: true },
  ]);
  const [searchJobText, setSearchJobText] = useState("");
  const [hasNext, setHasNext] = useState(false);
  const [Loading, setLoading] = useState(true); // State to track loading
  const [isChanged, setIschanged] = useState(false);

  const [filters, setFilters] = useState({
    startDate: "",
    endDate: "",
  });
  // const [nameSearch, setNameSearch] = useState("")
  const navigate = useNavigate();
  const updateJobStatus = async (jobId, status, index) => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/v3/change_job_status`;

    console.log(url);

    const response = await axios
      .post(
        url,
        {
          id: jobId,
          status,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      )
      .catch((error) => console.log(error));

    if (response?.data) {
      console.log(response?.data);
      LoadJobsOnFilter(index);
    }
  };

  const [openings, setOpenings] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [showFilter, setShowFilter] = useState(false);

  const toggleFilter = () => {
    setShowFilter((prev) => !prev);
    // setLoading(true);
  };

  // State to control loader visibility

  useEffect(() => {
    // Simulate a 2-3 second delay before showing the new opening button
    const delay = setTimeout(() => {
      setShowLoader(false);
      clearTimeout(delay);
    }, 2000); // Adjust this value to set the delay in milliseconds
  }, []);

  const LoadJobsOnFilter = async (index) => {
    setIschanged(true);
    Mixpanel.track("Filter Openings", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      distinct_id: localStorage.getItem("userId"),
      recruiter_id: localStorage.getItem("userId"),
    });
    let params = { limit: index ? index + 10 : 10 };
    // let params = { limit: 10 };

    if (name || searchJobText) params["name"] = searchJobText;
    if (filters.startDate || filters.startDate) {
      let start = new Date(
        filters.startDate ? filters.startDate : new Date().setFullYear(2000)
      ).toISOString();
      let end = new Date(
        filters.endDate ? filters.endDate : new Date()
      ).toISOString();

      params["timerange"] = `${start},${end}`;
    }

    const url = `${process.env.REACT_APP_baseURL}/recruiter/jobs`;
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
        params,
      })
      .catch((error) => console.log(error));

    if (response?.data) {
      console.log(response?.data);
      // setShowLoader(false)
      setIschanged(false);
      setOpenings(response?.data.jobs);
      setHasNext(response?.data.next);
      // setTimeout(() => {
      //   // Hide the loader after the delay
      //   setIschanged(false);

      //   setOpenings(response?.data.jobs);
      //   setHasNext(response?.data.next);
      // }, 1000);
    }
  };

  const LoadMoreJobsOnFilter = async () => {
    setIschanged(true);
    let params = { limit: 10, skip: openings.length };

    if (name || searchJobText) params["name"] = searchJobText;
    if (filters.startDate || filters.startDate) {
      let start = new Date(
        filters.startDate ? filters.startDate : new Date().setFullYear(2000)
      ).toISOString();
      let end = new Date(
        filters.endDate ? filters.endDate : new Date()
      ).toISOString();

      params["timerange"] = `${start},${end}`;
    }

    const url = `${process.env.REACT_APP_baseURL}/recruiter/jobs`;
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
        params,
      })
      .catch((error) => console.log(error));

    if (response?.data) {
      console.log(response?.data);
      setIschanged(false);
      setOpenings((prev) => {
        return [...prev, ...response?.data.jobs];
      });
      setHasNext(response?.data.next);
      // setTimeout(() => {
      //   // Hide the loader after the delay
      //   setIschanged(false);

      //   setOpenings((prev) => {
      //     return [...prev, ...response?.data.jobs];
      //   });
      //   setHasNext(response?.data.next);
      // }, 1000);
      // // setShowLoader(false);
    }
  };

  const LoadJobs = async () => {
    LoadJobsOnFilter();
  };

  const clearFilters = async () => {
    setSearchJobText("");
    setFilters({
      startDate: "",
      endDate: "",
    });
    LoadJobsOnFilter();
    //  LoadJobs();
  };

  useEffect(() => LoadJobsOnFilter(), [searchJobText]);

  // const searchJob = async () => {
  //   const url = `${process.env.REACT_APP_baseURL}/recruiter/jobs`;
  //   console.log(url);
  //   const response = await axios
  //     .get(url, {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("auth"),
  //       },
  //     })
  //     .catch((error) => console.log(error));

  //   if (response?.data) {
  //     console.log(response?.data);
  //     setOpenings(response?.data.jobs);
  //     setHasNext(response?.data.next);
  //     // localStorage.setItem("auth", response?.data.authToken);
  //     // navigator("/job/dashboard");
  //   }

  // useEffect(searchJob, [searchJobText]);
  // };

  const filterChange = async (e) => {
    const { name, value } = e.target;

    setFilters((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(LoadJobs, []);

  const searchBoxChange = (value) => {
    setSearchJobText(value);
  };

  // Your existing state and useEffect logic...

  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }
  return (
    <>
      <div
        className="text-sm h-screen overflow-scroll no-scrollbar min-w-auto md:max-w-screen gradient-50"
        style={{
          background:
            "linear-gradient(to right,rgba(22, 192, 131, 0.10) 0%, rgba(255, 218, 69, 0.10) 50%, rgba(22, 192, 131, 0.10) 100%), #FFF",
        }}
      >
        <BeepNavbar />
        <div className="header pt-16 overflow-scroll no-scrollbar">
          <div className="block md:flex p-4 md:pt-16 w-fit h-[8rem] md:w-full ">
            <h2 className="heading text-2xl font-bold w-max ">My Dashboard</h2>
            {/* <div className="w-64 h-64 opacity-20 bg-emerald-500 rounded-full blur-[256px]" /> */}

            <div className="ml-auto mr-0 flex text-sm mt-4 md:mt-0 sm:justify-evenly">
              {/* {(searchJobText || filters.startDate || filters.endDate) && (
              <button
                className="mx-2 border-2 border-gray-200 px-2  rounded-xl  text-gray-900/50 bg-white font-semibold cursor-pointer "
                onClick={clearFilters}
              >
                Clear filters
              </button>
            )} */}

              <SearchIconButton
                className="md:hidden mt-5 m-5"
                clearFilter={clearFilters}
                icon={
                  <svg
                    width="23"
                    height="23"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Input Field Icons / MagnifyingGlass">
                      <path
                        id="Vector"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.7394 18.0786C17.8152 18.0472 17.8842 18.0011 17.9422 17.943C18.0003 17.8849 18.0464 17.816 18.0779 17.7401C18.1093 17.6643 18.1255 17.5829 18.1255 17.5008C18.1255 17.4187 18.1093 17.3373 18.0779 17.2614C18.0464 17.1856 18.0003 17.1166 17.9422 17.0586L14.0313 13.1477C15.164 11.7886 15.7291 10.0452 15.6092 8.28004C15.4893 6.51491 14.6935 4.86396 13.3873 3.6706C12.0812 2.47723 10.3653 1.83332 8.59657 1.87281C6.82781 1.91229 5.14236 2.63213 3.89079 3.88259C2.63923 5.13305 1.9179 6.81787 1.87685 8.58659C1.83581 10.3553 2.4782 12.0718 3.67041 13.3789C4.86262 14.6861 6.51286 15.4834 8.27788 15.6049C10.0429 15.7264 11.7868 15.1627 13.1469 14.0313L17.0578 17.943C17.1159 18.0011 17.1848 18.0472 17.2607 18.0786C17.3366 18.1101 17.4179 18.1263 17.5 18.1263C17.5822 18.1263 17.6635 18.1101 17.7394 18.0786ZM11.8751 13.4278C10.9001 14.0459 9.86254 14.3758 8.75002 14.3758C7.25869 14.3741 5.82891 13.781 4.77438 12.7264C3.71984 11.6719 3.12668 10.2421 3.12502 8.75079C3.12502 7.63827 3.45492 6.55073 4.07301 5.6257C4.69109 4.70068 5.5696 3.97971 6.59743 3.55396C7.62526 3.12822 8.75626 3.01683 9.84741 3.23387C10.9386 3.45091 11.9408 3.98664 12.7275 4.77331C13.5142 5.55998 14.0499 6.56226 14.2669 7.6534C14.484 8.74455 14.3726 9.87555 13.9468 10.9034C13.5211 11.9312 12.8001 12.8097 11.8751 13.4278Z"
                        fill="#002032"
                        fill-opacity="0.6"
                      />
                    </g>
                  </svg>
                }
                onChangeFunction={setSearchJobText}
                value={searchJobText}
              />

              <GrayTextInput
                className="hidden md:flex"
                clearFilter={clearFilters}
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Input Field Icons / MagnifyingGlass">
                      <path
                        id="Vector"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.7394 18.0786C17.8152 18.0472 17.8842 18.0011 17.9422 17.943C18.0003 17.8849 18.0464 17.816 18.0779 17.7401C18.1093 17.6643 18.1255 17.5829 18.1255 17.5008C18.1255 17.4187 18.1093 17.3373 18.0779 17.2614C18.0464 17.1856 18.0003 17.1166 17.9422 17.0586L14.0313 13.1477C15.164 11.7886 15.7291 10.0452 15.6092 8.28004C15.4893 6.51491 14.6935 4.86396 13.3873 3.6706C12.0812 2.47723 10.3653 1.83332 8.59657 1.87281C6.82781 1.91229 5.14236 2.63213 3.89079 3.88259C2.63923 5.13305 1.9179 6.81787 1.87685 8.58659C1.83581 10.3553 2.4782 12.0718 3.67041 13.3789C4.86262 14.6861 6.51286 15.4834 8.27788 15.6049C10.0429 15.7264 11.7868 15.1627 13.1469 14.0313L17.0578 17.943C17.1159 18.0011 17.1848 18.0472 17.2607 18.0786C17.3366 18.1101 17.4179 18.1263 17.5 18.1263C17.5822 18.1263 17.6635 18.1101 17.7394 18.0786ZM11.8751 13.4278C10.9001 14.0459 9.86254 14.3758 8.75002 14.3758C7.25869 14.3741 5.82891 13.781 4.77438 12.7264C3.71984 11.6719 3.12668 10.2421 3.12502 8.75079C3.12502 7.63827 3.45492 6.55073 4.07301 5.6257C4.69109 4.70068 5.5696 3.97971 6.59743 3.55396C7.62526 3.12822 8.75626 3.01683 9.84741 3.23387C10.9386 3.45091 11.9408 3.98664 12.7275 4.77331C13.5142 5.55998 14.0499 6.56226 14.2669 7.6534C14.484 8.74455 14.3726 9.87555 13.9468 10.9034C13.5211 11.9312 12.8001 12.8097 11.8751 13.4278Z"
                        fill="#002032"
                        fill-opacity="0.6"
                      />
                    </g>
                  </svg>
                }
                onChangeFunction={setSearchJobText}
                value={searchJobText}
                placeholder="Search by Opening Title"
              />

              <GrayOutlineButton
                onClick={toggleFilter}
                className="ml-4 w-11 sm:hidden"
                icon={
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="Input Field Icons / SlidersHorizontal">
                      <path
                        id="Vector"
                        d="M2.5 6.24926C2.5 6.0835 2.56585 5.92453 2.68306 5.80732C2.80027 5.69011 2.95924 5.62426 3.125 5.62426H6.02891C6.16362 5.17299 6.44038 4.77725 6.81803 4.49587C7.19568 4.21449 7.65405 4.0625 8.125 4.0625C8.59595 4.0625 9.05432 4.21449 9.43197 4.49587C9.80962 4.77725 10.0864 5.17299 10.2211 5.62426H16.875C17.0408 5.62426 17.1997 5.69011 17.3169 5.80732C17.4342 5.92453 17.5 6.0835 17.5 6.24926C17.5 6.41502 17.4342 6.57399 17.3169 6.6912C17.1997 6.80841 17.0408 6.87426 16.875 6.87426H10.2211C10.0864 7.32553 9.80962 7.72128 9.43197 8.00266C9.05432 8.28404 8.59595 8.43603 8.125 8.43603C7.65405 8.43603 7.19568 8.28404 6.81803 8.00266C6.44038 7.72128 6.16362 7.32553 6.02891 6.87426H3.125C2.95924 6.87426 2.80027 6.80841 2.68306 6.6912C2.56585 6.57399 2.5 6.41502 2.5 6.24926ZM16.875 13.1243H15.2211C15.0864 12.673 14.8096 12.2772 14.432 11.9959C14.0543 11.7145 13.5959 11.5625 13.125 11.5625C12.6541 11.5625 12.1957 11.7145 11.818 11.9959C11.4404 12.2772 11.1636 12.673 11.0289 13.1243H3.125C2.95924 13.1243 2.80027 13.1901 2.68306 13.3073C2.56585 13.4245 2.5 13.5835 2.5 13.7493C2.5 13.915 2.56585 14.074 2.68306 14.1912C2.80027 14.3084 2.95924 14.3743 3.125 14.3743H11.0289C11.1636 14.8255 11.4404 15.2213 11.818 15.5027C12.1957 15.784 12.6541 15.936 13.125 15.936C13.5959 15.936 14.0543 15.784 14.432 15.5027C14.8096 15.2213 15.0864 14.8255 15.2211 14.3743H16.875C17.0408 14.3743 17.1997 14.3084 17.3169 14.1912C17.4342 14.074 17.5 13.915 17.5 13.7493C17.5 13.5835 17.4342 13.4245 17.3169 13.3073C17.1997 13.1901 17.0408 13.1243 16.875 13.1243Z"
                        fill="#002032"
                        fill-opacity="0.6"
                      />
                    </g>
                  </svg>
                }
              />

              <BorderButton
                onClick={toggleFilter}
                className="hidden sm:flex ml-4"
                text="Filter"
                color="gray"
                textColor="gray"
                leftIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M2.5 6.24926C2.5 6.0835 2.56585 5.92453 2.68306 5.80732C2.80027 5.69011 2.95924 5.62426 3.125 5.62426H6.02891C6.16362 5.17299 6.44038 4.77725 6.81803 4.49587C7.19568 4.21449 7.65405 4.0625 8.125 4.0625C8.59595 4.0625 9.05432 4.21449 9.43197 4.49587C9.80962 4.77725 10.0864 5.17299 10.2211 5.62426H16.875C17.0408 5.62426 17.1997 5.69011 17.3169 5.80732C17.4342 5.92453 17.5 6.0835 17.5 6.24926C17.5 6.41502 17.4342 6.57399 17.3169 6.6912C17.1997 6.80841 17.0408 6.87426 16.875 6.87426H10.2211C10.0864 7.32553 9.80962 7.72128 9.43197 8.00266C9.05432 8.28404 8.59595 8.43603 8.125 8.43603C7.65405 8.43603 7.19568 8.28404 6.81803 8.00266C6.44038 7.72128 6.16362 7.32553 6.02891 6.87426H3.125C2.95924 6.87426 2.80027 6.80841 2.68306 6.6912C2.56585 6.57399 2.5 6.41502 2.5 6.24926ZM16.875 13.1243H15.2211C15.0864 12.673 14.8096 12.2772 14.432 11.9959C14.0543 11.7145 13.5959 11.5625 13.125 11.5625C12.6541 11.5625 12.1957 11.7145 11.818 11.9959C11.4404 12.2772 11.1636 12.673 11.0289 13.1243H3.125C2.95924 13.1243 2.80027 13.1901 2.68306 13.3073C2.56585 13.4245 2.5 13.5835 2.5 13.7493C2.5 13.915 2.56585 14.074 2.68306 14.1912C2.80027 14.3084 2.95924 14.3743 3.125 14.3743H11.0289C11.1636 14.8255 11.4404 15.2213 11.818 15.5027C12.1957 15.784 12.6541 15.936 13.125 15.936C13.5959 15.936 14.0543 15.784 14.432 15.5027C14.8096 15.2213 15.0864 14.8255 15.2211 14.3743H16.875C17.0408 14.3743 17.1997 14.3084 17.3169 14.1912C17.4342 14.074 17.5 13.915 17.5 13.7493C17.5 13.5835 17.4342 13.4245 17.3169 13.3073C17.1997 13.1901 17.0408 13.1243 16.875 13.1243Z"
                      fill="currentColor"
                      fill-opacity="0.6"
                    />
                  </svg>
                }
              />

              {/* Action required btton if for next verstion */}

              {/* <RedOutlineButtonWithIcon
              className="ml-4 opacity-30 cursor-not-allowed"
              disabled
              icon={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Input Field Icons / FlagPennant">
                    <path
                      id="Vector"
                      d="M19.375 8.12536C19.3751 8.25519 19.3347 8.38182 19.2595 8.48766C19.1844 8.5935 19.0781 8.67331 18.9555 8.71599L5 13.5699V16.8754C5 17.0411 4.93415 17.2001 4.81694 17.3173C4.69973 17.4345 4.54076 17.5004 4.375 17.5004C4.20924 17.5004 4.05027 17.4345 3.93306 17.3173C3.81585 17.2001 3.75 17.0411 3.75 16.8754V3.12536C3.74994 3.0256 3.77377 2.92728 3.81948 2.8386C3.86519 2.74993 3.93147 2.67349 4.01277 2.61567C4.09407 2.55785 4.18803 2.52034 4.28679 2.50626C4.38555 2.49218 4.48625 2.50194 4.58047 2.53474L18.9555 7.53474C19.0781 7.57742 19.1844 7.65722 19.2595 7.76307C19.3347 7.86891 19.3751 7.99554 19.375 8.12536Z"
                      fill="#DB1717"
                    />
                  </g>
                </svg>
              }
              text="Action Required"
            /> */}
              <Link to="/job/create">
                <FillButtonV2
                  className="ml-4 hidden sm:flex"
                  text="New Job/Openings"
                  color="green"
                  textColor="white"
                  leftIcon={
                    <svg
                      className=""
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Input Field Icons / PlusCircle">
                        <path
                          id="Vector"
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                          fill="currentColor"
                        />
                      </g>
                    </svg>
                  }
                />
              </Link>
              <Link to="/job/create">
                <FillButtonV2
                  className="ml-4 sm:hidden"
                  color="green"
                  textColor="white"
                  leftIcon={
                    <svg
                      className=""
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g id="Input Field Icons / PlusCircle">
                        <path
                          id="Vector"
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                          fill="currentColor"
                        />
                      </g>
                    </svg>
                  }
                />
              </Link>
            </div>
          </div>
        </div>

        {/* Table */}
        <div className="bg-white h-full">
          <div class=" overflow-x-auto  sm:rounded-lg">
            {/* Row */}

            <InfiniteScroll
              hasMore={hasNext}
              // loader={<div className="mx-auto">Loading</div>}
              dataLength={openings.length}
              next={LoadMoreJobsOnFilter}
              scrollableTarget="scrollableDiv"
            >
              {isChanged ? (
                <Loader />
              ) : (
                <table class="w-full table-auto	text-sm text-left text-slate-500">
                  <thead
                    class=" text-[rgba(0, 32, 50, 0.60)] text-sm uppercase"
                    style={{
                      background:
                        "linear-gradient(0deg, rgba(0, 32, 50, 0.05) 0%, rgba(0, 32, 50, 0.05) 100%), #FFF",
                    }}
                  >
                    <tr>
                      {title &&
                        title.map((nameObj) => {
                          return !nameObj.isSortable ? (
                            <th scope="col" class="px-4 py-3">
                              {nameObj.name}
                            </th>
                          ) : (
                            <th scope="col" class="px-4 py-3">
                              <div class="flex items-center text-center">
                                <div>{nameObj.name}</div>
                                <div href="#">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="w-3 h-3 ml-1"
                                    aria-hidden="true"
                                    fill="currentColor"
                                    viewBox="0 0 320 512"
                                  >
                                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z" />
                                  </svg>
                                </div>
                              </div>
                            </th>
                          );
                        })}
                    </tr>
                  </thead>

                  <tbody>
                    <>
                      {openings.length != 0 &&
                        openings.map((rowData, index) => (
                          <tr
                            className="bg-white border-b w-screen border-slate-200  "
                            key={index}
                          >
                            <th
                              scope="row"
                              className=" text-left w-fit font-[700] text-sm text-slate-900 whitespace-nowrap py-6 px-4"
                            >
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  Mixpanel.track("View Job Details", {
                                    date: moment().format(),
                                    browser: browserName,
                                    mobile_device: isMobileOnly,
                                    distinct_id: localStorage.getItem("userId"),
                                    recruiter_id:
                                      localStorage.getItem("userId"),
                                    job_id: rowData?._id,
                                    job_title: rowData?.job_profile,
                                  });
                                  navigate(`/job/opening/${rowData?._id}`);
                                }}
                              >
                                {" "}
                                {rowData?.job_profile}
                              </div>
                            </th>
                            <td className="p-2 text-left min-w-fit">
                              {rowData.status == 1 && (
                                <div
                                  // className="border-2 inline-flex border-emerald-500 font-semibold rounded-xl px-[5px] py-1 text-emerald-500 w-max"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    Mixpanel.track("View Applicants", {
                                      date: moment().format(),
                                      browser: browserName,
                                      mobile_device: isMobileOnly,
                                      distinct_id:
                                        localStorage.getItem("userId"),
                                      recruiter_id:
                                        localStorage.getItem("userId"),
                                      job_id: rowData?._id,
                                      job_title: rowData?.job_profile,
                                    });
                                    navigate(`/job/candidate/${rowData?._id}`);
                                  }}
                                >
                                  <BorderButton
                                    className={`min-w-[200px] justify-evenly
                            
                            `}
                                    text="View Applications"
                                    color="green"
                                    textColor="green"
                                    rightIcon={
                                      <div className="flex justify-center">
                                        <span className="rounded-3xl w-auto h-auto bg-green-500 px-2 py-1 inline-flex text-center text-white">
                                          <div className="text-center text-white text-[10px] font-bold font-['Satoshi Variable'] leading-[10px]">
                                            {rowData?.applicant_count}
                                          </div>
                                        </span>
                                      </div>
                                    }
                                  />
                                </div>
                              )}
                              {rowData.status == 2 && (
                                <Link to={`/job/candidate/${rowData?._id}`}>
                                  <BorderButton
                                    className="min-w-[200px] flex justify-center"
                                    text="View Applications"
                                    color="gray"
                                    textColor="gray"
                                  />
                                </Link>
                              )}
                              {rowData?.status == 0 && (
                                <BorderButton
                                  onClick={() =>
                                    updateJobStatus(rowData?._id, 1, index)
                                  }
                                  className="min-w-[200px] flex justify-center"
                                  text=" Activate job"
                                  color="gray"
                                  textColor="gray"
                                />
                                // <button
                                //   className="border-2 border-gray-900/60 hover:shadow hover:opacity-60 text-gray-900/60 font-semibold rounded-xl px-2 py-2  text-center  min-w-[200px]"
                                //   onClick={() =>
                                //     updateJobStatus(rowData?._id, 1, index)
                                //   }
                                // >
                                //   Activate job
                                // </button>
                              )}
                            </td>
                            <td className="p-3">
                              {rowData?.status == 0 && (
                                <div className="Pill w-[70px] h-[22px] px-2 py-1 bg-gray-900 bg-opacity-5 rounded-3xl border border-gray-900 border-opacity-20 justify-start items-center gap-1 inline-flex border-dashed">
                                  <div className="Paused text-gray-900 text-opacity-60 text-sm font-bold leading-[14px]">
                                    Paused
                                  </div>
                                </div>
                              )}

                              {rowData?.status == 1 && (
                                <div className="Pill w-[63px] h-[22px] px-2 py-1 bg-emerald-500 bg-opacity-5 rounded-3xl border border-emerald-500 border-opacity-40 justify-start items-center gap-1 inline-flex ">
                                  <div className="Active text-emerald-500 text-sm font-bold leading-[14px]">
                                    Active
                                  </div>
                                </div>
                              )}

                              {rowData?.status == 2 && (
                                <div className="flex">
                                  <div className="Pill w-[66px] h-[22px] px-2 py-1 bg-gray-900 bg-opacity-5 rounded-3xl justify-start items-center gap-1 inline-flex">
                                    <div className="Closed text-gray-900 text-opacity-40 text-sm font-bold leading-[14px]">
                                      Closed
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                            <td class="px-4 text-left w-fit font-semibold ">
                              {rowData?.job_date_of_closing
                                ? dateCustom(
                                    rowData?.job_date_of_closing,
                                    "D MMM 'YY"
                                  )
                                : "None"}
                            </td>
                            <td class="px-2 text-left w-fit font-semibold ">
                              {rowData?.job_type}
                            </td>
                            <td class="px-4 text-left w-fit font-semibold ">
                              {dateCustom(rowData?.created_at, "D MMM 'YY")}
                            </td>
                            <td class="px-4 text-left text-[rgba(0, 32, 50, 0.60)] w-fit font-semibold ">
                              {rowData?.job_openings
                                ? `${
                                    rowData?.filledPosition
                                      ? rowData?.filledPosition
                                      : "-"
                                  } / ${rowData?.job_openings}`
                                : "None"}
                            </td>
                            {/* <td class="px-2 text-left text-[rgba(0, 32, 50, 0.60)] w-fit ">
                      {rowData?.appledCount ?? "None"}
                    </td>
                    <td class="px-2 text-left text-[rgba(0, 32, 50, 0.60)] w-fit ">
                      {rowData?.listingViews ?? "None"}
                    </td> */}
                          </tr>
                        ))}
                    </>
                  </tbody>
                </table>
              )}
            </InfiniteScroll>

            {showLoader && openings.length === 0 && openings && isChanged ? (
              <div
                className="flex items-center justify-center h-auto"
                style={{ minHeight: "70vh" }} // Adjust the minimum height as needed
              >
                <Loader />
                {/* <div className="bg-[url('../public/spinner.gif')] bg-cover h-[70px] w-[70px] mx-auto"></div> */}
              </div>
            ) : (
              <div
                className={`font-bold text-center w-screen my-4 text-gray-900 opacity-[1.5] ${
                  isChanged || openings.length != 0 ? "hidden" : ""
                }`}
              >
                <div className="bg-[url('../public/NewPostIcon.png')] bg-cover w-36 h-36 xl:w-48 xl:h-48 m-auto"></div>
                <div className="text-center text-teal-950 text-base font-bold leading-none">
                  Post a Job or Internship to get started!
                </div>
                <div className="my-4">
                  <NavigationGreenButtonWithIcon
                    className="ml-4"
                    track={true}
                    onClick={() => {
                      Mixpanel.track("View Job Details", {
                        date: moment().format(),
                        browser: browserName,
                        mobile_device: isMobileOnly,
                        distinct_id: localStorage.getItem("userId"),
                        recruiter_id: localStorage.getItem("userId"),
                      });
                      navigate(`/job/create`);
                    }}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Input Field Icons / PlusCircle">
                          <path
                            id="Vector"
                            d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    }
                    text={"New Job/Openings"}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Modals */}

        {showFilter && (
          <div
            class="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div class="fixed inset-0  bg-white bg-opacity-80 backdrop-blur-[4px] transition-opacity"></div>

            <div class="fixed inset-0 z-10 overflow-y-auto">
              <div class="flex items-end justify-center h-screen p-4 text-center items-center p-0">
                <div class="relative transform overflow-hidden w-full  my-auto rounded-lg bg-white text-left shadow-xl transition-all px-8 py-8 sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="flex mb-8">
                    <div className="w-[284px] text-teal-950 text-2xl font-bold leading-normal">
                      Filter Posts
                    </div>
                    <button
                      className="ml-auto mr-0 text-gray-300 "
                      onClick={toggleFilter}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={3}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>

                  <div className="form">
                    {/* Type of opp */}
                    {/* <div className="flex flex-col my-2">
                    <div className="lable mt-1 mb-2 text-sm">
                      Type of Opportunity
                    </div>
                    <div>
                      <ul class="flex ">
                        <li className="mr-1">
                          <input
                            type="radio"
                            id="fullTime"
                            name="OpportunityType"
                            value="fullTime"
                            class="hidden peer"
                          />
                          <label
                            for="fullTime"
                            class="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-emerald-500 peer-checked:text-emerald-500 hover:text-gray-600 hover:bg-gray-100 "
                          >
                            <div class="block">
                              <div class="w-full font-semibold">Full-time</div>
                            </div>
                          </label>
                        </li>
                        <li className="mr-1">
                          <input
                            type="radio"
                            id="part-time"
                            name="OpportunityType"
                            value="part-time"
                            class="hidden peer"
                          />
                          <label
                            for="part-time"
                            class="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-emerald-500 peer-checked:text-emerald-500 hover:text-gray-600 hover:bg-gray-100 "
                          >
                            <div class="block">
                              <div class="w-full font-semibold">Part-time</div>
                            </div>
                          </label>
                        </li>
                        <li className="mr-1">
                          <input
                            type="radio"
                            id="internship"
                            name="OpportunityType"
                            value="internship"
                            class="hidden peer"
                          />
                          <label
                            for="internship"
                            class="inline-flex items-center justify-between w-full p-2 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer peer-checked:border-emerald-500 peer-checked:text-emerald-500 hover:text-gray-600 hover:bg-gray-100 "
                          >
                            <div class="block">
                              <div class="w-full  font-semibold">
                                Internship
                              </div>
                            </div>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div> */}

                    {/* Status */}
                    {/* <div>
                  <select data-te-select-init multiple>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                    <option value="4">Four</option>
                    <option value="5">Five</option>
                    <option value="6">Six</option>
                    <option value="7">Seven</option>
                    <option value="8">Eight</option>
                  </select>
                  <label data-te-select-label-ref>Example label</label>
                </div> */}

                    {/* date 1 */}
                    <div className="flex flex-col mb-4">
                      <label className="w-[343px] text-teal-950 text-sm font-medium leading-tight mb-4">
                        Date of creation - From
                      </label>
                      <input
                        type="datetime-local"
                        name="startDate"
                        onChange={filterChange}
                        value={filters.startDate}
                        id=""
                        className="outline outline-gray-200 px-2 py-2 rounded-lg"
                      />
                    </div>

                    {/* date 2 */}
                    <div className="flex flex-col mb-8">
                      <label className="doc mb-4">To</label>
                      <input
                        type="datetime-local"
                        name="endDate"
                        value={filters.endDate}
                        onChange={filterChange}
                        id=""
                        className="outline outline-gray-200 px-2 py-2 rounded-lg"
                      />
                    </div>
                    <div>
                      <button
                        className="hover:bg-emerald-500 hover:text-white shadow border  border-emerald-500 rounded-lg text-emerald-500 my-2 px-2 py-2 w-full flex"
                        onClick={() => {
                          setShowFilter(false);
                          LoadJobsOnFilter();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                          />
                        </svg>
                        <div className="mx-2">Apply filter</div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default JobDashboard;
