import axios from "axios";

export const getJobStatusText = (status) => {
  switch (status) {
    case 0:
      return "Hold";
    case 1:
      return "Active";
    default:
      return "Closed";
  }
};

export const getJobStatusColor = (status) => {
  switch (status) {
    case 0:
      return " border-2 border-slate-500 text-slate-500 ";
    case 1:
      return " border-2 border-emerald-500 text-emerald-500 ";
    default:
      return " border-2 border-dashed border-red-500 text-red-500 ";
  }
};

export const getUserDetails = async () => {
  const url = `${process.env.REACT_APP_baseURL}/recruiter/verify`;
  const response = await axios
    .get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("auth")}`,
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
    });

  if (response) {
    // localStorage.setItem("isPhoneVerified", response?.data?.isPhoneVerified);
    console.log(response?.data)
    return response?.data;
  }
};

export const JobProfileApiCall = async (query) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_baseURL}/user/job_profiles?query=${query}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    );
    if (response.data?.jobProfiles) {
      console.log(
        response.data?.jobProfiles.map((index) => ({
          value: index._id,
          label: index.job_profile,
        }))
      );
      return response.data?.jobProfiles.map((index) => ({
        value: index._id,
        label: index.job_profile,
      }));
    }
    console.log(response.data, "getJobProfiles");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const DownloadAllResumeApi = async (email, jobid) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_baseURL}/recruiter/resume/${jobid}`, 
      {
        email: email,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("auth")}`,
        },
      }
    );
    if (response.data) {
      console.log( response.data, "Response" );
      return response.data;
    }
    
  } catch (error) {
    console.log(error);
  }
};