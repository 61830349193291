import React from "react";

const QuestionsCard = ({ jobDetails }) => {
  // console.log(jobDetails.questions, "What is your name")
  const questions = ["What is your name?", "How are you?"];
  return (
    <div className="flex mt-4 p-8 bg-white rounded-3xl">
      <div className=" OpeningDetails w-1/6 text-gray-900 text-[16px] font-bold leading-normal mr-4">
        Questions
      </div>
      <div className="w-5/6 mx-4">
      {jobDetails.questions &&
  jobDetails.questions.map((que) => (
    <>
    {(que?.question !== "") &&
    <>
      <div className="mb-2 Posted text-gray-900 text-opacity-40 text-sm font-medium leading-tight">
        • {que?.question}
      </div>
      {que?.options && que.options.length > 0 ? (
        <div className="ml-4 mb-2 Posted text-gray-900 text-opacity-40 text-sm font-medium leading-tight">
         Options: {que.options.map((option, index) => `${index + 1}) ${option}`).join(' ')}
        </div>
      ) : (
        // Delete the options property if it doesn't exist or is empty
        (() => {
          delete que.options;
          return null;
        })()
      )}
      </>
}
    </>
  ))}

      </div>
    </div>
  );
};

export default QuestionsCard;
