import moment from "moment";
import React from "react";

const RightSideExperience = ({ userInfo: { applicant_info } }) => {
  return (
    <div className=" bg-white rounded-3xl">
      {/* <div className="rounded-lg  bg-white px-2 py-2">
        <div className=" flex mx-4 mb-4">
          <div className="w-3/12">
            <div className="font-bold ">Experience</div>
          </div>
          <div className="w-9/12 flex flex-col">
            {applicant_info.work_profile.experience.map((data) => (
              <div className="w-8/12 flex mb-2">
                <div className="w-6/12 mx-2 text-slate-400">
                  <span> {moment(data.startDate).format("MMM")}</span>
                  <span>-</span>
                  <span>
                    {" "}
                    {data.endDate
                      ? moment(data.endDate).format("MMM, yyyy")
                      : "Present"}
                  </span>
                </div>
                <div className="w-6/12 mx-2 flex flex-col">
                  <div className="font-semibold">{data.title}</div>
                  <div className="text-slate-700">{data.companyName}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}

      <div className="Exp w-full mt-2 p-8  rounded-xl justify-start items-start gap-4 sm:gap-8 inline-flex flex-col xl:flex-row">
        <div className="Experience1Relevant w-1/6 mr-8">
          <span className="text-gray-900 text-[16px] font-bold leading-normal">
            Experience:
            <br />
          </span>
          <span className="text-emerald-500 text-[16px] font-medium leading-normal hidden">
            1 Relevant
          </span>
        </div>
        <div className="Details w-5/6  flex-col justify-start items-start gap-6 inline-flex">
          {applicant_info?.work_profile?.experience.map((data) => (
            <div className="Row self-stretch justify-start items-start gap-6 sm:inline-flex">
              <div className="JanMar2023 w-40 text-gray-900 text-opacity-40 text-[14px] font-medium leading-tight my-4 sm:my-0">
                {moment(data?.startDate).format("MMM, yyyy")} -{" "}
                {data?.endDate
                  ? moment(data.endDate).format("MMM, yyyy")
                  : "Present"}
              </div>
              <div className="Txt grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                <div className="Highlight   justify-start items-start gap-2 inline-flex">
                  <div className="SocialMediaIntern text-gray-900 text-[14px] font-bold leading-tight">
                    {data?.title}
                  </div>
                </div>
                <div className="Highlight  justify-start items-start gap-2 inline-flex">
                  <div className="Watconsult text-gray-900 text-[14px] font-medium leading-tight">
                    {data?.companyName}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RightSideExperience;
