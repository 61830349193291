import React, { useEffect, useRef, useState } from "react";
// import BeepNavbar from "../../components/navbar/navbar";
// import { io } from "socket.io-client";
// import { w3cwebsocket as W3CWebSocket } from "websocket";
import { useNavigate } from "react-router-dom";
import ChatBox from "./chatBox";
import "./message.css";
import ButtonChild from "../../../components/buttons/UI_Buttons_2/buttonChild";
import {
  getAConversation,
  getAllConversations,
  getChatToken,
  getConversationId,
  getFileSizeText,
  getPresignedUrl,
  putObjectInUrl,
  readConversation,
  sendMessage,
  trimFileName,
} from "../../../services/chat";
import moment from "moment";
import { Link } from "react-router-dom";
import "../../../index.css"
import Loader from "../../../components/Loader/loader";

const MessageComp = ({ newUser }) => {
  const lastMessageRef = useRef();
  const imageBUttonRef = useRef();
  const videoBUttonRef = useRef();
  const fileBUttonRef = useRef();
  const videoPlayerRef = useRef();
  const attachmentRef = useRef();
  const [conversations, setConversations] = useState({ conversations: [] });
  const [conversationIds, setConversationIds] = useState([]);
  const [conversationIdsMap, setConversationIdsMap] = useState({});
  const [activeFile, setActiveFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  // const [currentSocket, setCurrentSocket] = useState(null);

  const [textFiled, setTextField] = useState("");
  const [isChatboxOpen, setIsChatboxOpen] = useState(false);
  const [activeConvo, setActiveConvo] = useState("");
  const [conversationMessage, setConversationMessage] = useState({
    messages: [],
  });
  const [activeUserInfo, setActiveUserInfo] = useState();

  const [userId, setUserId] = useState("647872448cc6099087b62ca7");

  const [latestMsg, setLatestMsg] = useState({});

  const navigate = useNavigate();


  const onRecivingMsg = async () => {
    // printState(data);
    let msg = latestMsg;
    if (msg.conversationId == null || msg.conversationId == undefined) return;

    //check in convo,
    // load new convo

    if (msg.conversationId == activeConvo) {
      setConversationMessage((prev) => {
        let messages =
          prev.messages.length == 0 ||
            prev.messages[prev.messages.length - 1]._id != msg._id
            ? [...prev.messages, msg]
            : [...prev.messages];
        console.log("updated msg", messages);

        //---------update read msg
        readConversation(msg.conversationId, msg._id);
        return {
          ...prev,
          messages,
        };
      });
    } else {
      const conv = await getAllConversations();
      if (conv) setConversations(conv);

      let convoIdsMap = {};
      conv.conversations.map((data, index) => {
        convoIdsMap[data.conversationId] = index;
      });

      setConversationIdsMap(convoIdsMap);
    }
  };

  const clearNewMsg = () => {
    setActiveFile(null);
  };

  useEffect(onRecivingMsg, [latestMsg]);

  // const onRec = (data) => onRecivingMsg.bind(data, null);

  const connectSocet = async () => {
    const token = await getChatToken();

    const url = `${process.env.REACT_APP_socketURL}/?token=${token}`;
    const socket = new WebSocket(url);

    // setCurrentSocket(socket);

    socket.addEventListener("open", () => {
      console.log("WebSocket connection established");
    });

    socket.onmessage = (event, activeConvo) => {
      setLatestMsg(JSON.parse(event.data));
    };

    socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed", event.code, event.reason);
    });
  };

  const onConversationSelect = async (convoId) => {
    setActiveConvo(convoId);
    setInConversation(true);
    console.log(
      conversations?.conversations,
      conversations?.conversations[conversationIdsMap[convoId]]?.userInfo
    );
    // conversationIdsMap[convoId] != undefined &&
    // conversations?.conversations[conversationIdsMap[convoId]] != undefined &&
    // conversations?.conversations[conversationIdsMap[convoId]]?.convo?.userInfo)

    if (
      conversations?.conversations != undefined &&
      conversationIdsMap[convoId] != undefined &&
      conversations?.conversations[conversationIdsMap[convoId]] != undefined &&
      conversations?.conversations[conversationIdsMap[convoId]]?.userInfo
    )
      setActiveUserInfo(
        conversations?.conversations[conversationIdsMap[convoId]]?.userInfo
      );

    setConversations((prev) => {
      let temp = { ...prev };
      temp.conversations[conversationIdsMap[convoId]].unreadMessageCount = 0;
      return temp;
    });
    setConversationMessage(await getAConversation(convoId));
  };

  // useEffect(() => {
  //   console.log(
  //     conversationIdsMap[activeConvo],
  //     conversationIdsMap,
  //     activeConvo,
  //     conversations[conversationIdsMap[activeConvo]]
  //   );
  //   if (
  //     conversationIdsMap[activeConvo] &&
  //     conversations[conversationIdsMap[activeConvo]]?.convo?.userInfo
  //   )
  //     setActiveUserInfo(
  //       conversations[conversationIdsMap[activeConvo]]?.convo?.userInfo
  //     );
  //   else setActiveUserInfo(conversationMessage?.messages[0]?.senderInfo);
  // }, [conversationMessage]);

  const downloadWithName = (uri, name) => {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click();
  };

  const openForthisUser = async () => {
    if (localStorage.getItem("openChat")) {
      let user = JSON.parse(localStorage.getItem("openChat"));
      try {
        user.avatar = { small: user.avatar };
      } catch (e) { }
      setActiveUserInfo(user);
      const convoIdObj = await getConversationId(user._id);

      if (convoIdObj && convoIdObj.conversationId) {
        console.log(convoIdObj.conversationId);
        setActiveConvo(convoIdObj.conversationId);
        const msgsTemp = await getAConversation(convoIdObj.conversationId);
        console.log(msgsTemp);
        setConversationMessage(msgsTemp);
      }
      localStorage.removeItem("openChat");
    }
  };
  useEffect(async () => {
    connectSocet();
    const conv = await getAllConversations();
    if (conv) {
      setConversations(conv);
      openForthisUser();

      let convoIdsMap = {};
      conv.conversations.map((data, index) => {
        convoIdsMap[data.conversationId] = index;
      });

      setConversationIdsMap(convoIdsMap);
    }
  }, []);

  // Received message from server: {"createdAt":"2023-07-07T04:37:30.412Z",
  // "localId":""
  // "_id":"64a7968af5053b1f7ac0b092",
  // "messageType":"text",
  // "text":"yes",
  // "from":"647872448cc6099087b62ca7",
  // "conversationType":"private",
  // "conversationId":"64a7967ff5053b1f7ac0b08d",
  // "senderInfo":{"_id":"647872448cc6099087b62ca7","name":"Hari  Prasath"}}

  const scrollToElement = () => {
    lastMessageRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToElement();
  }, [latestMsg, conversationMessage]);

  useEffect(() => {
    clearNewMsg(null);
    setTextField("");
  }, [activeConvo]);

  const sendMsg = async () => {
    setIsUploading(true);
    let data = {
      conversationType: "private",
      // "toUser":"647872448cc6099087b62ca7",
      messageType: "text", //"audio, video, image, file, gif, text"
      text: textFiled,
      // "mediaUrl":"https://helpx.adobe.com/content/dam/help/en/photoshop/using/convert-color-image-black-white/jcr_content/main-pars/before_and_after/image-before/Landscape-Color.jpg",

      localId: "",
    };

    if (activeFile) {
      let mimeType = activeFile.type;
      let name = activeFile.name;
      let extenstion =
        activeFile.name.split(".")[activeFile.name.split(".").length - 1];

      let messageType;
      if (activeFile.type.slice(0, 5) == "video") {
        messageType = "video";
      } else if (activeFile.type.slice(0, 5) == "image") {
        messageType = "image";
      } else messageType = "file";

      const urlResponse = await getPresignedUrl(
        messageType,
        mimeType,
        extenstion
      );

      console.log(urlResponse, activeFile);
      const url = urlResponse.url;

      await putObjectInUrl(url, activeFile);

      data.messageType = messageType;
      data.mediaUrl = url;
      data.metadata = {
        name: activeFile.name,
        size: activeFile.size,
      };
      console.log(data, "inside");
    }

    data["toUser"] = activeUserInfo._id;

    console.log(data);

    await sendMessage(data);
    setTextField("");
    if (activeFile) clearNewMsg();
    setIsUploading(false);
  };

  useEffect(() => {
    if (attachmentRef) attachmentRef.current?.scrollIntoView();
  }, [activeFile]);

  const [selectedUserId, setSelectedUserId] = useState(""); // New state variable to store the selected user ID

  // ... (other code)

  const selectUser = (userId) => {
    setSelectedUserId(userId); // Update the selected user ID
  };

  // State to track whether the user is in a conversation or not
  const [inConversation, setInConversation] = useState(false);

  // Function to handle going back to the chat list
  const handleGoBack = () => {
    setInConversation(false);
  };


  const [Loading, setLoading] = useState(true); // State to track loading

  // Your existing state and useEffect logic...
  
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };
  
    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount
  
  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }
  return (
    <div>
      <div
        className="4 w-screen h-screen flex-col justify-center items-center inline-flex overflow-hidden no-scrollbar"
        style={{
          background:
            "linear-gradient(180deg, rgba(22, 192, 131, 0.10) 0%, rgba(255, 218, 69, 0.10) 50%, rgba(22, 192, 131, 0.10) 100%), #FFF",
        }}
      >

        <div className="Listing w-screen  grow shrink basis-0 bg-white justify-start items-start inline-flex overflow-scroll no-scrollbar">
          {!inConversation ? (
            <div className={`Listing w-full sm:min-w-[35%] sm:max-w-[40%] mt-[3.2rem] overflow-scroll no-scrollbar self-stretch border-[0.5px] border-gray-900 border-opacity-10 flex-col justify-start items-start inline-flex`}

            >
              <div className="Title w-full  p-4 border-[0.5px] border-gray-900 border-opacity-10 flex-col justify-end items-start inline-flex">
              <div  onClick={() => navigate('/')}>
              <ButtonChild
                text="Go to Dashboard"
                textColor="gray"
                leftIcon={
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M5.80781 10.4427L12.0578 16.6927C12.1452 16.7802 12.2566 16.8398 12.3779 16.8639C12.4992 16.8881 12.625 16.8757 12.7392 16.8284C12.8535 16.781 12.9512 16.7009 13.0198 16.598C13.0885 16.4951 13.1251 16.3742 13.125 16.2505V3.75049C13.1251 3.62681 13.0885 3.50587 13.0198 3.403C12.9512 3.30013 12.8535 3.21995 12.7392 3.1726C12.625 3.12526 12.4992 3.11288 12.3779 3.13704C12.2566 3.16119 12.1452 3.2208 12.0578 3.3083L5.80781 9.5583C5.7497 9.61635 5.7036 9.68528 5.67215 9.76115C5.6407 9.83703 5.62451 9.91836 5.62451 10.0005C5.62451 10.0826 5.6407 10.164 5.67215 10.2398C5.7036 10.3157 5.7497 10.3846 5.80781 10.4427Z" 
                  fill="currentColor" fill-opacity="0.6" 
                  />
                </svg>
                }
              />
              
              </div>
                <div className="MyMessages block w-max sm:w-fit text-gray-900 text-[32px] font-bold leading-normal">
                  My Messages
                </div>
                {/* <div className="ButtonLarge w-[368px] p-4 bg-white  rounded-xl border  border-gray-900 border-opacity-10 justify-start items-center gap-2 inline-flex">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  className="InputFieldIconsSlidershorizontal  w-5 h-5 "
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Input Field Icons / SlidersHorizontal">
                    <path
                      id="Vector"
                      d="M2.5 6.24926C2.5 6.0835 2.56585 5.92453 2.68306 5.80732C2.80027 5.69011 2.95924 5.62426 3.125 5.62426H6.02891C6.16362 5.17299 6.44038 4.77725 6.81803 4.49587C7.19568 4.21449 7.65405 4.0625 8.125 4.0625C8.59595 4.0625 9.05432 4.21449 9.43197 4.49587C9.80962 4.77725 10.0864 5.17299 10.2211 5.62426H16.875C17.0408 5.62426 17.1997 5.69011 17.3169 5.80732C17.4342 5.92453 17.5 6.0835 17.5 6.24926C17.5 6.41502 17.4342 6.57399 17.3169 6.6912C17.1997 6.80841 17.0408 6.87426 16.875 6.87426H10.2211C10.0864 7.32553 9.80962 7.72128 9.43197 8.00266C9.05432 8.28404 8.59595 8.43603 8.125 8.43603C7.65405 8.43603 7.19568 8.28404 6.81803 8.00266C6.44038 7.72128 6.16362 7.32553 6.02891 6.87426H3.125C2.95924 6.87426 2.80027 6.80841 2.68306 6.6912C2.56585 6.57399 2.5 6.41502 2.5 6.24926ZM16.875 13.1243H15.2211C15.0864 12.673 14.8096 12.2772 14.432 11.9959C14.0543 11.7145 13.5959 11.5625 13.125 11.5625C12.6541 11.5625 12.1957 11.7145 11.818 11.9959C11.4404 12.2772 11.1636 12.673 11.0289 13.1243H3.125C2.95924 13.1243 2.80027 13.1901 2.68306 13.3073C2.56585 13.4245 2.5 13.5835 2.5 13.7493C2.5 13.915 2.56585 14.074 2.68306 14.1912C2.80027 14.3084 2.95924 14.3743 3.125 14.3743H11.0289C11.1636 14.8255 11.4404 15.2213 11.818 15.5027C12.1957 15.784 12.6541 15.936 13.125 15.936C13.5959 15.936 14.0543 15.784 14.432 15.5027C14.8096 15.2213 15.0864 14.8255 15.2211 14.3743H16.875C17.0408 14.3743 17.1997 14.3084 17.3169 14.1912C17.4342 14.074 17.5 13.915 17.5 13.7493C17.5 13.5835 17.4342 13.4245 17.3169 13.3073C17.1997 13.1901 17.0408 13.1243 16.875 13.1243Z"
                      fill="#002032"
                      fill-opacity="0.6"
                    />
                  </g>
                </svg>

                <div className="ButtonText grow shrink basis-0 text-gray-900 text-opacity-60 text-[14px] font-bold leading-tight">
                  Filter by Opening
                </div>
                <div className="InputFieldIconsCaretdown  w-5 h-5 " />
              </div> */}
              </div>

              <div className="Internship self-stretch w-screen sm:w-auto grow shrink basis-0 border-b-[0.5px] border-gray-900 border-opacity-10 flex-col justify-start items-start flex overflow-overlay no-scrollbar">
                {conversations?.conversations?.map((convo) => (
                  <
                    div
                    className={`Contact self-stretch p-4 border-[0.5px] border-gray-900 border-opacity-10 justify-start items-start gap-4 inline-flex ${convo?.conversationId == activeConvo
                      ? "bg-emerald-500/10"
                      : ""
                      }`}
                    onClick={() => {
                      onConversationSelect(convo?.conversationId);
                      selectUser(convo?.userInfo?._id);

                    }}
                  >
                    <div className="Pfp w-12 h-12 bg-white rounded-full justify-center items-center flex rounded-full" >
                      <img
                        className="Rectangle w-12 h-12 rounded-full"
                        src={
                          convo?.userInfo?.avatar?.small
                            ? convo?.userInfo?.avatar?.small
                            : `https://ui-avatars.com/api/?name=${convo?.userInfo?.name}`
                        }
                      />
                    </div>
                    <div className="AllTxt grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex cursor-pointer">
                      <div className="NameTime self-stretch justify-start items-start gap-4 inline-flex">
                        <div className="RajKumarRoy grow shrink basis-0 text-gray-900 text-[16px] font-bold leading-normal">
                          {convo?.userInfo?.name}
                        </div>
                        <div className="34Pm text-right text-gray-900 text-[14px] font-medium leading-tight">
                          {moment(convo?.lastMessage?.createdAt).format(
                            "hh:MM A"
                          )}
                        </div>
                      </div>
                      {/* <div className="ShortlistSocialMediaIntern self-stretch text-gray-900 text-[14px] font-medium leading-tight">
                      Shortlist: Social Media Intern
                    </div> */}
                      <div className="flex w-full">
                        <div className="HiThankYouForYourInvitationIAmInterestedInThisJobPleaseLetMeKnowRegardingNextStepsRegardsRaj self-stretch text-gray-900 opacity-40 text-[14px] font-medium leading-tight">
                          {convo?.lastMessage?.senderInfo?._id == userId &&
                            "You : "}{" "}
                          {convo?.lastMessage?.messageType == "text"
                            ? convo?.lastMessage?.text
                            : convo?.lastMessage?.messageType}
                        </div>
                        {convo?.unreadMessageCount > 0 && (
                          <div className="ml-auto mr-10  bg-emerald-500 text-white p-1 rounded-full text-[14px] font-medium leading-tight">
                            {convo?.unreadMessageCount}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                ))}
                {(!conversations ||
                  !conversations?.conversations ||
                  conversations?.conversations.length == 0) && (
                    <div className="mx-auto font-semibold mt-24 text-opacity-50">
                      {" "}
                      No Conversations
                    </div>
                  )}
              </div>

            </div>
          ) : (
            
            <div>
              <button onClick={handleGoBack}>
                <div className="sm:hidden mb-4 mt-[3.8rem] mx-2 fixed" style={{ "z-index": "9" }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M5.80781 10.4427L12.0578 16.6927C12.1452 16.7802 12.2566 16.8398 12.3779 16.8639C12.4992 16.8881 12.625 16.8757 12.7392 16.8284C12.8535 16.781 12.9512 16.7009 13.0198 16.598C13.0885 16.4951 13.1251 16.3742 13.125 16.2505V3.75049C13.1251 3.62681 13.0885 3.50587 13.0198 3.403C12.9512 3.30013 12.8535 3.21995 12.7392 3.1726C12.625 3.12526 12.4992 3.11288 12.3779 3.13704C12.2566 3.16119 12.1452 3.2208 12.0578 3.3083L5.80781 9.5583C5.7497 9.61635 5.7036 9.68528 5.67215 9.76115C5.6407 9.83703 5.62451 9.91836 5.62451 10.0005C5.62451 10.0826 5.6407 10.164 5.67215 10.2398C5.7036 10.3157 5.7497 10.3846 5.80781 10.4427Z" fill="#002032" fill-opacity="0.6" />
                  </svg>
                </div>
              </button>
              {activeConvo && (

                <div className="chatbox-mobile">
                  <ChatBox
                    activeConvo={activeConvo}
                    conversationMessage={conversationMessage}
                    activeUserInfo={activeUserInfo}
                    sendMsg={sendMsg}
                    selectedUserId={selectedUserId}
                    conversationIdsMap={conversationIdsMap}
                    className=""
                  />


                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default MessageComp;