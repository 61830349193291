import moment from "moment";
import React, { useState } from "react";
import ButtonWithicon from "../../../../components/buttons/buttonWithIcon";
import FillButtonV2 from "../../../../components/buttons/UI_Buttons_2/fillButton";
import ButtonChild from "../../../../components/buttons/UI_Buttons_2/buttonChild";

const AddNotes = ({ close, addNote }) => {
  const [note, setNote] = useState();

  return (
    <div class="relative z-10 " aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0  bg-white bg-opacity-80 backdrop-blur-[4px] blur-md transition-opacity"></div>

      <div class="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative m-auto w-full transform overflow-scroll no-scrollbar rounded-lg bg-white text-left shadow-xl transition-all px-8 py-8 sm:my-8 sm:w-full sm:max-w-lg w-4/12">
            <div className="flex justify-between items-center w-screen sm:w-full">
              <div className="h3 font-bold"> Add Notes</div>
              <div onClick={close}>
                <ButtonChild
                  className="px-1"
                  textColor="gray"
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z"
                        fill="currentColor"
                      />
                    </svg>
                  }
                />
              </div>
            </div>

            {/* Row -1 */}

            {/* {oldnotes.map((note) => (
              <div className="my-4">
                <div className="text-slate-500">
                  {moment(note.date).format("DD MMM, YYYY")}
                </div>
                <div className="bg-slate-100 p-4 rounded-lg">{note.notes}</div>
              </div>
            ))} */}

            {/* Row-2 */}
            <div className="form w-screen sx:w-full my-4">
              <div className="mb-1 font-poppins my-2">Note</div>
              <textarea
                name="note"
                id=""
                rows="5"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                className="w-full  border border-gray-500/50  rounded-xl p-2"
              ></textarea>
              <div className="flex justify-end ml-auto">
                <FillButtonV2
                  // className=" mr-0 ml-auto"
                  onClick={() => addNote(note)}
                  text="Add Note"
                  color="green"
                  textColor="white"
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                      />
                    </svg>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNotes;
