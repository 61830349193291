import moment from "moment";
import React from "react";

const CertificationsCard = ({ userInfo: { applicant_info } }) => {
  return (
    <div className="Certifications w-full my-2 p-8 bg-white rounded-xl justify-start items-start gap-4 sm:gap-8 inline-flex flex-col xl:flex-row">
      <div className="Certifications1Relevant w-1/6 mr-8">
        <span className="text-gray-900 text-[16px] font-bold leading-normal">
          Certifications:
          <br />
        </span>
        <span className="text-emerald-500 text-[16px] font-normal leading-normal hidden">
          1 Relevant
        </span>
      </div>
      <div className="Details w-5/6 flex-col justify-start items-start gap-6 inline-flex">
        {applicant_info?.work_profile?.certifications.map((certificate) => (
          <div className="Row self-stretch justify-start items-start gap-6 sm:inline-flex">
            <div className="Apr2022Oct2022 w-40 text-gray-900 text-opacity-40 text-[14px] font-medium leading-tight my-4 sm:my-0">
              {moment(certificate?.startDate).format("MMM, yyyy")} -{" "}
              {certificate?.endDate
                ? moment(certificate?.endDate).format("MMM, yyyy")
                : "Present"}
            </div>
            <div className="Txt grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
              <div className="Highlight bg-opacity-20 justify-start items-start gap-2 inline-flex">
                <div className="CertificateInDigitalMarketingCommunication text-gray-900 text-[14px] font-bold leading-tight">
                  {certificate?.course}
                </div>
              </div>
              <div className="Highlight bg-opacity-20 justify-start items-start gap-2 inline-flex">
                <div className="MicaInAssociationWithUpgrad text-gray-900 text-[14px] font-medium leading-tight">
                  {certificate?.institute}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CertificationsCard;
