import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Resume from "./resume";


import { Link } from "react-router-dom";
// import jsPDF from "jspdf";

const GenerateResume = () => {
  const { jobId, applicationNo: appNo } = useParams();
  const [applicationNo, setApplicationNo] = useState(appNo);
  const [userInfo, setUserInfo] = useState(null);

  
  const FetchUserInfo = async () => {
    let params = {};
    const url = `${process.env.REACT_APP_baseURL}/recruiter/application_by_number/${jobId}/${applicationNo}`;
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
        params,
      })
      .catch((error) => console.log(error));

    if (response?.data) {
      console.log(response?.data.application.application);
      setUserInfo(response?.data.application.application);
    }
  };

  useEffect(FetchUserInfo, [applicationNo]);

  

  return (
    <div>
 
         {userInfo && (
            <>
            <div>

            <Resume  
            
            userInfo={userInfo}
            
            />
            </div>
         
            </>
         )}
    </div>
  );
};

export default GenerateResume;
