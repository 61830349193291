
import React, { useState, useEffect, useRef } from "react";
import TextInput from "../inputfields/UI_Inputs/textInput";
import ButtonChild from "../buttons/UI_Buttons_2/buttonChild";
import InfoBox from "../inputfields/UI_Inputs/infoBox";

const MultiSelectComp = ({ labelText,type, setOption, text, onChange, options, leftIcon,className, hasError}) => {

  const [selectedOptions, setSelectedOptions] = useState(setOption && Array.isArray(setOption) ? setOption : []);

  const [searchText, setSearchText] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const menuRef = useRef(null);
  const [customOptionInput, setCustomOptionInput] = useState("");
 
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  const addTag = () => {
    if (inputValue.trim() !== '') {
      setSelectedOptions([...selectedOptions, inputValue.trim()]);
      setInputValue('');
      onChange([...selectedOptions, inputValue.trim()])
    }
  };

  const handleInputKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ',') {
      e.preventDefault();
      addTag();

    }
  };


  const handleAddCustomOption = () => {
    if (customOptionInput.trim() !== "") {
      const newCustomOption = { label: customOptionInput.trim(), value: customOptionInput.trim() };
      setSelectedOptions([...selectedOptions, newCustomOption]);
      setCustomOptionInput("");
      if (onChange) {
        onChange([...selectedOptions, newCustomOption]);
      }
    }
  };


  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // const handleOptionClick = (option) => {
  //   const isSelected = selectedOptions.some(
  //     (selectedOption) => selectedOption.label === (typeof option === "string" ? option : option.label)
  //   );

  //   let updatedOptions;

  //   if (isSelected) {
  //     updatedOptions = selectedOptions.filter(
  //       (item) => item.label !== (typeof option === "string" ? option : option.label)
  //     );
  //   } else {
  //     updatedOptions = [
  //       ...selectedOptions,
  //       typeof option === "string" ? { label: option, value: option } : option,
  //     ];
  //     setSearchText("");
  //   }

  //   setSelectedOptions(updatedOptions);

  //   if (onChange) {
  //     onChange(updatedOptions);
  //   }
  // };

  const handleOptionClick = (option) => {
    const isSelected = selectedOptions.some(
      (selectedOption) => selectedOption.label === (typeof option === "string" ? option : option.label)
    );
  
    let updatedOptions;
  
    if (isSelected) {
      updatedOptions = selectedOptions.filter(
        (item) => item.label !== (typeof option === "string" ? option : option.label)
      );
    } else {
      const newOption = typeof option === "string" ? { label: option, value: option } : option;
  
      // Check if the new option already exists in the selectedOptions
      const isDuplicate = selectedOptions.some(
        (selectedOption) => selectedOption.label === newOption.label
      );
  
      updatedOptions = isDuplicate
        ? selectedOptions.map((selectedOption) =>
            selectedOption.label === newOption.label ? newOption : selectedOption
          )
        : [...selectedOptions, newOption];
  
      setSearchText("");
    }
  
    setSelectedOptions(updatedOptions);
  
    if (onChange) {
      onChange(updatedOptions);
    }
  };
  
  console.log(selectedOptions);


  // const filteredOptions = Array.isArray(options)
  //   ? options
  //     .filter((option) =>
  //       typeof option === 'string' &&
  //       option.toLowerCase().includes(searchText.toLowerCase())
  //     )
  //   : [];

  const handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    setInputValue(searchValue);
    setSearchText(searchValue);

    if (searchValue.trim() !== '') {
      // Update the filteredOptions based on the search text
      const newFilteredOptions = options.filter((option) => {
        if (typeof option === 'string') {
          return option.toLowerCase().includes(searchValue.toLowerCase());
        } else if (typeof option === 'object' && option.label) {
          return option.label.toLowerCase().includes(searchValue.toLowerCase());
        }
        return false;
      }).map(option => (typeof option === 'string' ? option : option.label));

      setFilteredOptions(newFilteredOptions);
    } else {
      setFilteredOptions([]);
    }
  };

  const allOptions = Array.from(new Set([...options, ...filteredOptions]));
  
  console.log(filteredOptions)

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsDropdownOpen(false);


    }
  };



  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("click", handleClickOutside);
    console.log(options, labelText, "sdfghjk")

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleRemoveOption = (optionToRemove) => {
    const updatedOptions = selectedOptions.filter(option => option !== optionToRemove);
    setSelectedOptions(updatedOptions);
  };

  const renderTags = () => {
  
    return selectedOptions?.map((option, index) => (
      <div key={index} className="w-max mr-1 my-[2px] bg-gray-100 rounded-2xl border-2 border-gray-200 justify-start items-center flex">
        <div className="px-2 py-1 justify-start items-center gap-1 flex">
          <div className="text-teal-950 text-sm font-medium font-['Satoshi Variable'] leading-tight">{option.label ? option.label : option}</div>
        </div>
        <div className="p-1 justify-start items-center flex">
          <div className="w-5 h-5 relative" onClick={() => handleRemoveTag(index)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.125 10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z" fill="#DB1717" />
            </svg>
          </div>
        </div>
      </div>
    ));
    
  };

  const handleRemoveTag = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
    if (onChange) {
      onChange(updatedOptions);
    }
  };
  const toggleIcon = isDropdownOpen ? ((leftIcon && text) ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z"
      fill="currentColor" />
  </svg>) : leftIcon ? '' : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z"
      fill="currentColor" />
  </svg>)

  ) : (
    (leftIcon && text) ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z"
        fill="currentColor" />
    </svg>) : leftIcon ? '' : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z"
        fill="currentColor" />
    </svg>)

  );
  return (
    <div className={`${className}`}>
      <div className={`w-full inline-flex justify-between text-gray-600 text-sm font-medium font-satoshi leading-tight mb-spaceXxxSmall `}>
        <label>
          {labelText}
        </label>
      </div>
      <div className="relative inline-block w-full" ref={menuRef}>
      {/* {renderTags()} */}
        <div onClick={toggleDropdown} >
        
          <TextInput
          className="h-auto overflow-scroll no-scrollbar"
          buttonClass="justify-between"

          leftTextClass="w-full max-h-[4rem] flex-wrap"
            // textType="input"
            textColor="gray"
            rightIcon={
              <div className="cursor-pointer" onClick={toggleDropdown} >
              {toggleIcon}
              </div>
            }
            rightIconClass="items-center"
            leftText={selectedOptions.length > 0 ? renderTags() : "Select...."}
            value={inputValue}
            onKeyDown={handleInputKeyDown}
            onChange={handleSearchInputChange}
            placeholder={selectedOptions.length > 0 ? "" : "Select..."}
            hasError={hasError}
          />
        </div>
        {isDropdownOpen && (
          <ul className="absolute w-full max-h-[20rem] overflow-y-scroll no-scrollbar z-10 flex flex-col bg-white rounded-xl border border-gray-300 mt-2 mb-4 overflow-hidden shadow-md">
           
           {type != "customOptions" &&
            <li>
              <TextInput 
              textType="input"
              textColor="gray"
              type="text"
              value={inputValue}
              onChange={handleSearchInputChange}
              placeholder="Serach here.."
              
              // onKeyDown={handleInputKeyDown}
              // rightText={
              //   <div className="text-green-500" onClick={addTag}>
              //     Add
              //   </div>
              // }

              />
            </li>
}
           {type == "customOptions" &&
            <li>
              <TextInput 
              textType="input"
              textColor="gray"
              type="text"
              value={inputValue}
              onChange={handleSearchInputChange}
              placeholder="Add your custom option here.."
              
              onKeyDown={handleInputKeyDown}
              rightText={
                <div className="text-green-500" onClick={addTag}>
                  Add
                </div>
              }

              />
            </li>
}
            {inputValue  &&
            <>
             {filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="z-1 hover:bg-gray-100 active:bg-gray-300 cursor-pointer"
              >

                <InfoBox //used for option list                
                  infoText={typeof option == "string" ? option : option.label || searchText}
                  leftIcon={
                    // <div>

                    //   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={`rounded-xl w-5 h-5 ${selectedOptions.some(
                    //     (selectedOption) =>
                    //       selectedOption.label === (typeof option === "string" ? option : option.label)
                    //   )
                    //       ? "bg-[#667984]" : ""} `}>
                    //     <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0003 16.3337C13.4981 16.3337 16.3337 13.4981 16.3337 10.0003C16.3337 6.50252 13.4981 3.66699 10.0003 3.66699C6.50252 3.66699 3.66699 6.50252 3.66699 10.0003C3.66699 13.4981 6.50252 16.3337 10.0003 16.3337ZM18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
                    //       fill={selectedOptions.some(
                    //         (selectedOption) =>
                    //           selectedOption.label === (typeof option === "string" ? option : option.label)
                    //       ) ? "#fff" : "#667984"} />
                    //   </svg>
                    // </div>

                    <div>
  <input
    type="checkbox"
    id={option.label}
    name={option.label}
    checked={selectedOptions.some(
      (selectedOption) =>
        selectedOption.label === (typeof option === "string" ? option : option.label)
    )}
    onChange={() => handleOptionClick(option)}
    className="rounded-xl w-5 h-5"
    style={{
      appearance: 'none',
      WebkitAppearance: 'none',
      MozAppearance: 'none',
      outline: 'none',
      border: '1px solid #ccc',
      borderRadius: '4px',
      // padding: '2px',
      marginRight: '5px',
      cursor: 'pointer',
      backgroundColor: selectedOptions.some(
        (selectedOption) =>
          selectedOption.label === (typeof option === 'string' ? option : option.label)
      )
        ? '#4caf50' // green color when checked
        : '#fff', // default background color
    }}
  />
  {/* <label htmlFor={option.label}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={`w-5 h-5 ${selectedOptions.some(
        (selectedOption) =>
          selectedOption.label === (typeof option === "string" ? option : option.label)
      )
        ? "bg-[#667984]"
        : ""}`}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0003 16.3337C13.4981 16.3337 16.3337 13.4981 16.3337 10.0003C16.3337 6.50252 13.4981 3.66699 10.0003 3.66699C6.50252 3.66699 3.66699 6.50252 3.66699 10.0003C3.66699 13.4981 6.50252 16.3337 10.0003 16.3337ZM18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
        fill={selectedOptions.some(
          (selectedOption) =>
            selectedOption.label === (typeof option === "string" ? option : option.label)
        )
          ? "#fff"
          : "#667984"}
      />
    </svg>
  </label> */}
</div>



                  }

                />


              </li>
            ))}
            </>
            
            }

            {options.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionClick(option)}
                className="z-1 hover:bg-gray-100 active:bg-gray-300 cursor-pointer"
              >

                <InfoBox //used for option list                
                  infoText={typeof option == "string" ? option : option.label || searchText}
                  leftIcon={
                    // <div>

                    //   <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" className={`rounded-xl w-5 h-5 ${selectedOptions.some(
                    //     (selectedOption) =>
                    //       selectedOption.label === (typeof option === "string" ? option : option.label)
                    //   )
                    //       ? "bg-[#667984]" : ""} `}>
                    //     <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0003 16.3337C13.4981 16.3337 16.3337 13.4981 16.3337 10.0003C16.3337 6.50252 13.4981 3.66699 10.0003 3.66699C6.50252 3.66699 3.66699 6.50252 3.66699 10.0003C3.66699 13.4981 6.50252 16.3337 10.0003 16.3337ZM18.3337 10.0003C18.3337 14.6027 14.6027 18.3337 10.0003 18.3337C5.39795 18.3337 1.66699 14.6027 1.66699 10.0003C1.66699 5.39795 5.39795 1.66699 10.0003 1.66699C14.6027 1.66699 18.3337 5.39795 18.3337 10.0003Z"
                    //       fill={selectedOptions.some(
                    //         (selectedOption) =>
                    //           selectedOption.label === (typeof option === "string" ? option : option.label)
                    //       )  ? "#fff" : "#667984"} />
                    //   </svg>
                    // </div>

                    <div className="mt-1">




<input
  type="checkbox"
  id={option.label}
  name={option.label}
  checked={selectedOptions.some(
    (selectedOption) =>
      selectedOption.label === (typeof option === "string" ? option : option.label)
  )}
  onChange={() => handleOptionClick(option)}
  className="rounded-xl w-5 h-5 border "
  style={{
    appearance: 'none',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    outline: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    // padding: '2px',
    marginRight: '5px',
    cursor: 'pointer',
    backgroundColor: selectedOptions.some(
      (selectedOption) =>
        selectedOption.label === (typeof option === 'string' ? option : option.label)
    )
      ? '#16C083' // green color when checked
      : '#fff', // default background color
    backgroundImage: selectedOptions.some(
      (selectedOption) =>
        selectedOption.label === (typeof option === 'string' ? option : option.label)
    )
      ? 'url("data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'%23fff\' stroke-width=\'4\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3E%3Cpolyline points=\'20 6 9 17 4 12\' /%3E%3C/svg%3E")'
      : 'none', // white checkmark when checked
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: '60% 60%',
    // borderColor:"white"
    
    
  }}
/>


</div>

                  }

                />


              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default MultiSelectComp;


