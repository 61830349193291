import axios from "axios";

export const getLeaderboardData = async () => {
    try {
      const token = localStorage.getItem("auth"); // Assuming the token is stored in localStorage
      const response = await axios.get(
        `${process.env.REACT_APP_baseURL}/recruiter/leaderboard`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      throw error; // Rethrow the error to handle it in the component where this function is used
    }
  };

  export const getLeaderboardPreference = async () => {
    try {
      const token = localStorage.getItem("auth"); // Assuming the token is stored in localStorage
      const response = await axios.get(
        `${process.env.REACT_APP_baseURL}/recruiter/leaderboard/preference`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      throw error; // Rethrow the error to handle it in the component where this function is used
    }
  };
  export const postLeaderboardPreference = async (enable) => {
    try {
      const token = localStorage.getItem("auth"); // Assuming the token is stored in localStorage
      const response = await axios.post(
        `${process.env.REACT_APP_baseURL}/recruiter/leaderboard/preference`,
        {
          leaderboardEnabled: enable
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching leaderboard data:", error);
      throw error; // Rethrow the error to handle it in the component where this function is used
    }
  };

  export const getRecommendedStudent = async (id) => {
      try {
          const token = localStorage.getItem("auth");
          const response = await axios.get(
              `${process.env.REACT_APP_baseURL}/recruiter/recommended/${id}`, // Removed the colon before id
              {
                  headers: {
                      Authorization: `Bearer ${token}`,
                  },
              }
          );
          return response.data;
      } catch (error) {
          console.error("Error fetching recommended student data:", error); // Changed the error message for clarity
          throw error;
      }
  };
  

  export const loadCompany = async () => {
    const url = `${process.env.REACT_APP_baseURL}/company/mycompany`;
try {
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      return response?.data;
    }  catch (error) {
        console.log(error.response?.data);
      }

    };


    