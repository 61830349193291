import axios from "axios";
import React, { useEffect, useState } from "react";
import "../auth/login.css";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../components/alert/alert";
import { getToken } from "firebase/messaging";
import { getTokenForApis, requestForToken } from "../../services/firebase";
import { Mixpanel } from "../../services/mixpanel";
import moment from "moment";
import { isMobile, browserName } from "react-device-detect";
import Title from "../../components/title/title";
import "../../index.css";
import CommonButtonWithIcon from "../../components/buttons/commonButtonWithIcon";
import FillButtonV2 from "../../components/buttons/UI_Buttons_2/fillButton";
import ButtonChild from "../../components/buttons/UI_Buttons_2/buttonChild";
import TextInput from "../../components/inputfields/UI_Inputs/textInput";
import InputFieldComp from "../../components/inputfields/UI_Inputs/InputFieldComp";
import BorderButton from "../../components/buttons/UI_Buttons_2/borderButton";
import jwtDecode from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import Loader from "../../components/Loader/loader";

export function Login() {
  const [form, setForm] = React.useState({ emailOrPhone: "", password: "" });
  const [emailVerified, setEmailVerified] = useState(false);
  const [alertData, setAlertData] = React.useState(null);
  const closeAlert = () => setAlertData(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

 

  const navigator = useNavigate();
  const onFormChange = async (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    // await test();
    if (value) {
      setError(false);
    }
  };

  const login = useGoogleLogin({
    
    
    onSuccess: async (tokenResponse) => {
      try {
        let fcmToken = null;
        console.log(fcmToken);
        Mixpanel.track("sign_in_google_clicked", {
          date: moment().format(),
          mobile_device: isMobile,
          token: tokenResponse.access_token,
          // timeSpent: timeSpentInSeconds,
        })
        const response = await axios.post(
          `${process.env.REACT_APP_baseURL}/recruiter/sso/verify`,
          {
            authToken: tokenResponse.access_token,
            fcmToken,
            service: 0, // Google service is represented by 0
          }
        );
        if(response?.data){
        const userData = response.data;
        const status = response.data.status;
        console.log(response);
        Mixpanel.identify(response?.data.userId);
        Mixpanel.track("sign_in_google_complete", {
          date: moment().format(),
          emailOrPhone: form.emailOrPhone,
          browser: browserName,
          mobile_device: isMobile,
          recruiter_id: response?.data.userId,
          distinct_id: response?.data.userId,
          // timeSpent: timeSpentInSeconds,
        })
        // Redirect to the Create Account page with user data
        localStorage.setItem("userId", response?.data.userId);
        if (status == 50 || status === 5) {
          localStorage.setItem("auth", response?.data.authToken);
          navigator("/");
        } else {
          localStorage.setItem("auth", response?.data.authToken);
          localStorage.setItem("status", status);
          if (status === 11) {
            localStorage.setItem("auth", response?.data.authToken);
            navigator("/create/verify", {
              state: { userData, authToken: response.data.authToken },
            });
          } else if (status === 30 || status === 31) {
            navigator("/create/selectcompany", {
              state: { userData, authToken: response.data.authToken },
            });
          } else if (status === 10) {
            localStorage.setItem("auth", response?.data.authToken);
            navigator("/create/account", {
              state: { userData, authToken: response.data.authToken },
            });
          }
        }
      }
      } catch (error) {
        console.error("An error occurred while fetching user data", error);
      }
    },
  });

  const LoginService = async () => {
    
    setIsLoading(true);
    const errorMessages = {
      emailOrPhone: "Email or Phone is required",
      invalidPhoneNumber: "Enter a valid phone number",
      invalidEmail: "Please enter a valid email address",
      password: "Password is required",
      shortPassword: "Password must be a minimum of 8 characters",
      passwordMissMatch: "Incorrect Password",
      unRegEmail: "This email is not registered with us.",
    };
    const errors = {};

    //   const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!form.emailOrPhone || form.emailOrPhone === "") {
      errors.emailOrPhone = errorMessages.emailOrPhone;
    }

    if (!form.password || form.emailOrPhone === "") {
      errors.password = errorMessages.password;
    } else if (form.password.length < 8) {
      errors.password = errorMessages.shortPassword;
    }

    if (Object.keys(errors).length > 0) {
      // If there are errors, update the error state
      setError(errors);
      setIsLoading(false);
      return;
    }

    setError({});

    Mixpanel.track("Sign_In_Password_Clicked", {
      date: moment().format(),
      emailOrPhone: form.emailOrPhone,
      browser: browserName,
      mobile_device: isMobile,
    });

    const url = `${process.env.REACT_APP_baseURL}/recruiter/login`;
    // let fcmToken = await getTokenForApis().catch((error) => console.log(error));
    let fcmToken = null;
    console.log(fcmToken);
    const response = await axios
      .post(url, {
        emailOrPhone: form.emailOrPhone,
        password: form.password,
        fcmToken,
      })
      .catch((error) => {
        Mixpanel.track("Sign In Failed", {
          date: moment().format(),
          emailOrPhone: form.emailOrPhone,
          browser: browserName,
          mobile_device: isMobile,
          reason: error.response?.data,
        });

        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
        setError(error.response?.data.message);
        setIsLoading(false);
      });

    if (response?.data) {
      // Mixpanel.identify(response?.data.userId);
      Mixpanel.track("sign_in_Password_Complete", {
        date: moment().format(),
        emailOrPhone: form.emailOrPhone,
        browser: browserName,
        mobile_device: isMobile,
        recruiter_id: response?.data.userId,
        distinct_id: response?.data.userId,
        // timeSpent: timeSpentInSeconds,
      })
      setIsLoading(false);
      Mixpanel.track("Sign In Success", {
        date: moment().format(),
        emailOrPhone: form.emailOrPhone,
        browser: browserName,
        distinct_id: response?.data.userId,
        recruiter_id: response?.data.userId,
        mobile_device: isMobile,
      });
      const status = response.data.status;
      const userData = response.data;
      console.log(response);
      localStorage.setItem("userId", response?.data.userId);
      console.log(response?.data.userId);
      if (status === 50 || status === 5) {
        localStorage.setItem("auth", response?.data.authToken);
        navigator("/");
      } else {
        localStorage.setItem("auth", response?.data.authToken);
        localStorage.setItem("status", status);
        if (status === 11) {
          navigator("/create/verify", {
            state: { userData, authToken: response.data.authToken },
          });
        } else if (status === 30 || status === 31) {
          navigator("/create/selectcompany", {
            state: { userData, authToken: response.data.authToken },
          });
        } else if (status === 10) {
          navigator("/create/account", {
            state: { userData, authToken: response.data.authToken },
          });
        }
      }
    }
  };

  const createAccount = () => {
    Mixpanel.track("Sign Up Click", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobile,
    });
    navigator("/create/account");
  };

  const forgotPasswordClick = () => {
    Mixpanel.track("Forgot Password Click", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobile,
    });
    navigator("/password/verifyaccount");
  };
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = async () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const [Loading, setLoading] = useState(true); // State to track loading

  // Your existing state and useEffect logic...

  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }

  return (
    <>
      <div
        className="flex flex-col w-screen overflow-auto no-scrollbar gradient sm:py-[64px] min-h-screen
                   xl:flex-row xl:h-[100vh] xl:p-0 xl:m-0"
      >
        <div className="relative Graphics max-w-full xl:w-full  xl:h-[100vh] mb-16 xl:mb-0 ">
          <img
            className="absolute flex Gfx xl:w-full
             left-0 top-0 bg-contain bg-center h-full object-cover"
            src="/gfx.png"
          />
          <Title />
        </div>

        <div
          className="form p-0 m-0 w-100% flex flex-nowrap overflow-scroll no-scrollbar h-auto sm:m-16 
        md:mx-[97px] xl:m-0  xl:w-[105vh]"
        >
          <div
            className=" relative bg-white w-full overflow-scroll no-scrollbar mt-[161px] border-none shadow-none xl:mt-0 p-[32px] 
            sm:border sm:border-white sm:p-spaceSLarge sm:self-stretch sm:rounded-[28px] sm:shadow-white-xl
            md:py-[64px] h-fit  xl:h-full 2xl:h-auto sm:mt-[209px] md:mt-[230px] lg:mt-[211px] xl:border-none xl:shadow-none xl:rounded-none xl:m-0
             xl:p-[64px]
         "
          >
            <div className="flex flex-col gap-spaceBase mb-spaceSLarge">
              <div
                className="SignInToStartRecruiting m-0 text-gray-900 text-[32px] 
             font-black font-satoshi leading-[38.40px]"
              >
                Sign In
              </div>
              <div onClick={createAccount}>
                <span className=" cursor-pointer text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">
                  Don’t Have an Account?{" "}
                </span>
                <span>
                  <ButtonChild className="items-baseline" text="Sign Up" textColor="green" />
                </span>
              </div>
            </div>
            <div className="w-full h-auto">
              <div className="flex flex-col mb-spaceLarge sm:text-base">
                <TextInput
                  textColor="gray"
                  textType="input"
                  htmlFor="emailOrPhone"
                  type="text"
                  name="emailOrPhone"
                  labelText="Email or Mobile"
                  placeholder="Enter Email Address or Mobile Number"
                  value={form.emailOrPhone}
                  onChange={onFormChange}
                  hasError={error?.emailOrPhone || alertData}
                  errorMsg={error?.emailOrPhone || alertData}
                />
              </div>
              <div className="flex flex-col mb-spaceLarge">
                <TextInput
                  textType="input"
                  textColor="gray"
                  name="password"
                  htmlFor="password"
                  labelText="Password"
                  hasError={error?.password || alertData}
                  errorMsg={error?.password || alertData}
                  // inforText={
                  //   <div>
                  //     {error ?
                  //   <button
                  //     onClick={forgotPasswordClick}
                  //     className="InfoLink text-right ml-auto text-emerald-500 text-[12px]  font-medium"
                  //   >
                  //     Forgot password?{" "}
                  //   </button>
                  //   : ""}

                  //   </div>
                  //   }
                  type={isPasswordVisible ? "text" : "password"}
                  placeholder="Enter your password"
                  value={form.password}
                  onChange={onFormChange}
                  rightIcon={
                    <button onClick={togglePasswordVisibility}>
                      {isPasswordVisible ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M19.3211 9.74688C19.2937 9.68516 18.632 8.21719 17.1609 6.74609C15.2008 4.78594 12.725 3.75 9.99999 3.75C7.27499 3.75 4.79921 4.78594 2.83905 6.74609C1.36796 8.21719 0.703118 9.6875 0.678899 9.74688C0.643362 9.82681 0.625 9.91331 0.625 10.0008C0.625 10.0883 0.643362 10.1748 0.678899 10.2547C0.706243 10.3164 1.36796 11.7836 2.83905 13.2547C4.79921 15.2141 7.27499 16.25 9.99999 16.25C12.725 16.25 15.2008 15.2141 17.1609 13.2547C18.632 11.7836 19.2937 10.3164 19.3211 10.2547C19.3566 10.1748 19.375 10.0883 19.375 10.0008C19.375 9.91331 19.3566 9.82681 19.3211 9.74688ZM9.99999 13.125C9.38193 13.125 8.77774 12.9417 8.26383 12.5983C7.74993 12.255 7.34939 11.7669 7.11287 11.1959C6.87634 10.6249 6.81446 9.99653 6.93504 9.39034C7.05562 8.78415 7.35324 8.22733 7.79028 7.79029C8.22732 7.35325 8.78414 7.05562 9.39033 6.93505C9.99652 6.81447 10.6249 6.87635 11.1959 7.11288C11.7669 7.3494 12.255 7.74994 12.5983 8.26384C12.9417 8.77775 13.125 9.38193 13.125 10C13.125 10.8288 12.7958 11.6237 12.2097 12.2097C11.6236 12.7958 10.8288 13.125 9.99999 13.125Z"
                            fill="currentColor"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M18.0417 12.8125C18.1246 12.9564 18.1469 13.1273 18.1038 13.2877C18.0606 13.448 17.9556 13.5847 17.8117 13.6676C17.7404 13.7086 17.6618 13.7352 17.5802 13.7459C17.4987 13.7565 17.4159 13.751 17.3365 13.7296C17.1761 13.6865 17.0395 13.5814 16.9566 13.4375L15.4722 10.8438C14.6099 11.427 13.6588 11.8666 12.6558 12.1453L13.1144 14.8969C13.1279 14.9779 13.1254 15.0608 13.1068 15.1408C13.0883 15.2208 13.0542 15.2964 13.0065 15.3632C12.9587 15.43 12.8983 15.4868 12.8286 15.5302C12.7589 15.5737 12.6813 15.603 12.6003 15.6164C12.567 15.6219 12.5333 15.6247 12.4995 15.625C12.3517 15.6248 12.2087 15.5721 12.096 15.4765C11.9833 15.3808 11.9081 15.2482 11.8839 15.1023L11.4331 12.4008C10.4825 12.5331 9.51814 12.5331 8.56751 12.4008L8.11673 15.1023C8.09247 15.2485 8.01711 15.3812 7.90407 15.477C7.79103 15.5727 7.64767 15.6251 7.49954 15.625C7.46499 15.6249 7.43051 15.622 7.39642 15.6164C7.3154 15.603 7.23783 15.5737 7.16814 15.5302C7.09845 15.4868 7.03801 15.43 6.99027 15.3632C6.94253 15.2964 6.90842 15.2208 6.88991 15.1408C6.87139 15.0608 6.86882 14.9779 6.88235 14.8969L7.34329 12.1453C6.33988 11.8659 5.38845 11.4256 4.5261 10.8414L3.04642 13.4375C2.96354 13.5819 2.82668 13.6875 2.66596 13.731C2.50523 13.7745 2.3338 13.7524 2.18939 13.6695C2.04497 13.5867 1.93939 13.4498 1.89587 13.2891C1.85236 13.1283 1.87447 12.9569 1.95735 12.8125L3.51985 10.0781C2.97083 9.60418 2.46614 9.08118 2.01204 8.51562C1.92211 8.4044 1.87305 8.26569 1.87305 8.12266C1.87305 7.97962 1.92211 7.84091 2.01204 7.72969C3.48079 5.91563 6.05814 3.75 9.99954 3.75C13.9409 3.75 16.5183 5.91562 17.9855 7.73438C18.0754 7.8456 18.1245 7.98431 18.1245 8.12734C18.1245 8.27038 18.0754 8.40909 17.9855 8.52031C17.5314 9.08587 17.0267 9.60887 16.4777 10.0828L18.0417 12.8125Z"
                            fill="currentColor"
                          />
                        </svg>
                      )}
                    </button>
                  }
                />
              </div>
              <div className="w-full flex flex-col sm:flex-row sm:justify-between justify-end gap-3 sm:gap-0 items-center">
                <div className="w-full sm:w-max">
                  <FillButtonV2
                    className={`px-8`}
                    onClick={LoginService}
                    text="Sign In"
                    color="green"
                    textColor="white"
                    isLoading={isLoading}
                    // rightIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    //   <path d="M14.1922 10.4422L7.94219 16.6922C7.85478 16.7797 7.74337 16.8393 7.62207 16.8635C7.50076 16.8876 7.37502 16.8752 7.26076 16.8279C7.14649 16.7805 7.04884 16.7004 6.98017 16.5975C6.91151 16.4946 6.8749 16.3737 6.875 16.25V3.75C6.8749 3.62632 6.91151 3.50539 6.98017 3.40252C7.04884 3.29964 7.14649 3.21946 7.26076 3.17211C7.37502 3.12477 7.50076 3.11239 7.62207 3.13655C7.74337 3.1607 7.85478 3.22031 7.94219 3.30782L14.1922 9.55782C14.2503 9.61586 14.2964 9.68479 14.3279 9.76067C14.3593 9.83654 14.3755 9.91787 14.3755 10C14.3755 10.0821 14.3593 10.1635 14.3279 10.2393C14.2964 10.3152 14.2503 10.3841 14.1922 10.4422Z" fill="currentColor" />
                    // </svg>}
                  />
                </div>
                {error ? (
                  <div
                    onClick={forgotPasswordClick}
                    className="w-full sm:w-max flex justify-end"
                  >
                    {/* {error ? */}
                    <ButtonChild textColor="green" text="Forgot password?" />
                    {/* <button
                      onClick={forgotPasswordClick}
                      className="InfoLink text-right ml-auto text-emerald-500 text-[12px]  font-medium"
                    >
                      Forgot password?{" "}
                    </button> */}
                    {/* : ""} */}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div
              className="flex w-full h-[0px] border my-spaceSLarge border-dashed border-t-0 border-gray-100 justify-evenly"
              style={{ borderSpacing: "5px" }}
            >
              {/* <div className="w-[23px] mt-[-10px] h-[18px] p-1 bg-gray-100 rounded-2xl justify-center items-center inline-flex"> */}
              {/* <div className=" text-center text-slate-500 text-[10px] font-black font-['Satoshi Variable'] leading-[9px]">OR</div> */}
              <button className="pointer-none m-[-10px] w-[23px] h-[18px] p-1 bg-gray-100 rounded-2xl text-[9px] font-['Satoshi Variable'] font-bold text-slate-500 leading-[1.19]">
                OR
              </button>
              {/* </div> */}
            </div>
            <div className="flex gap-spaceBase justify-center items-center ">
              <BorderButton
                onClick={() => login()}
                className={`w-full justify-center sm:w-[320px] xl:w-full`}
                textColor="gray"
                color="gray"
                text="Sign In with Google"
                leftIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M18.7509 10.1944C18.7509 9.47495 18.6913 8.94995 18.5624 8.40552H10.1794V11.6527H15.1C15.0009 12.4597 14.4652 13.675 13.2747 14.4916L13.258 14.6003L15.9085 16.6126L16.0921 16.6305C17.7786 15.1041 18.7509 12.8583 18.7509 10.1944Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M10.1788 18.75C12.5895 18.75 14.6133 17.9722 16.0915 16.6305L13.274 14.4916C12.5201 15.0068 11.5081 15.3666 10.1788 15.3666C7.81773 15.3666 5.81379 13.8402 5.09944 11.7305L4.99473 11.7392L2.23868 13.8295L2.20264 13.9277C3.67087 16.786 6.68674 18.75 10.1788 18.75Z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.10014 11.7305C4.91165 11.186 4.80257 10.6027 4.80257 9.99992C4.80257 9.3971 4.91165 8.81379 5.09022 8.26935L5.08523 8.1534L2.29464 6.02954L2.20333 6.0721C1.5982 7.25823 1.25098 8.5902 1.25098 9.99992C1.25098 11.4096 1.5982 12.7415 2.20333 13.9277L5.10014 11.7305Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M10.1789 4.63331C11.8554 4.63331 12.9864 5.34303 13.6312 5.93612L16.1511 3.525C14.6035 2.11528 12.5895 1.25 10.1789 1.25C6.68676 1.25 3.67088 3.21387 2.20264 6.07218L5.08953 8.26943C5.81381 6.15972 7.81776 4.63331 10.1789 4.63331Z"
                      fill="#EB4335"
                    />
                  </svg>
                }
              />
            </div>
          </div>
        </div>
      </div>

      {alertData && <Alert text={alertData} alertClose={closeAlert} time={2} />}
    </>
  );
}
