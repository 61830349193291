import React, { useEffect, useState } from "react";
import Loader from "../../../components/Loader/loader";
import { getLeaderboardPreference, postLeaderboardPreference } from "../../../services/leaderboard";
import OnSaveChangeToggle from "../modals/onSaveChangesToggle";
import BorderButton from "../../../components/buttons/UI_Buttons_2/borderButton";

export const Leaderboard = ({ setActiveSection,
    showDiscard,
    isChanged,
    setShowDiscard,
    setIsChanged}) => {
        const [changed, setChanged] = useState(false)
        const [enable, setEnable] = useState(false)
        const [showChangesSaved, setShowChangesSaved] = useState("");

        const [isLoading, setIsLoading] = useState(true); // State to track loading

// Your existing state and useEffect logic...
const toggleLeaderboard =()=>{
    setEnable((prev)=>!prev)
    setChanged(true);
    setIsChanged(true);

}

const resetPreferences = () => {
    
}

const OnSaveChanges =async ()=>{
    const response = await postLeaderboardPreference(enable);
    console.log(response)
    setShowChangesSaved(true);
    setTimeout(() => setShowChangesSaved(false), 3000);
    setIsChanged(false);
    if(showDiscard){
      setActiveSection(showDiscard); 
      setShowDiscard(false)
      }
}

useEffect(()=>{
const getData =async()=>{
const response = await getLeaderboardPreference();
console.log(response);
setEnable(response?.leaderboardEnabled);
}
getData();
},[])

useEffect(() => {
  // Simulating an asynchronous action
  const fakeAsyncAction = async () => {
    try {
      // Simulate an API call or any asynchronous action
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      // Set isLoading to false once the asynchronous action is done
      setIsLoading(false);
    }
  };

  // Call the fakeAsyncAction when the component mounts
  fakeAsyncAction();
}, []);
  return <div>
    <div className="Menu w-full border-l border-gray-900 border-opacity-10 sm:w-[75vw] xl:w-[50vw] sm:pt-[3rem] sm:m-0 mb-8 overflow-scroll no-scrollbar grow shrink basis-0 self-stretch p-spaceBase sm:px-spaceSLarge sm:py-spaceLarge bg-white flex-col justify-start items-start inline-flex gap-spaceXxLarge">

        {isLoading && (
          <>
        
            <div className=" m-auto"><Loader /></div>
          </>
        )}
          {!isLoading && 
          <>

    <div className="text-teal-950 text-[32px] font-black font-satoshi leading-[38.40px]">LeaderBoard</div>
    <div className="self-stretch justify-start items-center gap-4 inline-flex mt-spaceXxLarge sm:mt-spaceSLarge">
<div className="w-[588px] text-teal-950 text-2xl font-black font-satoshi leading-[28.80px]">Enable Visibility</div>
<div className="p-1 rounded-xl justify-center items-center gap-1 flex">
<div className={`w-7 h-5 `} onClick={toggleLeaderboard}>
    {enable ? 
   <svg xmlns="http://www.w3.org/2000/svg" width="28" height="21" viewBox="0 0 28 21" fill="none">
   <path d="M19.25 2.46436H8.75C6.66142 2.46436 4.65838 3.31097 3.18153 4.81796C1.70469 6.32495 0.875 8.36887 0.875 10.5001C0.875 12.6313 1.70469 14.6752 3.18153 16.1822C4.65838 17.6892 6.66142 18.5358 8.75 18.5358H19.25C21.3386 18.5358 23.3416 17.6892 24.8185 16.1822C26.2953 14.6752 27.125 12.6313 27.125 10.5001C27.125 8.36887 26.2953 6.32495 24.8185 4.81796C23.3416 3.31097 21.3386 2.46436 19.25 2.46436ZM19.25 14.9644C18.3847 14.9644 17.5388 14.7025 16.8194 14.212C16.0999 13.7214 15.5392 13.0242 15.208 12.2085C14.8769 11.3927 14.7903 10.4951 14.9591 9.62913C15.1279 8.76314 15.5446 7.96768 16.1564 7.34334C16.7683 6.719 17.5478 6.29382 18.3965 6.12156C19.2451 5.94931 20.1248 6.03772 20.9242 6.37561C21.7237 6.7135 22.4069 7.2857 22.8877 8.01985C23.3684 8.75399 23.625 9.61712 23.625 10.5001C23.625 11.6841 23.1641 12.8196 22.3436 13.6568C21.5231 14.494 20.4103 14.9644 19.25 14.9644Z" fill="#16C083"/>
 </svg>

:
<svg xmlns="http://www.w3.org/2000/svg" width="28" height="20" viewBox="0 0 28 20" fill="none">
    <path d="M19.25 1.96436H8.75C6.66142 1.96436 4.65838 2.81097 3.18153 4.31796C1.70469 5.82495 0.875 7.86887 0.875 10.0001C0.875 12.1313 1.70469 14.1752 3.18153 15.6822C4.65838 17.1892 6.66142 18.0358 8.75 18.0358H19.25C21.3386 18.0358 23.3416 17.1892 24.8185 15.6822C26.2953 14.1752 27.125 12.1313 27.125 10.0001C27.125 7.86887 26.2953 5.82495 24.8185 4.31796C23.3416 2.81097 21.3386 1.96436 19.25 1.96436ZM8.75 14.4644C7.88471 14.4644 7.03885 14.2025 6.31938 13.712C5.59992 13.2214 5.03916 12.5242 4.70803 11.7085C4.37689 10.8927 4.29025 9.99512 4.45906 9.12913C4.62787 8.26314 5.04455 7.46768 5.65641 6.84334C6.26826 6.219 7.04781 5.79382 7.89648 5.62156C8.74515 5.44931 9.62481 5.53772 10.4242 5.87561C11.2237 6.2135 11.9069 6.7857 12.3877 7.51985C12.8684 8.25399 13.125 9.11712 13.125 10.0001C13.125 11.1841 12.6641 12.3196 11.8436 13.1568C11.0231 13.994 9.91032 14.4644 8.75 14.4644Z" fill="#667984"/>
  </svg>

    }
</div>
</div>

</div>
<div className="self-stretch text-slate-500 text-sm font-medium font-satoshi leading-tight">Disable this feature to remove your status from the leaderboard. However, you will be able to view other recruiters on the leaderboard.</div>

    <div className="Cta w-full sm:w-max justify-start items-start gap-4 flex-col sm:flex-row inline-flex mt-spaceSLarge">
          <BorderButton
          className="w-full"
            onClick={resetPreferences}
            text="Reset changes"
            color="gray"
            textColor="gray"
            disabled={changed ? false : true}
            leftIcon={<svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z"
                fill="#002032"
                fill-opacity="0.6"
              />
            </svg>}
          />
       
          <BorderButton
          className="w-full"
            onClick={OnSaveChanges}
            text="Save Changes"
            color="green"
            textColor="green"
            disabled={changed ? false : true}
            leftIcon={<svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                fill="currentColor"
              />
            </svg>}
          />
   
   
          {showChangesSaved && (
            <div className="py-4 flex px-4">
              <div className="Checkcircle  w-5 h-5  text-emerald-500 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="SaveChanges text-emerald-500 text-sm font-bold leading-tight px-1 py-0.5">
                Saved
              </div>
            </div>
          )}
        </div>
        </>
}
    
</div>

{showDiscard && <OnSaveChangeToggle close={()=>{setShowDiscard(false)}} onSave={OnSaveChanges}/>}
  </div>;
};
