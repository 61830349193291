import React from "react";
import OpeningDetailsCard from "./Cards/openingDetails";
import QuestionsCard from "./Cards/questions";
import DescrptionCard from "./Cards/description";
import AssigmentCard from "./Cards/assignments";

const RightSideCards = ({ jobDetails }) => {
  return (
    <div className="bg-slate-200 w-full p-4 overflow-y-scroll no-scrollbar">
      {/* <AssigmentCard jobDetails={jobDetails} /> */}
      <OpeningDetailsCard jobDetails={jobDetails} />
      {jobDetails.questions?.filter((d) => d.questionType !== "" || d.question !== "").length > 0 && (
  <QuestionsCard jobDetails={jobDetails} />
)}

      <DescrptionCard jobDetails={jobDetails} />
    </div>
  );
};

export default RightSideCards;
