import React from "react";

const AwardsAndHonors = ({ userInfo }) => {
  return (
    <div className="AwardsHonors  w-full my-2 p-8  bg-white  rounded-xl justify-start items-start gap-8 inline-flex flex-col xl:flex-row">
      <div className="AwardsHonours1Relevant w-1/5 mr-8">
        <span className="text-gray-900 text-[16px] font-bold leading-normal">
          Awards & Honours: <br />
        </span>
        <span className="text-emerald-500 text-[16px] font-normal leading-normal hidden">
          1 Relevant
        </span>
      </div>
      <div className="Details w-5/6 flex-col justify-start items-start gap-6 inline-flex">
        {[].map((data) => (
          <div className="Row self-stretch justify-start items-start gap-6 sm:inline-flex">
            <div className="JanMar2023 w-40 text-gray-900 text-opacity-40 text-[14px] font-medium leading-tight my-4 sm:my-0">
              Jan – Mar, 2023
            </div>
            <div className="Txt flex-col justify-start items-start gap-1 inline-flex">
              <div className="Highlight  bg-opacity-20 justify-start items-start gap-2 inline-flex">
                <div className="BestInternAward text-gray-900 text-[14px] font-bold leading-tight">
                  Best Intern Award
                </div>
              </div>
              <div className="Highlight  bg-opacity-20 justify-start items-start gap-2 inline-flex">
                <div className="Watconsult text-gray-900 text-[14px] font-medium leading-tight">
                  WATConsult
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AwardsAndHonors;
