import {
  deleteToken,
  getMessaging,
  getToken,
  onMessage,
} from "firebase/messaging";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDxGVQIjI631kFUEsbQ3g-SARdhQsH9G8w",
  authDomain: "eventbeep-production.firebaseapp.com",
  databaseURL: "https://eventbeep-production.firebaseio.com",
  projectId: "eventbeep-production",
  storageBucket: "eventbeep-production.appspot.com",
  messagingSenderId: "1033941590067",
  appId: "1:1033941590067:web:9a1a6a997720f826",
  measurementId: "G-KB2ZFF9YQ3",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
export const messaging = getMessaging(firebaseApp);

export const deRegisterToken = async () => {
  deleteToken(messaging)
    .then((data) => {
      console.log("Data on deregister token:", data);
    })
    .catch((error) => {
      console.log("Error on deleteing token", error);
    });
};

export const requestForToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BP4f_MUoqQDwi4cx-eLkvunURPi5GhuyarrYr58J9c9TuFglb8Z7pk-vtl9MTss-DolvzRj2ih0y9F9-enuzBqE",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        setTokenFound(true);
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const getTokenForApis = () => {
  return getToken(messaging, {
    vapidKey:
      "BP4f_MUoqQDwi4cx-eLkvunURPi5GhuyarrYr58J9c9TuFglb8Z7pk-vtl9MTss-DolvzRj2ih0y9F9-enuzBqE",
  })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // setTokenFound(true);
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
