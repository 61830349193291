import React from "react";

const GreenButtonWithIcon = ({ icon, text, className, ...props }) => {
  return (
    <button
      className={`ButtonMedium cursor-pointer h-11 p-3 bg-emerald-500  rounded-xl border  border-gray-900 border-opacity-10 justify-center items-center gap-2 inline-flex ${className}`}
      {...props}
    >
      <div className="InputFieldIconsPluscircle  w-5 h-5 ">{icon}</div>
      <div className="ButtonText text-center text-white text-[14px] font-bold leading-tight w-max">
        {text}
      </div>
    </button>
  );
};

export default GreenButtonWithIcon;
