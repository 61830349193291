import React, { useEffect, useState } from "react";
import {
  getLeaderboardData,
  loadCompany,
} from "../../../../services/leaderboard";
import Loader from "../../../../components/Loader/loader";
import { useNavigate } from "react-router-dom";

export const Leaderboard = ({
  isLeaderboardClicked,
  setIsLeaderboardClicked,
}) => {
  const [recruiterData, setRecruiterData] = useState([]);
  const [currentCompany, setCurrentComapny] = useState("");
  console.log(currentCompany);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getLeaderboardData();
        setRecruiterData(response);
        const companyData = await loadCompany();
        console.log(companyData);
        setCurrentComapny(companyData?.name);
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, []);
  return (
    <>
      <div
      class="relative z-11"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0 bg-opacity-5 backdrop-blur-[4px] blur-sm transition-opacity"></div>
    
      <div class="fixed inset-0 z-10 overflow-y-auto no-scrollbar flex justify-end">
      <div className="rounded-xl cursor-pointer self-stretch  justify-start items-end gap-2 inline-flex overflow-scroll no-scrollbar ">
        <div className="w-full max-h-[500px]  px-8 py-6 bg-white rounded-xl shadow flex-col justify-start items-start gap-[25px] inline-flex">
          <div className="w-full justify-start items-start gap-[25px] inline-flex">
            <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="p-3 justify-center items-center gap-1 flex">
                  <div className="w-[21.71px] h-4 relative">
                    <div className="w-[6.86px] h-2 left-0 top-[8px] absolute bg-slate-700 rounded-[0.89px]" />
                    <div className="w-[6.86px] h-4 left-[7.43px] top-0 absolute bg-slate-700 rounded-[0.89px]" />
                    <div className="w-[6.86px] h-[11.43px] left-[14.86px] top-[4.57px] absolute bg-slate-700 rounded-[0.89px]" />
                  </div>
                </div>
                <div className="grow shrink basis-0 text-teal-950 text-base font-bold font-satoshi leading-tight">
                  Leaderboard
                </div>
              </div>
              <div className="self-stretch text-slate-500 text-sm font-semibold font-satoshi leading-tight">
                Top partners in our mission to employ the next generation
              </div>
            </div>
            <div
              onClick={() => setIsLeaderboardClicked(!isLeaderboardClicked)}
              className="w-6 h-6 relative"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.15213 5.1515C5.37716 4.92654 5.68233 4.80016 6.00052 4.80016C6.31872 4.80016 6.62389 4.92654 6.84892 5.1515L12.0005 10.3031L17.1521 5.1515C17.2628 5.03689 17.3952 4.94547 17.5416 4.88258C17.688 4.81969 17.8455 4.78659 18.0048 4.7852C18.1642 4.78382 18.3222 4.81418 18.4697 4.87452C18.6171 4.93485 18.7511 5.02396 18.8638 5.13663C18.9765 5.2493 19.0656 5.38328 19.1259 5.53076C19.1862 5.67823 19.2166 5.83625 19.2152 5.99558C19.2138 6.15492 19.1807 6.31238 19.1178 6.45879C19.055 6.60519 18.9635 6.73761 18.8489 6.8483L13.6973 11.9999L18.8489 17.1515C19.0675 17.3778 19.1885 17.6809 19.1857 17.9956C19.183 18.3102 19.0568 18.6112 18.8343 18.8337C18.6118 19.0562 18.3108 19.1824 17.9962 19.1851C17.6816 19.1878 17.3784 19.0669 17.1521 18.8483L12.0005 13.6967L6.84892 18.8483C6.6226 19.0669 6.31948 19.1878 6.00484 19.1851C5.69021 19.1824 5.38923 19.0562 5.16674 18.8337C4.94425 18.6112 4.81805 18.3102 4.81532 17.9956C4.81258 17.6809 4.93354 17.3778 5.15213 17.1515L10.3037 11.9999L5.15213 6.8483C4.92716 6.62327 4.80078 6.3181 4.80078 5.9999C4.80078 5.68171 4.92716 5.37654 5.15213 5.1515Z"
                  fill="#99A6AD"
                />
              </svg>
            </div>
          </div>
          <div
            className={`w-full justify-center items-start gap-3 inline-flex h-[500px] overflow-scroll no-scrollbar pb-8`}
          >
            <div className=" flex-col justify-start items-start inline-flex overflow-scroll no-scrollbar">
              {/* Map over recruiterData and render each item */}
              {loading ? (
                <div className="w-full justify-start h-auto items-center">
                  <Loader />
                </div>
              ) : (
                recruiterData.map((recruiter, index) => (
                  <div
                    key={index}
                    className={`w-full justify-start items-center inline-flex ${
                      currentCompany === recruiter.companyName
                        ? "border border-green-500 gradient-50 shadow rounded-xl"
                        : "border-b"
                    }`}
                  >
                    <div className="w-40 self-stretch p-3 justify-start items-center gap-[30px] flex">
                      <div className="text-center text-teal-950 text-sm font-medium font-satoshi leading-tight">
                        {index + 1}.
                      </div>
                      <div className="w-7 h-7 justify-center items-center flex">
                        <img
                          className="w-7 h-7 rounded-[64px]"
                          src={recruiter.companyLogo}
                          alt={recruiter.companyName}
                        />
                      </div>
                    </div>
                    <div className="h-11 p-3 justify-start items-center gap-2 flex">
                      <div className="w-max grow shrink basis-0 text-teal-950 text-sm font-bold font-satoshi capitalize leading-tight">
                        {recruiter.companyName}
                      </div>
                    </div>
                    <div className="grow shrink basis-0 h-11 p-3 justify-end items-center gap-2 flex">
                      <div className="text-emerald-500 text-sm font-bold font-satoshi capitalize leading-tight">
                        {recruiter.hiredCandidates} hired
                      </div>
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="w-full h-max p-3 justify-start items-center gap-2 inline-flex ">
            <div
              onClick={() => navigate("/leaderboard")}
              className="w-full h-max text-gray-400 text-[10px] font-semibold font-satoshi leading-tight hover:cursor-pointer"
            >
              *You can change your leaderboard visibility from Account Settings
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};
