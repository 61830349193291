import axios from "axios";
import React, { useEffect, useState } from "react";
import Alert from "../../../components/alert/alert";
import BeepNavbar from "../../../components/navbar/navbar";
import ProfileHeading from "./heading";
import { Link } from "react-router-dom";
import BorderButton from "../../../components/buttons/UI_Buttons_2/borderButton";
import FillButtonV2 from "../../../components/buttons/UI_Buttons_2/fillButton";
import InputFieldComp from "../../../components/inputfields/UI_Inputs/InputFieldComp";
import InfoBox from "../../../components/inputfields/UI_Inputs/infoBox";
import ButtonChild from "../../../components/buttons/UI_Buttons_2/buttonChild";
import TextInput from "../../../components/inputfields/UI_Inputs/textInput";
import OnSaveChangeToggle from "../modals/onSaveChangesToggle";
import ProfileLogo from "../../../components/changeLogo/profileLogo";
import SingleSelectComp from "../../../components/menu/singleSelectComp";
import Loader from "../../../components/Loader/loader";

const MyProfileComp = ({
  setActiveSection,
  showDiscard,
  isChanged,
  setShowDiscard,
  setIsChanged,
}) => {
  const [userData, setUserData] = useState("");
  const [verifySent, setVerifySend] = useState([false, false]);
  const [showChangesSaved, setShowChangesSaved] = useState(false);
  const [alertData, setAlertData] = useState(null);
  const [step, setStep] = useState("change"); // 'change' or 'verify'
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isPhoneOtpSent, setIsPhoneOtpSent] = useState(false);
  const [isEmailOtpSent, setIsEmailOtpSent] = useState(false);

  const [changed, setChanged] = useState(false);
  const [missing, setMissing]= useState({})

  const onFormChange = (e) => {
    setUserData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });

    setIsChanged(true);
    setChanged(true);
    // setForm({ ...userData, [e.target.name]: e.target.value });
  };
   console.log(isChanged);
  const onLogoChange = async (file) => {
    setUserData((prev) => {
      return {
        ...prev,
        file,
      };
    });
    setIsChanged(true);
    setChanged(true);
    // await test();
  };
  const [selectedGender, setSelectedGender] = useState("");

  const onGenderChange = (e) => {
    setSelectedGender(e.target.value);
    setUserData({
      ...userData,
      gender: e.target.value,
    });
    setIsChanged(true);
    setChanged(true);
  };

  const onPhoneChange = (e) => {
    setPhoneNumber(e.target.value);
    setIsChanged(true);
    setChanged(true);

    // setForm({ ...userData, [e.target.name]: e.target.value });
  };

  const authToken = "Bearer " + localStorage.getItem("auth"); // Replace 'yourAuthToken' with the actual token value.
  const missingFields = {};
  const handleChangePhoneNumber = async () => {
    try {
      
      console.log(phoneNumber, userData?.phoneNumber, "kk");
      // if (phoneNumber === userData?.phoneNumber.slice(3)) missingFields["phoneNumber"] = true;
      if (!phoneNumber) missingFields["phoneNumber1"] = true;
  
      setMissing(missingFields);
      console.log(Object.keys(missingFields).length);
  
      if (Object.keys(missingFields).length === 0) {
        console.log("iioijjjj");
        const response = await axios.patch(
          `${process.env.REACT_APP_baseURL}/recruiter/change/phone`,
          { phoneNumber },
          {
            headers: {
              Authorization: authToken,
            },
          }
        );
  
        console.log(response, "jjjj");
  
        setUserData((prev) => ({
          ...prev,
          phoneNumber: phoneNumber,
        }));
  
        setMessage(response.message);
        setIsPhoneOtpSent(true);
        setStep("verify");
      }
    } catch (error) {
      console.log(error, "********************************");
      // missingFields["phoneNumber"] = true;
      if (error.response && error.response.data && error.response.data.message) {
        // missingFields["phoneNumber"] = true;
        setError(error.response.data.message); // Set the error message to the state
        // setAlertData(error.response?.data.message); // Displaying the error message using alert
      } else {
        setError("An error occurred while updating phone number."); // Default error message
        setAlertData("An error occurred while updating phone number."); // Displaying the default error message using alert
      }
    }
  };
  
  
  // console.log(localStorage.getItem('phoneNumber'))

  const handleVerifyOTP = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_baseURL}/recruiter/verify/phone`,
        { OTP },
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setMessage(response.data.message);
      setMobileBoxVisible(false);
      setPhoneNumber("");
      setOTP("");
      setStep("change");
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setChanged(true);

    // setForm({ ...userData, [e.target.name]: e.target.value });
  };
  const handleChangeEmail = async () => {
    try {
      const missingFields = {};
      console.log(email, userData?.email, "kk")
      if (email==userData?.email) missingFields["email"] = true;
      if (!email) missingFields["email1"] = true;

      setMissing(missingFields);
      console.log(missingFields, "lll");
      if (Object.keys(missingFields).length == 0) {
      const response = await axios.patch(
        `${process.env.REACT_APP_baseURL}/recruiter/change/email`,
        { email },
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      console.log(response);
      // localStorage.setItem("email", email);
      setUserData((prev) => ({
        ...prev,
        email: email,
      }));
      setMessage(response.data.message);
      setIsEmailOtpSent(true);
      setStep("verify");
    }
    } catch (error) {
      console.log(error)
      if (error.response && error.response.data && error.response.data.message) {
        // missingFields["phoneNumber"] = true;
        setError(error.response.data.message); // Set the error message to the state
        // setAlertData(error.response?.data.message); // Displaying the error message using alert
      } else {
        setError("An error occurred while updating email."); // Default error message
        setAlertData("An error occurred while updating email."); // Displaying the default error message using alert
      }
    }
  };
  // console.log(localStorage.getItem('email'))

  const handleEmailVerifyOTP = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_baseURL}/recruiter/verify/email`,
        { OTP },
        {
          headers: {
            Authorization: authToken,
          },
        }
      );
      setMessage(response.data.message);
      setMailBoxVisible(false);
      setEmail("");
      setOTP("");
      setStep("change");
    } catch (error) {
      setError(error.response.data.error);
    }
  };

  const onClicVerify = (index) => {
    setVerifySend((prev) => {
      let temp = [...prev];
      temp[index] = true;
      return temp;
    });
  };

  // useEffect(() => {
  //   // Get user data from the location state
  //   const authToken=localStorage.getItem("auth")
  //   // const authToken = location.state?.authToken;
  // console.log(authToken)
  //     if (authToken) {
  //       // If user data is not available in the location state, you can make an API request here
  //       const config = {
  //         headers: {
  //           Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
  //         },
  //       };
  //       axios
  //         .get(`${process.env.REACT_APP_baseURL}/recruiter/verify`, config)
  //         .then((response) => {
  //           console.log(response)
  //           setUserData(response?.data);
  //         })
  //         .catch((error) => {
  //           console.error('Error fetching user data:', error);

  //         });
  //     }
  // }, []);

  const loadProfile = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/verify`;

    // console.log(jobId);
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
      });
    console.log(response);
    if (response?.data) {
      console.log(response?.data);
      setUserData(response?.data);
    }
  };

  const updateMyProfile = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter`;
    let formData = new FormData();
    if (userData.file) formData.append("upload", userData.file[0]);
    formData.append("name", userData.name);
    formData.append("position", userData.position);
    formData.append("gender", userData.gender);

    // console.log(jobId);
    console.log(url);
    const response = await axios
      .patch(url, formData, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
      });

    if (response?.data) {
      console.log(response?.data.userdata);
      console.log(userData);
      setUserData(response?.data.userdata);
      setIsChanged(false);

      setShowChangesSaved(true);
      setTimeout(() => setShowChangesSaved(false), 3000);
      if (showDiscard) {
        setActiveSection(showDiscard);
        setShowDiscard(false);
      }
    }
  };

  console.log(userData.google_SSO_Id);
  const sendVerificationEmail = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/send_verification_email`;

    // console.log(jobId);
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
      });

    if (response?.data) {
      onClicVerify(1);
    }
  };

  useEffect(loadProfile, []);

  const [isMobileBoxVisible, setMobileBoxVisible] = useState(false);
  const [isMailBoxVisible, setMailBoxVisible] = useState(false);

  const toggleMobileBoxVisibility = () => {
    setMobileBoxVisible(!isMobileBoxVisible);
    setMailBoxVisible(false);
  };

  const toggleMailBoxVisibility = () => {
    setMailBoxVisible(!isMailBoxVisible);
    setMobileBoxVisible(false);
  };

  const [showLogOutBox, setShowLogOutBox] = useState(false);

  const close = () => {
    setShowLogOutBox((prev) => !prev);
  };
  // const onReset = () => {
  //   setForm({
  //     phoneNumber: '',
  //     isVerified: false,
  //   });
  // };

  // const onVerify = async () => {
  //   if (!form.phoneNumber) {
  //     setAlertData("Phone number required");
  //     return;
  //   }

  //   const url = `${process.env.REACT_APP_baseURL}/recruiter/verify/phone`;
  //   try {
  //     const response = await axios.post(url, { identifier: form.phoneNumber });
  //     localStorage.setItem("changedNumber", form.phoneNumber);
  //     const authToken = localStorage.setItem("auth",form.phoneNumber )
  //     // navigator("/resetpassword/otp", {state: {authToken:authToken}});
  //     console.log(response.data);
  //   } catch (error) {
  //     console.log(error);

  //     if (error.response && error.response.data.message) {
  //       setAlertData(error.response.data.message);
  //     }
  //   }
  // };
  return (
    <>
      {/* <div className="sm:hidden">
        <BeepNavbar />

        

      </div> */}

      <div className="Menu w-full sm:w-[75vw] border-l border-gray-900 border-opacity-10 min-h-screen xl:w-[50vw] sm:pt-[3rem] sm:m-0 mb-8 overflow-scroll no-scrollbar grow shrink basis-0 self-stretch p-spaceBase sm:px-spaceSLarge sm:py-spaceLarge bg-white flex-col justify-start items-start inline-flex">
        {/* <Link to="/profile">
          <div className="sm:hidden mb-4  flex flex-row ">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5.80781 10.4427L12.0578 16.6927C12.1452 16.7802 12.2566 16.8398 12.3779 16.8639C12.4992 16.8881 12.625 16.8757 12.7392 16.8284C12.8535 16.781 12.9512 16.7009 13.0198 16.598C13.0885 16.4951 13.1251 16.3742 13.125 16.2505V3.75049C13.1251 3.62681 13.0885 3.50587 13.0198 3.403C12.9512 3.30013 12.8535 3.21995 12.7392 3.1726C12.625 3.12526 12.4992 3.11288 12.3779 3.13704C12.2566 3.16119 12.1452 3.2208 12.0578 3.3083L5.80781 9.5583C5.7497 9.61635 5.7036 9.68528 5.67215 9.76115C5.6407 9.83703 5.62451 9.91836 5.62451 10.0005C5.62451 10.0826 5.6407 10.164 5.67215 10.2398C5.7036 10.3157 5.7497 10.3846 5.80781 10.4427Z" fill="#002032" fill-opacity="0.6" />
            </svg>
            </div>
            <span className='text-gray-500 font-bold leading-tight'>Go Back</span>
          </div>
        </Link> */}
        {!userData && (
          <>
        
            <div className=" m-auto"><Loader /></div>
          </>
        )}

        {userData && (
          <>
            <div className="InputFields self-stretch h-fit flex-col justify-start items-start flex">
              <div className="text-teal-950 text-[32px] font-black font-['Satoshi Variable'] leading-[38.40px] mb-spaceXxLarge sm:mb-spaceSLarge">
                My Profile
              </div>
              <div className="w-max h-16 mb-[16px] sm:mb-[32px] rounded-[32px] justify-start items-center gap-3 inline-flex">
                <ProfileLogo
                  defaultLogo={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M16.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM3.75 3.75H16.25V9.79531L14.3211 7.86562C14.0867 7.63138 13.7689 7.4998 13.4375 7.4998C13.1061 7.4998 12.7883 7.63138 12.5539 7.86562L4.16953 16.25H3.75V3.75ZM6.25 7.5C6.25 7.25277 6.32331 7.0111 6.46066 6.80554C6.59801 6.59998 6.79324 6.43976 7.02165 6.34515C7.25005 6.25054 7.50139 6.22579 7.74386 6.27402C7.98634 6.32225 8.20907 6.4413 8.38388 6.61612C8.5587 6.79093 8.67775 7.01366 8.72598 7.25614C8.77421 7.49861 8.74946 7.74995 8.65485 7.97835C8.56024 8.20676 8.40002 8.40199 8.19446 8.53934C7.9889 8.67669 7.74723 8.75 7.5 8.75C7.16848 8.75 6.85054 8.6183 6.61612 8.38388C6.3817 8.14946 6.25 7.83152 6.25 7.5Z"
                        fill="#16C083"
                      />
                    </svg>
                  }
                  onLogoChange={onLogoChange}
                  currentLogo={userData.profile}
                />
                <div className="grow shrink basis-0 text-slate-500 text-sm font-medium font-satoshi leading-tight">
                  Profile Logo
                </div>
              </div>

              <div className="Row flex-col sm:flex-row w-full self-stretch justify-between sm:gap-8 items-center inline-flex">
                <div className="w-full">
                  <InputFieldComp
                    textInputClass={`w-full`}
                    labelText="Full Name"
                    type="input"
                    placeholder="Full Name"
                    onChange={onFormChange}
                    value={userData.name}
                    name="name"
                  />
                </div>
                <div className="w-full py-4">
                  <SingleSelectComp
                   labelText="Gender"
                   options={["Male", "Female", "Other"]}
                    absoluteClass=""
                    // hasError={error.gender}
                    // errorMsg={error.gender}
                    selectedOption={userData.gender}
                    onChange={onGenderChange}
                  />
                </div>
              </div>

              <div className="Row flex-col sm:flex-row w-full self-stretch justify-between gap-8 items-center inline-flex">
                <div className="w-full">
                  <InputFieldComp
                    textInputClass={`w-full`}
                    labelText="Designation"
                    type="input"
                    name="position"
                    placeholder="Designation"
                    onChange={onFormChange}
                    value={userData.position}
                  />
                </div>
              </div>
              <div className="Row flex-col sm:flex-col md:flex-row self-stretch justify-start items-start gap-8 sm:gap-4 lg:gap-8 mb-spaceBase sm:mb-spaceXxLarge mt-4 inline-flex">
                <div className="Type grow w-full shrink basis-0 flex-col justify-start gap-1 inline-flex">
                  <div>
                    <InfoBox
                      className={`w-full`}
                      infoClass={`border-2`}
                      variant="standard"
                      labelText="Mobile"
                    
                      infoText={`${userData.phoneNumber}`}
                      onChange={onFormChange}
                      name="phoneNumber"
                      buttonLeftIcon={
                        <div onClick={toggleMobileBoxVisibility}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M17.7586 5.73201L14.268 2.24061C14.1519 2.1245 14.0141 2.0324 13.8624 1.96957C13.7107 1.90673 13.5482 1.87439 13.384 1.87439C13.2198 1.87439 13.0572 1.90673 12.9056 1.96957C12.7539 2.0324 12.6161 2.1245 12.5 2.24061L2.86641 11.875C2.74983 11.9906 2.65741 12.1283 2.59451 12.28C2.5316 12.4317 2.49948 12.5944 2.50001 12.7586V16.25C2.50001 16.5815 2.6317 16.8994 2.86612 17.1339C3.10054 17.3683 3.41849 17.5 3.75001 17.5H7.24141C7.40563 17.5005 7.5683 17.4684 7.71999 17.4055C7.87168 17.3426 8.00935 17.2502 8.12501 17.1336L17.7586 7.49998C17.8747 7.3839 17.9668 7.24609 18.0296 7.09442C18.0925 6.94274 18.1248 6.78017 18.1248 6.616C18.1248 6.45182 18.0925 6.28925 18.0296 6.13758C17.9668 5.9859 17.8747 5.84809 17.7586 5.73201ZM15 8.49061L11.5086 4.99998L13.3836 3.12498L16.875 6.61561L15 8.49061Z"
                              fill="#16C083"
                            />
                          </svg>
                        </div>
                      }
                    />
                  </div>

                  <div className={`${userData.phoneNumberVerified && "hidden"}`}>
                    {!verifySent[0] && (
                      <div
                        className={`Alert w-auto h-[42px] bg-red-600 bg-opacity-5 rounded-xl justify-start items-start inline-flex `}
                      >
                        <div className="Input grow shrink basis-0 h-[42px] p-3 rounded-xl justify-start items-center gap-2 flex">
                          <div className="InputFieldIconsShieldwarning w-[18px] h-[18px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M14.625 2.8125H3.375C3.07663 2.8125 2.79048 2.93103 2.5795 3.142C2.36853 3.35298 2.25 3.63913 2.25 3.9375V8.06977C2.25 14.3712 7.58109 16.4609 8.64844 16.8152C8.87632 16.8932 9.12368 16.8932 9.35156 16.8152C10.4203 16.4602 15.75 14.3705 15.75 8.06906V3.9375C15.75 3.63913 15.6315 3.35298 15.4205 3.142C15.2095 2.93103 14.9234 2.8125 14.625 2.8125ZM8.4375 6.75C8.4375 6.60082 8.49676 6.45774 8.60225 6.35225C8.70774 6.24676 8.85082 6.1875 9 6.1875C9.14918 6.1875 9.29226 6.24676 9.39775 6.35225C9.50324 6.45774 9.5625 6.60082 9.5625 6.75V9.5625C9.5625 9.71168 9.50324 9.85476 9.39775 9.96025C9.29226 10.0657 9.14918 10.125 9 10.125C8.85082 10.125 8.70774 10.0657 8.60225 9.96025C8.49676 9.85476 8.4375 9.71168 8.4375 9.5625V6.75ZM9 12.9375C8.83312 12.9375 8.66999 12.888 8.53124 12.7953C8.39248 12.7026 8.28434 12.5708 8.22048 12.4166C8.15662 12.2625 8.13991 12.0928 8.17246 11.9291C8.20502 11.7655 8.28538 11.6151 8.40338 11.4971C8.52138 11.3791 8.67172 11.2988 8.83539 11.2662C8.99906 11.2337 9.16871 11.2504 9.32289 11.3142C9.47706 11.3781 9.60884 11.4862 9.70155 11.625C9.79426 11.7637 9.84375 11.9269 9.84375 12.0938C9.84375 12.3175 9.75485 12.5321 9.59662 12.6904C9.43839 12.8486 9.22378 12.9375 9 12.9375Z"
                                fill="#DB1717"
                              />
                            </svg>
                          </div>
                          <div className="ThisMobileIsNotVerified text-red-600 text-xs font-medium leading-[18px]">
                            This mobile is not verified.
                          </div>
                        </div>
                        <div className="Input p-3 rounded-xl justify-start items-center gap-2 flex">
                          <div className="Button rounded-xl justify-start items-start gap-2 flex">
                            <button
                              className="VerifyNow text-right text-red-600 text-xs font-bold leading-[18px]"
                              onClick={() => onClicVerify(0)}
                            >
                              Verify Now
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {verifySent[0] && (
                      <div className="Input w-auto h-[52px] px-3 py-2 bg-amber-300 bg-opacity-5 rounded-xl justify-start items-start gap-2 inline-flex">
                        <div className="InputFieldIconsPaperplanetilt w-[18px] h-[18px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M16.2711 3.1185C16.2711 3.1185 16.2711 3.12553 16.2711 3.12904L12.1789 16.6248C12.117 16.844 11.9895 17.039 11.8135 17.1837C11.6376 17.3284 11.4216 17.4157 11.1946 17.4341C11.1622 17.4369 11.1299 17.4383 11.0975 17.4383C10.8848 17.439 10.6763 17.3788 10.4966 17.2648C10.3169 17.1508 10.1736 16.9878 10.0836 16.795L7.57277 11.4941C7.54798 11.4417 7.54001 11.3829 7.54996 11.3257C7.5599 11.2686 7.58727 11.2159 7.62832 11.1749L11.6544 7.1481C11.7555 7.04176 11.8109 6.90015 11.8091 6.75347C11.8072 6.60679 11.7481 6.46665 11.6444 6.36292C11.5406 6.2592 11.4005 6.20009 11.2538 6.19822C11.1071 6.19634 10.9655 6.25183 10.8592 6.35287L6.82676 10.379C6.78577 10.42 6.7331 10.4474 6.67595 10.4573C6.6188 10.4673 6.55998 10.4593 6.50754 10.4345L1.2341 7.93631C1.02699 7.84142 0.852696 7.68721 0.733287 7.4932C0.613878 7.29919 0.554725 7.07411 0.56332 6.84646C0.574772 6.61249 0.659478 6.38803 0.805463 6.20482C0.951447 6.02162 1.15132 5.88893 1.37684 5.82553L14.8726 1.73334H14.8832C15.0753 1.67935 15.2784 1.67746 15.4715 1.72786C15.6646 1.77825 15.8409 1.87912 15.9821 2.0201C16.1234 2.16109 16.2246 2.33713 16.2754 2.53015C16.3262 2.72318 16.3247 2.92623 16.2711 3.1185Z"
                              fill="#FFB941"
                            />
                          </svg>
                        </div>
                        <div className="WeHaveSentAnSmsToThisMobileForVerification w-auto text-amber-300 text-xs font-medium leading-[18px]">
                          We have sent an SMS to this Mobile for verification.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="Type grow w-full shrink basis-0 flex-col justify-start gap-1 inline-flex">
                  <div>
                    <InfoBox
                      infoClass={`border-2`}
                      variant="standard"
                      labelText="Email"
                      infoText={`${userData.email}`}
                      onChange={onFormChange}
                      name="email"
                      buttonLeftIcon={
                        <div>
                          {!userData.google_SSO_Id ? (
                            <div onClick={toggleMailBoxVisibility}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M17.7586 5.73201L14.268 2.24061C14.1519 2.1245 14.0141 2.0324 13.8624 1.96957C13.7107 1.90673 13.5482 1.87439 13.384 1.87439C13.2198 1.87439 13.0572 1.90673 12.9056 1.96957C12.7539 2.0324 12.6161 2.1245 12.5 2.24061L2.86641 11.875C2.74983 11.9906 2.65741 12.1283 2.59451 12.28C2.5316 12.4317 2.49948 12.5944 2.50001 12.7586V16.25C2.50001 16.5815 2.6317 16.8994 2.86612 17.1339C3.10054 17.3683 3.41849 17.5 3.75001 17.5H7.24141C7.40563 17.5005 7.5683 17.4684 7.71999 17.4055C7.87168 17.3426 8.00935 17.2502 8.12501 17.1336L17.7586 7.49998C17.8747 7.3839 17.9668 7.24609 18.0296 7.09442C18.0925 6.94274 18.1248 6.78017 18.1248 6.616C18.1248 6.45182 18.0925 6.28925 18.0296 6.13758C17.9668 5.9859 17.8747 5.84809 17.7586 5.73201ZM15 8.49061L11.5086 4.99998L13.3836 3.12498L16.875 6.61561L15 8.49061Z"
                                  fill="#16C083"
                                />
                              </svg>
                            </div>
                          ) : (
                            <div>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                {/* <path d="M17.7586 5.73201L14.268 2.24061C14.1519 2.1245 14.0141 2.0324 13.8624 1.96957C13.7107 1.90673 13.5482 1.87439 13.384 1.87439C13.2198 1.87439 13.0572 1.90673 12.9056 1.96957C12.7539 2.0324 12.6161 2.1245 12.5 2.24061L2.86641 11.875C2.74983 11.9906 2.65741 12.1283 2.59451 12.28C2.5316 12.4317 2.49948 12.5944 2.50001 12.7586V16.25C2.50001 16.5815 2.6317 16.8994 2.86612 17.1339C3.10054 17.3683 3.41849 17.5 3.75001 17.5H7.24141C7.40563 17.5005 7.5683 17.4684 7.71999 17.4055C7.87168 17.3426 8.00935 17.2502 8.12501 17.1336L17.7586 7.49998C17.8747 7.3839 17.9668 7.24609 18.0296 7.09442C18.0925 6.94274 18.1248 6.78017 18.1248 6.616C18.1248 6.45182 18.0925 6.28925 18.0296 6.13758C17.9668 5.9859 17.8747 5.84809 17.7586 5.73201ZM15 8.49061L11.5086 4.99998L13.3836 3.12498L16.875 6.61561L15 8.49061Z" fill="#16C083" /> */}
                              </svg>
                            </div>
                          )}
                        </div>
                      }
                    />
                  </div>

                  <div className={`${userData.emailVerified && "hidden"}`}>
                    {!verifySent[1] && (
                      <div
                        className={`Alert w-auto h-[42px] bg-red-600 bg-opacity-5 rounded-xl justify-start items-start inline-flex  `}
                      >
                        <div className="Input grow shrink basis-0 h-[42px] p-3 rounded-xl justify-start items-center gap-2 flex">
                          <div className="InputFieldIconsShieldwarning w-[18px] h-[18px]">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M14.625 2.8125H3.375C3.07663 2.8125 2.79048 2.93103 2.5795 3.142C2.36853 3.35298 2.25 3.63913 2.25 3.9375V8.06977C2.25 14.3712 7.58109 16.4609 8.64844 16.8152C8.87632 16.8932 9.12368 16.8932 9.35156 16.8152C10.4203 16.4602 15.75 14.3705 15.75 8.06906V3.9375C15.75 3.63913 15.6315 3.35298 15.4205 3.142C15.2095 2.93103 14.9234 2.8125 14.625 2.8125ZM8.4375 6.75C8.4375 6.60082 8.49676 6.45774 8.60225 6.35225C8.70774 6.24676 8.85082 6.1875 9 6.1875C9.14918 6.1875 9.29226 6.24676 9.39775 6.35225C9.50324 6.45774 9.5625 6.60082 9.5625 6.75V9.5625C9.5625 9.71168 9.50324 9.85476 9.39775 9.96025C9.29226 10.0657 9.14918 10.125 9 10.125C8.85082 10.125 8.70774 10.0657 8.60225 9.96025C8.49676 9.85476 8.4375 9.71168 8.4375 9.5625V6.75ZM9 12.9375C8.83312 12.9375 8.66999 12.888 8.53124 12.7953C8.39248 12.7026 8.28434 12.5708 8.22048 12.4166C8.15662 12.2625 8.13991 12.0928 8.17246 11.9291C8.20502 11.7655 8.28538 11.6151 8.40338 11.4971C8.52138 11.3791 8.67172 11.2988 8.83539 11.2662C8.99906 11.2337 9.16871 11.2504 9.32289 11.3142C9.47706 11.3781 9.60884 11.4862 9.70155 11.625C9.79426 11.7637 9.84375 11.9269 9.84375 12.0938C9.84375 12.3175 9.75485 12.5321 9.59662 12.6904C9.43839 12.8486 9.22378 12.9375 9 12.9375Z"
                                fill="#DB1717"
                              />
                            </svg>
                          </div>
                          <div className="ThisMobileIsNotVerified text-red-600 text-xs font-medium leading-[18px]">
                            This Email is not verified.
                          </div>
                        </div>
                        <div className="Input p-3 rounded-xl justify-start items-center gap-2 flex">
                          <div className="Button rounded-xl justify-start items-start gap-2 flex">
                            <button
                              className="VerifyNow text-right text-red-600 text-xs font-bold leading-[18px]"
                              onClick={sendVerificationEmail}
                            >
                              Verify Now
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {verifySent[1] && (
                      <div className="Input w-auto h-[34px] px-3 py-2 bg-amber-300 bg-opacity-5 rounded-xl justify-start items-start gap-2 inline-flex">
                        <div className="InputFieldIconsPaperplanetilt w-[18px] h-[18px]">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M16.2711 3.1185C16.2711 3.1185 16.2711 3.12553 16.2711 3.12904L12.1789 16.6248C12.117 16.844 11.9895 17.039 11.8135 17.1837C11.6376 17.3284 11.4216 17.4157 11.1946 17.4341C11.1622 17.4369 11.1299 17.4383 11.0975 17.4383C10.8848 17.439 10.6763 17.3788 10.4966 17.2648C10.3169 17.1508 10.1736 16.9878 10.0836 16.795L7.57277 11.4941C7.54798 11.4417 7.54001 11.3829 7.54996 11.3257C7.5599 11.2686 7.58727 11.2159 7.62832 11.1749L11.6544 7.1481C11.7555 7.04176 11.8109 6.90015 11.8091 6.75347C11.8072 6.60679 11.7481 6.46665 11.6444 6.36292C11.5406 6.2592 11.4005 6.20009 11.2538 6.19822C11.1071 6.19634 10.9655 6.25183 10.8592 6.35287L6.82676 10.379C6.78577 10.42 6.7331 10.4474 6.67595 10.4573C6.6188 10.4673 6.55998 10.4593 6.50754 10.4345L1.2341 7.93631C1.02699 7.84142 0.852696 7.68721 0.733287 7.4932C0.613878 7.29919 0.554725 7.07411 0.56332 6.84646C0.574772 6.61249 0.659478 6.38803 0.805463 6.20482C0.951447 6.02162 1.15132 5.88893 1.37684 5.82553L14.8726 1.73334H14.8832C15.0753 1.67935 15.2784 1.67746 15.4715 1.72786C15.6646 1.77825 15.8409 1.87912 15.9821 2.0201C16.1234 2.16109 16.2246 2.33713 16.2754 2.53015C16.3262 2.72318 16.3247 2.92623 16.2711 3.1185Z"
                              fill="#FFB941"
                            />
                          </svg>
                        </div>
                        <div className="WeHaveSentALinkToThisEmailForVerification w-auto text-amber-300 text-xs font-medium leading-[18px]">
                          We have sent a link to this Email for verification.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* Edit mobile */}
              {isMobileBoxVisible && (
                <div className="w-full bg-gray-100 h-auto p-spaceLarge rounded-[44px] flex-col justify-start items-start gap-8 inline-flex">
                  <div className="self-stretch justify-start items-center gap-4 inline-flex">
                    <div className="grow shrink basis-0 text-teal-950 text-xl font-black font-['Satoshi Variable'] leading-normal">
                      Edit Mobile
                    </div>
                    <div className="p-1 rounded-xl justify-center items-center gap-1 flex">
                      <div
                        className="w-5 h-5 relative cursor-pointer"
                        onClick={toggleMobileBoxVisibility}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M16.067 15.1828C16.1251 15.2409 16.1712 15.3098 16.2026 15.3857C16.234 15.4616 16.2502 15.5429 16.2502 15.625C16.2502 15.7071 16.234 15.7885 16.2026 15.8643C16.1712 15.9402 16.1251 16.0091 16.067 16.0672C16.009 16.1253 15.94 16.1713 15.8642 16.2028C15.7883 16.2342 15.707 16.2504 15.6249 16.2504C15.5427 16.2504 15.4614 16.2342 15.3855 16.2028C15.3097 16.1713 15.2407 16.1253 15.1827 16.0672L9.99986 10.8836L4.81705 16.0672C4.69977 16.1845 4.54071 16.2504 4.37486 16.2504C4.20901 16.2504 4.04995 16.1845 3.93267 16.0672C3.8154 15.9499 3.74951 15.7909 3.74951 15.625C3.74951 15.4592 3.8154 15.3001 3.93267 15.1828L9.11627 10L3.93267 4.8172C3.8154 4.69992 3.74951 4.54086 3.74951 4.37501C3.74951 4.20916 3.8154 4.0501 3.93267 3.93282C4.04995 3.81555 4.20901 3.74966 4.37486 3.74966C4.54071 3.74966 4.69977 3.81555 4.81705 3.93282L9.99986 9.11642L15.1827 3.93282C15.2999 3.81555 15.459 3.74966 15.6249 3.74966C15.7907 3.74966 15.9498 3.81555 16.067 3.93282C16.1843 4.0501 16.2502 4.20916 16.2502 4.37501C16.2502 4.54086 16.1843 4.69992 16.067 4.8172L10.8835 10L16.067 15.1828Z"
                            fill="#667984"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-between items-center gap-8 inline-flex flex-col sm:flex-row">
                    {/* {step === 'change' && ( */}
                    <div>
                      <InputFieldComp
                        // textInputClass={`w-full`}
                        disabled={isPhoneOtpSent ? true : false}
                        labelText="New Mobile"
                        hasError={missing?.phoneNumber || missing?.phoneNumber1 || error}
                        errorMsg={missing?.phoneNumber || error ? error : missing?.phoneNumber1 ? "Phone Number is Required" : ""}
                        inputLeftText={`+91`}
                        buttonClass={`bg-white`}
                        type="input"
                        htmlFor="phoneNumber"
                        placeholder="Enter New Number"
                        onChange={onPhoneChange}
                        value={phoneNumber}
                        rightIcon={
                          <div>
                            {/* {!form.phoneNumber && */}

                            <div onClick={handleChangePhoneNumber}>
                              <ButtonChild 
                              disabled={isPhoneOtpSent ? true : false}
                              textColor="green" text={isPhoneOtpSent ? `Sent` : `Reset`} />
                            </div>
                            {/* } */}
                            {/* {(form.phoneNumber || "") && */}
                            {/* <div onClick={onVerify}>
      <ButtonChild
      textColor="green"
      text={`Verify`}
      />
      </div> */}
                            {/* } */}
                          </div>
                        }
                        name="phoneNumber"
                      />
                    </div>
                    {/* )} */}

                    {/* {step === 'verify' && ( */}
                    <div className="">
                      <TextInput
                        buttonClass={`bg-white`}
                        placeClass={"tracking-[10px]"}
                        labelText="Enter OTP"
                        textColor="gray"
                        textType="input"
                        type="text"
                        htmlFor="OTP"
                        name="OTP"
                        placeholder="000000"
                        value={OTP}
                        onChange={(e) => setOTP(e.target.value)}
                        rightIcon={
                          <div onClick={handleVerifyOTP}>
                            <ButtonChild
                              textColor="green"
                              text={`verify`}
                              rightIcon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              }
                            />
                          </div>
                        }
                      />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              )}
              {/* Edit email */}
              {isMailBoxVisible && (
                <div className="w-full bg-gray-100 h-auto p-spaceLarge rounded-[44px] flex-col justify-start items-start gap-8 inline-flex">
                  <div className="self-stretch justify-start items-center gap-4 inline-flex">
                    <div className="grow shrink basis-0 text-teal-950 text-xl font-black font-['Satoshi Variable'] leading-normal">
                      Edit Email
                    </div>
                    <div className="p-1 rounded-xl justify-center items-center gap-1 flex">
                      <div
                        className="w-5 h-5 relative cursor-pointer"
                        onClick={toggleMailBoxVisibility}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M16.067 15.1828C16.1251 15.2409 16.1712 15.3098 16.2026 15.3857C16.234 15.4616 16.2502 15.5429 16.2502 15.625C16.2502 15.7071 16.234 15.7885 16.2026 15.8643C16.1712 15.9402 16.1251 16.0091 16.067 16.0672C16.009 16.1253 15.94 16.1713 15.8642 16.2028C15.7883 16.2342 15.707 16.2504 15.6249 16.2504C15.5427 16.2504 15.4614 16.2342 15.3855 16.2028C15.3097 16.1713 15.2407 16.1253 15.1827 16.0672L9.99986 10.8836L4.81705 16.0672C4.69977 16.1845 4.54071 16.2504 4.37486 16.2504C4.20901 16.2504 4.04995 16.1845 3.93267 16.0672C3.8154 15.9499 3.74951 15.7909 3.74951 15.625C3.74951 15.4592 3.8154 15.3001 3.93267 15.1828L9.11627 10L3.93267 4.8172C3.8154 4.69992 3.74951 4.54086 3.74951 4.37501C3.74951 4.20916 3.8154 4.0501 3.93267 3.93282C4.04995 3.81555 4.20901 3.74966 4.37486 3.74966C4.54071 3.74966 4.69977 3.81555 4.81705 3.93282L9.99986 9.11642L15.1827 3.93282C15.2999 3.81555 15.459 3.74966 15.6249 3.74966C15.7907 3.74966 15.9498 3.81555 16.067 3.93282C16.1843 4.0501 16.2502 4.20916 16.2502 4.37501C16.2502 4.54086 16.1843 4.69992 16.067 4.8172L10.8835 10L16.067 15.1828Z"
                            fill="#667984"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="self-stretch justify-between items-center gap-8 inline-flex flex-col sm:flex-row">
                    {/* {step === 'change' && ( */}
                    <div>
                      <InputFieldComp
                        // textInputClass={`w-full`}
                        labelText="New Email"
                        // inputLeftText={`+91`}
                        disabled={isEmailOtpSent ? true : false}
                        hasError={missing?.email || missing?.email1 || error}
                        errorMsg={missing?.email || error ? error : missing?.email1 ? "Phone Number is Required" : ""}
                        buttonClass={`bg-white`}
                        type="input"
                        htmlFor="email"
                        placeholder="Enter New Email"
                        onChange={onEmailChange}
                        value={email}
                        rightIcon={
                          <div>
                            <div onClick={handleChangeEmail}>
                              <ButtonChild
                                textColor="green"
                                textClass={`w-max`}
                                disabled={isEmailOtpSent ? true : false}
                                text={isEmailOtpSent ? `OTP Sent` : `Reset`}
                              />
                            </div>
                          </div>
                        }
                        name="email"
                      />
                    </div>
                    {/* )} */}

                    {/* {step === 'verify' && ( */}
                    <div className="">
                      <TextInput
                        buttonClass={`bg-white`}
                        placeClass={"tracking-[10px]"}
                        labelText="Enter OTP"
                        textColor="gray"
                        textType="input"
                        type="text"
                        htmlFor="OTP"
                        name="OTP"
                        placeholder="000000"
                        value={OTP}
                        onChange={(e) => setOTP(e.target.value)}
                        rightIcon={
                          <div onClick={handleEmailVerifyOTP}>
                            <ButtonChild
                              textColor="green"
                              text={`verify`}
                              rightIcon={
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              }
                            />
                          </div>
                        }
                      />
                    </div>
                    {/* )} */}
                  </div>
                </div>
              )}
              <div className="Cta flex-col w-full sm:w-max sm:flex-row justify-start items-start gap-4 inline-flex mt-spaceSLarge">
                <BorderButton
                  className="w-full"
                  onClick={loadProfile}
                  text="Reset Changes"
                  color="gray"
                  textColor="gray"
                  disabled={changed ? false : true}
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z"
                        fill="currentColor"
                        fill-opacity="0.7"
                      />
                    </svg>
                  }
                />

                <BorderButton
                  className="w-full"
                  onClick={updateMyProfile}
                  text="Save Changes"
                  color="green"
                  textColor="green"
                  disabled={changed ? false : true}
                  leftIcon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                        fill="currentColor"
                      />
                    </svg>
                  }
                />
                {showDiscard && (
                  <OnSaveChangeToggle
                    close={() => {
                      setShowDiscard(false);
                    }}
                    onSave={updateMyProfile}
                  />
                )}

                {showChangesSaved && (
                  <div className="py-4 flex">
                    <div className="Checkcircle  w-5 h-5  text-emerald-500 ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                    <div className="SaveChanges text-emerald-500 text-sm font-bold leading-tight px-1 py-0.5">
                      Changes saved
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {alertData && <Alert text={alertData} alertClose={() => setAlertData(null)} time={3} />}
      </div>
    </>
  );
};

export default MyProfileComp;
