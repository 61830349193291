import React, { useEffect, useState } from "react";
import "./App.css";
import MainROutes from "./routes/mainRoutes";
import { getToken } from "firebase/messaging";
import { onMessageListener, requestForToken } from "./services/firebase";
import NotificationAlert from "./components/alert/Notification";
import { clarity } from 'react-microsoft-clarity';
import { Mixpanel } from "./services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import moment from "moment";

const App = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState(null);
  const [isTokenFound, setTokenFound] = useState(false);
  const closeAlert = () => setNotification(null);

  requestForToken(setTokenFound);
  const auth = localStorage.getItem("auth");

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(()=>{
clarity.init("jwlw952fuu")
  },[])

  return (
    <>
      <MainROutes />
      {notification && (
        <NotificationAlert
          notification={notification}
          alertClose={closeAlert}
          time={2}
        />
      )}
    </>
  );
};

export default App;


// get - company/search - query supported companyName, limit, skip
// post - '/raiseverification/:companyId' - raise company request - you can send duplicate request too that will update new comapny id.
// get - '/verify' - give user's data  - auth token needed
// patch -'/email_or_phone' - change user email / phone on onboarding

// {phoneNumber, email } - send only the the data needs to change
// post - '/sso/verify' -  on user successfull sso login call this function

// { token, service: 0-"google"| 1-"linkedin", fcmToken} - req body
// post - '/sso/update'  - update sso user profile for create account page also same - but use this only for sso if create account then use old api
// for uploading user profile picute send in formdata as "upload"