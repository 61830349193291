import React, {useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import BeepNavbar from "../../../components/navbar/navbar";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { Mixpanel } from "../../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import moment from "moment";

const Pricing = () => {

    // const [select, isSelect] = useState('');

   const navigate = useNavigate();
   const auth = localStorage.getItem('auth');
   const topRef = useRef(null);

      const handlePlanChangeAndRedirect = (value) => {
        // You can perform additional logic if needed
        console.log(`Selected plan: ${value}`);
        localStorage.setItem('plan', value);
    
        // Redirect to the "/checkout" path
        navigate('/pricing/checkout');
      };
    
  


    const cancelPayment = async () => {
        try {
          const response = await axios.get(

            // `https://23a3-117-214-21-182.ngrok-free.app/recruiter/cancel/payment/recruiter/cancel/payment`,
            `${process.env.REACT_APP_baseURL}/recruiter/cancel/payment`,
            {
              headers: {
                Authorization:auth,
              },
            }
          );
          console.log(response.data)
           localStorage.removeItem('isPremium');
         
          return response.data;
        } catch (error) {
          console.log(error);
          throw error;
        }
      };

      const generagePromoCode = async () => {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_baseURL}/admin/promocode`,
            {
                promoCode:"10_PERCENTAGE",
                promocodeCount:100,
                description:"test",
                amount:10000,
                planId:1,
            },
            {
            //   headers: {
            //     Authorization:auth,
            //   },
            }
          );
          console.log(response.data)
         
          return response.data;
        } catch (error) {
          console.log(error);
          throw error;
        }
      };

      useEffect(()=>{
        topRef?.current?.scrollIntoView({ behavior: "smooth" });
        if(!auth)
        navigate("/")
      },[])
   
  return <div className="gradient-50 h-[100vh]">
   <BeepNavbar />
  <div ref={topRef} className="w-full m-auto h-[100vh] pt-14 flex flex-col justify-between items-center bg-white">
   
<div className="flex flex-col justify-center items-center">
<div className="text-teal-950 text-5xl font-bold font-satoshi leading-[57.60px] my-8">Pricing Plans
</div>
   
    <div className="w-screen flex flex-wrap justify-center items-center gap-6 sm:gap-3">

        <div className="w-full sx:w-[280px] h-[1090px] mx-2 sm:mx-0 px-3 pt-[25px] pb-3 bg-white rounded-2xl border-2 border-gray-100  justify-start items-start gap-2.5 flex">
            <div className="w-full h-full p-4 md:p-8 flex-col justify-between  items-center lg:items-start gap-[60px] inline-flex">
                <div className="w-full  h-full top-0 left-0 flex-col justify-start items-start gap-[50px] inline-flex">
    <div className="text-teal-950 text-[32px] font-medium font-satoshi leading-[38.40px]">FREE</div>
    <div className="flex-col justify-start items-start flex">
        <div className="text-center text-slate-500 text-lg font-medium font-satoshi line-through leading-snug"> </div>
        <div className="text-teal-950 text-5xl font-bold font-['Satoshi'] leading-[57.60px]">₹0</div>
    </div>
    <div className="w-full h-auto flex-col justify-center items-start gap-4 flex">
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Validity</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">30 days</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Job Postings</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Only twice</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">AI - Filter Tool</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">valid for 15 days</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Interview Scheduler</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Access your Resume</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">15 times per job posting</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">AI Benefits</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">5 Beep AI prompts</div>
        </div>
        <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
        <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
        <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
        <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
        <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
    </div>
</div>
                <div className="w-full py-3 bg-emerald-200 rounded-xl justify-center items-center gap-2 inline-flex left-0 bottom-0 cursor-not-allowed">
                    <div className="text-center text-white text-sm font-extrabold font-satoshi leading-tight">Selected</div>
                </div>
            </div>
        </div>
       
        <div className="w-full sx:w-[280px] min-h-[1090px] mx-2 sm:mx-0 px-3 pt-[25px] pb-3 bg-white rounded-2xl border-2 border-gray-100 justify-start items-start gap-2.5 flex">
            <div className="w-full flex-col justify-start  items-center lg:items-start p-4 md:p-8 gap-[60px] inline-flex">
            <div className="w-full h-auto flex-col justify-start items-start gap-12 inline-flex">
    <div className="text-center text-teal-950 text-[32px] font-medium font-satoshi leading-[38.40px]">Monthly</div>
    <div className="flex-col justify-start items-start flex">
        <div className="text-center text-slate-500 text-2xl font-medium font-satoshi line-through leading-[28.80px]">18000</div>
        <div className="text-center text-teal-950 text-5xl font-bold font-['Satoshi'] leading-[57.60px]">₹11,000</div>

    </div>
    <div className="w-full flex-col justify-start items-start gap-4 flex">
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Validity</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">1 month</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Job Postings</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">AI - Filter Tool</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Interview Scheduler</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Access your Resume</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Hiring Consultancy</div>
            <div className="text-slate-500 text-sm font-semibold font-satoshi underline leading-[16.80px] cursor-pointer">Connect with RM</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">AI Benefits</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">100 Beep AI prompts</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">AI matched candidates</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">20 Assessment credits</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Smart Database Promotions</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Extra Perks</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Export your resume to excel</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Dedicated Relationship Manager</div>
            <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Virtual Hiring Drive</div>
                <div className="px-2 py-[2.50px] bg-slate-500 rounded-[10px] justify-center items-start gap-2.5 flex">
                    <div className="text-white text-[10px] font-medium font-satoshi leading-3">NEW</div>
                </div>
            </div>
            <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Student AI Data</div>
            </div>
        </div>
        <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
    </div>
</div>
                <div  onClick={() => {
                    Mixpanel.track("monthly_select", {
                        date: moment().format(),
                        browser: browserName,
                        mobile_device: isMobileOnly,
                        recruiter_id: localStorage.getItem("userId"),
                        distinct_id: localStorage.getItem("userId"),
                        isPremium: localStorage.getItem("isPremium"),
                        planId: 1,
                        planType: "monthly_select", // Add the plan type string to the tracked event
                    }),
                    handlePlanChangeAndRedirect("1")}} className="w-full py-3 bg-emerald-500 rounded-xl justify-center items-center gap-2 inline-flex cursor-pointer">
                    <div className="text-center text-white text-sm font-extrabold font-satoshi leading-tight">Select </div>
                </div>
            </div>
        </div>
        <div className="w-full sx:w-[280px] min-h-[1090px] mx-2 sm:mx-0 px-3 pt-[25px] pb-3 bg-white rounded-2xl border-2 border-gray-100 justify-start items-start gap-2.5 flex">
            <div className="w-full flex-col justify-start  items-center lg:items-start p-4 md:p-8 gap-[60px] inline-flex">
            <div className="w-full h-auto flex-col justify-start items-start gap-12 inline-flex">
    <div className="text-center text-teal-950 text-[32px] font-medium font-satoshi leading-[38.40px]">Quaterly</div>
    <div className="flex-col justify-start items-start gap-px flex">
        <div className="text-center text-slate-500 text-2xl font-medium font-satoshi line-through leading-[28.80px]">54,000</div>
        <div className="text-center text-teal-950 text-5xl font-bold font-['Satoshi'] leading-[57.60px]">₹36,999</div>
    </div>
    <div className="w-full flex-col justify-start items-start gap-4 flex">
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Validity</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">3 months</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Job Postings</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">AI - Filter Tool</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Interview Scheduler</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Access your Resume</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Hiring Consultancy</div>
            <div className="text-slate-500 text-sm font-semibold font-satoshi underline leading-[16.80px] cursor-pointer">Connect with RM</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">AI Benefits</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">300 Beep AI prompts</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">AI matched candidates</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">50 Assessment credits</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Smart Database Promotions</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-slate-700 text-sm font-semibold font-satoshi leading-[16.80px]">Extra Perks</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Export your resume to excel</div>
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Dedicated Relationship Manager</div>
            <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Virtual Hiring Drive</div>
                <div className="px-2 py-[2.50px] bg-slate-500 rounded-[10px] justify-center items-start gap-2.5 flex">
                    <div className="text-white text-[10px] font-medium font-satoshi leading-3">NEW</div>
                </div>
            </div>
            <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Student AI Data</div>
            </div>
        </div>
        <div className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
    </div>
</div>

                <div  onClick={() => {
                    Mixpanel.track("quarterly_select", {
                        date: moment().format(),
                        browser: browserName,
                        mobile_device: isMobileOnly,
                        recruiter_id: localStorage.getItem("userId"),
                        distinct_id: localStorage.getItem("userId"),
                        isPremium: localStorage.getItem("isPremium"),
                        planId: 2,
                        planType: "quarterly_select", // Add the plan type string to the tracked event
                    }),
                    handlePlanChangeAndRedirect("2")}} className="w-full py-3 bg-emerald-500 rounded-xl justify-center items-center gap-2 inline-flex cursor-pointer">
                    <div className="text-center text-white text-sm font-extrabold font-satoshi leading-tight">Select </div>
                </div>
            </div>
        </div>
        <div className="w-full sx:w-[280px] min-h-[1090px] mx-2 sm:mx-0 h-auto rounded-2xl  flex flex-col justify-start" style={{
  boxShadow: "0px 8px 32px 0px #FFCA70",
  backgroundImage: "linear-gradient(135deg, #FFB942 0%, #FFDA46 50%, #FFDA46 50%, #FFB942 100%)",
}}
>
            <div className="w-full flex justify-end items-end px-4">
            <div className="w-[75px] px-[7px] py-[5px] top-0 bg-teal-950 rounded-bl-[10px] rounded-br-[10px] gap-2.5 inline-flex">
                <div className="text-white text-xs font-bold font-satoshi leading-tight">Best Offer</div>
            </div>
            </div>
           <div className="w-full pt-8 rounded-2xl justify-center items-center gap-2.5 inline-flex p-4 md:p-8">
                <div className="w-full flex-col justify-start items-center lg:items-start gap-16 inline-flex">
                <div className="w-full h-auto flex-col justify-start items-start gap-12 inline-flex">
    <div className="text-center text-teal-950 text-[32px] font-extraold font-satoshi leading-[38.40px]">Annual</div>
    <div className="flex-col justify-start items-start gap-px flex">
        <div className="text-center text-teal-950 text-2xl font-medium font-satoshi line-through leading-[28.80px]">1,80,000</div>
        <div className="text-center text-teal-950 text-5xl font-bold font-['Satoshi'] leading-[57.60px]">₹99,999</div>
    </div>
    <div className="w-full flex-col justify-start items-start gap-4 flex">
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">Validity</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">12 months</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">Job Postings</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">AI - Filter Tool</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">Interview Scheduler</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-[0px] border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">Access your Resume</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">unlimited</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">Hiring Consultancy</div>
            <div className="text-slate-700 text-sm font-semibold font-satoshi underline leading-[16.80px] cursor-pointer">Connect with RM</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">AI Benefits</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">1200 Beep AI prompts</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">AI matched candidates</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">150 Assessment credits</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">Smart Database Promotions</div>
        </div>
        <div className="self-stretch h-px border border-neutral-300"></div>
        <div className="flex-col justify-center items-start gap-2 flex">
            <div className="text-teal-950 text-sm font-semibold font-satoshi leading-[16.80px]">Extra Perks</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">Export your resume to excel</div>
            <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">Dedicated Relationship Manager</div>
            <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">Virtual Hiring Drive</div>
                <div className="px-2 py-[2.50px] bg-slate-700 rounded-[10px] justify-center items-start gap-2.5 flex">
                    <div className="text-white text-[10px] font-medium font-satoshi leading-3">NEW</div>
                </div>
            </div>
            <div className="justify-start items-center gap-2 inline-flex">
                <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]">Student AI Data</div>
            </div>
        </div>
        <div className="text-slate-700 text-sm font-medium font-satoshi leading-[16.80px]"> </div>
    </div>
</div>
                    {/* <div  onClick={cancelPayment} className="w-full py-3 bg-orange-50 rounded-xl shadow justify-center items-end gap-2 inline-flex">
                        <div className="text-center text-teal-950 text-sm font-bold font-satoshi leading-tight">cancel </div>
                    </div> */}
                    {/* <div  onClick={generagePromoCode} className="min-w-[311px] py-3 bg-orange-50 rounded-xl shadow justify-center items-end gap-2 inline-flex">
                        <div className="text-center text-teal-950 text-sm font-bold font-satoshi leading-tight">Generate </div>
                    </div> */}
                    
                    <div  onClick={() =>{
                Mixpanel.track("yearly_select", {
                date: moment().format(),
                browser: browserName,
                mobile_device: isMobileOnly,
                recruiter_id: localStorage.getItem("userId"),
                distinct_id: localStorage.getItem("userId"),
                isPremium: localStorage.getItem("isPremium"),
                planId: 0,
                planType: "yearly_select", // Add the plan type string to the tracked event
            }),
             handlePlanChangeAndRedirect("0")}
             } className="w-full py-3 my-3 bg-black rounded-xl shadow justify-center items-end gap-2 inline-flex cursor-pointer">
                        <div className="text-center text-white text-sm font-extrabold font-satoshi leading-tight">Select </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
    </div>

<div className="w-full h-[92px] px-16 py-8 bg-gray-100 flex-col justify-start items-center gap-16 inline-flex mt-16">
<div className="self-stretch justify-center items-center gap-8 inline-flex">
        <Link to="/tnc" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Terms of Use</div>
        </div>
        </Link>
        <Link to="/privacy" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Privacy Policy</div>
        </div>
        </Link>
    </div>
</div>
  </div>
  </div>
};

export default Pricing;
