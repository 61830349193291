import React from "react";
import { Link } from "react-router-dom";

const GreenButtonOutlineWithIcon = ({ icon, text, className, ...props }) => {
  return (
    <div
      className={`ButtonLarge cursor-pointer p-3 bg-white  rounded-xl border  border-emerald-500 justify-center items-center gap-2 flex ${className}`}
      {...props}
    >
      {icon && (
        <div className="InputFieldIconsPluscircle  w-5 h-5 ">{icon}</div>
      )}
      <div className="ButtonText text-emerald-500 text-[14px] font-bold leading-tight">
        {text}
      </div>
    </div>
  );
};

export default GreenButtonOutlineWithIcon;
