import moment from "moment";
import React from "react";

const DisplayNotes = ({ notes }) => {
  return (
    <div>
      <div className="p-4 rounded-3xl bg-white flex my-4 flex-col sm:flex-row">
        <div className="w-1/6 font-semibold mr-8">Notes</div>
        <div className="w-5/6">
          {notes?.map((note) => (
            <div>
              <div className="text-gray-900/50">
                <div>{moment(note.timeline).format("DD MMM, hh:mm")}</div>
                <div className="bg-gray-900/5 border-2 border-gray-900/20 p-4  rounded-xl">
                  {note.notes}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DisplayNotes;
