import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// This is for the users to verify email that sent through the email from my profile screen

const EmailVerification = () => {
  const [verified, setVerified] = useState(null);
  // null - waiting for verification, true, false - dependnds on the token result

  const navigator = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("token");

  const verifyToken = async () => {
    console.log("token", token);

    const url = `${process.env.REACT_APP_baseURL}/recruiter/verify_email_token?token=${token}`;

    const response = await axios
      .get(url, {
        headers: {},
      })
      .catch((error) => {
        setVerified(false);
      });

    if (response?.data) {
      console.log(response?.data);
      setVerified(true);
    }
  };

  //If link is broken or not valid
  const redirect = () => {
    // If user logged in he redirected to the progile else to signin
    if (localStorage.getItem("auth")) navigator("/profile");
    else navigator("/signin");
  };

  useEffect(verifyToken, []);
  return (
    <div>
      <div
        className="EmailVerified w-screen pt-32 h-screen pb-[156px to-white flex-col justify-start items-center gap-[100px] inline-flex"
        style={{
          background:
            "linear-gradient(180deg, rgba(22, 192, 131, 0.10) 0%, rgba(255, 218, 69, 0.10) 50%, rgba(255, 255, 255, 0.10) 100%), #FFF",
        }}
      >
        {/* Token verified successfully */}
        {verified === true && (
          <div className="IllTxtButton  self-stretch h-[400px] w-[500px] mx-auto p-8 bg-white rounded-[44px] shadow flex-col justify-start items-center gap-8 inline-flex">
            <div className="Ill w-64 h-64 relative">
              <img
                className=" w-48 h-48  absolute origin-top-left"
                src="/48.png"
              />

              <img
                className="w-32 h-32 left-[107.50px]  absolute"
                src="/28 1.png"
              />
            </div>
            <div className="Txt self-stretch h-[72px] flex-col justify-start items-center gap-2 flex">
              <div className="GreatYourEmailIsVerified text-center text-teal-950 text-base font-bold leading-normal">
                Great! Your Email is Verified
              </div>
              <div className="NowYouCanTakeAdvantageOfAllTheFeaturesForSelectingCandidatesAtBeep self-stretch text-center text-teal-950 text-sm font-medium leading-tight">
                Now you can take advantage of all the features for selecting
                candidates at BEEP!
              </div>
            </div>
            <Link
              to="/"
              className="ButtonMedium h-11 p-3 bg-emerald-500 rounded-xl border border-teal-950 border-opacity-10 justify-center items-center gap-2 inline-flex"
            >
              <div className="GoToDashboard text-center text-white text-sm font-bold leading-tight">
                Go to Dashboard
              </div>
            </Link>
          </div>
        )}

        {/* Invalid token or Expired */}
        {verified === false && (
          <div className="IllTxtButton self-stretch h-[500px] w-[500px]  mx-auto p-8 bg-white rounded-[44px] shadow flex-col justify-start items-center gap-8 inline-flex">
            <div className="Ill w-64 h-64 relative">
              <img
                className="1 w-48 h-48 left-[32px] top-[32px] absolute"
                src="/26 1.png"
              />
            </div>
            <div className="Txt self-stretch h-[72px] flex-col justify-start items-center gap-2 flex">
              <div className="OopsTheLinkHasExpired self-stretch text-center text-teal-950 text-base font-bold leading-normal">
                Oops! The link has Expired.
              </div>
              <div className="ResendAnEmailFromSettingsToFinishVerification self-stretch text-center text-teal-950 text-sm font-medium leading-tight">
                Resend an email from settings to finish verification.
              </div>
            </div>
            <button
              className="ButtonMedium h-11 p-3 bg-emerald-500 rounded-xl border border-teal-950 border-opacity-10 justify-center items-center gap-2 inline-flex"
              onClick={redirect}
            >
              <div className="GoToSettings text-center text-white text-sm font-bold leading-tight">
                Go to Settings
              </div>
            </button>
          </div>
        )}

        {/* Loader until getting response */}
        {verified === null && (
          <div className="mx-auto">
            <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
              <div class="border-t-transparent border-solid animate-spin  rounded-full border-emerald-500 border-8 h-12 w-12"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerification;
