
import React, { useState } from "react";
import axios from "axios";
import FillButtonV2 from "../../../components/buttons/UI_Buttons_2/fillButton";
import ButtonChild from "../../../components/buttons/UI_Buttons_2/buttonChild";
import BorderButton from "../../../components/buttons/UI_Buttons_2/borderButton";
import InputFieldComp from "../../../components/inputfields/UI_Inputs/InputFieldComp";

const InviteUser = ({ close, onCancel, invitedRecruiter }) => {
    const [email, setEmail] = useState('');
    const [invitationStatus, setInvitationStatus] = useState(null);
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
      };
      
      const inviteUser = async () => {
        try {
          // Assuming you have an authentication token in a variable called authToken
          const authToken = localStorage.getItem('auth');
      
          // Make an API request to send an email invitation with the token in the headers
          const response = await axios.post(
            `${process.env.REACT_APP_baseURL}/recruiter/invite`,
            {
              email,
              // Add any other required data for your API request here
            },
            {
              headers: {
                'Authorization': `Bearer ${authToken}`, // Adjust the header format as per your API's requirements
              },
            }
          );
      
          // Handle the API response
          if (response.data.success) {
            console.log(response.data.message)
            setInvitationStatus('Invitation sent successfully.');
            invitedRecruiter()
            close()
            // showInvite();
          } else {
            setInvitationStatus('Failed to send invitation. Please try again.');
            
          }
        } catch (error) {
          console.error('Error sending invitation:', error);
          setInvitationStatus('Recruiter Already Exist with this email or already sent invitation to this mail. Try with other Email.');
        }
      };
      

    //   useEffect(() => {
    //     // Get user data from the location state
    //     const authToken=localStorage.getItem("auth")
    //     // const authToken = location.state?.authToken;
    //   console.log(authToken)
    //       if (authToken) {
    //         // If user data is not available in the location state, you can make an API request here
    //         const config = {
    //           headers: {
    //             Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
    //           },
    //         };
    //         axios
    //           .get(`${process.env.REACT_APP_baseURL}/recruiter/invitation`, config)
    //           .then((response) => {
    //             console.log(response)
    //             setUserData(response?.data);           
    //           })
    //           .catch((error) => {
    //             console.error('Error fetching user data:', error);
                
    //           });
    //       }  
    //   }, [location.state]);
    // const [showLogOutBox, setShowLogOutBox] = useState(false);

    // const close = () => {
    //     setShowLogOutBox((prev) => !prev);
    //   };
  return (
    <div
      class="relative z-11 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0  bg-white bg-opacity-80 backdrop-blur-[4px] blur-md transition-opacity"></div>
    
      <div class="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative m-auto w-full transform overflow-scroll no-scrollbar rounded-lg bg-white text-left shadow-xl transition-all px-8 py-8 sm:my-8 sm:w-full sm:max-w-lg w-4/12">
            <div className="flex justify-between items-center w-screen sm:w-full">
            <div className="w-max text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px]">Invite New User</div>
              
              <div onClick={close}>
              <ButtonChild 
              className="px-1"
              
              textColor="gray"
              leftIcon={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z" 
              fill="currentColor"/>
            </svg>}
              />
              </div>
            </div>
            <div className="w-max text-teal-950 text-sm font-bold font-['Satoshi Variable'] leading-tight mt-spaceXxLarge mb-spaceLarge">
            Enter the email of a user and we will send them a sign up invite.
                </div>
            <div className="form w-screen sx:w-full">
                <InputFieldComp 
                labelText="Email"
                type="email"
                name='email'
                htmlFor="email"
                placeholder="Enter Email of user"
                value={email}
                onChange={handleEmailChange}
                errorMsg={invitationStatus}
                />
            
          
              <div className="flex flex-row justify-start gap-spaceBase ml-auto mt-spaceLarge" >
              <div>

<BorderButton 
onClick={onCancel}
text="Cancel"
textColor="gray"
color="gray"
leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z" 
fill="currentColor"/>
</svg>}
/>
</div>
                <div>
              <FillButtonV2 
              onClick={inviteUser}
              text="Invite User"
              color="green"
              textColor="white"
              leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <g clip-path="url(#clip0_598_928)">
                <path d="M19.9999 10.625C19.9999 10.7907 19.934 10.9497 19.8168 11.0669C19.6996 11.1841 19.5406 11.25 19.3749 11.25H18.1249V12.5C18.1249 12.6657 18.059 12.8247 17.9418 12.9419C17.8246 13.0591 17.6656 13.125 17.4999 13.125C17.3341 13.125 17.1751 13.0591 17.0579 12.9419C16.9407 12.8247 16.8749 12.6657 16.8749 12.5V11.25H15.6249C15.4591 11.25 15.3001 11.1841 15.1829 11.0669C15.0657 10.9497 14.9999 10.7907 14.9999 10.625C14.9999 10.4592 15.0657 10.3002 15.1829 10.183C15.3001 10.0658 15.4591 9.99995 15.6249 9.99995H16.8749V8.74995C16.8749 8.58419 16.9407 8.42522 17.0579 8.30801C17.1751 8.1908 17.3341 8.12495 17.4999 8.12495C17.6656 8.12495 17.8246 8.1908 17.9418 8.30801C18.059 8.42522 18.1249 8.58419 18.1249 8.74995V9.99995H19.3749C19.5406 9.99995 19.6996 10.0658 19.8168 10.183C19.934 10.3002 19.9999 10.4592 19.9999 10.625ZM11.2499 12.3187C12.2296 11.7085 12.984 10.7957 13.3988 9.7186C13.8137 8.64146 13.8664 7.45852 13.549 6.34874C13.2317 5.23897 12.5615 4.26273 11.64 3.56775C10.7184 2.87276 9.59553 2.49683 8.44127 2.49683C7.28701 2.49683 6.16415 2.87276 5.24257 3.56775C4.321 4.26273 3.65084 5.23897 3.3335 6.34874C3.01615 7.45852 3.06889 8.64146 3.48372 9.7186C3.89855 10.7957 4.65291 11.7085 5.63268 12.3187C4.0194 12.8468 2.56783 13.8335 1.40065 15.2226C1.32423 15.3134 1.27526 15.4241 1.25947 15.5418C1.24367 15.6594 1.2617 15.7791 1.31144 15.8869C1.36119 15.9947 1.4406 16.086 1.54037 16.1503C1.64015 16.2146 1.75617 16.2492 1.87486 16.25H14.9999C15.119 16.2501 15.2356 16.2162 15.3361 16.1522C15.4365 16.0883 15.5167 15.997 15.567 15.889C15.6173 15.7811 15.6357 15.661 15.6201 15.5429C15.6045 15.4249 15.5554 15.3137 15.4788 15.2226C14.3108 13.8335 12.8592 12.8468 11.2499 12.3187Z" 
                fill="currentColor"/>
              </g>
              <defs>
                <clipPath id="clip0_598_928">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>}
              />
              </div>
              

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteUser;
