import React, { useState, useEffect } from "react";
import ApplicationHeading from "./heading";
// import OpeningInfo from "./openingInfo";
// import RightSideInfo from "./rightSideInfo";
// import AddNotes from "./modals/addNotes";
// import SendOffer from "./modals/sendOffer";
// import ScheduleInterview from "./modals/scheduleInterview";
// import SendAssignment from "./modals/sendAssignment";
import OpeningInfoLeft from "./openingInfo";
import RightSideCards from "./rightCards";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "../../../index.css"
import Loader from "../../../components/Loader/loader";

const OpeningDetails = (props) => {
  const { id: jobId } = useParams();

  const [showAddNotes, setshowAddNotes] = useState(false);
  const [showSendOffer, setshowSendOffer] = useState(false);
  const [jobDetails, setJobDetails] = useState(null);

  const navigate = useNavigate();

  const toggleSendOffer = () => {
    setshowSendOffer((prev) => !prev);
  };
  const toggleNotes = () => {
    setshowAddNotes((prev) => !prev);
  };

  const [showScheduleInterview, setshowScheduleInterview] = useState(false);

  const toggleScheduleInterview = () => {
    setshowScheduleInterview((prev) => !prev);
  };

  const [showSendAssignment, setShowSendAssignment] = useState(false);

  const toggleSendAssignment = () => {
    setShowSendAssignment((prev) => !prev);
  };

  const getJobDetails = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/job/${jobId}`;

    console.log(jobId);
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => console.log(error));

    if (response?.data) {
      console.log(response?.data);
      setJobDetails(response?.data);
    }
  };

  const updateJobStatus = async (status) => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/v3/change_job_status`;

    console.log(url);

    const response = await axios
      .post(
        url,
        {
          id: jobDetails._id,
          status,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      )
      .catch((error) => console.log(error));

    if (response?.data) {
      console.log(response?.data);
      getJobDetails();
    }
  };

  useEffect(getJobDetails, []);

  return (
    <div className="text-sm flex flex-col h-screen overflow-scroll no-scrollbar w-full ">
      {jobDetails && (
        <div>
          <ApplicationHeading
            scheduleInterview={toggleScheduleInterview}
            sendAssignment={toggleSendAssignment}
            jobDetails={jobDetails}
            updateJobStatus={updateJobStatus}
            navigate={navigate}
          />

          <div className="flex flex-col lg:flex-row h-screen overflow-scroll no-scrollbar">
            <OpeningInfoLeft
              className="w-full lg:w-5/12 flex"
              addNotetoggle={toggleNotes}
              style={{ flex: "1 1 auto" }}
              jobDetails={jobDetails}
            />
            <div
              className="w-full lg:w-7/12 flex height-min mb-0 lg:mb-[4rem] "
              style={{ flex: "1 1 auto" }}
            >
              <RightSideCards jobDetails={jobDetails} />
            </div>
          </div>
          {/* {showAddNotes && <AddNotes close={toggleNotes} />}
      {showSendOffer && <SendOffer close={toggleSendOffer} />}
      {showScheduleInterview && (
        <ScheduleInterview close={toggleScheduleInterview} />
      )}

      {showSendAssignment && <SendAssignment close={toggleSendAssignment} />} */}
        </div>
      )}

      {!jobDetails && 
      <Loader />
      }
    </div>
  );
};

export default OpeningDetails;
