import React, { useState, useRef } from 'react'
import moment from 'moment';
import GreenButtonWithIcon from '../../../components/buttons/buttonGreenIcon';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import { PDFDocument, degrees } from 'pdf-lib';
import { saveAs } from 'file-saver';
import 'jspdf-autotable';
import "../../../index.css"
import FillButtonV2 from '../../../components/buttons/UI_Buttons_2/fillButton';


const Resume = ({
    userInfo: { applicant_info }

}) => {

    const contentRef = useRef(null);
    // _______________________________________________________________________________________________________
    //    using html2canvas :
    // const generatePDF = async () => {
    //   const componentRef = document.querySelector('.resume');
    //   const canvas = await html2canvas(componentRef);
    //   const imageDataUrl = canvas.toDataURL('image/png');


    // async function splitAndSaveAsPDF(imageDataUrl, numParts) {
    //     const image = new Image();
    //     image.src = imageDataUrl;

    //     const pageWidth = 595; // Adjust this as needed
    //     const pageHeight = 700; // Adjust this as needed
    //     const margin = 20; // Adjust this as needed


    //     const doc = await PDFDocument.create();

    //     const partHeight = Math.max(image.height / numParts);

    //     for (let i = 0; i < numParts; i++) {
    //       const page = doc.addPage([pageWidth, pageHeight]);
    //       const { width, height } = page.getSize();
    //       const imageWidth = width - 2 * margin;
    //       const imageHeight = height - 2 * margin;

    //       const x = margin;
    //       const y = margin;

    //       const startPixel = i * partHeight;
    //       const endPixel = Math.min(startPixel + partHeight, image.height);

    //       const canvas = document.createElement('canvas');
    //       canvas.width = image.width;
    //       canvas.height = endPixel - startPixel;

    //       const ctx = canvas.getContext('2d');
    //       ctx.drawImage(image, 0, -startPixel);

    //       const imageBytes = canvas.toDataURL('image/png');

    //       const embeddedImage = await doc.embedPng(imageBytes);

    //       page.drawImage(embeddedImage, {
    //         x,
    //         y: height - imageHeight - margin,
    //         width: imageWidth,
    //         height: imageHeight,
    //         rotate: degrees(0),
    // //         dpi: 300, // Adjust the DPI for higher quality (higher resolution)
    // //   useCORS: true, 
    //       });
    //     }

    //     const pdfBytes = await doc.save();

    //     // Save the PDF to a file
    //     const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
    //     saveAs(pdfBlob, 'Resume.pdf');
    //     setLoader(false);
    //   }

    //   // Call this function with the captured image data URL and the number of parts
    //   const numParts = 2; // Change this to the desired number of parts
    //   splitAndSaveAsPDF(imageDataUrl, numParts);


    // };


    //   _____________________________________________________________________________________________________ 
    // Using jspdf and jspdf-autotable
     
    const generatePDF = () => {
        const content = contentRef.current;
        // Set the desired PDF page size (e.g., A4)
        const pageSize = { width: 210, height: 297 }; // A4 size in millimeters
      
        // Calculate the scaling factor to fit content within the page
        const scaleFactor = Math.min(pageSize.width / content.clientWidth, pageSize.height / content.clientHeight);
      
        // Create a new jsPDF instance with the specified page size
        const doc = new jsPDF({
          orientation: 'portrait', // 'portrait' or 'landscape'
          unit: 'mm',
          format: [pageSize.width, pageSize.height],
        });
      
        const margin = {
            top: 20,
            bottom: 20,
          };
        // Adjust the font size based on the scaling factor
        const fontSize = 12 * scaleFactor;
        doc.setFontSize(fontSize);
        
      
        // Function to add content to PDF recursively, splitting content into multiple pages if needed
        const addContentToPDF = (offsetY = 0) => {
            
          doc.html(content, {
            
            x: margin,
            y: offsetY,
            html2canvas: { scale: scaleFactor },
            callback: function () {
              // Check if there's more content below
              if (offsetY + content.clientHeight * scaleFactor < pageSize.height) {
                doc.internal.write(0, "Tw")
                doc.save('resume.pdf'); // Save the PDF when all content is added
              } else {
                // Add a new page and continue with the rest of the content
                doc.addPage();
                addContentToPDF(offsetY - pageSize.height);
              }
            },
          });
        };
        addContentToPDF();
    };
    return (
        <>
            <div className='flex justify-center ml-[20rem] mt-8 mb-0'>
                <FillButtonV2 
                onClick={generatePDF}
                text="Download"
                color="green"
                textColor="white"
                leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1922 2.18281L16.5672 6.55781C16.6252 6.6159 16.6712 6.68485 16.7026 6.76072C16.734 6.83659 16.7501 6.9179 16.75 7V17C16.75 17.3315 16.6183 17.6495 16.3839 17.8839C16.1495 18.1183 15.8315 18.25 15.5 18.25H4.25C3.91848 18.25 3.60054 18.1183 3.36612 17.8839C3.1317 17.6495 3 17.3315 3 17V3.25C3 2.91848 3.1317 2.60054 3.36612 2.36612C3.60054 2.1317 3.91848 2 4.25 2H11.75C11.8321 1.99994 11.9134 2.01605 11.9893 2.04742C12.0652 2.07878 12.1341 2.12479 12.1922 2.18281ZM11.75 3.5625V7H15.1875L11.75 3.5625ZM11.6074 11.4168C11.4943 11.7916 11.2667 12.1216 10.9566 12.3605C11.762 12.7011 12.3968 13.3874 12.6151 14.2355C12.6271 14.2817 12.6282 14.33 12.6186 14.3767C12.609 14.4234 12.5888 14.4673 12.5596 14.505C12.5303 14.5427 12.4929 14.5733 12.45 14.5942C12.4072 14.6152 12.3601 14.6261 12.3124 14.6261H7.31264C7.26494 14.6261 7.21786 14.6152 7.17502 14.5942C7.13218 14.5733 7.09471 14.5427 7.06549 14.505C7.03627 14.4673 7.01607 14.4234 7.00644 14.3767C6.9968 14.33 6.998 14.2817 7.00992 14.2355C7.22827 13.3878 7.86301 12.7015 8.66844 12.3605C8.35831 12.1216 8.13073 11.7916 8.0176 11.4168C7.90448 11.0421 7.9115 10.6413 8.03767 10.2707C8.16383 9.9001 8.40283 9.5783 8.72113 9.35042C9.03943 9.12253 9.42107 9 9.81253 9C10.204 9 10.5856 9.12253 10.9039 9.35042C11.2222 9.5783 11.4612 9.9001 11.5874 10.2707C11.7136 10.6413 11.7206 11.0421 11.6074 11.4168Z" 
                fill="currentColor"/>
              </svg>}
                />
                {/* <GreenButtonWithIcon
                    className="text-white bg-gray h-5 p-4 w-max "
                    onClick={generatePDF}
                    text="Export PDF"
                    icon={
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <g id="Input Field Icons / ClipboardText">
                                <path
                                    id="Vector"
                                    d="M15.625 2.49989H12.7922C12.441 2.1067 12.0108 1.79212 11.5296 1.57673C11.0484 1.36134 10.5272 1.25 10 1.25C9.47282 1.25 8.95158 1.36134 8.47041 1.57673C7.98924 1.79212 7.55899 2.1067 7.20781 2.49989H4.375C4.04348 2.49989 3.72554 2.63158 3.49112 2.866C3.2567 3.10042 3.125 3.41837 3.125 3.74989V16.8749C3.125 17.2064 3.2567 17.5243 3.49112 17.7588C3.72554 17.9932 4.04348 18.1249 4.375 18.1249H15.625C15.9565 18.1249 16.2745 17.9932 16.5089 17.7588C16.7433 17.5243 16.875 17.2064 16.875 16.8749V3.74989C16.875 3.41837 16.7433 3.10042 16.5089 2.866C16.2745 2.63158 15.9565 2.49989 15.625 2.49989ZM10 2.49989C10.663 2.49989 11.2989 2.76328 11.7678 3.23212C12.2366 3.70096 12.5 4.33685 12.5 4.99989H7.5C7.5 4.33685 7.76339 3.70096 8.23223 3.23212C8.70107 2.76328 9.33696 2.49989 10 2.49989ZM12.5 12.4999H7.5C7.33424 12.4999 7.17527 12.434 7.05806 12.3168C6.94085 12.1996 6.875 12.0406 6.875 11.8749C6.875 11.7091 6.94085 11.5502 7.05806 11.4329C7.17527 11.3157 7.33424 11.2499 7.5 11.2499H12.5C12.6658 11.2499 12.8247 11.3157 12.9419 11.4329C13.0592 11.5502 13.125 11.7091 13.125 11.8749C13.125 12.0406 13.0592 12.1996 12.9419 12.3168C12.8247 12.434 12.6658 12.4999 12.5 12.4999ZM12.5 9.99989H7.5C7.33424 9.99989 7.17527 9.93404 7.05806 9.81683C6.94085 9.69962 6.875 9.54065 6.875 9.37489C6.875 9.20913 6.94085 9.05016 7.05806 8.93295C7.17527 8.81573 7.33424 8.74989 7.5 8.74989H12.5C12.6658 8.74989 12.8247 8.81573 12.9419 8.93295C13.0592 9.05016 13.125 9.20913 13.125 9.37489C13.125 9.54065 13.0592 9.69962 12.9419 9.81683C12.8247 9.93404 12.6658 9.99989 12.5 9.99989Z"
                                    fill="white"
                                />
                            </g>
                        </svg>
                    }
                /> */}
            </div>


            {/* Top-Heading -student Name */}

            <div className='resume relative pr-10 flex flex-col w-[600px] m-auto ' ref={contentRef} style={{"height":"100vh"}} >

                {/* <div className="w-full justify-start items-center gap-4 inline-flex px-10 pt-5 pb-5 border-b justify-between" > */}
                {/* <div className='inline-flex'> */}
                {/* <div className="w-11 h-11 bg-white rounded-[40px] justify-center items-center flex"> */}
                {/* <img
                            className="Rectangle w-11 h-11 2xl:w-16 2xl:h-16 rounded-full"
                            src={
                                applicant_info?.profileInfo?.avatar ??
                                (applicant_info?.profileInfo?.gender == 1
                                    ? "https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
                                    : "https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png")
                            }
                        /> */}
                {/* </div> */}
                {/* <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex ml-4"> */}
                {/* <div className="self-stretch text-teal-950 text-xl font-bold leading-loose">Raj Kumar Roy</div> */}
                {/* <div className="self-stretch text-teal-950 text-xl font-bold leading-loose"> {applicant_info?.profileInfo?.name}</div> */}
                {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-none">Pursuing B.B.A. at Narsee Monjee College in Mumbai</div> */}
                {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-none">
                            {applicant_info?.work_profile?.currentlyAt}
                        </div> */}
                {/* </div> */}
                {/* </div> */}

                {/* </div> */}

                <div className='flex flex-col w-[600px] '>
                    <div className='w-full flex flex-col'>
                        <div className='mb-[1rem] w-full'>
                            {/* <div className="w-11 h-11 bg-white rounded-[40px] justify-center items-center flex"> */}
                            {/* <img className="w-10 h-10" src="https://via.placeholder.com/40x40" /> */}
                            {/* <img
                            className="Rectangle w-11 h-11 rounded-full"
                            src={
                                applicant_info?.profileInfo?.avatar ??
                                (applicant_info?.profileInfo?.gender == 1
                                    ? "https://www.pngkey.com/png/full/72-729716_user-avatar-png-graphic-free-download-icon.png"
                                    : "https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png")
                            }
                        /> */}
                            {/* </div> */}

                            {/* Commenting out the profile img, if I add profile img, pdf showing blank pages. */}
                            <div className=" ml-10  self-stretch text-teal-950 text-xl font-bold leading-loose"> {applicant_info?.profileInfo?.name}</div>
                            {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-none">Pursuing B.B.A. at Narsee Monjee College in Mumbai</div> */}
                            <div className=" ml-10  self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-none">
                                {applicant_info?.work_profile?.currentlyAt}
                            </div>

                        </div>
                        <div className='mt-[1rem] flex-col border-t'>
                            <div className='h-auto border-r w-3/5 flex-row absolute py-4 pl-8 pr-4'>
                            <div className='h-max pb-4'>
                                {/* Bio */}
                                <div className="Bio w-full h-max mb-5 flex-col justify-start items-start gap-2 flex flex-wrap">
                                    <div className="self-stretch text-neutral-800 text-[13px] font-bold leading-tight" >Bio</div>
                                    <div className="self-stretch opacity-50 text-neutral-800 text-[11px] font-medium leading-tight flex flex-wrap">
                                        {applicant_info?.work_profile?.bio}</div>
                                </div>
                                {/* Experience */}
                                {!applicant_info?.work_profile?.experience || applicant_info.work_profile.experience.length === 0 ? " " : (
                                    <div className="Experience h-max mb-5 flex-col justify-start items-start gap-2 flex">
                                        <div className="self-stretch text-neutral-800 text-[13px] font-bold leading-tight">Experience</div>
                                        {applicant_info?.work_profile?.experience?.map((data) => (
                                            <div className="self-stretch h-max flex-col justify-start items-start inline-flex">

                                                <div className="self-stretch text-neutral-800 opacity-50 text-[11px] font-medium leading-tight">

                                                    {moment(data?.startDate).format("MMM, yyyy")} -{" "}
                                                    {data?.endDate
                                                        ? moment(data.endDate).format("MMM, yyyy")
                                                        : "Present"}
                                                </div>
                                                <div className="text-teal-950 text-[11px] font-bold leading-tight"> {data?.title}</div>
                                                <div className="text-teal-950 text-[11px] font-medium leading-tight"> {data?.companyName}</div>
                                            </div>
                                        ))}
                                    </div>
                                )}

                                {/* Education */}
                                <div className="Education h-max mb-5  flex-col justify-start items-start gap-2 flex">
                                    <div className="self-stretch text-neutral-800 text-[13px] font-bold leading-tight">Education</div>
                                    {applicant_info?.work_profile?.education?.map((education) => (
                                        <div className="self-stretch h-max flex-col justify-start items-start inline-flex">
                                            <div className="w-40 text-neutral-800 opacity-50 text-[11px] font-medium leading-tight">
                                                {moment(education?.startDate).format("MMM, yyyy")} -{" "}
                                                {education?.endDate
                                                    ? moment(education?.endDate).format("MMM, yyyy")
                                                    : "Present"}
                                            </div>
                                            <div className="text-teal-950 text-[11px] font-bold leading-tight">  {education?.course}</div>
                                            <div className="self-stretch text-teal-950 text-[11px] font-medium leading-tight">  {education?.institute}</div>
                                        </div>
                                    ))}
                                    {/* <div className="self-stretch h-12 flex-col justify-start items-start flex">
                                <div className="w-40 text-teal-950 text-opacity-40 text-[11px] font-medium leading-tight">Jun, 2021 – Current</div>
                                <div className="text-teal-950 text-[11px] font-bold leading-tight">B.B.A.</div>
                                <div className="self-stretch text-teal-950 text-[11px] font-medium leading-tight">Narsee Monjee College of Commerce and Economics</div>
                            </div> */}

                                </div>
                                {/* Awards */}
                                {!applicant_info?.work_profile?.awardsandhonors || applicant_info.work_profile.awardsandhonors.length === 0 ? " " : (
                                    <div className="Awards h-max mb-5  flex-col justify-start items-start gap-2 flex">
                                        <div className="self-stretch text-neutral-800 text-[13px] font-bold leading-tight">Awards & Honours</div>
                                        {/* {[].map((data) => (
                            <div className="self-stretch h-12 flex-col justify-start items-start flex">
                                <div className="w-40 text-teal-950 text-opacity-40 text-[11px] font-medium leading-tight">
                                {moment([]).format("MMM, yyyy")} -{" "}
                                {[]
                                    ? moment([]).format("MMM, yyyy")
                                    : " "}
                                </div>
                                <div className="text-teal-950 text-[11px] font-bold leading-tight">{data?.name}</div>
                                <div className="text-teal-950 text-[11px] font-medium leading-tight">{data?.name}</div>
                            </div>
                            ))} */}
                                        <div className="self-stretch h-12 flex-col justify-start items-start flex">
                                            <div className="w-40 text-neutral-800 opacity-50 text-[11px] font-medium leading-tight">Jan – Mar, 2023</div>
                                            <div className="text-teal-950 text-[11px] font-bold leading-tight">Best Intern Award</div>
                                            <div className="text-teal-950 text-[11px] font-medium leading-tight">WATConsult</div>
                                        </div>
                                    </div>
                                )}
                                {/* Projects */}
                                {!applicant_info?.work_profile?.projects || applicant_info.work_profile.projects.length === 0 ? " " : (
                                    <div className="Projects h-max mb-5 flex-col justify-start items-start gap-2 flex">
                                        <div className="self-stretch text-neutral-800 text-[13px] font-bold leading-tight">Projects</div>
                                        {applicant_info?.work_profile?.projects?.map((projects) => (

                                            <div className="self-stretch h-max flex-col justify-start items-start inline-flex">
                                                <div className="self-stretch text-neutral-800 opacity-50 text-[11px] font-medium leading-tight">{projects?.companyName}</div>
                                                <div className="self-stretch h-5 text-teal-950 text-[11px] font-bold leading-tight">{projects?.title}</div>
                                                <div className="self-stretch text-teal-950 text-[11px] font-medium leading-tight">{projects?.description}</div>
                                            </div>

                                        ))}
                                        {/* <div className='inline-flex'>
                            <div className="self-stretch h-[52px] flex-col justify-start items-start flex">
                                <div className="self-stretch text-teal-950 text-opacity-40 text-[11px] font-medium leading-tight">Jun, 2021 – Mar, 2023</div>
                                <div className="self-stretch h-5 text-teal-950 text-[11px] font-bold leading-tight">Red Bull India: Influencer Marketing Campaign</div>
                                <div className="self-stretch text-teal-950 text-[11px] font-medium leading-tight">WATConsult</div>
                            </div>
                            </div>
                            
                            */}
                                    </div>
                                )}

                                {/* Certifications*/}
                                {!applicant_info?.work_profile?.certifications || applicant_info.work_profile.certifications.length === 0 ? " " : (
                                    <div className="Certificates h-max mb-5  flex-col justify-start items-start gap-2 flex">
                                        <div className="self-stretch text-neutral-800 text-[13px] font-bold leading-tight">Certificates</div>
                                        {applicant_info?.work_profile?.certifications?.map((certificate) => (
                                            <div className="self-stretch h-12 flex-col justify-start items-start inline-flex">
                                                <div className="w-40 text-neutral-800 opacity-50 text-[11px] font-medium leading-tight">
                                                    {moment(certificate?.startDate).format("MMM, yyyy")} -{" "}
                                                    {certificate?.endDate
                                                        ? moment(certificate?.endDate).format("MMM, yyyy")
                                                        : "Present"}
                                                </div>
                                                <div className="text-teal-950 text-[11px] font-bold leading-tight">  {certificate?.course}</div>
                                                <div className="self-stretch text-teal-950 text-[11px] font-medium leading-tight">  {certificate?.institute}</div>
                                            </div>
                                        ))}
                                        {/* <div className="self-stretch h-12 flex-col justify-start items-start flex">
                                <div className="w-40 text-teal-950 text-opacity-40 text-[11px] font-medium leading-tight">Jun, 2021 – Current</div>
                                <div className="text-teal-950 text-[11px] font-bold leading-tight">B.B.A.</div>
                                <div className="self-stretch text-teal-950 text-[11px] font-medium leading-tight">Narsee Monjee College of Commerce and Economics</div>
                            </div> */}
                                    </div>
                                )}
                           
                            </div>
                            </div>
                            <div className='h-auto w-2/5 border-l absolute flex-row right-0 py-4 pr-4 pl-4 '>
                            <div className='h-max pb-4'>
                                {/* Right Side */}
                                <div className=''>
                                    <div className="Rightside h-max  flex-col justify-start items-start gap-4 flex">
                                        {/* Conatct details */}
                                        <div className="Contact self-stretch text-neutral-800 text-[13px] font-bold leading-tight">Contact</div>
                                        {/* Email */}
                                        <div className="Email self-stretch h-8 flex-col justify-start items-start flex">
                                            <div className="text-neutral-800 text-[11px] font-bold leading-tight">Email</div>
                                            {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">rajkumarroy@gmail.com</div> */}
                                            <div className="self-stretch opacity-50 text-neutral-800 text-[11px] font-medium leading-tight">
                                                {applicant_info?.work_profile?.email}
                                            </div>
                                        </div>
                                        {/* Phone */}
                                        <div className="Phone self-stretch h-8 flex-col justify-start items-start flex">
                                            <div className="text-neutral-800 text-[11px] font-bold leading-tight">Phone</div>
                                            {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">+91 98765 43210</div> 
                                */}
                                            <div className="self-stretch opacity-50 text-neutral-800 text-[11px] font-medium leading-tight">
                                                {applicant_info?.number}
                                            </div>
                                        </div>
                                        {/* Social media */}
                                        {/* Website */}
                                        {!applicant_info?.work_profile?.portfolioUrls || applicant_info.work_profile.portfolioUrls.length === 0 ? " " : (
                                            <div>
                                                <div>
                                                    {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">rajkumarroy.com</div> */}
                                                    {applicant_info?.work_profile?.portfolioUrls &&
                                                        applicant_info.work_profile.portfolioUrls?.map((url) => (
                                                            <div>
                                                                {/* {applicant_info?.work_profile?.portfolioUrls} */}
                                                                {!(
                                                                    url.toLowerCase().includes("linkedin") ||
                                                                    url.toLowerCase().includes("twitter") ||
                                                                    url.toLowerCase().includes("youtube") ||
                                                                    url.toLowerCase().includes("github") ||
                                                                    url.toLowerCase().includes("drive")
                                                                )
                                                                    ?
                                                                    (
                                                                        <div className="Website self-stretch h-8 flex-col justify-start items-start flex">

                                                                            <div className="text-neutral-800 text-[11px] font-bold leading-tight">Website</div>
                                                                            <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">
                                                                                {url}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                    :
                                                                    (" ")}
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                                {/* Linkedin */}
                                                <div>
                                                    {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">rajkumarroy.com</div> */}
                                                    {applicant_info?.work_profile?.portfolioUrls &&
                                                        applicant_info.work_profile.portfolioUrls?.map((url) => (
                                                            <div>
                                                                {/* {applicant_info?.work_profile?.portfolioUrls} */}
                                                                {
                                                                    url.toLowerCase().includes("linkedin")
                                                                        ?
                                                                        (
                                                                            <div className="Website self-stretch h-8 flex-col justify-start items-start flex">

                                                                                <div className="text-neutral-800 text-[11px] font-bold leading-tight">Linkedin</div>
                                                                                <div className="self-stretch opacity-50 text-neutral-800 text-[11px] font-medium leading-tight">
                                                                                    {url}
                                                                                </div>
                                                                            </div>
                                                                        ) : (" ")}
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                                {/* Twitter */}
                                                <div>
                                                    {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">rajkumarroy.com</div> */}
                                                    {applicant_info?.work_profile?.portfolioUrls &&
                                                        applicant_info.work_profile.portfolioUrls?.map((url) => (
                                                            <div>
                                                                {/* {applicant_info?.work_profile?.portfolioUrls} */}
                                                                {
                                                                    url.toLowerCase().includes("twitter")
                                                                        ?
                                                                        (
                                                                            <div className="Website self-stretch h-8 flex-col justify-start items-start flex">
                                                                                <div className="text-neutral-800 text-[11px] font-bold leading-tight">Twitter</div>
                                                                                <div className="self-stretch opacity-50 text-neutral-800 text-[11px] font-medium leading-tight">
                                                                                    {url}
                                                                                </div>
                                                                            </div>
                                                                        ) : (" ")}
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                                {/* Youtube */}
                                                <div>
                                                    {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">rajkumarroy.com</div> */}
                                                    {applicant_info?.work_profile?.portfolioUrls &&
                                                        applicant_info.work_profile.portfolioUrls?.map((url) => (
                                                            <div>
                                                                {/* {applicant_info?.work_profile?.portfolioUrls} */}
                                                                {
                                                                    url.toLowerCase().includes("youtube")
                                                                        ?
                                                                        (
                                                                            <div className="Website self-stretch h-8 flex-col justify-start items-start flex">
                                                                                <div className="text-neutral-800 text-[11px] font-bold leading-tight">Youtube</div>
                                                                                <div className="self-stretch opacity-50 text-neutral-800 text-[11px] font-medium leading-tight">
                                                                                    {url}
                                                                                </div>
                                                                            </div>
                                                                        ) : (" ")}
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                                {/* Github */}
                                                <div>
                                                    {/* <div className="self-stretch opacity-60 text-neutral-800 text-[11px] font-medium leading-tight">rajkumarroy.com</div> */}
                                                    {applicant_info?.work_profile?.portfolioUrls &&
                                                        applicant_info.work_profile.portfolioUrls?.map((url) => (
                                                            <div>
                                                                {/* {applicant_info?.work_profile?.portfolioUrls} */}
                                                                {
                                                                    url.toLowerCase().includes("github")
                                                                        ?
                                                                        (
                                                                            <div className="Website self-stretch h-8 flex-col justify-start items-start flex">
                                                                                <div className="text-neutral-800 text-[11px] font-bold leading-tight">Github</div>
                                                                                <div className="self-stretch opacity-50 text-neutral-800 text-[11px] font-medium leading-tight ">
                                                                                    {url}
                                                                                </div>
                                                                            </div>
                                                                        ) : (" ")}
                                                            </div>
                                                        )
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <hr className=' ml-[-1rem] mr-[-1rem] my-4' />
                                <div>
                                    {/* Skills */}
                                    <div className="Skills h-max  flex-col flex-wrap justify-start items-start gap-4 flex">
                                        <div className="text-neutral-800 text-[13px] font-bold leading-tight">Skills</div>
                                        {/* Skill list sample */}

                                        <div className="self-stretch justify-start items-start gap-2 inline-flex flex-wrap">
                                            {applicant_info?.skills?.map((skill) => (
                                                <div className="px-2 py-1 justify-start items-start inline-flex">
                                                    <div className="text-neutral-800 text-[11px] font-medium leading-tight">
                                                        {"*"}
                                                        {skill}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </div>
                            </div>
                            {/* <div className="w-[1px] h-auto bg-teal-950 bg-opacity-10 ml-[22.5rem]" /> */}
                        </div>
                    </div>
                    {/* Bottom line */}
                    {/* <div className="Right w-40 left-[8px] mb-8 text-teal-950 text-opacity-40 text-[16px] font-medium leading-[9px]">Generated via Beep</div> */}
                    {/* <div className="Left left-[574px] bottom-8 absolute text-right text-teal-950 text-opacity-40 text-[9px] font-medium leading-[9px]">1/1</div> */}
                </div>
              </div>
        </>

    )
}

export default Resume;
