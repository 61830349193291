

import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Alert from "../../components/alert/alert";
import Title from "../../components/title/title";
import "../../index.css";
import ButtonChild from "../../components/buttons/UI_Buttons_2/buttonChild";
import FillButtonV2 from "../../components/buttons/UI_Buttons_2/fillButton";
import InputFieldComp from "../../components/inputfields/UI_Inputs/InputFieldComp";
import Loader from "../../components/Loader/loader";
import { browserName, isMobileOnly } from "react-device-detect";
import moment from "moment";
import { Mixpanel } from "../../services/mixpanel";

export function ForgotPassVerify() {
  const navigator = useNavigate();
  const [form, setForm] = useState({ email: "" }); // Change 'identifier' to 'email'
  const [alertData, setAlertData] = useState(null);
  const closeAlert = () => setAlertData(null);
const [error, setError] = useState('')
  const onFormChange = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const test = async () => {
    if (!form.email) {
      setError("Email / Phone number required");
      return;
    }

    const url = `${process.env.REACT_APP_baseURL}/recruiter/forgot_password_otp`;
    try {
      const response = await axios.post(url, { identifier: form.email });
      localStorage.setItem("forgotPassIdentifier", form.email);
      const authToken = localStorage.setItem("auth",form.email )
      Mixpanel.track("get_otp_clicked_success", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        recruiter_id: localStorage.getItem("userId"),
        browser: browserName,
        mobile_device: isMobileOnly,
      });
      navigator("/resetpassword/otp", {state: {authToken:authToken}});
      console.log(response.data);

    } catch (error) {
      console.log(error);
      
      if (error.response && error.response.data.message) {
        setAlertData(error.response.data.message);
        setError(error.response.data.message)
      }
    }
  };

  const [Loading, setLoading] = useState(true); // State to track loading

  // Your existing state and useEffect logic...
  
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };
  
    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount
  
  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }
  return (
    <>
      <div className="flex flex-col w-screen overflow-auto no-scrollbar gradient sm:py-[64px] min-h-screen xl:flex-row xl:h-[100vh] xl:p-0 xl:m-0">
        <div className="relative Graphics max-w-full xl:w-full xl:h-[100vh] mb-16 xl:mb-0">
          <img
            className=" object-cover absolute Gfx xl:w-full xl:h-full 2xl:w-full 2xl:h-full xl1:w-full xl1:h-full left-0 top-0 bg-fixed bg-cover h-full"
            src="/gfx.png"
            alt="Graphics"
          />
          <Title />
        </div>
        <div className="form p-0 m-0 w-100% flex flex-nowrap h-auto sm:m-16 overflow-scroll no-scrollbar md:mx-[97px] md:mt-[32px] lg:mx-[192px] lg:mt-[64px] xl:m-0 xl:w-[105vh]">
          <div className="relative bg-white mt-[161px] border-none shadow-none w-full h-max xl:mt-0 p-[32px] sm:border sm:border-white sm:p-spaceSLarge sm:self-stretch sm:rounded-[28px] sm:shadow-white-xl md:py-[64px] md:w-full lg:w-full h-fit xl:h-full 2xl:h-auto sm:mt-[209px] md:mt-[230px] lg:mt-[211px] xl:border-none xl:shadow-none xl:rounded-none xl:m-0 xl:w-full xl:p-[64px] flex flex-col gap-spaceXxLarge">
            <div className="flex flex-col gap-spaceBase">
              <div>
                <Link to="/signin">
                  <ButtonChild
                    text="Back to Sign In"
                    textColor="green"
                    leftIcon={
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        stroke="currentColor"
                      >
                        <path
                          d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z"
                          fill="currentColor"
                        />
                      </svg>
                    }
                  />
                </Link>
              </div>
              <div className="w-max text-gray-900 xl1:text-[32px] text-[27px] font-black font-satoshi leading-[38.40px]">
                Reset Your Account
              </div>
            </div>
            <div>
              <InputFieldComp
                labelText="Enter your email or mobile to receive an OTP"
                type="text"
                placeholder="Enter Email Address / Phone Number"
                name="email"
                value={form.email}
                onChange={onFormChange}
                hasError={error}
                errorMsg={error}
              />
            </div>
            <div>
              <FillButtonV2
                onClick={test}
                className="px-8"
                text="Get OTP"
                color="green"
                textColor="white"
              />
            </div>
          </div>
        </div>
        {alertData && <Alert text={alertData} alertClose={closeAlert} time={2} />}
      </div>
    </>
  );
}
