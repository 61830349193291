import React, { useEffect, useState } from "react";
import ProfileHeading from "./profile/heading";
import BeepNavbar from "../../components/navbar/navbar";
import LeftMenu from "./profile/leftmenu";
import LeftMenuSmall from "./LeftMenuSmall/leftMenuSmall";
import RightMenu from "./LeftMenuSmall/rightMenu";
import Loader from "../../components/Loader/loader";
import { useNavigate } from "react-router-dom";
// import "../../index.css"

// Recruiters profile screen. CRUDoperations for his/her profile

const Profile = ({ profile, transaction,tnc, privacy, leaderboard}) => {
  // const { section } = useParams();
  const [activeSection, setActiveSection] = useState('');
  const [isChanged, setIsChanged] = useState(false)
  const [showDiscard, setShowDiscard] = useState(false)
  const auth =  localStorage.getItem('auth');
  const navigate = useNavigate();

  useEffect(() => {
    // Set the active section based on props
    if (profile) {
      setActiveSection('profile');
    } else if (transaction) {
      setActiveSection('transaction');
    } else if (tnc) {
      setActiveSection('tnc');
    } else if (privacy) {
      setActiveSection('privacy');
    } else if (leaderboard) {
      setActiveSection('leaderboard');
    }  
  }, [profile, transaction]);

  useEffect(()=>{
    setIsChanged(false)
  }, [showDiscard])

  const [isLoading, setIsLoading] = useState(true); // State to track loading

  // Your existing state and useEffect logic...
 

  useEffect(()=>{
    if(!auth)
    navigate("/")
  },[])

  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setIsLoading(false);
      }
    };

    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount

  // Render the loader if isLoading is true
  if (isLoading) {
    return <Loader />;
  }



  return (
    <div  className="gradient"
   
    >
    
       <BeepNavbar userData={activeSection || activeSection==null}/>
    <div
      className="1A bg-white min-h-screen max-h-auto max-w-max xl:w-[80vw] xl:mx-auto flex-col justify-start items-center overflow-hidden no-scrollbar"
      
    >
    
     <div className="mt-14">
    

      <div className="flex h-screen">
      <LeftMenu
       setShowDiscard={setShowDiscard}
      showDiscard={showDiscard}
      isChanged={isChanged}
      setIsChanged={setIsChanged}
      
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />
      </div>
      </div>
    </div>
    </div>
  );
};

export default Profile;
