
import BeepNavbar from "../../../components/navbar/navbar";
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import InputFieldComp from "../../../components/inputfields/UI_Inputs/InputFieldComp";
import TextInput from "../../../components/inputfields/UI_Inputs/textInput";
import FillButtonV2 from "../../../components/buttons/UI_Buttons_2/fillButton";
import PaymentSuccess from "./paymentSuccess";
import { Mixpanel } from "../../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import moment from "moment";

const Checkout = () => {


const auth = localStorage.getItem('auth');
const planId = localStorage.getItem('plan');
const [isPromoApplied, setIsPromoApplied] = useState(false);
const [missing, setMissing]= useState({})
console.log(isPromoApplied)
const [invalid, setInvalid] = useState(false);
console.log(planId)
console.log(auth)
const [isChecked, setIsChecked] = useState(false)
const topRef = useRef(null);

const isPremium = localStorage.getItem('isPremium')
const companyName = localStorage.getItem('company');

const navigate = useNavigate();
const [promoCode, setPromoCode] = useState("")
    const [userData, setUserData] = useState({
        fname:"",
        lname:"",
        cname:"",
        add:"",
        country:"",
        state:"",
        city:"",
        pcode:"",
        gstn:"",
      
    })
const [amt, setAmt]=useState('');
console.log(amt)

    const onChange = (e) => {
        const { name, value } = e.target;
        setUserData((prev) => ({
          ...prev,
          [name]: value,
        }));

         // Clear the missing flag for the current field
    // setMissing((prevMissingFields) => ({
    //   ...prevMissingFields,
    //   [name]: false,
    // }));
       
      };
      
    const onPromoChange = (e) => {
     
      setPromoCode(e.target.value);
      
    } 

  const [PaymentId, setPaymentId] = useState("");
  // tick payment.authorized and payment.cature and payment.failed
  const createRazorpayOrder = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_baseURL}/recruiter/create/order`,
        { 
        plan:planId,
        promoCode: promoCode,
        userData: userData,
        },
        {
          headers: {
            Authorization:auth,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      console.error('Error:', error.message);
      setErrorMsg(error.response.data.message);
    }
  };
// 
  const checkPromoCode = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_baseURL}/recruiter/promocode/valid?promoCode=${promoCode}&planId=${planId}`,
        {
          headers: {
            Authorization:auth,
          },
        }
      );
      console.log(response.data)
      Mixpanel.track("apply_promo_code", {
        date: moment().format(),
        browser: browserName,
        mobile_device: isMobileOnly,
        recruiter_id: localStorage.getItem("userId"),
        distinct_id: localStorage.getItem("userId"),
        isPremium: localStorage.getItem("isPremium"),
        planId:planId,
        promoCode:promoCode,
                 
      });
      setIsPromoApplied(true);
      setAmt(response.data.amount);
      return response.data;
    } catch (error) {
      console.log(error);
      console.error('Error:', error);
      setErrorMsg(error.response.data.message);
    }
  };

  const [errorMsg, setErrorMsg] = useState('');

//   0-yearly, 1-monthly
const handleClick = async () => {

  try {

    const missingFields = {};
    if (!userData.fname) missingFields["fname"] = true;
    if (!userData.lname) missingFields["lname"] = true;
    if (!userData.add) missingFields["add"] = true;
    if (!userData.country) missingFields["country"] = true;
    if (!userData.state) missingFields["state"] = true;
    if (!userData.city) missingFields["city"] = true;
    if (!userData.pcode) missingFields["pcode"] = true;
    if (isChecked == true && !userData.gstn) missingFields["gstn"] = true;
    


      setMissing(missingFields);
      console.log(missingFields);

      if (Object.keys(missingFields).length === 0) {
    const orderData = await createRazorpayOrder();
    Mixpanel.track("continue_to_payement_clicked", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      recruiter_id: localStorage.getItem("userId"),
      distinct_id: localStorage.getItem("userId"),
      isPremium: localStorage.getItem("isPremium"),
      planId:planId,
      promoCode:promoCode,
      amount:orderData.data.amount,
      orderId:orderData.data.id,
               
    });
    console.log(orderData, "Order Data");
    console.log(orderData.data.amount, "Amount", orderData.data.id);
    const amount = orderData.data.amount;
    const options = {
      key: "rzp_live_XsHPpqaONsbIZ6",
      amount,
      currency: "INR",
      name: "EventBeep Payment Portal",
      order_id: orderData.data.id,
      description: "Make Payment Instant",
      notes: {
        userId: orderData.data?.notes?.userId,
      },
      handler: async function (response) {
        console.log("Razorpay Payment response", response);
        Mixpanel.track("pro_plan_company", {
          date: moment().format(),
          browser: browserName,
          mobile_device: isMobileOnly,
          recruiter_id: localStorage.getItem("userId"),
          distinct_id: localStorage.getItem("userId"),
          isPremium: localStorage.getItem("isPremium"),
          planId:planId,
          promoCode:promoCode,
          amount:orderData.data.amount,
          orderId:orderData.data.id,
          paymentId:response.razorpay_payment_id
                   
        });
        setPaymentId(response.razorpay_payment_id);
        localStorage.setItem('paymentId', response.razorpay_payment_id);

        // Check if the page has already been redirected
        if (!sessionStorage.getItem("isRedirected")) {
          sessionStorage.setItem("isRedirected", "true");

          // Redirect to the 'Thank You' page
          console.log("Redirecting to Success page");
          navigate('/pricing/checkout/success');
        } else {
          // If already redirected, handle the failure case
          sessionStorage.setItem("isRedirected", "true");
          console.log("Redirecting to Failure page from handler");
          navigate('/pricing/checkout/failure');
        }
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();

    rzp.on('payment.failed', function (response) {
      sessionStorage.setItem("failureRefreshPending", "true");
      
      console.log(response.error.code);
      console.log(response.error.description);
      console.log(response.error.source);
      console.log(response.error.step);
      console.log(response.error.reason);
      console.log(response.error.metadata.order_id);
      console.log(response.error.metadata.payment_id);
    
      // Close the Razorpay modal before redirecting

      rzp.close();
      // Set a flag to indicate redirection
      sessionStorage.setItem("isRedirected", "true");
      navigate('/pricing/checkout/failure');
    });
  
  }
  } catch (error) {
    console.error('Error:', error.message);
    setErrorMsg(error?.response?.data?.message);
    sessionStorage.setItem("isRedirected", "true");
    // Redirect to the 'Failure' page in case of an error
    console.log("Redirecting to Failure page from catch block");
    navigate('/pricing/checkout/failure');
  }
};

const loadProfile = async () => {
  const url = `${process.env.REACT_APP_baseURL}/recruiter/verify`;

  // console.log(jobId);
  console.log(url);
  const response = await axios
    .get(url, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("auth"),
      },
    })
    .catch((error) => {
      console.log(error.response?.data);
      if (error.response && error.response?.data.message)
      setErrorMsg(error?.response?.data?.message);
    });
  console.log(response);
  if (response?.data) {
    console.log(response?.data);
    const responseData = response?.data;
    setUserData((prev)=>({
...prev,
fname:responseData?.firstName,
lname:responseData?.lastName,
cname:companyName,

    }));
  }
};

  useEffect(() => {
    topRef?.current?.scrollIntoView({ behavior: "smooth" });
    loadProfile();
    localStorage.setItem('paymentId', PaymentId);
    
    
  }, [PaymentId]);

  useEffect(()=>{
    if(!auth)
    navigate("/")
  },[])

    // const [select, isSelect] = useState('');
  return <div className="gradient-50 h-[100vh]">

    
   <BeepNavbar />
  
  <div ref={topRef} className="w-full m-auto h-[100vh] pt-14 flex flex-col justify-between items-center bg-white">
   
  <div className="w-full h-auto py-[120px] flex-col justify-start items-start gap-6 inline-flex">
    <div className="ml-[60px] text-teal-950 text-2xl font-bold font-satoshi leading-[28.80px]">Billing Details</div>
    <div className="w-full px-8 md:px-16 justify-center items-center lg:items-start gap-[43px] inline-flex flex-col lg:flex-row" >
        <div className="w-full lg:max-w-[834px] px-[25px] py-[51px] bg-white rounded-2xl shadow border-2 border-gray-100 flex-col justify-start items-start gap-5 inline-flex">
            <div className=" w-full justify-start items-start gap-[15px] inline-flex">
                <div className="w-full">
            <InputFieldComp
                  labelText="First Name*"
                  type="text"
                  name="fname"
                  placeholder="Rajat"
                  hasError={missing.fname}
                  errorMsg={missing.fname ? `First name is required.` : ""}
                  value={userData.fname}
                  onChange={onChange}
                  
                  
                />
                </div>
                <div className="w-full ">
            <InputFieldComp
                  labelText="Last Name*"
                  type="text"
                  name="lname"
                  placeholder="Sharma"
                  hasError={missing.lname}
                  errorMsg={missing.lname ? `Last name is required.` : ""}
                  value={userData.lname}
                  onChange={onChange}
                  
                  
                />
                </div>
            </div>
            <div className="w-full">
            <InputFieldComp
                  labelText="Company Name"
                  type="text"
                  name="cname"
                  placeholder="Eventbeep pvt. ltd"
                //   hasError={error.name}
                //   errorMsg={error.name}
                  value={userData.cname}
                  onChange={onChange}
                  
                  
                />
                </div>
    
<div className="w-full">
            <InputFieldComp
                  labelText="Address*"
                  type="text"
                  name="add"
                  placeholder="Address"
                  hasError={missing.add}
                  errorMsg={missing.add ? `Address is required.` : ""}
                  value={userData.add}
                  onChange={onChange}
                  
                  
                />  
            </div>

            <div className="w-full">
            <InputFieldComp
                  labelText="Country*"
                  type="text"
                  name="country"
                  placeholder="Country"
                  hasError={missing.country}
                  errorMsg={missing.country ? `country is required.` : ""}
                  value={userData.country}
                  onChange={onChange}
                  
                  
                />  
            </div>

<div className="w-full">
            <InputFieldComp
                  labelText="State*"
                  type="text"
                  name="state"
                  placeholder="State"
                  hasError={missing.state}
                  errorMsg={missing.state}
                  value={userData.state}
                  onChange={onChange}
                  
                  
                />      
            </div>
          
            <div className="w-full justify-start items-start gap-[15px] inline-flex">
            <div className="w-full">
                <InputFieldComp
                  labelText="City*"
                  type="text"
                  name="city"
                  placeholder="city"
                  hasError={missing.city}
                  errorMsg={missing.city ? `City is required.` : ""}
                  value={userData.city}
                  onChange={onChange}
                  
                  
                />  
                </div>
             
               <div className="w-full">
                 <InputFieldComp
                  labelText="Postal Code*"
                  type="text"
                  name="pcode"
                  placeholder="Postal Code"
                  hasError={missing.pcode}
                  errorMsg={missing.pcode ? `Postal Code is required.` : ""}
                  value={userData.pcode}
                  onChange={onChange}
                  
                  
                />  
              </div>
            </div>
            <div className="self-stretch h-[0px] border-2 border-neutral-50"></div>
            <div className="py-2.5 flex-col justify-start items-start gap-[15px] flex">
            <div className="flex my-4">
                          <label className="label cursor-pointer">
                          <input
  onChange={() => setIsChecked(prev => !prev)}
  type="checkbox"
  // checked="checked"
  className="w-5 h-5 border-none rounded-sm border-2 focus:border-emerald-500 accent-emerald-500 text-white"
/>

                            <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight mx-3 ">
                            I am a business owner
                            </span>
                          </label>
                        </div>
                {/* <div className="justify-start items-start gap-[15px] inline-flex">
                    <div className="w-5 h-5 relative" />
                    <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">I am a business owner</div>
                </div> */}
                <div>
                <InputFieldComp
                disabled={isChecked == true ? false : true}
                  labelText="GST IN*"
                  type="text"
                  name="gstn"
                  placeholder="GST IN"
                  hasError={isChecked == true && missing.gstn}
                  errorMsg={(isChecked == true && missing.gstn) ? `GST IN is required.` : ""}
                  value={userData.gstn}
                  onChange={onChange}
                  
                  
                />  
                </div>
               
            </div>
        </div>
        <div className="w-full sm:max-w-[397px] flex-col justify-start items-start gap-[30px] inline-flex">
            <div className="w-full p-5 bg-white rounded-2xl shadow border-2 border-gray-100 flex-col justify-start items-start gap-11 flex">
                <div className="w-full px-1.5 flex-col justify-start items-start gap-6 flex">
                    <div className="px-[9px] py-[7px] bg-gradient-to-br from-amber-300 via-yellow-300 to-amber-300 rounded-2xl shadow justify-center items-center gap-2.5 inline-flex">
                        <div className="text-teal-950 text-base font-bold font-satoshi leading-tight">Pro Plan</div>
                    </div>
                    <div className="text-teal-950 text-[32px] font-extrabold font-satoshi leading-[38.40px]">{`${planId == 0 ? "Yearly" : planId == 1 ? "Monthly" : "Quarterly"}`}</div>
                    <div className="w-full flex-col justify-start items-start gap-3 flex">
                        <div className="w-full justify-between items-start inline-flex">
                            <div className="text-slate-700 text-base font-medium font-satoshi leading-tight">{`${planId == 0 ? "1 Year" : planId == 1 ? "1 Month" : planId == 2 ? "3 Month" : ""}`} of access:</div>
                            <div className="text-slate-700 text-base font-medium font-['Satoshi'] leading-tight">₹ {`${planId == 0 ? "99,999" : planId == 1 ? "11,000" : planId == 2 ? "36,999" : ""}`}</div>
                        </div>
                        {isPromoApplied && 
                         <div className="w-full justify-between items-startt inline-flex">
                         <div className="text-teal-950 text-base font-bold font-satoshi leading-tight">Applied PromoCode:</div>
                         <div className="text-teal-950 text-base font-black font-['Satoshi'] leading-tight">- ₹ {amt}</div>
                     </div>
                        }
                       
                        <div className="w-full justify-between items-start inline-flex">
                            <div className="text-teal-950 text-base font-bold font-satoshi leading-tight">Total:</div>
                            <div className="text-teal-950 text-base font-black font-['Satoshi'] leading-tight">₹ {`${planId == 0 ? (99999 - amt) : planId == 1 ? (11000 - amt) : planId == 2 ? (36999 - amt) : ""}`}</div>
                        </div>
                    </div>
                </div>
              <div className={`w-auto px-3 ${promoCode ? "opacity-50" : ""} bg-gray-100 rounded-xl justify-start items-center gap-1 inline-flex`}>
                <InputFieldComp
                  // labelText="Last Name"
                 
                  disabled={isPromoApplied ? true : false}
                  type="text"
                  name="promocode"
                  placeholder="Promo Code"
                  placeClass={`rounded-xl px-2 bg-gray-100 font-bold text-gray-900  ${promoCode ? "bg-gray-100" : "bg-white"}`}
                  value={promoCode}
                  onChange={onPromoChange}
                  // hasError={errorMsg}
                  errorMsg={errorMsg}
                      
                />

<div onClick={checkPromoCode}>
                    <FillButtonV2 
                    className={`px-8 mr-0`}
                    color="green"
                    textColor="white"
                    text={` ${isPromoApplied ? "Applied" : "Apply" }`}
                    disabled={isPromoApplied ? true : false}
                    // errorMsg={errorMsg}
                    />
                  </div>
                 
                </div>
            </div>
            <div  className="w-full h-auo flex flex-col justify-center items-center" onClick={handleClick}>
                    <FillButtonV2 
                    className={` px-[6rem] mr-0`}
                    color="green"
                    textColor="white"
                    text="Continue to Payment"
                    disabled={isPremium === "true" ? true : false}
                    />
                    <span className="text-red-500 text-sm py-2">{Object.keys(missing).length > 0 ? "Please fill all the required field before continue." : ""}</span>
                  </div>

            {/* <div onClick={handleClick} className="px-[142px] py-3 bg-emerald-500 rounded-xl justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-sm font-bold font-satoshi leading-tight">Continue to Payment</div>
            </div> */}
        </div>
    </div>
</div>
<div className="w-full
 h-[92px] px-16 py-8 bg-gray-100 flex-col justify-start items-center gap-16 inline-flex">
     <div className="self-stretch justify-center items-center gap-8 inline-flex">
        <Link to="/tnc" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Terms of Use</div>
        </div>
        </Link>
        <Link to="/privacy" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Privacy Policy</div>
        </div>
        </Link>
    </div>
</div>
  </div>

  </div>
};

export default Checkout;
