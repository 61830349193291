import React, { useState, useEffect } from "react";
import BeepNavbar from "../../../components/navbar/navbar";
import { Link, useNavigate } from "react-router-dom";

const PaymentFailure = () => {
  const [select, isSelect] = useState("");


   // const [select, isSelect] = useState('');
   const paymentId = localStorage.getItem('paymentId')
   const navigate = useNavigate();
   useEffect(() => {
       // Check if the session flag is set
       const isRedirected = sessionStorage.getItem('isRedirected');
   
       try {
        const elements = document.getElementsByClassName("razorpay-container");
  
        elements[0].parentNode.removeChild(elements[0]);
       } catch (error) {
        
       }
       // If the flag is not set, redirect to another page
       if (!isRedirected) {
         navigate('/pricing/checkout'); // You can redirect to any other page
       }
       if(sessionStorage.getItem("failureRefreshPending") == true){
        sessionStorage.removeItem('failureRefreshPending');
        window.location.reload();
       } else {
        sessionStorage.removeItem('isRedirected');
       
       }
       // Clean up by removing the session flag
       
     }, []); 

     


  return (
    <div className="gradient-50 h-[100vh]">
      <BeepNavbar />
      <div className="w-full md:max-w-[1024px] m-auto h-[100vh] pt-14 flex flex-col justify-between items-center bg-white">
        <div className="w-full h-auto py-[130px] flex-col justify-start items-center gap-[50px] inline-flex">
          <div className="flex-col justify-center items-center gap-[25px] flex">
            <div className="w-[163px] h-[163px]">
            <img src="/failureIcon.png" alt="failureIcon" width="256" height="256" />
             
            </div>
            {/* <img className="w-[163px] h-[163px]" src="https://via.placeholder.com/163x163" /> */}
            <div className="w-min sx:w-max text-teal-950 text-5xl font-bold font-['Satoshi'] leading-[57.60px]">
              Payment Failed!
            </div>
            <div className="w-full md:max-w-[671px] text-center">
              <span className="text-slate-500 text-2xl font-medium font-['Satoshi'] leading-[28.80px]">
                Your transaction didn’t go through. <br />
                There might be a simple reason such as card details or network
                hiccup. You can try again or{" "}
              </span>
              <span className="text-slate-500 text-2xl font-medium font-['Satoshi'] underline leading-[28.80px]">
                contact support
              </span>
              <span className="text-slate-500 text-2xl font-medium font-['Satoshi'] leading-[28.80px]">
                {" "}
              </span>
            </div>
          </div>
          <Link to="/pricing/checkout">
          <div className="px-4 py-3 bg-emerald-500 rounded-xl justify-center items-center gap-2 inline-flex">
            <div className="text-center text-white text-sm font-black font-satoshi leading-tight">
              Try Again
            </div>
          </div>
          </Link>
        </div>
        <div className="w-full md:max-w-[1020px] h-[92px] px-16 py-8 bg-gray-100 flex-col justify-start items-center gap-16 inline-flex">
        <div className="self-stretch justify-center items-center gap-8 inline-flex">
        <Link to="/tnc" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Terms of Use</div>
        </div>
        </Link>
        <Link to="/privacy" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Privacy Policy</div>
        </div>
        </Link>
    </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailure;
