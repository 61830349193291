import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import Alert from "../../components/alert/alert";
import { Mixpanel } from "../../services/mixpanel";
import moment from "moment";
import { isMobile, browserName } from "react-device-detect";
import ProfileLogoChange from "../../components/changeLogo/changeProfile";
import ButtonChild from "../../components/buttons/UI_Buttons_2/buttonChild";
import BorderButton from "../../components/buttons/UI_Buttons_2/borderButton";
import FillButtonV2 from "../../components/buttons/UI_Buttons_2/fillButton";




const CreateCompany = () => {
  var domainExtraction = /^(?:https?:\/\/)?(?:www\.)?([^.]+)\./;
  const navigate = useNavigate();
  // const [imagePreviewUrl, setImagePreviewUrl] = useState('https://github.com/OlgaKoplik/CodePen/blob/master/profile.jpg?raw=true');

  const [form, setForm] = useState({
    registeredName: "",
    name: "",
    logo: null,
  });
  const [alertData, setAlertData] = useState(null);

  const [isAnyoneVerified, setIsAnyOneVerified] = useState(null);

  const [urls, setUrls] = useState([""]);

  const onUrlValueChanage = (index, value) => {
    setUrls((prev) => {
      let temp = [...prev];
      temp[index] = value;

      return temp;
    });
  };





  const loadCompany = async () => {
    // if (localStorage.getItem("auth")) navigate("job/dashboard");
    // const url = `${process.env.REACT_APP_baseURL}/company/mycompany`;
    // console.log(url);
    // const response = await axios
    //   .get(url, {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("auth"),
    //     },
    //   })
    //   .catch((error) => console.log(error));
    // if (response?.data) {
    //   console.log(response?.data);
    //   setForm(response?.data);
    //   setUrls(response?.data.webUrls);
    // }
  };

  useEffect(loadCompany, []);

  const onFormChange = async (e) => {

    const { name, value } = e.target;
    setForm((prev) => {
      if (name == "file")
        return {
          ...prev,
          [name]: e.target.files,
        };
      else
        return {
          ...prev,
          [name]: value,
        };
    });
    // await test();
  };
  const onLogoChange = async (file) => {

    setForm((prev) => {

      return {
        ...prev,
        file,
      };
    });
    // await test();
  };


  const addList = () => {
    setUrls((prev) => {
      return [...prev, ""];
    });
  };

  const removeUrl = (indexToRemove) => {
    setUrls((prev) => {
      return prev.filter((url, index) => index !== indexToRemove);
    });
  };

  const createCompany = async () => {
    const url = `${process.env.REACT_APP_baseURL}/company/create`;

    let formData = new FormData();
    formData.append("registeredName", form.registeredName);
    formData.append("name", form.name);
    if (form.file)
      formData.append("upload", form.file[0]);
    formData.append("webUrls", urls.join(","));

    // if(localStorage.getItem('companyId'))

    const response = await axios.post(url, formData, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("auth"),
      },
    });

    if (response?.data) {
      return true;
    }

    return false;
  };

  const VerifyPan = async () => {
    await createCompany();

    const url = `${process.env.REACT_APP_baseURL}/company/verify/pan`;

    const response = await axios
      .post(
        url,
        {
          pan: form.PAN,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      )
      .catch((error) => {
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
      });

    if (response?.data) {
      setIsAnyOneVerified("PAN");
    }
  };

  const VerifyGSTIN = async () => {
    await createCompany();
    const url = `${process.env.REACT_APP_baseURL}/company/verify/gstin`;

    const response = await axios
      .post(
        url,
        {
          gstin: form.GSTIN,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      )
      .catch((error) => {
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
      });

    if (response?.data) {
      setIsAnyOneVerified("GSTIN");
    }
  };

  const onFinish = async () => {
    Mixpanel.track("New Company Created", {
      date: moment().format(),
      distinct_id: localStorage.getItem("userId"),
      recruiter_id: localStorage.getItem("userId"),
      registeredName: form.registeredName,
      companyName: form.name,
      verificationMethod: isAnyoneVerified,
      linksCount: urls.length,
      links: urls.toString(),
      browser: browserName,
      mobile_device: isMobile,
    });
    // await
    if (await createCompany()) navigate("/");
  };

  return (
    <div
      className=""
      style={{
        background:
          "linear-gradient(180deg, rgba(255, 218, 69, 0.10) 0%, rgba(22, 192, 131, 0.10) 100%), #FFF",
      }}
    >
      <div className="div mx-auto min-w-fit max-w-[800px] bg-white p-8 mt-[32px] md:p-16">
        {/* <div className="div mx-auto max-w-[800px] bg-white p-16"> */}
        <Link
          to="/signin"
          className="flex text-emerald-500 font-bold text-sm mb-4"
        >
          <ButtonChild
            text="Back to Sign In"
            textColor="green"
            leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
              <path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="currentColor" />
            </svg>}
          />
        </Link>

        <div className="CreateYourCompanySProfile w-[672px] text-teal-950 text-2xl font-bold leading-[28.80px]">
          Create Your Company’s Profile
        </div>

        <div className="Line w-full h-[0px] border border-dashed my-8 border-gray-900 border-opacity-10"></div>

        <div className="flex flex-col md:flex-row">
          {/* Business Profile */}
          <div className="w-3/12 font-bold  ">
            <div className="BusinessProfile w-[311px] text-teal-950 text-base font-bold leading-normal">
              Business Profile
            </div>
          </div>
          {/* Input right side */}
          <div className="w-full md:w-9/12 my-8">
            {/* Row 1 */}
            <div className="flex flex-col mb-8">
              <label
                htmlFor="email"
                className="LabelText  text-gray-900 text-[14px] font-medium leading-tight"
              >
                Registered Name
              </label>
              <input
                type="text"
                name="registeredName"
                
                placeholder="Company Name Inc."
                className="border-2 p-4  rounded-xl my-1 outline-none text-sm"
                value={form.registeredName}
                onChange={onFormChange}
              />
            </div>
            {/* Row 2 */}
            <div className="flex my-4 mb-8">
              <div className="w-3/12 mr-4">
                <div className="flex flex-col ">
                  <label
                    htmlFor="email"
                    className="LabelText  text-gray-900 text-[14px] font-medium leading-tight"
                  >
                    Logo
                  </label>

                  <ProfileLogoChange
                    className="mt-2"
                    onLogoChange={onLogoChange}
                  />

                </div>
              </div>

              <div className="w-9/12">
                <div className="flex flex-col ">
                  <label
                    htmlFor="email"
                    className="LabelText  text-gray-900 text-[14px] font-medium leading-tight"
                  >
                    Trade / Brand Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Company Name Inc."
                    className="border-2 padding-2  p-4  rounded-xl my-1 outline-none text-sm"
                    value={form.name}
                    onChange={onFormChange}
                  />
                </div>
              </div>
            </div>

            {/* Row 3 */}
            <div className="">
              <div className="flex flex-col">
                <label
                  htmlFor="email"
                  className="LabelText  text-gray-900 text-[14px] font-medium leading-tight"
                >
                  Websites
                </label>

                {urls?.map((url, index) => (
                  <div className="Input self-stretch bg-white overflow-auto no-scrollbar rounded-xl border border-gray-900 border-opacity-20 justify-start items-center inline-flex my-1">
                    <div className="LinkDropdown p-4 justify-start items-start gap-2 flex">
                      <div className="InputFieldIconsLinkedin  w-5 h-5 ">
                        <img
                          src={`http://www.google.com/s2/favicons?sz=128&domain=${url ? url : null
                            }`}
                          className="w-5 h-5"
                        />
                      </div>
                      <div className="Linkedin w-20 text-gray-900 text-opacity-60 text-sm font-medium leading-tight">
                        {url.match(domainExtraction)
                          ? url.match(domainExtraction)[1]
                          : "Custom"}
                      </div>
                    </div>
                    <input
                      placeholder="www.linkedin.com/swiggyindia"
                      value={url}
                      onChange={(e) => onUrlValueChanage(index, e.target.value)}
                      className="Input grow shrink basis-0 h-[52px] p-4 justify-start items-start gap-2 flex WwwLinkedinComSwiggyindia shrink basis-0 text-gray-900 text-opacity-40 text-sm font-medium leading-tight
                    outline-none"
                    />

                    {index === 0 ? "" 
                    : 
                    (
                    <div className="Icon w-[52px] h-[52px] p-4 justify-center items-center flex">
                      <button
                        className="InputFieldIconsMinuscircle  w-5 h-5  flex-col justify-start items-start flex"
                        onClick={() => removeUrl(index)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.125 10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                            fill="#DB1717"
                          />
                        </svg>
                      </button>
                    </div>)}

                  </div>
                ))}

                <>
                  <div onClick={addList} className="flex border-2 p-4  rounded-xl outline-none my-2">

                    <ButtonChild
                      text="Add Websites"
                      textColor="green"
                      leftIcon={<svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>}
                    />
                    {/* <button
                      onClick={addList}
                      className="mx-4 text-emerald-500 font-semibold"
                    >
                      <div className="flex">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                        <p>Add Website</p>
                      </div>
                    </button> */}
                  </div>
                </>
              </div>
            </div>
          </div>
        </div>

        <div className="Line w-full h-[0px] border border-dashed my-8 sm:my-16 border-gray-900 border-opacity-10"></div>

        <div className="flex my-4 w-full flex-col md:flex-row">
          {/* Profile Verification */}
          <div className="w-full md:w-3/12 font-bold ">
            <div className="BusinessProfile w-[311px] text-teal-950 text-base font-bold leading-normal mb-8">
              Profile Verification
            </div>
          </div>

          {/* section -2  */}
          <div className="w-full md:w-9/12">
            {/* Row 1 */}
            <div className="flex my-4 w-full">
              <div className=" mr-4 w-full">
                <div className="flex flex-col w-full">
                  <label
                    htmlFor="email"
                    className="LabelText  text-gray-900 text-[14px] font-medium leading-tight"
                  >
                    Verify via PAN
                  </label>
                  <div className="flex mb-8">
                    <input
                      type="text"
                      name="PAN"
                      disabled={isAnyoneVerified != null}
                      placeholder="Company Name Inc."
                      className={`border-2 padding-2  p-4  rounded-xl my-1 outline-none w-9/12 ${isAnyoneVerified == "PAN" ? "border-emerald-500" : ""
                        }`}
                      value={form?.PAN}
                      onChange={onFormChange}
                    />
                    <div className=" my-auto mr-0 ml-auto">
                      {isAnyoneVerified == null && (

                        <BorderButton
                          onClick={VerifyPan}
                          text="Verify"
                          color="green"
                          textColor="green"
                        />
                      
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* or */}
            <span className="rounded-full bg-slate-500 px-2 py-2 text-white text-sm">
              OR
            </span>
            

            <div className="flex my-4 w-full">
              <div className=" mr-4 w-full">
                <div className="flex flex-col w-full mt-8">
                  <label
                    htmlFor="email"
                    className="LabelText  text-gray-900 text-[14px] font-medium leading-tight"
                  >
                    Verify via GSTIN
                  </label>
                  <div className="flex">
                    <input
                      type="text"
                      name="GSTIN"
                      disabled={isAnyoneVerified != null}
                      placeholder="Company Name Inc."
                      className={`border-2 padding-2  p-4  rounded-xl my-1 outline-none w-9/12 ${isAnyoneVerified == "GSTIN" ? "border-emerald-500" : ""
                        }`}
                      value={form?.GSTIN}
                      onChange={onFormChange}
                    />
                    <div className=" my-auto mr-0 ml-auto">
                      {isAnyoneVerified == null && (

                        <BorderButton
                          onClick={VerifyGSTIN}
                          text="Verify"
                          color="green"
                          textColor="green"
                        />
                        // <button
                        //   className="outline outline-emerald-500 px-4 py-2 rounded disabled:text-slate-200 text-emerald-500 shadow-emerald-500/50 drop-shadow-lg outline-2 disabled:outline-slate-200"
                        //   onClick={VerifyGSTIN}
                        // >
                        //   Verify
                        // </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Line w-full h-[0px] border border-dashed my-8 sm:my-16 border-gray-900 border-opacity-10"></div>

        {(isAnyoneVerified == null) ?
          (
            <FillButtonV2
              // className="cursor-not-allowed"
              disabled={true}
              text="Finish"
              color="gray"
              textColor="white"
              rightIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                <path d="M14.1922 10.4422L7.94219 16.6922C7.85478 16.7797 7.74337 16.8393 7.62207 16.8635C7.50076 16.8876 7.37502 16.8752 7.26076 16.8279C7.14649 16.7805 7.04884 16.7004 6.98017 16.5975C6.91151 16.4946 6.8749 16.3737 6.875 16.25V3.75C6.8749 3.62632 6.91151 3.50539 6.98017 3.40252C7.04884 3.29964 7.14649 3.21946 7.26076 3.17211C7.37502 3.12477 7.50076 3.11239 7.62207 3.13655C7.74337 3.1607 7.85478 3.22031 7.94219 3.30782L14.1922 9.55782C14.2503 9.61586 14.2964 9.68479 14.3279 9.76067C14.3593 9.83654 14.3755 9.91787 14.3755 10C14.3755 10.0821 14.3593 10.1635 14.3279 10.2393C14.2964 10.3152 14.2503 10.3841 14.1922 10.4422Z" fill="currentColor" />
              </svg>}
            />
          ) : (
            <FillButtonV2
              onClick={onFinish}
              disabled={form.verified}
              text="Finish"
              color="green"
              textColor="white"
              rightIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                <path d="M14.1922 10.4422L7.94219 16.6922C7.85478 16.7797 7.74337 16.8393 7.62207 16.8635C7.50076 16.8876 7.37502 16.8752 7.26076 16.8279C7.14649 16.7805 7.04884 16.7004 6.98017 16.5975C6.91151 16.4946 6.8749 16.3737 6.875 16.25V3.75C6.8749 3.62632 6.91151 3.50539 6.98017 3.40252C7.04884 3.29964 7.14649 3.21946 7.26076 3.17211C7.37502 3.12477 7.50076 3.11239 7.62207 3.13655C7.74337 3.1607 7.85478 3.22031 7.94219 3.30782L14.1922 9.55782C14.2503 9.61586 14.2964 9.68479 14.3279 9.76067C14.3593 9.83654 14.3755 9.91787 14.3755 10C14.3755 10.0821 14.3593 10.1635 14.3279 10.2393C14.2964 10.3152 14.2503 10.3841 14.1922 10.4422Z" fill="currentColor" />
              </svg>}
            />
          )
        }


        {/* <button
          className={`${isAnyoneVerified == null
              ? "bg-gray-500 cursor-not-allowed  "
              : "bg-emerald-500"
            } px-4 py-2 rounded text-white w-full h-[52px] sm:w-[152px] rounded-xl mb-4 outline-2 disabled:bg-slate-200`}
          onClick={onFinish}
          disabled={form.verified}
        >
          <div className="relative flex w-[251px] text-white text-sm font-bold leading-tight">
            <div className="">Finish</div>

            <svg
              className=" absolute ml-[38rem] sm:ml-24 mt-1 text-red-400"
              width="9"
              height="11"
              viewBox="0 0 9 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.19219 7.44219L1.94219 13.6922C1.85478 13.7797 1.74337 13.8393 1.62207 13.8635C1.50076 13.8876 1.37502 13.8752 1.26076 13.8279C1.14649 13.7805 1.04884 13.7004 0.980175 13.5975C0.911506 13.4946 0.874903 13.3737 0.875 13.25V0.750004C0.874903 0.626319 0.911506 0.505386 0.980175 0.402515C1.04884 0.299644 1.14649 0.21946 1.26076 0.172115C1.37502 0.12477 1.50076 0.112392 1.62207 0.136548C1.74337 0.160705 1.85478 0.220309 1.94219 0.307816L8.19219 6.55782C8.2503 6.61586 8.2964 6.68479 8.32785 6.76067C8.3593 6.83654 8.37549 6.91787 8.37549 7C8.37549 7.08214 8.3593 7.16347 8.32785 7.23934C8.2964 7.31522 8.2503 7.38415 8.19219 7.44219Z"
                fill="#FFF"
              />
            </svg>
          </div>
        </button> */}
      </div>
      {alertData && (
        <Alert
          alertClose={() => setAlertData(null)}
          time={3}
          text={alertData}
        />
      )}
    </div>
  );
};

export default CreateCompany;
