import React, { useEffect, useState } from "react";
import ButtonChild from "../../../../components/buttons/UI_Buttons_2/buttonChild";
import InputFieldComp from "../../../../components/inputfields/UI_Inputs/InputFieldComp";
import axios from "axios";
import { Mixpanel } from "../../../../services/mixpanel";
import { isMobileOnly, browserName } from "react-device-detect";
import moment from "moment";
import { DownloadAllResumeApi, getUserDetails } from "../../../../services/job";
import BorderButton from "../../../../components/buttons/UI_Buttons_2/borderButton";
import FillButtonV2 from "../../../../components/buttons/UI_Buttons_2/fillButton";
import Loader from "../../../../components/Loader/loader";
import { Link } from "react-router-dom";

const DownloadAllResume = ({ close, resumeJobId, openDownloadResumeModal}) => {
  console.log(localStorage.getItem("isPremium"), "ggggggg")
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = useState(false);
  const [isDownload, setIsDownload] = useState(false);



  const onDownload = async () => {

    try {
        const authToken = localStorage.getItem('auth');
        console.log(authToken);
  
        const response = await DownloadAllResumeApi(email,resumeJobId);
        setIsDownload(true);
  if(response){
    Mixpanel.track("Download_All_Resumes_successfull", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      distinct_id:
        localStorage.getItem("userId"),
      recruiter_id:
        localStorage.getItem("userId"),
      job_id: rowData?._id,
      job_title: rowData?.job_profile,
    });
  
    // openDownloadResumeModal();

  }
        console.log('Resume Download successfully:', response.data);
    
    } catch (error) {
        console.error('Error updating data:', error.message);
    }
  };

useEffect(()=>{
setIsLoading(true);
  const fetchData = async()=>{
    const response = await getUserDetails();
    if(response){
        setIsLoading(false)
        setEmail(response?.email);
        console.log(response, "userData");
    }
  }
  fetchData()
}, [])

  return (
    <div
      class="relative z-11 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >

      <div class="fixed inset-0  bg-white bg-opacity-80 backdrop-blur-[4px] blur-md transition-opacity"></div>

     
    
      <div class="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
      {isLoading ? 
    <Loader />
    :
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative m-auto w-full h-auto transform rounded-3xl bg-white text-left shadow-xl transition-all px-8 py-8 sm:my-8 sm:w-full sm:max-w-lg w-4/12">
            <div className="flex justify-between items-center w-full">
            <div className="w-auto text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px]">Download Resume</div>
              
              <div onClick={openDownloadResumeModal}>
              <ButtonChild 
              className="px-1"
              
              textColor="gray"
              leftIcon={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z" 
              fill="currentColor"/>
            </svg>}
              />
              </div>
            </div>
            {!isDownload ? 
            <>
            <div className="w-full text-teal-950 text-sm font-bold font-satoshi leading-tight mt-spaceXxLarge">
            Donwload all applicants resumes for selected job.
                </div> 
            <div className="form w-full">
           
            <InputFieldComp
                labelText="Your Email"
                type="text"
                name="text"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // errorMsg={invitationStatus}
              />
                    
            
          
              <div className="flex flex-row justify-start gap-spaceBase ml-auto mt-spaceLarge flex-wrap" >
              <div>

<BorderButton 
onClick={openDownloadResumeModal}
text="Cancel"
textColor="gray"
color="gray"
leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z" 
fill="currentColor"/>
</svg>}
/>
</div>
                <div>
              <FillButtonV2 
              onClick={onDownload}
              text={"Download"}
              color="green"
              textColor="white"
              leftIcon={ <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.31719 1.05781L13.6922 5.43281C13.7502 5.4909 13.7962 5.55985 13.8276 5.63572C13.859 5.71159 13.8751 5.7929 13.875 5.875V15.875C13.875 16.2065 13.7433 16.5245 13.5089 16.7589C13.2745 16.9933 12.9565 17.125 12.625 17.125H1.375C1.04348 17.125 0.725537 16.9933 0.491116 16.7589C0.256696 16.5245 0.125 16.2065 0.125 15.875V2.125C0.125 1.79348 0.256696 1.47554 0.491116 1.24112C0.725537 1.0067 1.04348 0.875 1.375 0.875H8.875C8.9571 0.874936 9.03841 0.891048 9.11428 0.922415C9.19015 0.953783 9.2591 0.999791 9.31719 1.05781ZM8.875 2.4375V5.875H12.3125L8.875 2.4375ZM4.68295 11.0573C4.741 10.9992 4.80993 10.9531 4.8858 10.9217C4.96168 10.8902 5.04301 10.874 5.12514 10.874C5.20728 10.874 5.2886 10.8902 5.36448 10.9217C5.44035 10.9531 5.50928 10.9992 5.56733 11.0573L6.37514 11.8659V8.37451C6.37514 8.20875 6.44099 8.04978 6.5582 7.93257C6.67541 7.81536 6.83438 7.74951 7.00014 7.74951C7.1659 7.74951 7.32487 7.81536 7.44208 7.93257C7.55929 8.04978 7.62514 8.20875 7.62514 8.37451V11.8659L8.43295 11.0573C8.49102 10.9993 8.55996 10.9532 8.63583 10.9218C8.7117 10.8903 8.79302 10.8742 8.87514 10.8742C8.95726 10.8742 9.03858 10.8903 9.11445 10.9218C9.19032 10.9532 9.25926 10.9993 9.31733 11.0573C9.3754 11.1154 9.42146 11.1843 9.45289 11.2602C9.48431 11.3361 9.50049 11.4174 9.50049 11.4995C9.50049 11.5816 9.48431 11.6629 9.45289 11.7388C9.42146 11.8147 9.3754 11.8836 9.31733 11.9417L7.44233 13.8167C7.38428 13.8748 7.31535 13.9209 7.23948 13.9524C7.1636 13.9838 7.08228 14 7.00014 14C6.91801 14 6.83668 13.9838 6.7608 13.9524C6.68493 13.9209 6.616 13.8748 6.55795 13.8167L4.68295 11.9417C4.62484 11.8836 4.57874 11.8147 4.54729 11.7388C4.51584 11.663 4.49965 11.5816 4.49965 11.4995C4.49965 11.4174 4.51584 11.336 4.54729 11.2602C4.57874 11.1843 4.62484 11.1154 4.68295 11.0573Z" fill="#fff"/>
              </svg>
              }
              />
              </div>
              

              </div>
            </div>
            </>
            :
            <div className="form w-full flex flex-col gap-4 justify-center items-center">
<div className="w-full flex justify-center items-center text-teal-950 text-sm font-bold font-satoshi leading-tight mt-spaceXxLarge"> We will send the resume to your email.  Please check your inbox after 5-10 minutes. </div>

<FillButtonV2 
onClick={openDownloadResumeModal}
text="Go Back"
color="orange"
textColor="black"
/>

            </div>

                        }            
                        </div>
        </div>
    }
      </div>

    </div>
  );
};

export default DownloadAllResume;
