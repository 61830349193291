import React from "react";
import { Link } from "react-router-dom";

const NavigationGreenButtonWithIcon = ({
  icon,
  text,
  className,
  track,
  onClick,
  ...props
}) => {
  if (track) {
    return (
      <div
        className={`ButtonMedium cursor-pointer h-11 p-3 bg-emerald-500  rounded-xl border  border-gray-900 border-opacity-10 justify-center items-center gap-2 inline-flex ${className} cursor-pointer`}
        {...props}
        onClick={onClick}
      >
        <div className="InputFieldIconsPluscircle  w-5 h-5 ">{icon}</div>
        <div className="ButtonText text-center text-white text-[14px] font-bold leading-tight">
          {text}
        </div>
      </div>
    );
  }
  return (
    <Link
      className={`ButtonMedium cursor-pointer h-[2.60rem] p-3 bg-emerald-500  rounded-xl border  border-gray-900 border-opacity-10 justify-center items-center gap-2 inline-flex ${className}`}
      {...props}
    >
      <div className="InputFieldIconsPluscircle  w-5 h-5 ">{icon}</div>
      <div className="hidden sm:block ButtonText w-max text-center text-white text-[14px] font-bold leading-tight">
        {text}
      </div>
    </Link>
  );
};

export default NavigationGreenButtonWithIcon;
