import React, { useState, useEffect, useRef } from "react";
import BorderButton from "../buttons/UI_Buttons_2/borderButton";
import DropDownButton from "../buttons/UI_Buttons_2/dropDownButton";

const SingleSelectComp = ({leftIcon,disabled, selectedOption,onChange, addInfo, errorMsg, hasError,absoluteClass, options, labelText, rightText,leftText,className, textClass}) => {
//  const [ selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  // console.log(options, "category")
 
  const handleOptionClick = (option) => {
    // console.log(option)
    onChange({target:{value:option}})

    // setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const menuRef = useRef(null);
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsDropdownOpen(false);


    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts
    window.addEventListener("click", handleClickOutside);


    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  // const options = ["Male", "Female", "Other"];
  const borderStyles =  hasError ? 'border-red-500 active:shadow-[rgba(255,0,0,0.3)_0px_0px_0px_2px,_rgba(255,255,255,0.08)_0px_1px_0px_inset]' : ''; // Add a red border on error

  const toggleIcon = isDropdownOpen ?  ( (leftIcon && text) ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z" 
    fill="currentColor"/> 
  </svg>) : leftIcon ? '' : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z" 
    fill="currentColor"/> 
  </svg>)
        
          ) : (
            (leftIcon && text) ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z" 
            fill="currentColor"/>
          </svg>) : leftIcon ? '' : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z" 
            fill="currentColor"/>
          </svg>)

    );

  return (
    <div className="">
      <div className=" w-full inline-flex justify-between text-gray-600 text-sm font-medium font-satoshi leading-tight mb-spaceXxxSmall"
      
      >
        <label>{labelText}</label>
      </div>
      <div ref={menuRef} className="inline-block w-full">
        <BorderButton 
        disabled={disabled}
        fullWidth={true}
        className={`w-full ${borderStyles} ${className}`}
        buttonClass={`w-full flex justify-between text-gray-200`}
        textClass={` w-full flex justify-start ${selectedOption ? "w-full flex justify-start text-teal-950 font-medium" : "w-full flex justify-start text-gray-400 font-medium text-sm"} ${textClass}`}
        onClick={toggleDropdown}
        textColor="gray"
        rightText={rightText}
        leftText={leftText}
       
        color="gray"
        text={selectedOption ? (typeof selectedOption === "string" ? selectedOption : (selectedOption && selectedOption.label) || "Click to Select") : "Click to Select"}

        
        rightIcon={toggleIcon}
        hasError={hasError}
        errorMsg={errorMsg}
        
       />
       
       <div  className=" absolute w-[10rem]" style={{zIndex:11}}>
        {isDropdownOpen && (
          <ul  className={`w-full max-h-[15rem] overflow-scroll flex flex-col bg-white rounded-xl border border-xl mt-spaceXxxSmall justify-start overflow-hidden no-scrollbar shadow-[rgba(210,215,211,1)_0px_0.5px_5px] ${absoluteClass}`}
          style={{ zIndex: 1 }}
           >
            {options.map((option, index) => (
              <li
                key={index}
                
                className=" flex flex-col"
                onClick={() => handleOptionClick(option)}
              >
                <DropDownButton 
                className=""
                textColor="gray"
                color="gray"
                text={typeof option == "string" ? option : option.label || "Click to Select"}
                textClass="w-full"
                />
                
              </li>
            ))}
          </ul>
        )}
        </div>
      </div>
      {addInfo &&
                    <div className="w-full text-gray-400 text-xs font-medium font-['Satoshi Variable'] leading-[18px]">{addInfo}</div>
                }
                {errorMsg && 
                <div className="w-full h-[18px] text-red-500 text-xs font-medium font-['Satoshi Variable'] leading-[18px]">{errorMsg}</div>
    }
    </div>
  );
};

export default SingleSelectComp;
