import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

//This is home page url
// User logged in and company created successfylly - redirect ot dashboard
// user logged in but company not created then redirect to ('/create/selectcompany')
// User not logged in - redirect ot signin
const RedirectHome = () => {
  const navigator = useNavigate();
  useEffect(() => {
    console.log("navigator");

    if (localStorage.getItem("auth")) navigator("/job/dashboard");
    else navigator("/signin");
  }, []);

  return <div>...</div>;
};

export default RedirectHome;
