import moment from "moment/moment";

export const dateCustom = (date, format) => {
  //   date = new Date(date).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  const userLocale = navigator.language; // Auto-detect user's locale
  const inputDate = moment(date).local().toDate();
  if (isNaN(inputDate.getTime())) {
    return "Invalid";
  }

  const now = moment().startOf("day").local().toDate();
  const diffInDays = Math.floor((inputDate - now) / (1000 * 60 * 60 * 24));

  if (diffInDays === 0) {
    return "Today";
  } else if (diffInDays === 1) {
    return "Tomorrow";
  } else if (diffInDays === -1) {
    return "Yesterday";
  } else {
    return moment(inputDate).format(format);
  }
};
