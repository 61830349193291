import React from "react";

const GrayOutlineButton = ({ icon, text, className, ...props }) => {
  return (
    <div
      className={`ButtonLarge cursor-pointer h-11 p-3  bg-white rounded-xl border border-gray-900 border-opacity-10 justify-center items-center gap-2 inline-flex ${className}`}
      {...props}
    >
      {icon && (
        <div className="InputFieldIconsPluscircle ml-2 w-5 h-5 ">{icon}</div>
      )}
      <div className="ButtonText text-gray-900 text-opacity-60 text-sm font-medium leading-tight">
        {text}
      </div>
    </div>
  );
};

export default GrayOutlineButton;
