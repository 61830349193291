import React, { useState, useEffect } from "react";
import ApplicationHeading from "./heading";
import UserInfoLeft from "./userInfo";
import RightSideInfo from "./rightSideInfo";
import AddNotes from "./modals/addNotes";
import SendOffer from "./modals/sendOffer";
import ScheduleInterview from "./modals/scheduleInterview";
import SendAssignment from "./modals/sendAssignment";
import SendOfferPreNote from "./modals/sendOfferPreNote";
import { useNavigate, useParams } from "react-router-dom";
import { isMobileOnly, browserName } from "react-device-detect";
import { Mixpanel } from "../../../services/mixpanel";
import moment from "moment";
import axios from "axios";
import "../../../index.css"
import Loader from "../../../components/Loader/loader";


const Application = () => {
  const { jobId, applicationNo: appNo } = useParams();
  const navigate = useNavigate();

  const [applicationNo, setApplicationNo] = useState(appNo);

  const [showAddNotes, setshowAddNotes] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [notes, setNotes] = useState([]);
  const [assignment, setAssignement] = useState({
    message: "",
    file: null,
    deadline: "",
  });

  const [showSendOffer, setshowSendOffer] = useState(false);
  const [sentStatus, setSentStatus] = useState(false);
  console.log(sentStatus);


  const toggleSendOffer = () => {
    setshowSendOffer((prev) => !prev);
  };
  const toggleNotes = () => {
    setshowAddNotes((prev) => !prev);
  };

  const [showScheduleInterview, setshowScheduleInterview] = useState(false);

  const toggleScheduleInterview = () => {
    Mixpanel.track("schedule_interview_CTA_clicked", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      recruiter_id: localStorage.getItem("userId"),
      distinct_id: localStorage.getItem("userId"),
      job_id:jobId,
      application_number:userInfo._id
    });
    setshowScheduleInterview((prev) => !prev);
  };

  const [showSendAssignment, setShowSendAssignment] = useState(false);

  const toggleSendAssignment = () => {
    Mixpanel.track("send_assignment_CTA_clicked", {
      date: moment().format(),
      browser: browserName,
      mobile_device: isMobileOnly,
      recruiter_id: localStorage.getItem("userId"),
      distinct_id: localStorage.getItem("userId"),
      job_id:jobId,
      application_number:userInfo._id
    });
    setShowSendAssignment((prev) => !prev);
    setAssignement({
      message: "",
      file: null,
      deadline: "",
    });
  };

  const LoadApplication = async () => {
    let params = {};

    // if (name || searchJobText) params["name"] = searchJobText;
    // if(filters.startDate) params[]

    const url = `${process.env.REACT_APP_baseURL}/recruiter/v2/application_by_number/${jobId}/${applicationNo}`;
    console.log(url);
    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
        params,
      })
      .catch((error) => console.log(error));

    if (response?.data) {
      console.log(response?.data.application.application);
      setUserInfo(response?.data.application.application);
      setTotalCount(response?.data.application.count);
      // localStorage.setItem("auth", response?.data.authToken);
      // navigator("/job/dashboard");
    }
  };

  useEffect(LoadApplication, [applicationNo]);
  // useEffect(LoadApplication, []);

  const navigateToNeighbor = (count) => {
    console.log(count, "Count");
    setApplicationNo(count);
    // navigate();
  };

  useEffect(async () => {
    if (userInfo) {
      let params = {};

      // if (name || searchJobText) params["name"] = searchJobText;
      // if(filters.startDate) params[]

      const url = `${process.env.REACT_APP_baseURL}/recruiter/note/${userInfo._id}`;
      console.log(url);
      const response = await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
          params,
        })
        .catch((error) => console.log(error));

      if (response?.data) {
        console.log(response?.data, "Notes");
        setNotes(response?.data.notes);

        // setUserInfo(response?.data.application.application);
        // setTotalCount(response?.data.application.count);
        // localStorage.setItem("auth", response?.data.authToken);
        // navigator("/job/dashboard");
      }
    }
  }, [userInfo]);

  const setAssignmentData = (e) => {
    const { name, value } = e.target;

    console.log(e.target.files);


    setAssignement((prev) => {
      if (name == "file")
        return {
          ...prev,
          [name]: e.target.files,
        };
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const setDeadline = (date)=>{
    setAssignement((prev)=>({
      ...prev,
      deadline: date,
    }))
  }

  const sendAssignment = async () => {

    let formData = new FormData();

    formData.append("deadline", assignment.deadline);
    formData.append("assignment_title", assignment.message);
    formData.append("upload", assignment.file ? assignment.file[0] : null);
    formData.append("application_ids", userInfo._id);

    console.log(formData);

    const url = `${process.env.REACT_APP_baseURL}/recruiter/sendassignments`;
    await axios
      .post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .then(function (response) {
        Mixpanel.track("assignment_sent_successfull", {
          date: moment().format(),
          browser: browserName,
          mobile_device: isMobileOnly,
          recruiter_id: localStorage.getItem("userId"),
          distinct_id: localStorage.getItem("userId"),
          job_id:jobId,
          application_number:userInfo._id
        });
        
        toggleSendAssignment();
    setSentStatus(true);

    setTimeout(()=>{
setSentStatus(false);
    }, 5000);
        //handle success
        console.log(response);
        LoadApplication();
        
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };

  const cancelInterview = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/cancel_interview/${userInfo._id}`;
    // console.log(url, applicationId);
    const response = await axios
      .post(
        url,
        {},
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
          // params,
        }
      )
      .catch((error) => console.log(error));

    if (response?.data) {
      LoadApplication();
    }
  };

  const acceptInterview = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/schedule_interview`;
    // console.log(url, applicationId);
    const response = await axios
      .post(
        url,
        {
          applcation_id: userInfo._id,
          accept: true,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
          // params,
        }
      )
      .catch((error) => console.log(error));

    if (response?.data) {
      LoadApplication();
    }
  };

  const changeApplicationStatus = async (status) => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/change_application_status`;
    // console.log(url, applicationId);
    const response = await axios
      .post(
        url,
        {
          applicant_ids: [userInfo._id],
          status,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
          // params,
        }
      )
      .catch((error) => console.log(error));

    if (response?.data) {
      LoadApplication();
    }
  };

  const addNote = async (text) => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/note`;
    // console.log(url, applicationId);
    const response = await axios
      .post(
        url,
        {
          application_id: userInfo._id,
          note: text,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
          // params,
        }
      )
      .catch((error) => console.log(error));

    if (response?.data) {
      LoadApplication();
    }

    toggleNotes();
  };

  return (
    <div className="text-sm flex flex-col h-screen overflow-scroll no-scrollbar w-full ">

      {userInfo && (
        <>
          <ApplicationHeading
            scheduleInterview={toggleScheduleInterview}
            setApplicationNo={setApplicationNo}
            navigateToNeighbor={navigateToNeighbor}
            sendAssignment={toggleSendAssignment}
            userInfo={userInfo}
            changeApplicationStatus={changeApplicationStatus}
            totalCount={totalCount}
            sentStatus={sentStatus}
          />

          <div className="flex flex-col lg:flex-row h-screen overflow-scroll no-scrollbar">
            <div 
             className="w-full lg:w-5/12 flex "
             style={{ flex: "1 1 auto" }}
             >
            <UserInfoLeft
              className="overscroll-contain "
              addNotetoggle={toggleNotes}
              jobId={jobId}
              applicationNo={applicationNo}
              userInfo={userInfo}
              changeApplicationStatus={changeApplicationStatus}
            />
            </div>
            <div
              className="w-full lg:w-7/12 flex height-min mb-0"
              style={{ flex: "1 1 auto" }}
            >
              <RightSideInfo
                className=""
                userInfo={userInfo}
                cancelInterview={cancelInterview}
                acceptInterview={acceptInterview}
                setshowScheduleInterview={setshowScheduleInterview}
                notes={notes}
              />
            </div>
          </div>
          {showAddNotes && <AddNotes close={toggleNotes} addNote={addNote} />}
          {showSendOffer && <SendOffer close={toggleSendOffer} />}
          {showScheduleInterview && (
            <ScheduleInterview
              close={toggleScheduleInterview}
              LoadApplication={LoadApplication}
              applicationId={userInfo?._id}
            />
          )}

          {showSendAssignment && (
            <SendAssignment
              close={toggleSendAssignment}
              setAssignmentData={setAssignmentData}
              setDeadline={setDeadline}
              assignment={assignment}
              sendAssignment={sendAssignment}
              // sentStatus={sentStatus}
            />
          )}
        </>
      )}

      {/* <SendOfferPreNote /> */}
      {!userInfo && <div
       className=" m-auto"
       ><Loader /></div>}
    </div>
  );
};

export default Application;
