
import React, { useState } from "react";
import FillButtonV2 from "../../../components/buttons/UI_Buttons_2/fillButton";
import ButtonChild from "../../../components/buttons/UI_Buttons_2/buttonChild";
import BorderButton from "../../../components/buttons/UI_Buttons_2/borderButton";

const OnSaveChangeToggle = ({ close, onSave}) => {
    // const [showLogOutBox, setShowLogOutBox] = useState(false);

    // const close = () => {
    //     setShowLogOutBox((prev) => !prev);
    //   };
  return (
    <div
      class="relative z-11 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0  bg-white bg-opacity-80 backdrop-blur-[4px] blur-md transition-opacity"></div>
    
      <div class="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative m-auto w-full transform overflow-scroll no-scrollbar rounded-lg bg-white text-left shadow-xl transition-all px-8 py-8 sm:my-8 sm:w-full sm:max-w-lg w-4/12">
            <div className="flex w-screen justify-between items-center sm:w-full">
            <div className="w-max text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px]">You have unsaved changes!</div>
              
              <div onClick={close}>
              <ButtonChild 
              className="px-1"
              
              textColor="gray"
              leftIcon={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z" 
              fill="currentColor"/>
            </svg>}
              />
              </div>
            </div>

            <div className="form w-screen sx:w-full">
            {/* <div className="w-max text-teal-950 text-sm font-medium font-['Satoshi Variable'] leading-tight ">Are you sure you want to sign out?</div> */}
          
              <div className="flex flex-row justify-start gap-spaceBase ml-auto mt-spaceXxLarge">
              <div>

<BorderButton 
onClick={close}
text="Discard Changes"
textColor="gray"
color="gray"
leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z" 
fill="currentColor"/>
</svg>}
/>
</div>
                <div>
              <FillButtonV2 
              onClick={onSave}
              text="Save Changes"
              color="green"
              textColor="white"
              leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M11.2505 16.875C11.2505 17.0408 11.3163 17.1997 11.4335 17.3169C11.5508 17.4342 11.7097 17.5 11.8755 17.5H16.2505C16.582 17.5 16.9 17.3683 17.1344 17.1339C17.3688 16.8995 17.5005 16.5815 17.5005 16.25V3.75C17.5005 3.41848 17.3688 3.10054 17.1344 2.86612C16.9 2.6317 16.582 2.5 16.2505 2.5H11.8755C11.7097 2.5 11.5508 2.56585 11.4335 2.68306C11.3163 2.80027 11.2505 2.95924 11.2505 3.125C11.2505 3.29076 11.3163 3.44973 11.4335 3.56694C11.5508 3.68415 11.7097 3.75 11.8755 3.75H16.2505V16.25H11.8755C11.7097 16.25 11.5508 16.3158 11.4335 16.4331C11.3163 16.5503 11.2505 16.7092 11.2505 16.875ZM2.6833 9.55781L5.8083 6.43281C5.89571 6.34531 6.00712 6.2857 6.12842 6.26154C6.24972 6.23739 6.37547 6.24977 6.48973 6.29711C6.604 6.34446 6.70164 6.42464 6.77031 6.52751C6.83898 6.63038 6.87559 6.75132 6.87549 6.875V9.375H11.8755C12.0412 9.375 12.2002 9.44085 12.3174 9.55806C12.4346 9.67527 12.5005 9.83424 12.5005 10C12.5005 10.1658 12.4346 10.3247 12.3174 10.4419C12.2002 10.5592 12.0412 10.625 11.8755 10.625H6.87549V13.125C6.87559 13.2487 6.83898 13.3696 6.77031 13.4725C6.70164 13.5754 6.604 13.6555 6.48973 13.7029C6.37547 13.7502 6.24972 13.7626 6.12842 13.7385C6.00712 13.7143 5.89571 13.6547 5.8083 13.5672L2.6833 10.4422C2.62519 10.3841 2.57909 10.3152 2.54764 10.2393C2.51618 10.1635 2.5 10.0821 2.5 10C2.5 9.91787 2.51618 9.83654 2.54764 9.76066C2.57909 9.68479 2.62519 9.61586 2.6833 9.55781Z" 
              fill="currentColor"/>
            </svg>}
              />
              </div>
              

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnSaveChangeToggle;
