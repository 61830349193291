import react, { useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import { app, deRegisterToken, messaging } from "../../services/firebase";
import axios from "axios";
import moment from "moment";
import { dateCustom } from "../../services/date";
import InfiniteScroll from "react-infinite-scroll-component";
import MenuIcon from "../menu/menuIcon";
import "../../index.css"
import { Mixpanel } from "../../services/mixpanel";
import ButtonChild from "../buttons/UI_Buttons_2/buttonChild";
import { isMobile, browserName } from "react-device-detect";


const PopUpMenuList = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    const navigation = useNavigate();
    const [notificationLoaded, setNotificationLoaded] = useState(true);
    const [notifications, setNoifications] = useState([]);
    const [unreadcount, setUnreadCount] = useState(0);
    const [hasNextNotification, setHasNextNotification] = useState(false);
   
  
    const logout = () => {
      localStorage.removeItem("auth");
      localStorage.removeItem("chatAuth");
      localStorage.removeItem("userId");
      navigation("/signin");
  
      deRegisterToken();
    };
  
    const [isPremium, setIsPremium] = useState(false);


    const premiumPlan = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_baseURL}/recruiter/premium/details`,
          {
            headers: {
              Authorization:"Bearer " + localStorage.getItem("auth"),
            },
          }
        );
        console.log(response.data)
        localStorage.setItem('isPremium',response.data.isPremium)
       setIsPremium(response.data.isPremium)
       console.log(response.data.isPremium)
        return response.data;
      } catch (error) {
        console.log(error);
        throw error;
      }
    };
    const onPrice = () => {
   
      Mixpanel.track("pricing_clicked", {
       date: moment().format(),
       distinct_id: localStorage.getItem("userId"),
       recruiter_id: localStorage.getItem("userId"),
       browser: browserName,
       mobile_device: isMobile,
     });
    // setIsPriceOpen(true);
    navigation("/pricing");
  };

    useEffect(()=>{
      premiumPlan();
    }, [])

    const loadNotifications = async () => {
      setNotificationLoaded(false);
  
      const url = `${process.env.REACT_APP_baseURL}/recruiter/notifications`;
      console.log(url);
      const response = await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
          params: {
            skip: notifications.length,
            limit: 5,
          },
        })
        .catch((error) => console.log(error));
  
      if (response?.data) {
        console.log(response?.data, "Notes");
        // setNotes(response?.data.notes);
        setNoifications((prev) => {
          return [...prev, ...response?.data.notifications];
        });
        setHasNextNotification(response?.data.next);
        setUnreadCount(0);
      }
      setNotificationLoaded(true);
    };
  
    const loadNotificationsCount = async () => {
      const url = `${process.env.REACT_APP_baseURL}/recruiter/get_unread_count`;
      console.log(url);
      const response = await axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        })
        .catch((error) => console.log(error));
  
      if (response?.data) {
        // console.log(response?.data, "Notes");
        setUnreadCount(response?.data.unread);
      }
    };
  
    useEffect(loadNotificationsCount, []);

    return (
        <div className="md:invisible ">

            <div
                className="space-y-2 cursor-pointer"
                onClick={() => setIsNavOpen((prev) => !prev)}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 20 20" fill="none"
                className="md:hidden"
                >
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.125 10C2.125 9.44772 2.57272 9 3.125 9H16.875C17.4273 9 17.875 9.44772 17.875 10C17.875 10.5523 17.4273 11 16.875 11H3.125C2.57272 11 2.125 10.5523 2.125 10Z" fill="#002032" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.125 5C2.125 4.44772 2.57272 4 3.125 4H16.875C17.4273 4 17.875 4.44772 17.875 5C17.875 5.55228 17.4273 6 16.875 6H3.125C2.57272 6 2.125 5.55228 2.125 5Z" fill="#002032" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.125 15C2.125 14.4477 2.57272 14 3.125 14H16.875C17.4273 14 17.875 14.4477 17.875 15C17.875 15.5523 17.4273 16 16.875 16H3.125C2.57272 16 2.125 15.5523 2.125 15Z" fill="#002032" />
                </svg>
            </div>

            <div className={`${isNavOpen  ? "showMenuNav" : "hideMenuNav"}`} >
           
              {/* <ButtonChild 
              className="px-4 flex justify-end"
              
              textColor="gray"
              leftIcon={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z" 
              fill="currentColor"/>
            </svg>}
              /> */}
             
                <div
                    className="absolute top-0 right-0 py-2 px-2 cursor-pointer"
                    onClick={() => setIsNavOpen((prev) => !prev)}
                >
                    <ButtonChild 
              className="px-1 flex justify-end"
              
              textColor="gray"
              leftIcon={
                <div >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z" 
              fill="currentColor"/>
            </svg>
            </div>
            }
              /> 
                </div>
                <ul className="px-8 flex flex-col items-center justify-between min-h-[250px]" >
                <div className={`mt-16 justify-end items-start gap-6 flex flex-col w-full block flex-grow md:flex md:w-auto ${isOpen ? "block" : ""}`}>

                <div  className="relative"
          // className="p-2 bg-white  rounded-xl justify-center items-center gap-2 flex"
        >
          <div className="w-[61px] absolute ml-4 mb-1 h-3.5 px-1 py-0.5 bg-gradient-to-br from-purple-600 via-purple-400 to-purple-600 rounded-[5px] justify-center items-center gap-2.5 inline-flex">
    <div className="text-center text-white text-[8px] font-black font-['Satoshi'] leading-[10px]">Coming Soon</div>
</div>
          <ButtonChild
          
            className="mt-2"
            text="Events"
            textColor={"gray"}
            leftIcon={
              <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 20 20" fill="none">
 
</svg>
            }
          />
        </div>
        <div onClick={()=>{
            Mixpanel.track("about_us_clicked", {
             date: moment().format(),
             distinct_id: localStorage.getItem("userId"),
             recruiter_id: localStorage.getItem("userId"),
             browser: browserName,
             mobile_device: isMobile,
           });
           navigation("/about-us")
         }
         }
           >
         
            <ButtonChild
              className="mt-1"
              text="About Us"
              textColor={"gray"}
              leftIcon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                ></svg>
              }
            />
          </div>
          
        <div
          className={`${isPremium === true ? "hidden" : "flex"}`}
          onClick={onPrice}

          // className="p-2 bg-white  rounded-xl justify-center items-center gap-2 flex"
        >
          <ButtonChild
            className="mt-1 ml-2"
            text="Pricing"
            textColor={"gray"}
            leftIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M13.125 10C13.125 10.6181 12.9417 11.2223 12.5983 11.7362C12.255 12.2501 11.7669 12.6506 11.1959 12.8871C10.6249 13.1236 9.99653 13.1855 9.39034 13.065C8.78415 12.9444 8.22733 12.6467 7.79029 12.2097C7.35325 11.7727 7.05562 11.2158 6.93505 10.6097C6.81447 10.0035 6.87635 9.37513 7.11288 8.80411C7.3494 8.2331 7.74994 7.74504 8.26384 7.40166C8.77775 7.05828 9.38193 6.875 10 6.875C10.8288 6.875 11.6237 7.20424 12.2097 7.79029C12.7958 8.37634 13.125 9.1712 13.125 10ZM19.375 5V15C19.375 15.1658 19.3092 15.3247 19.1919 15.4419C19.0747 15.5592 18.9158 15.625 18.75 15.625H1.25C1.08424 15.625 0.925268 15.5592 0.808058 15.4419C0.690848 15.3247 0.625 15.1658 0.625 15V5C0.625 4.83424 0.690848 4.67527 0.808058 4.55806C0.925268 4.44085 1.08424 4.375 1.25 4.375H18.75C18.9158 4.375 19.0747 4.44085 19.1919 4.55806C19.3092 4.67527 19.375 4.83424 19.375 5ZM18.125 8.62109C17.4153 8.41125 16.7694 8.02719 16.2461 7.50389C15.7228 6.98059 15.3387 6.33469 15.1289 5.625H4.87109C4.66125 6.33469 4.27719 6.98059 3.75389 7.50389C3.23059 8.02719 2.58469 8.41125 1.875 8.62109V11.3789C2.58469 11.5887 3.23059 11.9728 3.75389 12.4961C4.27719 13.0194 4.66125 13.6653 4.87109 14.375H15.1289C15.3387 13.6653 15.7228 13.0194 16.2461 12.4961C16.7694 11.9728 17.4153 11.5887 18.125 11.3789V8.62109Z"
                  fill="currentColor"
                />
                <path
                  d="M2.88231 1.15705C2.99362 0.934541 3.25158 0.827719 3.4876 0.906393L7.69167 2.30775L12.3063 3.84621H1.53711L2.88231 1.15705Z"
                  fill="currentColor"
                />
              </svg>
            }
          />
        </div>
      
        <button
          onClick={() => {
            Mixpanel.track("Notifications Click", {
              date: moment().format(),
              distinct_id: localStorage.getItem("userId"),
              recruiter_id: localStorage.getItem("userId"),
              browser: browserName,
              mobile_device: isMobile,
            });
            if (notifications.length == 0) loadNotifications();
          }}
          className="p-2 bg-white"
        >
          <div className="dropdown justify-center items-center gap-1 flex">
            <div className="w-5 h-5 relative">
              {unreadcount > 0 && (
                <div className="w-3.5 h-3.5 p-0.5 left-3 bottom-3 bg-amber-300 rounded-lg flex-col justify-center items-center inline-flex z-50 absolute">
                  <div className="text-center text-white text-[10px] font-bold leading-3 ">
                    {unreadcount}
                  </div>
                </div>
              )}
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                className="text-gray-900"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Input Field Icons / Bell">
                  <path
                    id="Vector"
                    d="M17.3272 13.7453C16.8936 12.9984 16.2491 10.8852 16.2491 8.125C16.2491 6.4674 15.5906 4.87769 14.4185 3.70558C13.2464 2.53348 11.6567 1.875 9.99909 1.875C8.34149 1.875 6.75178 2.53348 5.57968 3.70558C4.40757 4.87769 3.74909 6.4674 3.74909 8.125C3.74909 10.8859 3.10378 12.9984 2.67019 13.7453C2.55946 13.9352 2.50076 14.1509 2.50001 14.3707C2.49925 14.5905 2.55647 14.8066 2.66589 14.9973C2.77531 15.1879 2.93306 15.3463 3.12324 15.4565C3.31342 15.5667 3.52929 15.6248 3.74909 15.625H6.93738C7.08157 16.3306 7.46505 16.9647 8.02295 17.4201C8.58085 17.8756 9.27892 18.1243 9.99909 18.1243C10.7193 18.1243 11.4173 17.8756 11.9752 17.4201C12.5331 16.9647 12.9166 16.3306 13.0608 15.625H16.2491C16.4688 15.6247 16.6846 15.5665 16.8747 15.4562C17.0647 15.346 17.2224 15.1875 17.3317 14.9969C17.441 14.8063 17.4982 14.5903 17.4974 14.3705C17.4966 14.1508 17.4379 13.9351 17.3272 13.7453ZM9.99909 16.875C9.61145 16.8749 9.23338 16.7546 8.91691 16.5308C8.60043 16.3069 8.36112 15.9905 8.23191 15.625H11.7663C11.6371 15.9905 11.3978 16.3069 11.0813 16.5308C10.7648 16.7546 10.3867 16.8749 9.99909 16.875Z"
                    fill="#002032"
                    fill-opacity="0.6"
                  />
                </g>
              </svg>
            </div>
            <label
              tabIndex={0}
              className=" text-gray-900 text-opacity-70 text-[14px] font-bold leading-tight cursor-pointer"
            >
              Notifications
            </label>

            <ul
              tabIndex={0}
              id="scrollableDiv23"
              className="dropdown-content w-screen max-w-auto max-h-[70vh] text-left left-0 mt-[-3rem] overflow-auto bg-white p-4 text-gray-900 text-[14px] font-bold leading-tight w-fit shadow  rounded-xl top-12 opacity-100"
            style={{"z-index":"1"}}
            >
              <InfiniteScroll
                hasMore={hasNextNotification}
                loader={
                  <div>
                    <div className="mx-auto ">
                      <div class=" right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
                        <div class="border-t-transparent border-solid animate-spin  rounded-full border-emerald-500 border-8 h-3 w-3"></div>
                      </div>
                    </div>
                  </div>
                }
                dataLength={notifications.length}
                next={loadNotifications}
                scrollableTarget="scrollableDiv23"
              >
                {notifications?.length > 0 &&
                  notifications.map((notificationObj, index) => (
                    <li className="w-fit h-full " key={index}>
                      <Link
                        to={`/job/application/${notificationObj.metadata.job_id}/${notificationObj.metadata.application_number}`}
                        className="my-2 font-semibold flex right-5 top-5"
                      >
                        <div
                          className={`Contact w-[400px] h-auto p-4 ${
                            index != notifications.length - 1 && "border-b-2"
                          } border-gray-900 border-opacity-10 justify-start items-center gap-4 inline-flex`}
                        >
                          <div className="Pfp w-12 h-12 relative">
                            <img
                              className="2 w-10 h-10 left-[4px] top-[8px] absolute opacity-40"
                              src="/22 1.png"
                            />
                            <img
                              className="1 w-10 h-10 left-[4px] top-[4px] absolute"
                              src="/22 1.png"
                            />
                          </div>
                          <div className="AllTxt grow shrink basis-0 flex-col justify-start items-start inline-flex">
                            <div className="ApplicantsHaveSubmittedTheirAssignments self-stretch text-gray-900 text-sm font-medium leading-tight">
                              {notificationObj.title}
                            </div>
                            <div className="ProductDesignIntern self-stretch text-gray-900 text-xs font-medium leading-[18px] text-opacity-60">
                              {notificationObj.body}
                            </div>
                          </div>
                          <div className="May10 text-right text-gray-900 text-xs font-medium leading-[18px]">
                            {dateCustom(notificationObj.timestamp, "DD MMM")}
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
              </InfiniteScroll>
              {!notificationLoaded && (
                <div>
                  <div className="mx-auto ">
                    <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
                      <div class="border-t-transparent border-solid animate-spin  rounded-full border-emerald-500 border-8 h-3 w-3"></div>
                    </div>
                  </div>
                </div>
              )}

              {notificationLoaded && notifications?.length == 0 && (
                <li className="w-fit h-max">
                  <div className="font-semibold text-sm opacity-60">
                    {" "}
                    No Notifications
                  </div>
                </li>
              )}
            </ul>
          </div>
        </button>

        <div 
          onClick={()=>{
            Mixpanel.track("messages_clicked", {
              date: moment().format(),
              distinct_id: localStorage.getItem("userId"),
              recruiter_id:localStorage.getItem("userId"),
              browser: browserName,
              mobile_device: isMobile,
            });
            navigation("/chat1")
          }}
            // to="/chat1"
            className="p-2 bg-white  rounded-xl cursor-pointer justify-center items-center gap-2 flex"
          >
          <div className=" w-5 h-5 ">
            {/* <div className="w-3.5 h-3.5 p-0.5 left-3 bottom-3 bg-amber-300 rounded-lg flex-col justify-center items-center inline-flex z-50 absolute">
              <div className="text-center text-white text-[10px] font-bold leading-3 ">
                2
              </div>
            </div> */}
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className=""
            >
              <g id="Input Field Icons / ChatTeardropText">
                <path
                  id="Vector"
                  d="M10.3125 1.875C8.24119 1.87727 6.25538 2.70111 4.79074 4.16574C3.32611 5.63038 2.50227 7.61619 2.5 9.6875V16.2758C2.50041 16.6003 2.62953 16.9115 2.85902 17.141C3.08852 17.3705 3.39966 17.4996 3.72422 17.5H10.3125C12.3845 17.5 14.3716 16.6769 15.8368 15.2118C17.3019 13.7466 18.125 11.7595 18.125 9.6875C18.125 7.6155 17.3019 5.62836 15.8368 4.16323C14.3716 2.6981 12.3845 1.875 10.3125 1.875ZM12.5 11.875H7.5C7.33424 11.875 7.17527 11.8092 7.05806 11.6919C6.94085 11.5747 6.875 11.4158 6.875 11.25C6.875 11.0842 6.94085 10.9253 7.05806 10.8081C7.17527 10.6908 7.33424 10.625 7.5 10.625H12.5C12.6658 10.625 12.8247 10.6908 12.9419 10.8081C13.0592 10.9253 13.125 11.0842 13.125 11.25C13.125 11.4158 13.0592 11.5747 12.9419 11.6919C12.8247 11.8092 12.6658 11.875 12.5 11.875ZM12.5 9.375H7.5C7.33424 9.375 7.17527 9.30915 7.05806 9.19194C6.94085 9.07473 6.875 8.91576 6.875 8.75C6.875 8.58424 6.94085 8.42527 7.05806 8.30806C7.17527 8.19085 7.33424 8.125 7.5 8.125H12.5C12.6658 8.125 12.8247 8.19085 12.9419 8.30806C13.0592 8.42527 13.125 8.58424 13.125 8.75C13.125 8.91576 13.0592 9.07473 12.9419 9.19194C12.8247 9.30915 12.6658 9.375 12.5 9.375Z"
                  fill="#002032"
                  fill-opacity="0.6"
                />
              </g>
            </svg>
          </div>

          <div className="text-gray-900 text-opacity-70 text-[14px] font-bold leading-tight">
            My Messages
          </div>
        </div>

        <div className="inline-flex  p-2 gap-1 justify-start items-center" 
            >
              <div  onClick={() => {
              //  localStorage.setItem("active", "true")
              Mixpanel.track("Account Clicked", {
                date: moment().format(),
                distinct_id: localStorage.getItem("userId"),
                recruiter_id: localStorage.getItem("userId"),
                browser: browserName,
                mobile_device: isMobile,
              });
  
              navigation("/profile");
            }}>
            <ButtonChild
              textColor="gray"
              text="Account"
              leftIcon={
                <div className=" w-5 h-5">
                  {/* {companyData.logo ? (
                    <img src={companyData.logo} />
                  ) : ( */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M13.4375 9.375C13.4375 10.0549 13.2359 10.7195 12.8582 11.2848C12.4805 11.8501 11.9436 12.2907 11.3155 12.5508C10.6874 12.811 9.99619 12.8791 9.32938 12.7464C8.66257 12.6138 8.05007 12.2864 7.56932 11.8057C7.08858 11.3249 6.76119 10.7124 6.62855 10.0456C6.49592 9.37881 6.56399 8.68765 6.82417 8.05953C7.08434 7.4314 7.52494 6.89454 8.09023 6.51682C8.65552 6.13911 9.32013 5.9375 10 5.9375C10.9114 5.93853 11.7851 6.30103 12.4295 6.94546C13.074 7.58989 13.4365 8.46364 13.4375 9.375ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.594 16.8916 13.1093 17.5065C11.6247 18.1215 9.99099 18.2824 8.4149 17.9689C6.8388 17.6554 5.39106 16.8815 4.25476 15.7452C3.11846 14.6089 2.34463 13.1612 2.03112 11.5851C1.71762 10.009 1.87852 8.37535 2.49348 6.8907C3.10844 5.40605 4.14985 4.1371 5.486 3.24431C6.82214 2.35152 8.39303 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.874 9.07464 16.6864 8.15898 16.3234 7.30778C15.9604 6.45658 15.4295 5.68734 14.7624 5.04604C14.0952 4.40475 13.3056 3.90457 12.4408 3.57544C11.5759 3.2463 10.6536 3.09496 9.72891 3.13047C6.04922 3.27266 3.11485 6.3375 3.125 10.0195C3.12853 11.6957 3.74674 13.3124 4.8625 14.5633C5.3169 13.9042 5.8941 13.339 6.5625 12.8984C6.61949 12.8608 6.68724 12.8429 6.75539 12.8474C6.82353 12.8519 6.88832 12.8786 6.93985 12.9234C7.78921 13.6581 8.87466 14.0624 9.99766 14.0624C11.1207 14.0624 12.2061 13.6581 13.0555 12.9234C13.107 12.8786 13.1718 12.8519 13.2399 12.8474C13.3081 12.8429 13.3758 12.8608 13.4328 12.8984C14.1021 13.3387 14.6801 13.904 15.1352 14.5633C16.2564 13.3078 16.8758 11.6833 16.875 10Z"
                        fill="currentColor"
                        fill-opacity="0.6"
                      />
                    </svg>
                  {/* )} */}
                </div>
              }
             
            />
            </div>
          <div className="mt-[-7px]" onClick={()=>{ navigation("/transaction")}}>
              {isPremium == true ? 

             ( <div className="w-[38px] h-5 px-2 py-[5px] bg-gradient-to-br from-amber-300 via-yellow-300 to-amber-300 rounded-2xl justify-center items-center inline-flex cursor-pointer">
    <div className="grow shrink basis-0 text-center text-teal-950 text-[10px] font-black font-['Satoshi Variable'] leading-[10px]">PRO</div>
</div>)
:
( <div className="w-[38px] h-5 px-2 py-[5px] bg-gray-200 rounded-2xl justify-center items-center inline-flex cursor-pointer">
<div className="grow shrink basis-0 text-center text-teal-950 text-[10px] font-black font-['Satoshi Variable'] leading-[10px]">FREE</div>
</div>)
}
</div>
</div>

        {/* <div  className="p-2" onClick={() => {
            
           
              Mixpanel.track("Profile Click", {
                date: moment().format(),
                user_id: localStorage.getItem("userId"),
                browser: browserName,
                mobile_device: isMobile,
              });
             
              navigation("/profile");
              
            }}>
          
            <ButtonChild 
            textColor="gray"
            text="Account"
            leftIcon={
              <div className=" w-5 h-5">
              
              <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M13.4375 9.375C13.4375 10.0549 13.2359 10.7195 12.8582 11.2848C12.4805 11.8501 11.9436 12.2907 11.3155 12.5508C10.6874 12.811 9.99619 12.8791 9.32938 12.7464C8.66257 12.6138 8.05007 12.2864 7.56932 11.8057C7.08858 11.3249 6.76119 10.7124 6.62855 10.0456C6.49592 9.37881 6.56399 8.68765 6.82417 8.05953C7.08434 7.4314 7.52494 6.89454 8.09023 6.51682C8.65552 6.13911 9.32013 5.9375 10 5.9375C10.9114 5.93853 11.7851 6.30103 12.4295 6.94546C13.074 7.58989 13.4365 8.46364 13.4375 9.375ZM18.125 10C18.125 11.607 17.6485 13.1779 16.7557 14.514C15.8629 15.8502 14.594 16.8916 13.1093 17.5065C11.6247 18.1215 9.99099 18.2824 8.4149 17.9689C6.8388 17.6554 5.39106 16.8815 4.25476 15.7452C3.11846 14.6089 2.34463 13.1612 2.03112 11.5851C1.71762 10.009 1.87852 8.37535 2.49348 6.8907C3.10844 5.40605 4.14985 4.1371 5.486 3.24431C6.82214 2.35152 8.39303 1.875 10 1.875C12.1542 1.87727 14.2195 2.73403 15.7427 4.25727C17.266 5.78051 18.1227 7.84581 18.125 10ZM16.875 10C16.874 9.07464 16.6864 8.15898 16.3234 7.30778C15.9604 6.45658 15.4295 5.68734 14.7624 5.04604C14.0952 4.40475 13.3056 3.90457 12.4408 3.57544C11.5759 3.2463 10.6536 3.09496 9.72891 3.13047C6.04922 3.27266 3.11485 6.3375 3.125 10.0195C3.12853 11.6957 3.74674 13.3124 4.8625 14.5633C5.3169 13.9042 5.8941 13.339 6.5625 12.8984C6.61949 12.8608 6.68724 12.8429 6.75539 12.8474C6.82353 12.8519 6.88832 12.8786 6.93985 12.9234C7.78921 13.6581 8.87466 14.0624 9.99766 14.0624C11.1207 14.0624 12.2061 13.6581 13.0555 12.9234C13.107 12.8786 13.1718 12.8519 13.2399 12.8474C13.3081 12.8429 13.3758 12.8608 13.4328 12.8984C14.1021 13.3387 14.6801 13.904 15.1352 14.5633C16.2564 13.3078 16.8758 11.6833 16.875 10Z"
                fill="currentColor"
                fill-opacity="0.6"
              />
            </svg>
          
              </div>
             }
            />
     


          
          </div> */}

       
        </div>
                </ul>
            </div>


        


        </div>
    );
}
export default PopUpMenuList