import axios from "axios";
import React, { useEffect, useState } from "react";
import Alert from "../../../components/alert/alert";
import BeepNavbar from "../../../components/navbar/navbar";
import ProfileHeading from "./heading";
import { Link } from "react-router-dom";
import ProfileLogoChange from "../../../components/changeLogo/changeProfile";
import ButtonChild from "../../../components/buttons/UI_Buttons_2/buttonChild";
import BorderButton from "../../../components/buttons/UI_Buttons_2/borderButton";
import FillButtonV2 from "../../../components/buttons/UI_Buttons_2/fillButton";
import ProfileLogo from "../../../components/changeLogo/profileLogo";
import InputFieldComp from "../../../components/inputfields/UI_Inputs/InputFieldComp";
import TextInput from "../../../components/inputfields/UI_Inputs/textInput";
import InviteUser from "../modals/inviteUser";
import GrantAdminAccess from "../modals/grantAdminAccess";
import autoTable from "jspdf-autotable";
import OnSaveChangeToggle from "../modals/onSaveChangesToggle";
import RevokeAdminAccess from "../modals/revokeAdminAccess";
import RemoveRecruiter from "../modals/removeRecruiter";
import Loader from "../../../components/Loader/loader";

const MyCompany = ({ setActiveSection,
  showDiscard,
  isChanged,
  setShowDiscard,
  setIsChanged,}) => {
  let domainExtraction = /^(?:https?:\/\/)?(?:www\.)?([^.]+)\./;
  const [showChangesSaved, setShowChangesSaved] = useState(false);

  const authToken=localStorage.getItem("auth")
  const [emails, setEmails] = useState([]);
  const [userData, setUserData]=useState({
    name:''
  })

  const [panNumber, setPanNumber] = useState("");
  const [recruiterInfo, setRecruiterInfo] = useState("");
  const [recruiterId, setRecruiterId] = useState("");
  const [changed, setChanged] = useState(false);




  const [gstinNumber, setGSTINNumber] = useState("");
  const [alertData, setAlertData] = useState(null);

  const [websiteUrls, setWebsiteuRLS] = useState([]);
  const [companyData, setCompanyData] = useState({
    logo: "",
    name: "",
    registeredName: "",
    type: "PAN",
    verificationID: "ABCCD8000T",
    webUrls: [],
  });
  // const []

  const [invitedRecruit, setInvitedRecruit] = useState(false);

  // Profile logo
  const [error, setError] = useState('');

  const onInputChange = (e) => {
    const { name, value } = e.target;

    setCompanyData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    setIsChanged(true);
    setChanged(true);
  };

  const onLogoChange = async (file) => {

    setCompanyData((prev) => {

      return {
        ...prev,
        file,
      };
    });
    setIsChanged(true);
    setChanged(true);
    // await test();
  };


  // const onUrlValueChanage = (index, value) => {

  //   const urlRegex = /[A-Za-z0-9]+\.[A-Za-z0-9]+/;
    
  //     // Valid URL or empty value
  //     setWebsiteuRLS((prev) => {
  //       let temp = [...prev];
  //       temp[index] = value;
  
  //       return temp;
  //     });

  //     if (urlRegex.test(value) || value === '') {
  //     setError(''); // Clear any previous error
  //   } else {
  //     // Invalid URL
  //     setError('Please enter a valid URL.');
  //   }
    
  // };
  console.log(isChanged);

  const onUrlValueChanage = (index, value) => {
    const urlRegex = /[A-Za-z0-9]+\.[A-Za-z0-9]+/;
  
    setWebsiteuRLS((prev) => {
      let temp = [...prev];
      temp[index] = value;
      return temp;
    });

    setIsChanged(true);
    setChanged(true);

    if (urlRegex.test(value) || value === '') {
      // Clear any previous error for this index
      setError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = '';
        return updatedErrors;
      });
    } else {
      // Set the error for this index
      setError((prevErrors) => {
        const updatedErrors = [...prevErrors];
        updatedErrors[index] = 'Please enter a valid URL.';
        return updatedErrors;
      });
    }
    
  };
  

  // const onUrlValueChanage = (index, value) => {
  //   setWebsiteuRLS((prev) => {
  //     let temp = [...prev];
  //     temp[index] = value;

  //     return temp;
  //   });
  // };

  const addUrl = () => {
    setWebsiteuRLS((prev) => {
      let temp = [...prev, ""];
      return temp;
    });
   setIsChanged(true);
    setChanged(true);
  };

  const removeUrl = (index) => {
    
    setWebsiteuRLS((prev) => {
      let temp = [...prev];
      temp = temp.filter((url, i) => i != index);
      return temp;
    });
    setIsChanged(true);
    setChanged(true);
  };

  useEffect(() => {
    // Get user data from the location state
    const authToken=localStorage.getItem("auth")
    // const authToken = location.state?.authToken;
  console.log(authToken)
      if (authToken) {
        // If user data is not available in the location state, you can make an API request here
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
          },
        };
        axios
          .get(`${process.env.REACT_APP_baseURL}/recruiter/verify`, config)
          .then((response) => {
            console.log(response)
            setUserData(response?.data);           
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            
          });
      }  
  }, [location.state]);


  const loadRecruiter = async ()=>{
   // Get user data from the location state
   const authToken = localStorage.getItem("auth") || location.state?.authToken;
   // const authToken = location.state?.authToken;
   console.log(authToken)
   if (authToken) {
     // If user data is not available in the location state, you can make an API request here
     const config = {
       headers: {
         Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
       },
     };
     axios
       .get(`${process.env.REACT_APP_baseURL}/company/allrecruiters`, config)
       .then((response) => {

         console.log(response.data.data)
         
         // setCompanyData(response.data.invitation.companyData)
         // if (Array.isArray(response)) {
           const recruiterInfo = response.data?.data.map(item => ({
             id:item._id,
             name: item.name,
             profile:item.profile,
             candidatesHired: item.candidatesHired,
             jobsCreated:item.jobsCreated,
             role:item.role,
           }));
           
       
           
           {Array.isArray(recruiterInfo) && recruiterInfo.map(recruiter => (
             console.log(recruiter.name)
           ))}
           
           setRecruiterInfo(recruiterInfo);
           console.log(recruiterInfo)
         // }
       })
       .catch((error) => {
         console.error('Error fetching user data:', error);

       });
   }
  }
  useEffect(() => {
    loadRecruiter();

  }, []);


  console.log(recruiterInfo, "hello users")

  const invitedRecruiter = async ()=>{
    // Get user data from the location state
    const authToken=localStorage.getItem("auth")
    // const authToken = location.state?.authToken;
  console.log(authToken)
      if (authToken) {
        // If user data is not available in the location state, you can make an API request here
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
          },
        };
        axios
          .get(`${process.env.REACT_APP_baseURL}/recruiter/invited/recruiters`, config)
          .then((response) => {
            console.log(response.data)
            setInvitedRecruit(response.data)

            if (Array.isArray(response.data.data)) {
              const emails = response.data.data.map(item => item.email);
              setEmails(emails);
              console.log('Emails:', emails);
              setInvitedRecruit(response.data.data);
            } else {
              console.error('Invalid data structure in the response.');
            }

            // setInvitedRecruit(response?.data.userdata);           
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            
          });
      }  
  }
  useEffect(() => {
    
    invitedRecruiter();
  }, [location.state]);

  console.log(invitedRecruit)
console.log(authToken)


  const loadCompany = async () => {
    const url = `${process.env.REACT_APP_baseURL}/company/mycompany`;

    console.log(url);

    const response = await axios
      .get(url, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("auth"),
        },
      })
      .catch((error) => {
        console.log(error.response?.data);
        if (error.response && error.response?.data.message)
          setAlertData(error.response?.data.message);
      });

      

    if (response?.data) {
      console.log(response?.data);
      setCompanyData(response?.data);
      if (response?.data.webUrls)
        setWebsiteuRLS(response?.data.webUrls);
    }
  };

  const updateCompany = async () => {
    const url = `${process.env.REACT_APP_baseURL}/company`;
    console.log(url);
    let formData = new FormData();
    formData.append("registeredName", companyData.registeredName);
    formData.append("name", companyData.name);
    formData.append("type", companyData.type);
    formData.append("_id", companyData._id);
    formData.append("createdBy", companyData.createdBy);
    formData.append("createdAt", companyData.createdAt);
    formData.append("webUrls", websiteUrls.join(","));
    if (companyData.file)
      formData.append("upload", companyData.file[0]);
    const response = await axios
      .patch(
        url,
        formData,
        // { ...companyData, webUrls: websiteUrls },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      )
      .catch((error) => console.log(error));

    delete companyData.file;

    if (response?.data) {
      console.log(response?.data);
      setCompanyData(response?.data);
      setWebsiteuRLS(response?.data.webUrls);
      setShowChangesSaved(true);
      setIsChanged(false);
      setTimeout(() => setShowChangesSaved(false), 3000);
      
      if(showDiscard){
        setActiveSection(showDiscard); 
        setShowDiscard(false);
        }
    }
  };

 

  const verifyPAN = async () => {
    const url = `${process.env.REACT_APP_baseURL}/company/verify/pan`;

    const response = await axios
      .post(
        url,
        { companyId: companyData._id, pan: panNumber },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("auth"),
          },
        }
      )
      .catch((error) => console.log(error));

    if (response?.data) {
      await loadCompany();
      setShowChangesSaved(true);
      setTimeout(() => setShowChangesSaved(false), 3000);
    }
  };

  

  

  // const verifyGSTIN = async () => {
  //   const url = `${process.env.REACT_APP_baseURL}/company/verify/gstin`;

  //   const response = await axios
  //     .post(
  //       url,
  //       { companyId: companyData._id, gstin: panNumber },
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("auth"),
  //         },
  //       }
  //     )
  //     .catch((error) => console.log(error));

  //   if (response?.data) {
  //     await loadCompany();
  //     setShowChangesSaved(true);
  //     setTimeout(() => setShowChangesSaved(false), 3000);
  //   }
  // };

  useEffect(loadCompany, []);

  const [showGrantAccessBox, setShowrantAccessBox] = useState(false);
  const [showRevokeAccessBox, setShowRevokeAccessBox] = useState(false);

  const [showInviteUserBox, setShowInviteUserBox] = useState(false);

const showInvite = () => {
  setShowInviteUserBox((prev) => !prev)
}

// const [email, setEmail] = useState('');
const [invitationStatus, setInvitationStatus] = useState(null);
const [isInviteUser, setIsInviteUser] = useState(false);


// const handleEmailChange = (e) => {
//   setEmail(e.target.value);
// };

// const inviteUser = async () => {
//   try {
//     // Assuming you have an authentication token in a variable called authToken
//     const authToken = localStorage.getItem('auth');

//     // Make an API request to send an email invitation with the token in the headers
//     const response = await axios.post(
//       `${process.env.REACT_APP_baseURL}/recruiter/invite`,
//       {
//         email,
//         // Add any other required data for your API request here
//       },
//       {
//         headers: {
//           'Authorization': `Bearer ${authToken}`, // Adjust the header format as per your API's requirements
//         },
//       }
//     );

//     // Handle the API response
//     if (response.data.success) {
//       setInvitationStatus('Invitation sent successfully.');
//       setIsInviteUser(true);
//       showInvite();
//     } else {
//       setInvitationStatus('Failed to send invitation. Please try again.');
//       setIsInviteUser(false);
//     }
//   } catch (error) {
//     console.error('Error sending invitation:', error);
//     setInvitationStatus('Error sending invitation. Please try again later.');
//   }
// };

  
  const close = () => {
    setShowrantAccessBox((prev) => !prev);
    
    };

    const onClose = () => {
      setShowRevokeAccessBox((prev) => !prev);
      
      };
    
    const [showLogOutBox, setShowLogOutBox] = useState(false);
    const [removeRectBox, setRemoveRectBox] = useState(false);


    const closeBox = () => {
        setShowLogOutBox((prev) => !prev);
      };

      const rClose = () => {
        setRemoveRectBox((prev) => !prev);
      };
      const onGrant = async (recruiterId) => {
        try {
          // Assuming you have an authentication token in a variable called authToken
          const authToken = localStorage.getItem('auth');
      
          // Make an API request to send an email invitation with the token in the headers
          const response = await axios.post(
            `${process.env.REACT_APP_baseURL}/company/admin_status/${recruiterId}`,
            {
              action:"promote"
            },
            {
              headers: {
                'Authorization': `Bearer ${authToken}`, // Adjust the header format as per your API's requirements
              },
            }
          );
      
          // Handle the API response
          if (response) {
            console.log(response)
            console.log('user status Granted.');
            loadRecruiter()
           close();
            // showInvite();
          } else {
            console.log('Failed to send invitation. Please try again.');
            
          }
        } catch (error) {
          console.error('Error sending invitation:', error);
          console.log('Error sending invitation. Please try again later.');
        }
      };

      const onRevoke = async (recruiterId) => {
        try {
          // Assuming you have an authentication token in a variable called authToken
          const authToken = localStorage.getItem('auth');
      
          // Make an API request to send an email invitation with the token in the headers
          const response = await axios.post(
            `${process.env.REACT_APP_baseURL}/company/admin_status/${recruiterId}`,
            {
              action:"demote"
            },
            {
              headers: {
                'Authorization': `Bearer ${authToken}`, // Adjust the header format as per your API's requirements
              },
            }
          );
      
          // Handle the API response
          if (response) {
            console.log(response)
            console.log('user status Demoted.');
            loadRecruiter();
            onClose()
            // showInvite();
          } else {
            console.log('Failed to demoted. Please try again.');
            
          }
        } catch (error) {
          console.error('Error sending invitation:', error);
          console.log('Error sending invitation. Please try again later.');
        }
      };

      const onRemove = async (recruiterId) => {
        try {
          // Assuming you have an authentication token in a variable called authToken
          const authToken = localStorage.getItem('auth');
      
          // Make an API request to send an email invitation with the token in the headers
          const response = await axios.delete(
            `${process.env.REACT_APP_baseURL}/recruiter/user/company/${recruiterId}`,
            {
              headers: {
                'Authorization': `Bearer ${authToken}`, // Adjust the header format as per your API's requirements
              },
            }
          );
      
          // Handle the API response
          if (response) {
            console.log(response)
            console.log('user remove from company.');
            loadRecruiter()
            rClose()
            // showInvite();
          } else {
            console.log('Failed to remove user recruiter. Please try again.');
            
          }
        } catch (error) {
          console.error('Error sending invitation:', error);
          console.log('Error sending invitation. Please try again later.');
        }
      };


const [isLoading, setIsLoading] = useState(true); // State to track loading

// Your existing state and useEffect logic...

useEffect(() => {
  // Simulating an asynchronous action
  const fakeAsyncAction = async () => {
    try {
      // Simulate an API call or any asynchronous action
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      // Set isLoading to false once the asynchronous action is done
      setIsLoading(false);
    }
  };

  // Call the fakeAsyncAction when the component mounts
  fakeAsyncAction();
}, []); // Empty dependency array means this effect runs once on mount

// Render the loader if isLoading is true
// if (isLoading) {
//   return <Loader />;
// }
      
  return (
    <div>
      {/* <div className="sm:hidden">
        <BeepNavbar />

      </div> */}


       
      <div className="Menu w-full sm:w-[75vw] border-l border-gray-900 border-opacity-10 xl:w-[50vw] sm:pt-[3rem] sm:m-0 mb-8 overflow-scroll no-scrollbar grow shrink basis-0 self-stretch p-spaceBase sm:px-spaceSLarge sm:py-spaceLarge bg-white flex-col justify-start items-start inline-flex">
      {/* <Link to="/profile" >
          <div className="sm:hidden mb-4  flex flex-row ">
            <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M5.80781 10.4427L12.0578 16.6927C12.1452 16.7802 12.2566 16.8398 12.3779 16.8639C12.4992 16.8881 12.625 16.8757 12.7392 16.8284C12.8535 16.781 12.9512 16.7009 13.0198 16.598C13.0885 16.4951 13.1251 16.3742 13.125 16.2505V3.75049C13.1251 3.62681 13.0885 3.50587 13.0198 3.403C12.9512 3.30013 12.8535 3.21995 12.7392 3.1726C12.625 3.12526 12.4992 3.11288 12.3779 3.13704C12.2566 3.16119 12.1452 3.2208 12.0578 3.3083L5.80781 9.5583C5.7497 9.61635 5.7036 9.68528 5.67215 9.76115C5.6407 9.83703 5.62451 9.91836 5.62451 10.0005C5.62451 10.0826 5.6407 10.164 5.67215 10.2398C5.7036 10.3157 5.7497 10.3846 5.80781 10.4427Z" fill="#002032" fill-opacity="0.6" />
            </svg>
            </div>
            <span className='text-gray-500 font-bold leading-tight'>Go Back</span>
          </div>
        </Link> */}
       
       {isLoading && (
          <>
        
            <div className=" m-auto"><Loader /></div>
          </>
        )}
          {!isLoading && 
        <>
        <div className="InputFields self-stretch flex-col justify-start items-start flex">
          <div className="Row self-stretch justify-start items-start gap-8 inline-flex">
            <div className="w-full InputFieldStandard grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
            <div className="w-auto text-teal-950 text-[32px] font-black font-['Satoshi Variable'] leading-[28.80px] mb-spaceXxLarge sm:mb-spaceSLarge">Manage Company</div>
              
              <div className="w-auto text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px] mb-spaceBase sm:mb-spaceXxLarge">Set Up Profile</div>

              <div className="w-max h-16 mb-[16px] rounded-[32px] justify-start items-center gap-3 inline-flex">
                <ProfileLogo

                  defaultLogo={
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M16.25 2.5H3.75C3.41848 2.5 3.10054 2.6317 2.86612 2.86612C2.6317 3.10054 2.5 3.41848 2.5 3.75V16.25C2.5 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41848 17.5 3.75 17.5H16.25C16.5815 17.5 16.8995 17.3683 17.1339 17.1339C17.3683 16.8995 17.5 16.5815 17.5 16.25V3.75C17.5 3.41848 17.3683 3.10054 17.1339 2.86612C16.8995 2.6317 16.5815 2.5 16.25 2.5ZM3.75 3.75H16.25V9.79531L14.3211 7.86562C14.0867 7.63138 13.7689 7.4998 13.4375 7.4998C13.1061 7.4998 12.7883 7.63138 12.5539 7.86562L4.16953 16.25H3.75V3.75ZM6.25 7.5C6.25 7.25277 6.32331 7.0111 6.46066 6.80554C6.59801 6.59998 6.79324 6.43976 7.02165 6.34515C7.25005 6.25054 7.50139 6.22579 7.74386 6.27402C7.98634 6.32225 8.20907 6.4413 8.38388 6.61612C8.5587 6.79093 8.67775 7.01366 8.72598 7.25614C8.77421 7.49861 8.74946 7.74995 8.65485 7.97835C8.56024 8.20676 8.40002 8.40199 8.19446 8.53934C7.9889 8.67669 7.74723 8.75 7.5 8.75C7.16848 8.75 6.85054 8.6183 6.61612 8.38388C6.3817 8.14946 6.25 7.83152 6.25 7.5Z" fill="#16C083" />
                    </svg>
                  }
                  onLogoChange={onLogoChange}
                  currentLogo={companyData.logo}
                />
                <div className="grow shrink basis-0 text-slate-500 text-sm font-medium font-satoshi leading-tight">Company Logo</div>
              </div>

              
            </div>
            
          </div>
        
          <div className="InputFieldWebsiteLandscape self-stretch  flex-col justify-start items-start flex mb-spaceXxLarge sm:mb-spaceSLarge">
          <div className="Label self-stretch justify-start items-center gap-2 inline-flex mb-[16px]">
            
            <InputFieldComp
              labelText="Brand Name / Trade Name"
              type="text"
              name='name'
              placeholder="Ex.Leisure Corporation"
              addInfo="This is additional information."
              value={companyData.name}
              onChange={onInputChange}
              disabled={companyData.verified}

            />
          
          
          </div>
              {websiteUrls?.map((url, index) => (
                <div className="w-full">

                  <InputFieldComp
                    className={"pt-1 py-0 pb-spaceXxxSmall"}
                    value={url}
                    onChange={(e) => onUrlValueChanage(index, e.target.value)}
                    labelText={index === 0 ? "Social Links" : ""}
                    type="text"
                    // inputLeftText={
                    //   <div>
                    //     {url.match(domainExtraction)
                    //       ? url.match(domainExtraction)[1]
                    //       : "Custom"}
                    //   </div>
                    // }
                    placeholder="Ex.poolsuite.fm"
                    leftIcon={
                      <div>
                        {
                        url ? 
                        <img src={`http://www.google.com/s2/favicons?sz=128&domain=${url}`} className="w-7 h-7" />
                        :
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM16.8461 9.375H13.7344C13.6242 7.15156 12.932 5.04141 11.7719 3.35938C13.1306 3.72417 14.3452 4.49613 15.2523 5.57152C16.1594 6.64691 16.7156 7.97419 16.8461 9.375ZM7.51797 10.625H12.482C12.3508 13.0367 11.4664 15.2828 10 16.8664C8.53672 15.2828 7.64922 13.0367 7.51797 10.625ZM7.51797 9.375C7.64922 6.96328 8.5336 4.71719 10 3.13359C11.4633 4.71719 12.3508 6.96172 12.482 9.375H7.51797ZM11.7719 16.6406C12.932 14.9563 13.6242 12.8461 13.7344 10.625H16.8461C16.7156 12.0258 16.1594 13.3531 15.2523 14.4285C14.3452 15.5039 13.1306 16.2758 11.7719 16.6406Z" fill="#667984"/>
</svg>
                    }
                      </div>
                        
                    }
                    rightIcon={
                      <div>

                        <div onClick={() => removeUrl(index)}>
                          <ButtonChild
                            textColor="gray"
                            leftIcon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                              >
                                <path
                                  d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.125 10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                                  fill="#DB1717"
                                />
                              </svg>
                            }
                          />
                        </div>

                      </div>
                    }

                    hasError={error[index]} // Use the error state for the specific index
        errorMsg={error[index]}
                  />
                </div>
              ))}
<div className="w-full mt-1">
              {/* <BorderButton
              
                onClick={addUrl}
                className="w-full"
                buttonClass="w-full py-1 justify-between"
                color="green"
                textColor="green"
                rightIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z" fill="#16C083" />
                </svg>}
                text="Add Link"
              /> */}

<div  onClick={addUrl} className=" cursor-pointer w-full h-11 px-3 py-2 bg-white rounded-xl border-2 border-emerald-500 justify-start items-center gap-1 inline-flex">
    <div className="grow shrink basis-0 text-emerald-500 text-sm font-black font-['Satoshi Variable'] leading-tight">Add Link</div>
    <div className="w-1 h-7" />
    <div className="p-1 justify-start items-center flex">
        <div className="w-5 h-5">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                            <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z" fill="#16C083" />
                                        </svg>
        </div>
    </div>
</div>     
              </div>

          </div>
        </div>
       
        <div className="InputFields self-stretch  flex-col justify-start items-start flex mb-spaceXxLarge sm:mb-spaceSLarge">
          <div className="TitleStatus flex-col gap-1 sm:gap-0 sm:flex-row self-stretch justify-between items-start inline-flex sm:mb-spaceLarge sm:mb-spaceXxLarge">
          <div className="w-auto text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px] ">
            Profile Verification</div>

            {companyData.verified ? (
              <div className="Title p-2 rounded-xl justify-start items-start gap-1 flex">
                <div className="InputFieldIconsCheckcircle  w-5 h-5 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                      fill="#16C083"
                    />
                  </svg>
                </div>
                <div className="CompanyVerified text-emerald-500 text-sm font-medium leading-tight">
                  Company Verified
                </div>
              </div>
            ) : (
              <div className="Title w-fit h-9 p-2  rounded-xl justify-start items-start gap-1 inline-flex">
                <div className="InputFieldIconsShieldwarning  w-5 h-5 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M16.25 3.125H3.75C3.41848 3.125 3.10054 3.2567 2.86612 3.49112C2.6317 3.72554 2.5 4.04348 2.5 4.375V8.96641C2.5 15.968 8.42344 18.2898 9.60938 18.6836C9.86258 18.7703 10.1374 18.7703 10.3906 18.6836C11.5781 18.2891 17.5 15.9672 17.5 8.96563V4.375C17.5 4.04348 17.3683 3.72554 17.1339 3.49112C16.8995 3.2567 16.5815 3.125 16.25 3.125ZM9.375 7.5C9.375 7.33424 9.44085 7.17527 9.55806 7.05806C9.67527 6.94085 9.83424 6.875 10 6.875C10.1658 6.875 10.3247 6.94085 10.4419 7.05806C10.5592 7.17527 10.625 7.33424 10.625 7.5V10.625C10.625 10.7908 10.5592 10.9497 10.4419 11.0669C10.3247 11.1842 10.1658 11.25 10 11.25C9.83424 11.25 9.67527 11.1842 9.55806 11.0669C9.44085 10.9497 9.375 10.7908 9.375 10.625V7.5ZM10 14.375C9.81458 14.375 9.63332 14.32 9.47915 14.217C9.32498 14.114 9.20482 13.9676 9.13386 13.7963C9.06291 13.625 9.04434 13.4365 9.08051 13.2546C9.11669 13.0727 9.20598 12.9057 9.33709 12.7746C9.4682 12.6435 9.63525 12.5542 9.8171 12.518C9.99896 12.4818 10.1875 12.5004 10.3588 12.5714C10.5301 12.6423 10.6765 12.7625 10.7795 12.9167C10.8825 13.0708 10.9375 13.2521 10.9375 13.4375C10.9375 13.6861 10.8387 13.9246 10.6629 14.1004C10.4871 14.2762 10.2486 14.375 10 14.375Z"
                      fill="#DB1717"
                    />
                  </svg>
                </div>
                <div className="CompanyNotVerified text-red-600 text-sm font-medium leading-tight">
                  Company Not Verified
                </div>
              </div>
            )}
          </div>

          <div className="w-full mb-spaceBase sm:mb-spaceLarge">
                                    <InputFieldComp
                                        labelText="Registered Name"
                                        type="text"
                                        name='registeredName'
                                        placeholder="Ex.Leisure Corporation"
                                        addInfo="As per the company’s official documents."
                                        value={companyData.registeredName}
                  onChange={onInputChange}
                  disabled={companyData.verified}
                                    />
                                </div>


          {(!companyData.verified || companyData.type == "PAN") && (
            <div className="Row self-stretch justify-start items-start gap-8 inline-flex">
              <div className="Type grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                
              <TextInput
                                        value={
                                          companyData.verified
                                            ? companyData.verificationID
                                            : panNumber
                                        }
                                        onChange={(e) => setPanNumber(e.target.value)}
                                            labelText="Verification"
                                            placeClass={`text-gray-800`}
                                            textType="input"
                                            htmlFor="email"
                                            type="text"
                                            textColor="gray"
                                            disabled={companyData.verified}
                                            placeholder="Ex.ABCDE1234Z"
                                            name="PAN"
                                            rightIcon={
                                                <div>
                                                {!companyData.verified ? (
                                                <div  onClick={verifyPAN}>
                                            <ButtonChild 
                                            textColor="green"
                                            text="Verify"
                                            
                                            />
                                            </div>
                                                )
                                              :(
                                                <div>
                                                <ButtonChild 
                                                disabled={companyData.verified}
                                                textColor="gray"
                                                textClass={`w-max text-gray-400`}
                                                text="Verified with PAN"
                                                rightIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z" fill="#16C083"/>
                                              </svg>}
                                                />
                                                </div>
                                              )
                                              }
                                                </div>
                                        }
                                        
                                        />
                {/* <div className="VerifiedViaPan self-stretch text-gray-900 text-opacity-60 text-sm font-medium leading-tight">
                  
                </div>
                <div className="Input self-stretch p-1 bg-gray-900 bg-opacity-5 rounded-xl border border-gray-900 border-opacity-10 justify-start items-center gap-2 inline-flex">
                  <input
                    value={
                      companyData.verified
                        ? companyData.verificationID
                        : panNumber
                    }
                    onChange={(e) => setPanNumber(e.target.value)}
                    className="Abcde9999q grow shrink basis-0 text-gray-900 text-sm font-medium leading-tight p-3 outline-none"
                    disabled={companyData.verified}
                    placeholder="Enter PAN"
                  />
                </div> */}
                {/* {!companyData.verified && (
                  <button
                    className="ButtonLarge w-32 h-[52px] px-8 py-4 bg-white rounded-xl border border-emerald-500 justify-center items-center gap-2 inline-flex"
                    onClick={verifyPAN}
                  >
                    <div className="ButtonText text-emerald-500 text-sm font-bold leading-tight">
                      Verify
                    </div>
                  </button>
                )} */}
              </div>
            </div>
          )}

          {/* {(!companyData.verified || companyData.type == "GSTIN") && (
            <div className="Row self-stretch justify-start items-start gap-8 inline-flex">
              <div className="Type grow shrink basis-0 flex-col justify-start items-start gap-1 flex">
                <div className="VerifiedViaPan self-stretch text-gray-900 text-opacity-60 text-sm font-medium leading-tight">
                  Verified via GSTIN
                </div>
                <div className="Input self-stretch p-1 bg-gray-900 bg-opacity-5 rounded-xl border border-gray-900 border-opacity-10 justify-start items-center gap-2 inline-flex">
                  <input
                    value={
                      companyData.verified
                        ? companyData.verificationID
                        : gstinNumber
                    }
                    className="Abcde9999q grow shrink basis-0 text-gray-900 text-sm font-medium leading-tight p-3 outline-none"
                    onChange={(e) => setGSTINNumber(e, target.value)}
                    disabled={companyData.verified}
                    placeholder="Enter GSTIN"
                  />
                </div>
                {!companyData.verified && (
                  <button
                    className="ButtonLarge w-32 h-[52px] px-8 py-4 bg-white rounded-xl border border-emerald-500 justify-center items-center gap-2 inline-flex"
                    onClick={verifyGSTIN}
                  >
                    <div className="ButtonText text-emerald-500 text-sm font-bold leading-tight">
                      Verify
                    </div>
                  </button>
                )}
              </div>
            </div>
          )} */}
        </div>


        {/* Manage Users */}
        {/* {recruiterInfo.map(recruiter => ( */}
         
       
          <div className="w-full">
        {userData.role === 1 ?

        <div className="w-full h-auto flex-col justify-start items-start inline-flex mb-spaceSLarge">
    <div className="self-stretch text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px] mb-spaceLarge sm:mb-spaceSLarge">Manage Company Users</div>
    {Array.isArray(recruiterInfo) && recruiterInfo.map(recruiter => (
    <div className="w-full self-stretch h-auto flex-col justify-start items-start flex">
    {recruiter.role === 1 &&
           <div className="inline-flex flex-col w-full ">
           <div className="w-full self-stretch flex-col md:flex-row justify-between md:justify-start item-start md:items-center gap-1 md:gap-0 inline-flex">
         
           <div className="justify-start flex-col md:flex-row items-center gap-4 flex sm:w-full">
             <div className="w-full inline flex gap-3 ">
             <div className="w-8 h-8 bg-gray-100 rounded-[32px] justify-center items-center flex">
               {recruiter.profile ? 
             <img className="w-8 h-8 rounded-[50%]" src={recruiter.profile} alt="profile"/>
             :  
             <div className="w-full p-1.5">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
             <path d="M18.0417 17.1877C17.9869 17.2827 17.908 17.3616 17.8129 17.4165C17.7179 17.4713 17.6101 17.5002 17.5003 17.5002H2.50034C2.39068 17.5001 2.28298 17.4711 2.18806 17.4162C2.09314 17.3613 2.01433 17.2824 1.95955 17.1874C1.90477 17.0924 1.87595 16.9847 1.87598 16.875C1.876 16.7654 1.90488 16.6576 1.95971 16.5627C3.14955 14.5056 4.98315 13.0306 7.12299 12.3314C6.06453 11.7013 5.24217 10.7412 4.7822 9.59845C4.32223 8.45573 4.25009 7.19361 4.57685 6.00592C4.90361 4.81823 5.61121 3.77064 6.59098 3.02402C7.57075 2.2774 8.76851 1.87305 10.0003 1.87305C11.2322 1.87305 12.4299 2.2774 13.4097 3.02402C14.3895 3.77064 15.0971 4.81823 15.4238 6.00592C15.7506 7.19361 15.6784 8.45573 15.2185 9.59845C14.7585 10.7412 13.9361 11.7013 12.8777 12.3314C15.0175 13.0306 16.8511 14.5056 18.041 16.5627C18.0959 16.6576 18.1249 16.7654 18.1251 16.8751C18.1252 16.9848 18.0965 17.0926 18.0417 17.1877Z" fill="#667984"/>
           </svg>
           </div>
           }
             </div>
               <div className="flex w-full"> 
                   <div className="w-full flex-col justify-center items-start gap-1 inline-flex">
                    <div className="w-full justify-start items-center gap-1 inline-flex">
                       <div className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{recruiter.name}</div>
                       <div className="px-2 py-1 bg-gray-100 rounded-[28px] border-2 border-gray-200 justify-start items-center flex">
                            <div className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-none">Admin</div>
                        </div>
                        </div>
                       <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Opportunities Posted: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{recruiter.jobsCreated}</span></div>
                       <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Candidates Hired: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{recruiter.candidatesHired}</span></div>
                   </div>
               </div>
               </div>
   {companyData.createdBy !== recruiter.id  ? 
               <div className="justify-start flex-col sm:flex-row md:justify-end items-start md:items-center w-full gap-3 flex">
                 
                   <div className="p-1 rounded-xl justify-center items-center gap-1 flex" onClick={onClose}>
                     <ButtonChild 
                     textColor="red"
                     text="Revoke Admin Access"
                     />
                       {/* <div className="text-center text-emerald-500 text-sm font-black font-['Satoshi Variable'] leading-tight">Grant Admin Access</div> */}
                   </div>
   
   
                   
   
                    <div className="p-1 rounded-xl justify-center items-center gap-1 flex" onClick={rClose}>
                   <ButtonChild 
                     textColor="red"
                     text="Remove"
                     />
                     
                   </div>
                  
                   
               </div>
               :""}
   
               {showGrantAccessBox && <GrantAdminAccess close={close} onCancel={close} onGrant={()=>onGrant(recruiter.id)} 
                   name={recruiter.name} job={recruiter.jobsCreated} hired={recruiter.candidatesHired} profile={recruiter.profile}
                   
                   />}
               
               </div>
               
   
   
           </div>
          
            <div className="Line self-stretch h-[0px] border border-gray-900 border-opacity-10 my-4"></div>
   
   
   
   
   </div>
  }
  
    {recruiter.role === 0 &&
        <div className="inline-flex flex-col w-full ">
        <div className="w-full self-stretch flex-col md:flex-row justify-between md:justify-start item-start md:items-center gap-1 md:gap-0 inline-flex">
      
        <div className="justify-start flex-col md:flex-row items-center gap-4 flex sm:w-full">
          <div className="w-full inline flex gap-3 ">
          <div className="w-8 h-8 bg-gray-100 rounded-[32px] justify-center items-center flex">
            {recruiter.profile ? 
          <img className="w-8 h-8 rounded-[50%]" src={recruiter.profile} alt="profile"/>
          :  
          <div className="w-full p-1.5">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M18.0417 17.1877C17.9869 17.2827 17.908 17.3616 17.8129 17.4165C17.7179 17.4713 17.6101 17.5002 17.5003 17.5002H2.50034C2.39068 17.5001 2.28298 17.4711 2.18806 17.4162C2.09314 17.3613 2.01433 17.2824 1.95955 17.1874C1.90477 17.0924 1.87595 16.9847 1.87598 16.875C1.876 16.7654 1.90488 16.6576 1.95971 16.5627C3.14955 14.5056 4.98315 13.0306 7.12299 12.3314C6.06453 11.7013 5.24217 10.7412 4.7822 9.59845C4.32223 8.45573 4.25009 7.19361 4.57685 6.00592C4.90361 4.81823 5.61121 3.77064 6.59098 3.02402C7.57075 2.2774 8.76851 1.87305 10.0003 1.87305C11.2322 1.87305 12.4299 2.2774 13.4097 3.02402C14.3895 3.77064 15.0971 4.81823 15.4238 6.00592C15.7506 7.19361 15.6784 8.45573 15.2185 9.59845C14.7585 10.7412 13.9361 11.7013 12.8777 12.3314C15.0175 13.0306 16.8511 14.5056 18.041 16.5627C18.0959 16.6576 18.1249 16.7654 18.1251 16.8751C18.1252 16.9848 18.0965 17.0926 18.0417 17.1877Z" fill="#667984"/>
        </svg>
        </div>
        }
          </div>
            <div className="flex w-full"> 
                <div className="w-full flex-col justify-center items-start gap-1 inline-flex">
                    <div className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{recruiter.name}</div>
                    <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Opportunities Posted: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{recruiter.jobsCreated}</span></div>
                    <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Candidates Hired: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{recruiter.candidatesHired}</span></div>
                </div>
            </div>
            </div>
{companyData.createdBy !== recruiter.id  ? 
            <div className="justify-start  flex-col sm:flex-row md:justify-end items-start md:items-center w-full gap-3 flex">
              
                <div className="p-1 rounded-xl justify-center items-center gap-1 flex" onClick={close}>
                  <ButtonChild 
                  textColor="green"
                  text="Grant Admin Access"
                  />
                    {/* <div className="text-center text-emerald-500 text-sm font-black font-['Satoshi Variable'] leading-tight">Grant Admin Access</div> */}
                </div>


                

                 <div className="p-1 rounded-xl justify-center items-center gap-1 flex" onClick={rClose}>
                <ButtonChild 
                  textColor="red"
                  text="Remove"
                  />
                  
                </div>
               
                
            </div>
            :""}

            {showGrantAccessBox && <GrantAdminAccess close={close} onCancel={close} onGrant={()=>onGrant(recruiter.id)} 
                name={recruiter.name} job={recruiter.jobsCreated} hired={recruiter.candidatesHired} profile={recruiter.profile}
                
                />}
            
            </div>
            


        </div>
        {/* <div className="Line self-stretch h-[0px] border border-gray-900 border-opacity-10"></div>
        <div className="self-stretch justify-between items-start gap-1 md:gap-0 flex-col sm:flex-row inline-flex">
            <div className="justify-start items-start gap-4 flex md:w-full">
                <img className="w-8 h-8 relative rounded-[32px]" src="https://via.placeholder.com/32x32" />
                <div className="flex-col justify-center items-start gap-1 inline-flex">
                    <div className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">Saket Nagar</div>
                    <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Opportunities Posted: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">12</span></div>
                    <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Candidates Hired: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">32</span></div>
                </div>
            </div>
            <div className="justify-end md:justify-center items-center w-full gap-3 flex">
                <div className="p-1 rounded-xl justify-center items-center gap-1 flex" onClick={close}>
                  <ButtonChild 
                  textColor="green"
                  text="Grant Admin Access"
                  />
                    
                </div>
                <div className="p-1 rounded-xl justify-center items-center gap-1 flex">
                <ButtonChild 
                  textColor="red"
                  text="Remove"
                  />
                   
                </div>
            </div>
        </div> */}
         <div className="Line self-stretch h-[0px] border border-gray-900 border-opacity-10 my-4"></div>




</div>
      }




{showRevokeAccessBox && <RevokeAdminAccess  close={onClose} onCancel={onClose} onRevoke={()=>onRevoke(recruiter.id)} 
                name={recruiter.name} job={recruiter.jobsCreated} hired={recruiter.candidatesHired} profile={recruiter.profile}
                />}

{removeRectBox && <RemoveRecruiter close={rClose} onCancel={rClose} onRemove={()=>onRemove(recruiter.id)}/>}

  
    </div>
     ))}


<div className="w-full flex flex-col">
<div className="w-full">
        {invitedRecruit && 
        <ul className="w-full">
        {emails.map((email, index) => (
          
          <li key={index} className="w-full">
    
        <div className="w-full h-8 justify-start item-start inline-flex mb-8 flex-col md:flex-row">
    <div className="justify-start items-center gap-4 flex">
        <div className="w-8 h-8 p-1.5 bg-gray-100 rounded-[32px] justify-center items-center flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
  <path d="M18.0417 17.1877C17.9869 17.2827 17.908 17.3616 17.8129 17.4165C17.7179 17.4713 17.6101 17.5002 17.5003 17.5002H2.50034C2.39068 17.5001 2.28298 17.4711 2.18806 17.4162C2.09314 17.3613 2.01433 17.2824 1.95955 17.1874C1.90477 17.0924 1.87595 16.9847 1.87598 16.875C1.876 16.7654 1.90488 16.6576 1.95971 16.5627C3.14955 14.5056 4.98315 13.0306 7.12299 12.3314C6.06453 11.7013 5.24217 10.7412 4.7822 9.59845C4.32223 8.45573 4.25009 7.19361 4.57685 6.00592C4.90361 4.81823 5.61121 3.77064 6.59098 3.02402C7.57075 2.2774 8.76851 1.87305 10.0003 1.87305C11.2322 1.87305 12.4299 2.2774 13.4097 3.02402C14.3895 3.77064 15.0971 4.81823 15.4238 6.00592C15.7506 7.19361 15.6784 8.45573 15.2185 9.59845C14.7585 10.7412 13.9361 11.7013 12.8777 12.3314C15.0175 13.0306 16.8511 14.5056 18.041 16.5627C18.0959 16.6576 18.1249 16.7654 18.1251 16.8751C18.1252 16.9848 18.0965 17.0926 18.0417 17.1877Z" fill="#667984"/>
</svg>
        </div>
        <div className=" w-full flex-row justify-start items-start gap-1 inline-flex ">
            <div className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{email}</div>
        </div>
    </div>
    <div className="w-full lg:ml-[10rem] xl:ml-5 2xl:ml-[10rem] justify-start md:justify-end items-start md:items-center gap-3 flex mt-2">
        <div className="text-teal-950 text-sm font-medium font-['Satoshi Variable'] leading-tight">Invitation Sent on Email</div>
    </div>
    {/* <div className="Line self-stretch h-[0px] border border-gray-900 border-opacity-10 mt-2"></div> */}
    
</div>
<div className="Line self-stretch h-[0px] border border-gray-900 border-opacity-10 my-4"></div>
</li>


          ))}
           {/* <div className="Line self-stretch h-[0px] border border-gray-900 border-opacity-10 mt-2"></div> */}
    </ul>
}

</div>
<div className="py-1 sm:mt-8 rounded-xl items-center gap-1 inline-flex" onClick={showInvite}>
        <ButtonChild 
                  textColor="green"
                  text="Invite New User"
                  leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z" 
                  fill="currentColor"/>
                </svg>}
                  />
        </div>
</div>

{showInviteUserBox && <InviteUser close={showInvite} onCancel={showInvite}  invitedRecruiter={invitedRecruiter}/>}


     
</div>
: ''}
</div>


        
        <div className="Cta w-full sm:w-max justify-start items-start gap-4 flex-col sm:flex-row inline-flex">

          <BorderButton
          className="w-full"
            onClick={loadCompany}
            text="Reset changes"
            color="gray"
            textColor="gray"
            disabled={changed ? false : true}
            leftIcon={<svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z"
                fill="#002032"
                fill-opacity="0.6"
              />
            </svg>}
          />
       
          <BorderButton
          className="w-full"
            onClick={updateCompany}
            text="Save Changes"
            color="green"
            textColor="green"
            disabled={changed ? false : true}
            leftIcon={<svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                fill="currentColor"
              />
            </svg>}
          />
   

          {showChangesSaved && (
            <div className="py-4 flex px-4">
              <div className="Checkcircle  w-5 h-5  text-emerald-500 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.41489 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="SaveChanges text-emerald-500 text-sm font-bold leading-tight px-1 py-0.5">
                Saved
              </div>
            </div>
          )}
        </div>
        {showDiscard && <OnSaveChangeToggle close={()=>{setShowDiscard(false)}} onSave={updateCompany} />}
        
        </>
         }
        {alertData && (
          <Alert
            text={alertData}
            alertClose={() => setAlertData(null)}
            time={2}
          />
        )}

      </div>
        
    </div>
  );
};

export default MyCompany;
