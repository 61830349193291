import BeepNavbar from "../../components/navbar/navbar";
import { Link } from "react-router-dom";

const Privacy = () => {
  return (
    <div>

    {/* <div className="sm:hidden">
            <BeepNavbar />
    
          </div> */}
    <div className="Menu w-full border-l border-gray-900 border-opacity-10 sm:w-[75vw] xl:w-[50vw] sm:pt-[3rem] sm:m-0 mb-8 overflow-scroll no-scrollbar grow shrink basis-0 self-stretch p-spaceBase sm:px-spaceSLarge sm:py-spaceLarge bg-white flex-col justify-start items-start inline-flex">
    {/* <Link to="/profile">
              <div className="sm:hidden mb-4  flex flex-row ">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M5.80781 10.4427L12.0578 16.6927C12.1452 16.7802 12.2566 16.8398 12.3779 16.8639C12.4992 16.8881 12.625 16.8757 12.7392 16.8284C12.8535 16.781 12.9512 16.7009 13.0198 16.598C13.0885 16.4951 13.1251 16.3742 13.125 16.2505V3.75049C13.1251 3.62681 13.0885 3.50587 13.0198 3.403C12.9512 3.30013 12.8535 3.21995 12.7392 3.1726C12.625 3.12526 12.4992 3.11288 12.3779 3.13704C12.2566 3.16119 12.1452 3.2208 12.0578 3.3083L5.80781 9.5583C5.7497 9.61635 5.7036 9.68528 5.67215 9.76115C5.6407 9.83703 5.62451 9.91836 5.62451 10.0005C5.62451 10.0826 5.6407 10.164 5.67215 10.2398C5.7036 10.3157 5.7497 10.3846 5.80781 10.4427Z" fill="#002032" fill-opacity="0.6" />
                </svg>
                </div>
                <span className='text-gray-500 font-bold leading-tight'>Go Back</span>
              </div>
            </Link> */}
        <div className="text-teal-950 text-[32px] font-black font-['Satoshi Variable'] leading-[38.40px] mb-spaceXxLarge sm:mb-spaceSLarge">Privacy Policy</div>
          <div className="p-2">
            <h4 className="heading font-bold ">1. Information We Collect</h4>
            <div className="p-4">
              <p>
                We collect personal information, such as your name, email
                address, and contact details, when you register for an account
                and use our Service. We also collect data related to job
                postings, candidate interactions, and AI-based tools usage.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">
              2.How We Use Your Information{" "}
            </h4>
            <div className="p-4">
              <p>
                We use your information to provide and improve the Service,
                including facilitating job postings, candidate interactions, and
                AI-based tools. We may also use your data for communication,
                research, and analytics.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">3. Data Sharing </h4>
            <div className="p-4">
              <p>
                We may share your information with third-party service providers
                and partners who help us operate the Service. We do not sell or
                rent your personal information to third parties.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">4. Data Security </h4>
            <div className="p-4">
              <p>
                We implement security measures to protect your information, but
                no method of transmission or storage is completely secure. We
                cannot guarantee the absolute security of your data.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">5. Cookies and Tracking </h4>
            <div className="p-4">
              <p>
                We use cookies and similar tracking technologies to improve the
                Service's functionality and user experience. You can manage your
                cookie preferences through your browser settings.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">6. Your Choices </h4>
            <div className="p-4">
              <p>
                You can access, update, or delete your personal information by
                logging into your account. You may also contact us to exercise
                your data rights.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">7. Children's Privacy </h4>
            <div className="p-4">
              <p>
                Our Service is not intended for children under 13 years of age.
                We do not knowingly collect or store information from children
                under 13.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">
              8. Changes to this Privacy Policy{" "}
            </h4>
            <div className="p-4">
              <p>
                We may update this Privacy Policy to reflect changes in our data
                practices. We will notify you of any significant changes.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">9. Contact Us </h4>
            <div className="p-4">
              <p>
                If you have questions or concerns about this Privacy Policy or
                our data practices, please contact us at{" "}
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = "mailto:contact@eventbeep.com";
                    e.preventDefault();
                  }}
                  className="ButtonText text-emerald-500 text-[14px] font-bold leading-tight mb-0 mt-auto"
                >
                  contact@eventbeep.com
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
   
  );
};

export default Privacy;
