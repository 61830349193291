import React, { useEffect } from "react";
import "../../index.css"
const Alert = ({ alertClose, time, text }) => {
  useEffect(() => {
    setTimeout(alertClose, time * 1000);
  }, []);

  return (
    <div
      className="relative z-10 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0  bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
        <div className="right-10 left-auto p-4 text-right sm:items-center sm:p-0 mr-8 mt-4">
          <div className="Title  h-14 p-4 bg-red-600  rounded-xl shadow justify-center items-center gap-4 inline-flex">
            {/* <div className="Warning w-6 h-6 relative" /> */}
            <div className="OopsWeVeEncounteredAnErrorThePageWillRefreshIn5Seconds text-center text-white text-[16px] font-medium leading-normal">
              {text}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
