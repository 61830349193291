
import React, { useState } from "react";
import FillButtonV2 from "../../../components/buttons/UI_Buttons_2/fillButton";
import ButtonChild from "../../../components/buttons/UI_Buttons_2/buttonChild";
import BorderButton from "../../../components/buttons/UI_Buttons_2/borderButton";
import InputFieldComp from "../../../components/inputfields/UI_Inputs/InputFieldComp";

const RevokeAdminAccess = ({ close, onRevoke, onCancel, name, job, hired, profile }) => {
    // const [showLogOutBox, setShowLogOutBox] = useState(false);

    // const close = () => {
    //     setShowLogOutBox((prev) => !prev);
    //   };


    
  return (
    <div
      class="relative z-11 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="fixed inset-0  bg-white bg-opacity-80 backdrop-blur-[4px] blur-md transition-opacity"></div>
    
      <div class="fixed inset-0 z-10 overflow-y-auto no-scrollbar">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div class="relative m-auto w-full transform overflow-scroll no-scrollbar rounded-lg bg-white text-left shadow-xl transition-all px-8 py-8 sm:my-8 sm:w-full sm:max-w-lg w-4/12">
            <div className="flex justify-between items-center w-screen sm:w-full">
            <div className="w-max text-teal-950 text-2xl font-black font-['Satoshi Variable'] leading-[28.80px]">Revoke Admin Access</div>
              
              <div onClick={close}>
              <ButtonChild 
              className="px-1"
              
              textColor="gray"
              leftIcon={
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.0673 15.1829C16.1254 15.241 16.1714 15.3099 16.2028 15.3858C16.2343 15.4617 16.2505 15.543 16.2505 15.6251C16.2505 15.7072 16.2343 15.7885 16.2028 15.8644C16.1714 15.9403 16.1254 16.0092 16.0673 16.0673C16.0092 16.1254 15.9403 16.1714 15.8644 16.2028C15.7885 16.2343 15.7072 16.2505 15.6251 16.2505C15.543 16.2505 15.4617 16.2343 15.3858 16.2028C15.3099 16.1714 15.241 16.1254 15.1829 16.0673L10.0001 10.8837L4.81729 16.0673C4.70002 16.1846 4.54096 16.2505 4.3751 16.2505C4.20925 16.2505 4.05019 16.1846 3.93292 16.0673C3.81564 15.95 3.74976 15.791 3.74976 15.6251C3.74976 15.4593 3.81564 15.3002 3.93292 15.1829L9.11651 10.0001L3.93292 4.81729C3.81564 4.70002 3.74976 4.54096 3.74976 4.3751C3.74976 4.20925 3.81564 4.05019 3.93292 3.93292C4.05019 3.81564 4.20925 3.74976 4.3751 3.74976C4.54096 3.74976 4.70002 3.81564 4.81729 3.93292L10.0001 9.11651L15.1829 3.93292C15.3002 3.81564 15.4593 3.74976 15.6251 3.74976C15.791 3.74976 15.95 3.81564 16.0673 3.93292C16.1846 4.05019 16.2505 4.20925 16.2505 4.3751C16.2505 4.54096 16.1846 4.70002 16.0673 4.81729L10.8837 10.0001L16.0673 15.1829Z" 
              fill="currentColor"/>
            </svg>}
              />
              </div>
            </div>
            <div className="w-full h-auto flex-col justify-start items-start gap-4 inline-flex mt-spaceXxLarge">
    <div className="justify-start items-center gap-4 inline-flex">
    <div className="w-8 h-8 bg-gray-100 rounded-[32px] justify-center items-center flex">
               {profile ? 
             <img className="w-8 h-8 rounded-[50%]" src={profile} alt="profile"/>
             :  
             <div className="w-full p-1.5">
             <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
             <path d="M18.0417 17.1877C17.9869 17.2827 17.908 17.3616 17.8129 17.4165C17.7179 17.4713 17.6101 17.5002 17.5003 17.5002H2.50034C2.39068 17.5001 2.28298 17.4711 2.18806 17.4162C2.09314 17.3613 2.01433 17.2824 1.95955 17.1874C1.90477 17.0924 1.87595 16.9847 1.87598 16.875C1.876 16.7654 1.90488 16.6576 1.95971 16.5627C3.14955 14.5056 4.98315 13.0306 7.12299 12.3314C6.06453 11.7013 5.24217 10.7412 4.7822 9.59845C4.32223 8.45573 4.25009 7.19361 4.57685 6.00592C4.90361 4.81823 5.61121 3.77064 6.59098 3.02402C7.57075 2.2774 8.76851 1.87305 10.0003 1.87305C11.2322 1.87305 12.4299 2.2774 13.4097 3.02402C14.3895 3.77064 15.0971 4.81823 15.4238 6.00592C15.7506 7.19361 15.6784 8.45573 15.2185 9.59845C14.7585 10.7412 13.9361 11.7013 12.8777 12.3314C15.0175 13.0306 16.8511 14.5056 18.041 16.5627C18.0959 16.6576 18.1249 16.7654 18.1251 16.8751C18.1252 16.9848 18.0965 17.0926 18.0417 17.1877Z" fill="#667984"/>
           </svg>
           </div>
           }
             </div>
        <div className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{name}</div>
    </div>
    <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Opportunities Posted: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{job}</span></div>
    <div><span className="text-slate-500 text-sm font-medium font-['Satoshi Variable'] leading-tight">Candidates Hired: </span><span className="text-teal-950 text-sm font-black font-['Satoshi Variable'] leading-tight">{hired}</span></div>
</div>
            <div className="form w-screen sx:w-full mt-spaceLarge">
               
            <div className="w-auto text-teal-950 text-sm font-medium font-['Satoshi Variable'] leading-tight">By doing this, {name} will not be able to manage admin access and add or remove users of this company profile.</div>
              
              <div className="flex flex-row justify-start gap-spaceBase ml-auto mt-spaceLarge" >
              <div>

<BorderButton 
onClick={onCancel}
text="Cancel"
textColor="gray"
color="gray"
leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
<path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z" 
fill="currentColor"/>
</svg>}
/>
</div>
                <div>
              <FillButtonV2 
              onClick={onRevoke}
              text="Revoke Admin Access"
              color="red"
              textColor="white"
              leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM13.5672 8.56719L9.19219 12.9422C9.13415 13.0003 9.06522 13.0464 8.98934 13.0779C8.91347 13.1093 8.83214 13.1255 8.75 13.1255C8.66787 13.1255 8.58654 13.1093 8.51067 13.0779C8.43479 13.0464 8.36586 13.0003 8.30782 12.9422L6.43282 11.0672C6.31554 10.9499 6.24966 10.7909 6.24966 10.625C6.24966 10.4591 6.31554 10.3001 6.43282 10.1828C6.55009 10.0655 6.70915 9.99965 6.875 9.99965C7.04086 9.99965 7.19992 10.0655 7.31719 10.1828L8.75 11.6164L12.6828 7.68281C12.7409 7.62474 12.8098 7.57868 12.8857 7.54725C12.9616 7.51583 13.0429 7.49965 13.125 7.49965C13.2071 7.49965 13.2884 7.51583 13.3643 7.54725C13.4402 7.57868 13.5091 7.62474 13.5672 7.68281C13.6253 7.74088 13.6713 7.80982 13.7027 7.88569C13.7342 7.96156 13.7504 8.04288 13.7504 8.125C13.7504 8.20712 13.7342 8.28844 13.7027 8.36431C13.6713 8.44018 13.6253 8.50912 13.5672 8.56719Z" 
              fill="currentColor"/>
            </svg>}
              />
              </div>
              

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevokeAdminAccess;
