// import React from "react";
// import "../../index.css"

// const SearchTextInput = ({
//   icon,
//   placeholder,
//   className,
//   onChangeFunction,
//   value,
//   clearFilter,
//   ...props
// }) => {
//   return (
//     <div
//       className={`search-box ${className}`}
//       {...props}
//     >
//       <div className="InputFieldIconsMagnifyingglass w-5 h-5 ">{icon}</div>
//       <input
//         type="text"
//         value={value}
//         onChange={(e) => onChangeFunction(e.target.value)}
//         placeholder={placeholder}
//         className="input-search"
//       />
//       {clearFilter && (
//         <button
//           className={` w-5 h-5 ${value == "" && "opacity-0"}`}
//           disabled={value == ""}
//           onClick={clearFilter}
//         >
//           <div className="InputFieldIconsMagnifyingglass w-5 h-5 ">{icon}</div>
//           {/* {
//             <svg
//               xmlns="http://www.w3.org/2000/svg"
//               width="20"
//               height="20"
//               // className="z-auto"
//               viewBox="0 0 20 20"
//               fill="black"
//             >
//               <path
//                 d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z"
//                 fill="#002032"
//                 fill-opacity="0.6"
//               />
//             </svg>
//           } */}
//         </button>
//       )}
//     </div>
//   );
// };

// export default SearchTextInput;


import React, { useState, useEffect } from 'react';

const SearchIconButton = ({ icon,
  placeholder,
  className,
  onChangeFunction,
  value,
  clearFilter,
  ...props}) => {
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [searchText, setSearchText] = useState('');

  // const handleSearchIconClick = () => {
  //   setIsSearchVisible(true);
    
  // };

  const handleInputChange = (event) => {
    setSearchText(event.target.value);
    onChangeFunction(event.target.value);

  };

  const handleClearClick = () => {
    setSearchText('');
    
  };

  const handleOutsideClick = (event) => {
    if (!event.target.closest('.search-container')) {
      setIsSearchVisible(false);
    }
  };
   

    const close = () => {
        setIsSearchVisible(!isSearchVisible);
      };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    document.addEventListener('click', handleClearClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={`relative search-container ${className}` }
    {...props}
    >
      <button
        className="absolute left-1 ml-[-3rem] h-11 border border-gray-900 border-opacity-10 top-0 transform translate-x-1/2 -translate-y-1/2 bg-white w-11 h-11 rounded-xl focus:outline-none"
        onClick={close}
        
      >
        <div className="InputFieldIconsMagnifyingglass w-5 h-5 ml-[0.7rem] ">{icon}</div>
      </button>
      {isSearchVisible && (
        <div className=" ml-[1.5rem] left-5 transform -translate-y-1/2 bg-white p-2 rounded-lg overflow-hidden">
          <input
            type="text"
            value={searchText}
            onChange={handleInputChange}
            placeholder="Search..."
            className=" rounded-xl p-1 pr-2 focus:outline-none"
          />
          {clearFilter && (
            <button
              className="absolute mt-[0.2rem] ml-[-1.7rem] text-gray-600 focus:outline-none text-[20px]"
              onClick={clearFilter}
            >
             <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              // className="z-auto"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM12.9422 12.0578C13.0003 12.1159 13.0463 12.1848 13.0777 12.2607C13.1092 12.3366 13.1254 12.4179 13.1254 12.5C13.1254 12.5821 13.1092 12.6634 13.0777 12.7393C13.0463 12.8152 13.0003 12.8841 12.9422 12.9422C12.8841 13.0003 12.8152 13.0463 12.7393 13.0777C12.6634 13.1092 12.5821 13.1253 12.5 13.1253C12.4179 13.1253 12.3366 13.1092 12.2607 13.0777C12.1848 13.0463 12.1159 13.0003 12.0578 12.9422L10 10.8836L7.94219 12.9422C7.88412 13.0003 7.81518 13.0463 7.73931 13.0777C7.66344 13.1092 7.58213 13.1253 7.5 13.1253C7.41788 13.1253 7.33656 13.1092 7.26069 13.0777C7.18482 13.0463 7.11588 13.0003 7.05782 12.9422C6.99975 12.8841 6.95368 12.8152 6.92226 12.7393C6.89083 12.6634 6.87466 12.5821 6.87466 12.5C6.87466 12.4179 6.89083 12.3366 6.92226 12.2607C6.95368 12.1848 6.99975 12.1159 7.05782 12.0578L9.11641 10L7.05782 7.94219C6.94054 7.82491 6.87466 7.66585 6.87466 7.5C6.87466 7.33415 6.94054 7.17509 7.05782 7.05781C7.17509 6.94054 7.33415 6.87465 7.5 6.87465C7.66586 6.87465 7.82492 6.94054 7.94219 7.05781L10 9.11641L12.0578 7.05781C12.1159 6.99974 12.1848 6.95368 12.2607 6.92225C12.3366 6.89083 12.4179 6.87465 12.5 6.87465C12.5821 6.87465 12.6634 6.89083 12.7393 6.92225C12.8152 6.95368 12.8841 6.99974 12.9422 7.05781C13.0003 7.11588 13.0463 7.18482 13.0777 7.26069C13.1092 7.33656 13.1254 7.41788 13.1254 7.5C13.1254 7.58212 13.1092 7.66344 13.0777 7.73931C13.0463 7.81518 13.0003 7.88412 12.9422 7.94219L10.8836 10L12.9422 12.0578Z"
                fill="#002032"
                fill-opacity="0.6"
              />
            </svg>
              
              
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default SearchIconButton;


