import React from 'react'
import { Link } from 'react-router-dom'
import TextInput from '../../components/inputfields/UI_Inputs/textInput'
import InputFieldComp from '../../components/inputfields/UI_Inputs/InputFieldComp'
import ButtonChild from '../../components/buttons/UI_Buttons_2/buttonChild'
import FillButtonV2 from '../../components/buttons/UI_Buttons_2/fillButton'
import Title from '../../components/title/title'

const ResetPassword = () => {
  return (
    <div>
         <div
        className="flex flex-col w-screen overflow-auto no-scrollbar gradient sm:py-[64px] min-h-screen
                   xl:flex-row xl:h-[100vh] xl:p-0 xl:m-0 ">
        <div className="relative Graphics max-w-full xl:min-w-[63%]  xl:h-[100vh] mb-16 xl:mb-0">
          <img
            className="absolute Gfx xl:w-full xl:h-full 2xl:w-full 2xl:h-full 
            xl1:w-full xl1:h-full left-0 top-0 bg-fixed bg-cover h-full"
            src="/gfx.png"
          />
          <Title />
        </div>
        <div className="form p-0 m-0 w-100% flex flex-nowrap h-auto sm:m-16 overflow-scroll no-scrollbar
        md:mx-[97px] md:mt-[32px] lg:mx-[192px] lg:mt-[64px] xl:m-0 xl:w-[105vh] ">

          <div className="relative bg-white mt-[161px] border-none shadow-none w-full h-max xl:mt-0 p-[32px] 
          sm:border sm:border-white sm:p-spaceSLarge sm:self-stretch sm:rounded-[28px] sm:shadow-white-xl
          md:py-[64px] md:w-full lg:w-full h-fit  xl:h-full 2xl:h-auto sm:mt-[209px] md:mt-[230px] lg:mt-[211px] xl:border-none xl:shadow-none xl:rounded-none xl:m-0
          xl:w-full xl:p-[64px]">
            <div className="flex flex-col gap-spaceBase mb-spaceSLarge">
            <div>
            <Link
              to="signIn"
            >
              <ButtonChild
                text="Back to Sign In"
                textColor="green"
                leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                <path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="currentColor"/>
              </svg>}
              />
            </Link>
            </div>
            <div className="w-max text-gray-900 xl1:text-[32px] text-[27px] font-black font-satoshi leading-[38.40px]">
              
              Reset Password
              </div>
           
           
           </div>
            <div className="flex flex-col h-auto gap-spaceBase">
             
              <div>
              <InputFieldComp
                  labelText="New Password"
                  type="password"
                  name="password"
                  placeholder="••••••••"
                  rightIcon={
                    <ButtonChild
                      textColor="gray"
                      rightIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M19.3211 9.74688C19.2937 9.68516 18.632 8.21719 17.1609 6.74609C15.2008 4.78594 12.725 3.75 9.99999 3.75C7.27499 3.75 4.79921 4.78594 2.83905 6.74609C1.36796 8.21719 0.703118 9.6875 0.678899 9.74688C0.643362 9.82681 0.625 9.91331 0.625 10.0008C0.625 10.0883 0.643362 10.1748 0.678899 10.2547C0.706243 10.3164 1.36796 11.7836 2.83905 13.2547C4.79921 15.2141 7.27499 16.25 9.99999 16.25C12.725 16.25 15.2008 15.2141 17.1609 13.2547C18.632 11.7836 19.2937 10.3164 19.3211 10.2547C19.3566 10.1748 19.375 10.0883 19.375 10.0008C19.375 9.91331 19.3566 9.82681 19.3211 9.74688ZM9.99999 13.125C9.38193 13.125 8.77774 12.9417 8.26383 12.5983C7.74993 12.255 7.34939 11.7669 7.11287 11.1959C6.87634 10.6249 6.81446 9.99653 6.93504 9.39034C7.05562 8.78415 7.35324 8.22733 7.79028 7.79029C8.22732 7.35325 8.78414 7.05562 9.39033 6.93505C9.99652 6.81447 10.6249 6.87635 11.1959 7.11288C11.7669 7.3494 12.255 7.74994 12.5983 8.26384C12.9417 8.77775 13.125 9.38193 13.125 10C13.125 10.8288 12.7958 11.6237 12.2097 12.2097C11.6236 12.7958 10.8288 13.125 9.99999 13.125Z"
                          fill="currentColor" />
                      </svg>}
                    />
                  }
                />
              </div>


              
            </div>

            <FillButtonV2 
className={`px-spaceLarge mt-spaceXxLarge`} 
text="Complete Reset"
textColor="white"
color="green"

/>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword