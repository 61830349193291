import React, { useState, useEffect } from "react";
import axios from "axios";

const Transactions = () => {
  const [transactionData, setTransactionData] = useState([]);
  const auth = localStorage.getItem("auth");
  const isPremium = localStorage.getItem("isPremium");
  console.log(isPremium);

  const planId = localStorage.getItem("planId");

  const transactionDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_baseURL}/recruiter/payment/transactions`,
        {
          headers: {
            Authorization: auth,
          },
        }
      );

      console.log(response.data);
      setTransactionData(response.data);
    } catch (error) {
      console.log(error);
      // Handle the error as needed
    }
  };

  useEffect(() => {
    // Call the transactionDetails function when the component mounts
    transactionDetails();
  }, [auth]);

  const [firstTransactionDate, setFirstTransactionDate] = useState(null);
  const [plan, setPlan] = useState("");

  useEffect(() => {
    // Check if there is any transaction data
    if (transactionData?.transcations?.length > 0) {
      // Extract the date of the first transaction
      const firstTransaction = transactionData.transcations[0];
      const planDetails = firstTransaction.planName;
      console.log(planDetails);
      setPlan(planDetails);
      const firstTransactionDate = new Date(
        firstTransaction.updatedAt
      ).toLocaleDateString("en-IN", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });

      // Set the first transaction date in state
      setFirstTransactionDate(firstTransactionDate);
    }
  }, [transactionData]);
  return (
    <div>
      <div className="Menu w-full border-l border-gray-900 border-opacity-10 sm:w-[75vw] xl:w-[50vw] sm:pt-[3rem] sm:m-0 mb-8 overflow-scroll no-scrollbar grow shrink basis-0 self-stretch p-4 py-8 md:px-spaceSLarge sm:py-spaceLarge bg-white flex-col justify-start items-start inline-flex">
        <div className="w-full h-auto flex-col justify-start items-start gap-16 inline-flex">
          <div className="text-teal-950 text-[32px] font-black font-satoshi leading-[38.40px]">
            Transactions
          </div>
          {isPremium === "true" ? (
            <div className="w-full justify-start items-start gap-2.5 inline-flex">
              <div className="w-full flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="w-full text-teal-950 text-2xl font-bold font-satoshi leading-[28.80px]">
                  Pro Plan Subscription
                </div>
                {plan ?
                <div className="text-slate-500 text-base font-semibold font-satoshi leading-tight">
                  Your{" "}
                  {plan === "pro_plan_yearly"
                    ? "yearly"
                    : plan === "pro_plan_monthly"
                    ? "monthly"
                    : plan === "pro_plan_45days"
                    ? "45 Days"
                    : plan === "pro_plan_quarterly"
                    ? "quarterly"
                    : ""}{" "}
                  plan renewed on {firstTransactionDate} which is valid for{" "}
                  {plan === "pro_plan_monthly"
                    ? "30"
                    : plan === "pro_plan_yearly"
                    ? "365"
                    : plan === "pro_plan_quarterly"
                    ? "90"
                    : plan === "pro_plan_45days"
                    ? "45"
                    : ""}{" "}
                  days
                </div>
                :
                <div className="text-slate-500 text-base font-semibold font-satoshi leading-tight">
                  Your subscription plan details will be displayed here shortly.
                </div>
}

              </div>
            </div>
          ) : (
            <div className="w-full justify-start items-start gap-2.5 inline-flex">
              <div className="w-full flex-col justify-start items-start gap-2.5 inline-flex">
                <div className="w-full text-teal-950 text-2xl font-bold font-satoshi leading-[28.80px]">
                  No Subscription Added.
                </div>
                {/* <div className="text-slate-500 text-base font-semibold font-satoshi leading-tight">
          Your monthly plan renews on 10 Feb 2024
        </div> */}
              </div>
            </div>
          )}

          {(isPremium === "true") && (
            <div className="w-full gap-1 self-stretch  flex-col justify-start items-start gap-[15px] flex overflow-scroll no-scrollbar">
              <div className="self-stretch text-teal-950 text-base font-bold font-satoshi leading-tight">
                Invoice History
              </div>
              <div className=" sx:w-full h-auto bg-white rounded-tl-xl rounded-tr-xl border-2 border-gray-200 flex-col justify-start items-start">
                <div className="w-full self-stretch bg-gray-200 justify-start items-center inline-flex overflow-scroll no-scrollbar">
                  <div className="w-full h-[84px] px-3 py-[22px] justify-start items-center gap-2.5 flex">
                    <div className="text-slate-500 text-sm font-bold font-satoshi capitalize leading-tight">
                      Date
                    </div>
                  </div>
                  <div className="w-full h-[84px] px-3 py-8 justify-start items-center gap-2.5 flex">
                    <div className="text-slate-500 text-sm font-bold font-satoshi capitalize leading-tight">
                      Description
                    </div>
                  </div>
                  <div className="w-full h-[84px] px-3 py-8 justify-center items-center gap-2.5 flex">
                    <div className="text-slate-500 text-sm font-bold font-satoshi capitalize leading-tight">
                      Txn ID
                    </div>
                  </div>
                  <div className="w-full h-[84px] px-3 py-8 justify-start items-center gap-2.5 flex">
                    <div className="text-slate-500 text-sm font-bold font-satoshi capitalize leading-tight">
                      Amount
                    </div>
                  </div>
                </div>

                <div className="max-h-[500px] overflow-scroll no-scrollbar">
                  {transactionData?.transcations?.map((trans) => (
                    <div
                      key={trans.id}
                      className="w-full self-stretch flex-col justify-start items-start flex"
                    >
                      <div className="w-full self-stretch h-[84px] flex-col justify-start  items-start gap-2.5 flex">
                        <div className="w-full justify-start items-start inline-flex">
                          <div className="w-full h-[84px] px-3 py-[22px] justify-start items-center gap-2.5 flex">
                            <div className="text-teal-950 text-sm font-medium font-satoshi leading-tight">
                              {new Date(trans.updatedAt).toLocaleDateString(
                                "en-IN",
                                { day: "numeric", month: "short" }
                              )}
                              <br />
                              {new Date(trans.updatedAt).toLocaleDateString(
                                "en-IN",
                                { year: "numeric" }
                              )}
                            </div>
                          </div>
                          <div className="w-full h-[84px] px-3 py-8 justify-start items-center gap-2.5 flex">
                            <div className="text-teal-950 text-sm font-medium font-satoshi capitalize leading-tight">
                              {trans.planName}
                            </div>
                          </div>
                          <div className="w-full h-[84px] px-3 py-8 justify-center items-center gap-2.5 flex">
                            <div className="text-teal-950 text-sm font-medium font-satoshi capitalize leading-tight">
                              {trans.paymentId ? trans.paymentId : "-"}
                            </div>
                          </div>
                          <div className="w-full h-[84px] px-3 py-8 justify-start items-center gap-2.5 flex">
                            <div className="text-teal-950 text-sm font-medium font-satoshi capitalize leading-tight">
                              ₹{trans.amount}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full grow self-stretch h-[0px] border-2 border-gray-200"></div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Transactions;
