import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Alert from "../../components/alert/alert";
import { Mixpanel } from "../../services/mixpanel";
import moment from "moment";
import { isMobile, browserName, isMobileOnly } from "react-device-detect";
import Title from "../../components/title/title";
import "../../index.css"
import ButtonChild from "../../components/buttons/UI_Buttons_2/buttonChild";
import FillButtonV2 from "../../components/buttons/UI_Buttons_2/fillButton";
import BorderButton from "../../components/buttons/UI_Buttons_2/borderButton";
import InfoBox from "../../components/inputfields/UI_Inputs/infoBox";
import TextInput from "../../components/inputfields/UI_Inputs/textInput";
import autoTable from "jspdf-autotable";
import Loader from "../../components/Loader/loader";

export function VerifyOTP(props) {
  const navigator = useNavigate();
  const [form, setForm] = useState({ mobileOTP: "", emailOTP: "" });
  //   const [showButton, setShowButton] = React.useState(false);
  const [counter, setCounter] = useState(30);
  const [counter1, setCounter1] = useState(30);
  const [timeoutId, setTimeoutid] = useState(null);
  const [timeoutId1, setTimeoutid1] = useState(null);
  const [isValidEmailOTP, setValidEmailOTP] = useState(null);
  const [isValidMobileOTP, setValidMobileOTP] = useState(null);
  const [alertData, setAlertData] = useState(null);
  const [loadData, setLoadData] = useState(null);

  // const { email, phoneNumber, emailVerified} = props;
  const [formData, setFormData] = useState({ 
    email: "",
     phoneNumber: "" });

 const location = useLocation();
 const authToken = localStorage.getItem("auth")
 console.log(authToken)
 const [emailVerified , setEmailVerified] = useState(false)
 // Add a state variable to track the loading state
const [isEmailLoading, setIsEmailLoading] = useState(false);


const [isMobLoading, setIsMobLoading] = useState(false);

//   // const navigator = useNavigate();



  const onFormChange = async (e) => {
    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
    // await test();
  };

  useEffect(() => {
    // Get user data from the location state
    
    const authToken = location.state?.authToken;
  console.log(authToken)
  
    
      if (authToken) {
        // If user data is not available in the location state, you can make an API request here
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
          },
        };
  
        // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint to fetch user data
        axios
          .get(`${process.env.REACT_APP_baseURL}/recruiter/verify`, config)
          .then((response) => {
            // Handle the API response and populate the form fields
            
            console.log(response)
            const userDataFromApi = response.data;
            const googleLogin = response.data.google_SSO_Id;
            setEmailVerified(googleLogin);
            setFormData((prevForm) => ({
              ...prevForm,
              // logo: userDataFromApi.logo || null,
              // name: userDataFromApi.fullName,
              phoneNumber: userDataFromApi.phoneNumber,
              email: userDataFromApi.email,
              // Populate other fields as needed
            }));
        // const status = response.data.status
        const authToken = response?.data.authToken;
        const userId = response?.data.userId;
        // Redirect to the Create Account page with user data
        localStorage.setItem("userId", userId);
        localStorage.setItem("auth", authToken);
        if(googleLogin){
          setValidEmailOTP("valid");
          bothVerified(true, false);
        }
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            // Handle the error, e.g., redirect to an error page
            // navigator('/error'); // Uncomment if you want to redirect on error
          });
      } 
    
  }, [location.state]);

  console.log(emailVerified)
 
  console.log(localStorage.getItem("userId"))

  const timer = () => {
    setCounter((prev) => {
      console.log("count2", prev);
      if (prev < 1) clearInterval(timeoutId);
      return --prev;
    });
   
  };

  const timer1 = () => {
    setCounter1((prev) => {
      console.log("count1", prev);
      if (prev < 1) {
        clearInterval(timeoutId1);
        setTimeoutid1(null);
      }
      return --prev;
    });
  };

  useEffect(() => {
    setTimeoutid(setInterval(timer, 1000));
    setTimeoutid1(setInterval(timer1, 1000));
    
  }, []);

  const resendEmailOTP = async () => {
    const url = `${process.env.REACT_APP_baseURL
      }/recruiter/email/resend/${localStorage.getItem("userId")}`;
      try {
        await axios.post(url, {});
        Mixpanel.track("Email_OTP_resend", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          recruiter_id: localStorage.getItem("userId"),
          browser: browserName,
          mobile_device: isMobileOnly,
        });
        setCounter(30);
        setTimeoutid(setInterval(timer, 1000));
    
      } catch (error) {
        console.log(error);
        if (error.response && error.response.data.message) {
          setAlertData(error.response.data.message);
        }
      }
    };

    

  const resendPhoneOTP = async () => {
    const url = `${process.env.REACT_APP_baseURL}/recruiter/phone/resend/${localStorage.getItem("userId")}`;
    try {
      await axios.post(url, {});
      Mixpanel.track("Mobile_OTP_resend", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        recruiter_id: localStorage.getItem("userId"),
        browser: browserName,
        mobile_device: isMobileOnly,
      });
      setCounter1(30);
      setTimeoutid(setInterval(timer1, 1000));
  
    } catch (error) {
      console.log(error);
      if (error.response && error.response.data.message) {
        setAlertData(error.response.data.message);
      }
    }
  };

  
  // To clear the interval later (if needed), you can use clearInterval like this:
  // clearInterval(timer1IntervalId);
  

  const verifyEmailOTP = async (e) => {
   

    const tempOTP = e.target.value;

    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    
    if (name === 'otp') {
      // Remove non-digits and keep only the first 10 digits
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 6);
      setForm({ ...form, [name]: sanitizedValue });
    } else {
      setForm({ ...form, [name]: value });
    }

    if (tempOTP.length == 6) {
      setIsEmailLoading(true);
      
      const url = `${process.env.REACT_APP_baseURL
        }/recruiter/verify/email/${localStorage.getItem("userId")}`;
      const response = await axios
        .post(url, { otp: tempOTP }, { params: { otp: tempOTP } })
        .catch((error) => {
          Mixpanel.track("Otp_verificaton_failed", {
            date: moment().format(),
            distinct_id: localStorage.getItem("userId"),
            recruiter_id: localStorage.getItem("userId"),
            browser: browserName,
            mobile_device: isMobileOnly,
          });
          if (
            error.name == "AxiosError" &&
            error.response?.data.code == "INVALID_OTP"
          ){
            setValidEmailOTP("invalid");
            setIsEmailLoading(false);
          } else if(error.response?.data.code == "INVALID_USER"){
            setAlertData("Unable to verify OTP, Please try after some time.")
          }
           
        });
      console.log(response);
      if (response?.data.message == "Email Verified") {
        Mixpanel.track("Email_OTP_verified", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          recruiter_id: localStorage.getItem("userId"),
          browser: browserName,
          mobile_device: isMobileOnly,
        });
        setTimeout(() => {
          setIsEmailLoading(false);
          setValidEmailOTP("valid");
        bothVerified(true, false);
        }, 2000);
       
      }

      if (response?.data) {
        console.log(response?.data)
        setLoadData(response?.data);
      }
      // http://localhost:4001/recruiter/verify/email/649164e25f0c995ca3ad289f?otp=096672
    }
  };

  // const url = `${process.env.REACT_APP_baseURL
  // }/recruiter/verify/phone/${localStorage.getItem("userId")}`
  // console.log(url)
  
  const verifyMobileOTP = async (e) => {
    const tempOTP = e.target.value;

    const { name, value } = e.target;
    setForm((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });

    if (name === 'mobileOTP') {
      // Remove non-digits and keep only the first 10 digits
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 4);
      setForm({ ...form, [name]: sanitizedValue });
    } else {
      setForm({ ...form, [name]: value });
    }

    if (tempOTP.length == 4) {
      setIsMobLoading(true);
      const url = `${process.env.REACT_APP_baseURL
        }/recruiter/verify/phone/${localStorage.getItem("userId")}`;
        console.log(url)
      const response = await axios
        .post(url, {}, { params: { otp: tempOTP } })
        .catch((error) => {
          Mixpanel.track("Otp_verificaton_failed", {
            date: moment().format(),
            distinct_id: localStorage.getItem("userId"),
            recruiter_id: localStorage.getItem("userId"),
            browser: browserName,
            mobile_device: isMobileOnly,
          });
          if (
            error.name == "AxiosError" &&
            error.response?.data.code == "INVALID_OTP"
          ){
            setValidMobileOTP("invalid");
            setIsMobLoading(false);
          } else if(error.response?.data.code == "INVALID_USER"){
            setAlertData("Unable to verify OTP, Please try after some time.")
          }
        });
      console.log(response);
      if (response && response?.data.message == "Phone Verified") {
        Mixpanel.track("Phone_OTP_verified", {
          date: moment().format(),
          distinct_id: localStorage.getItem("userId"),
          recruiter_id: localStorage.getItem("userId"),
          browser: browserName,
          mobile_device: isMobileOnly,
        });
        // setIsEmailLoading(false);
        setTimeout(() => {
          setIsMobLoading(false);
          setValidMobileOTP("valid");
        bothVerified(false, true);
        }, 2000);
       
      } 
      
      // http://localhost:4001/recruiter/verify/email/649164e25f0c995ca3ad289f?otp=096672
    }


  };

  
  console.log(isValidEmailOTP, isValidMobileOTP);
  const bothVerified = (email, phoneNumber) => {
   
    
    console.log("dadta 23232");
    console.log(isValidEmailOTP, isValidMobileOTP);
    if (
      (isValidEmailOTP == "valid" || email) &&
      (isValidMobileOTP == "valid" || phoneNumber)
    ) {


     

      Mixpanel.track("New Account Verified", {
        date: moment().format(),
        email: form.email,
        distinct_id: localStorage.getItem("userId"),
        recruiter_id: localStorage.getItem("userId"),
        browser: browserName,
        mobile_device: isMobile,
      });

      localStorage.setItem("status", "create/verified");
      setTimeout(() => {
       
        navigator("/create/selectcompany", {state: {authToken: authToken}});
      }, 1000);
     
    } else{
      Mixpanel.track("Otp_verificaton_failed", {
        date: moment().format(),
        distinct_id: localStorage.getItem("userId"),
        recruiter_id: localStorage.getItem("userId"),
        browser: browserName,
        mobile_device: isMobileOnly,
      });
    }


  };

  const [Loading, setLoading] = useState(true); // State to track loading

  // Your existing state and useEffect logic...
  
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };
  
    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount
  
  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader />;
  }

  
  return (
    <>

      <div
        className="flex flex-col w-screen overflow-auto no-scrollbar gradient sm:py-[64px] min-h-screen
                   xl:flex-row xl:h-[100vh] xl:p-0 xl:m-0">
        <div className="relative Graphics max-w-full xl:w-full  xl:h-[100vh] mb-16 xl:mb-0">
          <img
            className="absolute Gfx xl:w-full xl:h-full 2xl:w-full 2xl:h-full 
            xl1:w-full xl1:h-full left-0 top-0 bg-fixed bg-cover h-full object-cover"
            src="/gfx.png"
          />
          <Title />
          
        </div>
        <div className="form p-0 m-0 w-100% flex flex-nowrap h-auto sm:m-16 overflow-scroll no-scrollbar
        md:mx-[97px] md:mt-[32px] lg:mx-[192px] lg:mt-[64px] xl:m-0 xl:w-[105vh] ">

          <div className="relative bg-white mt-[161px] border-none shadow-none w-full h-max xl:mt-0 p-[32px] 
          sm:border sm:border-white sm:p-spaceSLarge sm:self-stretch sm:rounded-[28px] sm:shadow-white-xl
          md:py-[64px] md:w-full lg:w-full h-fit  xl:h-full 2xl:h-auto sm:mt-[209px] md:mt-[230px] lg:mt-[211px] xl:border-none xl:shadow-none xl:rounded-none xl:m-0
          xl:w-full xl:p-[64px]">
            <div className="flex flex-col gap-spaceBase mb-spaceSLarge">
              {/* {emailVerified ? ( */}
                <div>
                <Link
                  to="/signin"
                >
                  <ButtonChild
                    text="Back to signIn"
                    textColor="green"
                    leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                    <path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="currentColor"/>
                  </svg>}
                  />
                </Link>
                </div>
              {/* ):( */}
{/* <div>
            <Link
              to="/create/account"
            >
              <ButtonChild
                text="Back to Beep Profile"
                textColor="green"
                leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                <path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="currentColor"/>
              </svg>}
              />
            </Link>
            </div>
              )} */}
             {!emailVerified ? 
            <div className="w-auto text-gray-900 2xl:text-[32px] text-[27px] font-black font-satoshi leading-[38.40px]">
              
              Secure Your Account
              </div>
              :
              <div className="w-auto text-gray-900 2xl:text-[32px] text-[27px] font-black font-satoshi leading-[38.40px]">
              
              Verify Your Mobile
              </div>
}
            {/* <h2 className="text-xl font-bold">Verify OTP</h2> */}
            {/* <div className="w-[432px] text-teal-950 text-base font-bold leading-normal">Verify OTP</div> */}
           <div>
            <InfoBox 
            infoClass={`px-spaceBase py-spaceSmall`}
            variant="standard"
            infoText={
            <div className="">
               {!emailVerified ? 
               <div>
              <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight">A unique OTPs have been sent to:<br/></span>
             
              <div>
              <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight"> &nbsp; &#8226; your Email </span>
              <span className="text-green-500 text-sm font-medium font-satoshi leading-tight">{formData.email ? formData.email.substring(0, 5) + "....@gmail.com" : 'email...@gmail.com'}<br/></span>
           
            </div>
            </div>
            :
            <div>
              <span className="text-teal-950 text-sm font-medium font-satoshi leading-tight">An OTP have been sent to <span className="text-green-500">{formData.phoneNumber ? formData.phoneNumber : '+91 98765 43210' } </span><br/></span>
            </div>
            }
            </div>
              }

              buttonLeftIcon={<div onClick={()=>navigator('/create/account')}>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M17.7586 5.73203L14.268 2.24062C14.1519 2.12452 14.0141 2.03242 13.8624 1.96958C13.7107 1.90675 13.5482 1.8744 13.384 1.8744C13.2198 1.8744 13.0572 1.90675 12.9056 1.96958C12.7539 2.03242 12.6161 2.12452 12.5 2.24062L2.86641 11.875C2.74983 11.9907 2.65741 12.1283 2.59451 12.28C2.5316 12.4317 2.49948 12.5944 2.50001 12.7586V16.25C2.50001 16.5815 2.6317 16.8995 2.86612 17.1339C3.10054 17.3683 3.41849 17.5 3.75001 17.5H7.24141C7.40563 17.5005 7.5683 17.4684 7.71999 17.4055C7.87168 17.3426 8.00935 17.2502 8.12501 17.1336L17.7586 7.5C17.8747 7.38392 17.9668 7.24611 18.0296 7.09443C18.0925 6.94276 18.1248 6.78019 18.1248 6.61601C18.1248 6.45184 18.0925 6.28927 18.0296 6.13759C17.9668 5.98592 17.8747 5.84811 17.7586 5.73203ZM15 8.49062L11.5086 5L13.3836 3.125L16.875 6.61562L15 8.49062Z" fill="#16C083"/>
            </svg> 
            </div>}
            />
           </div>
           </div>
            <div className="flex flex-col h-auto gap-spaceLarge">
              {!emailVerified &&
              <div>
                
              <TextInput 
              htmlFor='emailOTP'
              labelText="Enter OTP sent on Email"
              textColor="gray"
              textType="input"
               type="text"
               placeClass={'tracking-[10px]'}
              placeholder="000000"
              name='emailOTP'
              value={form.emailOTP}
              onChange={verifyEmailOTP}
              rightText={<div>
                {isValidEmailOTP == "valid" && (
                  <ButtonChild 
                  textColor="green"
                  text="Verified"
                  // leftIcon={<div></div>}
                  // isLoading={isLoading}
                  />
                )}


                </div>
              }
              rightIcon={
                <div>
                  {!isEmailLoading ?
                  <div>
                  {isValidEmailOTP !== "valid" && (
                <div onClick={resendEmailOTP}>
                  {counter < 1 ? (
              <ButtonChild
              text="Resend OTP"
              textColor="green"
              />
                  ) : (
                    <div className=" w-max font-bold text-gray-300 text-sm">
                      {" "}
                      Resend OTP in <span className="p-1 w-4">{counter}</span>
                    </div>
                  )}
                </div>
              )}
              </div>
              : 
              <ButtonChild 
              textColor="green"
              leftIcon={<div></div>}
              isLoading={isEmailLoading}
              />
              }
                  </div>
              }
              />
              <div className="flex justify-end items-end">
  {isValidEmailOTP == "invalid" && (
              <ButtonChild 
              textColor="red"
            
                text="Invalid OTP"
             
              />
              )}
              </div>
              </div>
}
              <div>
              <TextInput 
              placeClass={'tracking-[10px]'}
              labelText="Enter OTP sent on Mobile"
              textColor="gray"
              textType="input"
              type="text"
              htmlFor='mobileOTP'
              name='mobileOTP'
              placeholder="0000"
             
              value={form.mobileOTP}
              onChange={verifyMobileOTP}
              rightText={<div>
                {isValidMobileOTP == "valid" && (
                  <ButtonChild 
                  textColor="green"
                  text="Verified"
                  // isLoading={isLoading}
                  />
                )}
                </div>
              }
             
               rightIcon={
                <div>
                  {!isMobLoading ?
                  <div>
                  {isValidMobileOTP !== "valid" && (
                <div onClick={resendPhoneOTP}>
                  {counter1 < 1 ? (
              <ButtonChild
              text="Resend OTP"
              textColor="green"
              />
                  ) : (
                    <div className=" w-max font-bold text-gray-300 text-sm">
                      {" "}
                      Resend OTP in <span className="p-1 w-4">{counter1}</span>
                    </div>
                  )}
                </div>
              )}
              </div>
              : 
              <ButtonChild 
              textColor="green"
              leftIcon={<div></div>}
              isLoading={isMobLoading}
              />
              }
                  </div>
              }
              />

<div className="flex justify-end items-end">
  {isValidMobileOTP == "invalid" && (
              <ButtonChild 
              textColor="red"
            
                text="Invalid OTP"
             
              />
              )}
              </div>
              </div>    
            </div>

          </div>
        </div>
        {alertData && (
          <Alert
            alertClose={() => setAlertData(null)}
            time={3}
            text={alertData}
          />
        )}
      </div>

    </>
  );
}



