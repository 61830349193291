import axios from "axios";
import * as React from "react";
import '../auth/login.css'
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { Mixpanel } from "../../services/mixpanel";
import moment from "moment";
import {isMobileOnly, browserName, isMobile } from "react-device-detect";
import Alert from "../../components/alert/alert";
import Title from "../../components/title/title";
import "../../index.css"
import FillButtonV2 from "../../components/buttons/UI_Buttons_2/fillButton";
import ButtonChild from "../../components/buttons/UI_Buttons_2/buttonChild";
import InfoBox from "../../components/inputfields/UI_Inputs/infoBox";
import TextInput from "../../components/inputfields/UI_Inputs/textInput";
import DropDownButton from "../../components/buttons/UI_Buttons_2/dropDownButton";
import Loader from "../../components/Loader/loader";


export function SelectCompany({ leftIcon, ...props }) {

  const [alertData, setAlertData] = React.useState(null);
  const closeAlert = () => setAlertData(null);
  const [companyData, setCompanyData] = useState('')
  const [userData, setUserData] = useState('')
  const [sentStatus, setSentStatus] = useState('')
  const [status, setStatus] = useState('')

  const [requestStatus, setRequestStatus] = useState('')
  const [requestData, setRequestData] = useState('')

  const navigator = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const menuRef = useRef(null);

  const location = useLocation();
  const authToken = localStorage.getItem("auth") || location.state?.authToken;
  console.log(authToken)
  const [error, setError] = useState('')

  const [companies, setCompanies] = useState([]); // To store company data
  // const [selectedCompanyId, setSelectedCompanyId] = useState(''); // To store the selected company ID



  useEffect(() => {
    // Get user data from the location state
    const authToken = localStorage.getItem("auth") || location.state?.authToken;
    // const authToken = location.state?.authToken;
    console.log(authToken)
    if (authToken) {
      // If user data is not available in the location state, you can make an API request here
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
        },
      };
      axios
        .get(`${process.env.REACT_APP_baseURL}/recruiter/myinvite`, config)
        .then((response) => {

          console.log(response.data.invitation.companyData)
          setCompanyData(response.data.invitation.companyData)

        })
        .catch((error) => {
          console.error('Error fetching user data:', error);

        });
    }

  }, []);

  useEffect(() => {
    // Get user data from the location state
    const authToken = localStorage.getItem("auth")
    // const authToken = location.state?.authToken;
    console.log(authToken)
    if (authToken) {
      // If user data is not available in the location state, you can make an API request here
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
        },
      };
      axios
        .get(`${process.env.REACT_APP_baseURL}/recruiter/verify`, config)
        .then((response) => {
          console.log(response.data, "enter beep")
          setUserData(response.data)
          console.log(response.data?.requestData)


          
            const requestData = response.data?.requestData ? response.data?.requestData[0].company_Info[0] : null;
            console.log(requestData?.name, "enter beep2");
            setRequestData(requestData)
            // console.log('Emails:', emails);
            // setInvitedRecruit(response.data.data);
          

          // const requestData = response.data?.requestData?.map(index =>{index})
          // console.log(requestData);
          // setRequestData(response.data?.requestData)
          setRequestStatus(response?.data.status);
          const status = response?.data.status
          // const userStatus =  response?.data.status
          console.log(status,'outside')
          if (status === 50) {
            // localStorage.setItem("auth", response?.data.authToken);

            console.log('status 50')
            navigator("/");
            
          } else if (status === 31) {
            setSentStatus(true);
          }

        })
        .catch((error) => {
          console.error('Error fetching user data:', error);

        });

    }
  }, [location.state]);



  const fetchData = async (name) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_baseURL}/company/search`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
          params: {
            companyName: name,
          }
        }
      );
      console.log(response.data)

      if (Array.isArray(response.data.company)) {
        const companyInfo = response.data.company.map(item => ({
          id: item._id,
          name: item.name,
        }));

         // Filter companies that start with the first letter of the query
    const filtered = companyInfo.filter((company) =>
      company.name.toLowerCase().startsWith(name.toLowerCase())
    );
    setFilteredCompanies(filtered);

        // setCompanies(companyInfo);
        console.log(companyInfo)

      } else {
        console.error('Invalid data structure in the response.');
      }

    } catch (error) {
      setError('failed to fetch data');
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [])
  const onSubmit = async () => {
    try {
      const authToken = localStorage.getItem('auth');

      // Make an API request to send an email invitation with the token in the headers
      const response = await axios.post(
        `${process.env.REACT_APP_baseURL}/company/raiseverification/${selectedCompanyId ? selectedCompanyId : companyData._id}`,
        {
          name: companyData.name,
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
          },
        }
      );
      if (response.data) {
        Mixpanel.track("company_requested", {
          date: moment().format(),
          // email: email,
          // phoneNumber: form.phoneNumber,
          companyName:companyData.name,
          distinct_id: localStorage.getItem("userId"),
          recruiter_id: localStorage.getItem("userId"),
          browser: browserName,
          mobile_device: isMobileOnly,
        });
        console.log(response.data)
        console.log(response.data.data?.status)
        const status = response.data.data?.status
        // setSentStatus(response.data.success)
        setStatus(response.data.data?.status)


        if (status === 50 || status === 5) {
          // localStorage.setItem("auth", response?.data.authToken);
          navigator("/");
        } else {
          setSentStatus(response.data?.success)
        }

      } else {
        setError('Failed to make raise varification. Please try again.');

      }

      // if(companyData._id){
      //   navigator('/job/dashboard')
      // }
    } catch (error) {
      console.error('Error sending verification:', error);
      setError('Error sending verifiaction. Please try again later.');
    }
  }
  console.log(sentStatus)

  const [isOpen, setIsOpen] = useState(false); // State for dropdown open/close
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false)

    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  // const [selectedCompanyId, setSelectedCompanyId] = useState(companyData?._id);

  const [selectedCompanyId, setSelectedCompanyId] = useState(companyData ? companyData._id : "");
  const [selectedCompanyName, setSelectedCompanyName] = useState(companyData ? companyData.name : "");

  const [filteredCompanies, setFilteredCompanies] = useState([]);



  const handleOptionClick = (company) => {
    Mixpanel.track("select_company_name", {
      date: moment().format(),
      companyName:company ,
      distinct_id: localStorage.getItem("userId"),
      recruiter_id: localStorage.getItem("userId"),
      browser: browserName,
      mobile_device: isMobile,
    });
    setSelectedCompanyId(company.id);
    setSelectedCompanyName(company.name);
    setSearchQuery("");
    setIsOpen(false);
    // Pass the selected company ID to another state or function.
  };


  const handleCompanySelect = (event) => {
    const query = event.target;
    setSearchQuery(query.value);
   fetchData(query.value)
    setIsOpen(true); // Open the dropdown when typing
  };


  const remove = () => {
    setSelectedCompanyId("");
    setSelectedCompanyName("");
  }

  const handleInputClick = () => {
    setIsOpen(true); // Open the dropdown when clicking on the input
    setSearchQuery("");
  };

  const [Loading, setLoading] = useState(true); // State to track loading

  // Your existing state and useEffect logic...
  
  useEffect(() => {
    // Simulating an asynchronous action
    const fakeAsyncAction = async () => {
      try {
        // Simulate an API call or any asynchronous action
        await new Promise((resolve) => setTimeout(resolve, 1000));
      } finally {
        // Set isLoading to false once the asynchronous action is done
        setLoading(false);
      }
    };
  
    // Call the fakeAsyncAction when the component mounts
    fakeAsyncAction();
  }, []); // Empty dependency array means this effect runs once on mount
  
  // Render the loader if isLoading is true
  if (Loading) {
    return <Loader/>;
  }

  const toggleIcon = isOpen ? ((leftIcon && text) ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z"
      fill="currentColor" />
  </svg>) : leftIcon ? '' : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M16.8273 12.7391C16.7801 12.8533 16.7 12.9509 16.5972 13.0196C16.4944 13.0883 16.3736 13.125 16.25 13.125H3.75C3.62632 13.1251 3.50539 13.0885 3.40252 13.0198C3.29964 12.9512 3.21946 12.8535 3.17211 12.7392C3.12477 12.625 3.11239 12.4992 3.13655 12.3779C3.1607 12.2566 3.22031 12.1452 3.30782 12.0578L9.55782 5.80782C9.61586 5.74971 9.68479 5.70361 9.76067 5.67215C9.83654 5.6407 9.91787 5.62451 10 5.62451C10.0821 5.62451 10.1635 5.6407 10.2393 5.67215C10.3152 5.70361 10.3841 5.74971 10.4422 5.80782L16.6922 12.0578C16.7796 12.1453 16.8391 12.2567 16.8631 12.3779C16.8872 12.4992 16.8747 12.6249 16.8273 12.7391Z"
      fill="currentColor" />
  </svg>)

  ) : (
    (leftIcon && text) ? (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z"
        fill="currentColor" />
    </svg>) : leftIcon ? '' : (<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M16.6922 7.94219L10.4422 14.1922C10.3841 14.2503 10.3152 14.2964 10.2393 14.3279C10.1635 14.3593 10.0821 14.3755 10 14.3755C9.91787 14.3755 9.83654 14.3593 9.76067 14.3279C9.68479 14.2964 9.61586 14.2503 9.55782 14.1922L3.30782 7.94219C3.22031 7.85478 3.1607 7.74337 3.13655 7.62207C3.11239 7.50076 3.12477 7.37502 3.17211 7.26076C3.21946 7.14649 3.29964 7.04884 3.40252 6.98017C3.50539 6.91151 3.62632 6.8749 3.75 6.875H16.25C16.3737 6.8749 16.4946 6.91151 16.5975 6.98017C16.7004 7.04884 16.7805 7.14649 16.8279 7.26076C16.8752 7.37502 16.8876 7.50076 16.8635 7.62207C16.8393 7.74337 16.7797 7.85478 16.6922 7.94219Z"
        fill="currentColor" />
    </svg>)



  );
  // COMAPANY_SELECTION_PENDING: 30,
  // COMPANY_REQUEST_SENT: 31,
  // COMPANY_REQUST_REJECTED: 32,
  return (
    <>
      <div
        className="flex flex-col w-screen overflow-auto no-scrollbar gradient sm:py-[64px] min-h-screen
                   xl:flex-row xl:h-[100vh] xl:p-0 xl:m-0">

        <div className="relative Graphics max-w-full xl:w-full  xl:h-[100vh] mb-16 xl:mb-0">
          <img
            className="absolute flex Gfx xl:w-full
             left-0 top-0 bg-contain bg-center h-full object-cover"
            src="/gfx.png"
          />
          <Title />
        </div>

        <div
          className="form p-0 m-0 w-100% flex flex-nowrap overflow-scroll no-scrollbar h-auto sm:m-16 
        md:mx-[97px] xl:m-0  xl:w-[105vh] ">
          <div
            className="relative bg-white w-full overflow-scroll no-scrollbar mt-[161px] border-none shadow-none xl:mt-0 p-[32px] 
            sm:border sm:border-white sm:p-spaceSLarge sm:self-stretch sm:rounded-[28px] sm:shadow-white-xl
            md:py-[64px] h-fit  xl:h-full 2xl:h-auto sm:mt-[209px] md:mt-[230px] lg:mt-[211px] xl:border-none xl:shadow-none xl:rounded-none xl:m-0
             xl:p-[64px]
         ">
            <div className="flex flex-col gap-spaceBase mb-spaceSLarge">
              {sentStatus === true ?
                <div>
                  <Link
                    to="/signin"
                  >
                    <ButtonChild
                      className={`px-0`}
                      text="SignOut"
                      textColor="green"
                      leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                        <path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="currentColor" />
                      </svg>}
                    />
                  </Link>
                </div>

                :

                <div>
                  <Link
                    to="/create/account"
                  >
                    <ButtonChild
                      className={`px-0`}
                      text="Edit Beep Profile"
                      textColor="green"
                      leftIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor">
                        <path d="M13.1255 3.75V16.25C13.1256 16.3737 13.089 16.4946 13.0203 16.5975C12.9516 16.7004 12.854 16.7805 12.7397 16.8279C12.6255 16.8752 12.4997 16.8876 12.3784 16.8635C12.2571 16.8393 12.1457 16.7797 12.0583 16.6922L5.8083 10.4422C5.75019 10.3841 5.70409 10.3152 5.67264 10.2393C5.64119 10.1635 5.625 10.0821 5.625 10C5.625 9.91787 5.64119 9.83654 5.67264 9.76067C5.70409 9.68479 5.75019 9.61586 5.8083 9.55782L12.0583 3.30782C12.1457 3.22031 12.2571 3.1607 12.3784 3.13655C12.4997 3.11239 12.6255 3.12477 12.7397 3.17211C12.854 3.21946 12.9516 3.29964 13.0203 3.40252C13.089 3.50539 13.1256 3.62632 13.1255 3.75Z" fill="currentColor" />
                      </svg>}
                    />
                  </Link>
                </div>
              }
              <div
                className="SignInToStartRecruiting m-0 text-gray-900 text-[32px] 
             font-black  font-satoshi leading-loose">
                Select Company
              </div>
              <div className="text-gray-900 text-sm font-medium font-satoshi leading-tight">
                You can add your account to an existing company from the list or create a new company profile.
              </div>
            </div>
            <div className="w-full h-auto">
              <div className="flex flex-col mb-spaceLarge sm:text-base">

                <div className="w-full mb-4">
                  <label className="w-full inline-flex justify-between text-gray-600 text-sm font-medium font-satoshi leading-tight mb-spaceXxxSmall">
                  Select Your Company
                  </label>
                  <div className="relative">
                    <TextInput
                      // disabled={`${status === 0 ? true : false}`}
                      placeClass="font-bold"
                      textColor="gray"
                      textType="input"
                      type="text"
                      onClick={handleInputClick}
                      value={searchQuery ? searchQuery : selectedCompanyName}
                      onChange={handleCompanySelect}
                      placeholder={`${(companyData.name && !requestData?.name) ? companyData.name : (requestData?.name && !companyData.name) ? requestData?.name :"Find by Name"}`}
                      rightIcon={
                        searchQuery
                          ?
                          toggleIcon
                          :
                          selectedCompanyName === ""
                            ?
                            <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7394 18.0777C17.8152 18.0462 17.8842 18.0001 17.9422 17.942C18.0003 17.884 18.0464 17.815 18.0779 17.7391C18.1093 17.6633 18.1255 17.5819 18.1255 17.4998C18.1255 17.4177 18.1093 17.3363 18.0779 17.2605C18.0464 17.1846 18.0003 17.1157 17.9422 17.0576L14.0313 13.1467C15.164 11.7876 15.7291 10.0442 15.6092 8.27906C15.4893 6.51393 14.6935 4.86298 13.3873 3.66962C12.0812 2.47626 10.3653 1.83235 8.59657 1.87183C6.82781 1.91132 5.14236 2.63115 3.89079 3.88161C2.63923 5.13207 1.9179 6.81689 1.87685 8.58561C1.83581 10.3543 2.4782 12.0708 3.67041 13.378C4.86262 14.6851 6.51286 15.4824 8.27788 15.6039C10.0429 15.7254 11.7868 15.1618 13.1469 14.0303L17.0578 17.942C17.1159 18.0001 17.1848 18.0462 17.2607 18.0777C17.3366 18.1091 17.4179 18.1253 17.5 18.1253C17.5822 18.1253 17.6635 18.1091 17.7394 18.0777ZM11.8751 13.4268C10.9501 14.0449 9.86254 14.3748 8.75002 14.3748C7.25869 14.3732 5.82891 13.78 4.77438 12.7255C3.71984 11.6709 3.12668 10.2411 3.12502 8.74981C3.12502 7.63729 3.45492 6.54975 4.07301 5.62473C4.69109 4.6997 5.5696 3.97873 6.59743 3.55299C7.62526 3.12725 8.75626 3.01585 9.84741 3.23289C10.9386 3.44994 11.9408 3.98566 12.7275 4.77233C13.5142 5.55901 14.0499 6.56128 14.2669 7.65243C14.484 8.74357 14.3726 9.87457 13.9468 10.9024C13.5211 11.9302 12.8001 12.8087 11.8751 13.4268Z" fill="#667984" />
                              </svg>
                            </div>
                            :
                            selectedCompanyId
                              ?
                              (
                                <div>
                                  {(selectedCompanyName || companyData.name) ? (
                                    <button onClick={remove}>
                                      <strong>✕</strong>
                                    </button>
                                  ) : ""}
                                </div>
                              ) : ""
                      }
                    />
                    {isOpen && (
                      <ul
                        ref={menuRef}
                        className="absolute w-full h-max max-h-[10rem] overflow-scroll no-scrollbar bg-white border border-gray-100 rounded-xl mt-1"
                      >
                        {
                        // filteredCompanies.length === 0 ? (
                        //   <li>
                        //     <DropDownButton
                        //       textColor="gray"
                        //       color="gray"
                        //       text="Company not found."
                        //     />
                        //   </li>
                        // ) : (
                          filteredCompanies.map((company) => (
                            <li
                              key={company.id}
                              onClick={() => handleOptionClick(company)}
                            >
                              <DropDownButton
                                textColor="gray"
                                color="gray"
                                text={company.name}
                              />
                            </li>
                          ))
                        // )
                        }
                      </ul>
                    )}
                  </div>
                </div>


                {/* <div>
                  {selectedCompanyId && (
          <p>Selected Company ID: {selectedCompanyId}</p>
        )}
                </div> */}


                {/* {navigator("/company/createnewcompany", {authToken} )} */}
                {(filteredCompanies.length == 0 && searchQuery) ? (
                 
                    <InfoBox
                      variant="standard"
                      infoText="Company name not found."
                      buttonText={
                        <div onClick={()=>{
                          Mixpanel.track("Create_company_CTA_Clicked", {
                            date: moment().format(),
                            distinct_id: localStorage.getItem("userId"),
                            recruiter_id: localStorage.getItem("userId"),
                            browser: browserName,
                            mobile_device: isMobile,
                          }),
                          navigator('/company/createnewcompany')
                        }}>

                      <ButtonChild 
                      textColor="green"
                      text="Create"
                      leftIcon={
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                          fill="currentColor" />
                      </svg>
                      }
                      />
                      </div>
                      }
                      // buttonLeftIcon={
                      // <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      //   <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1223 7.84594 17.2654 5.78088 15.7423 4.25773C14.2191 2.73457 12.1541 1.87769 10 1.875ZM13.125 10.625H10.625V13.125C10.625 13.2908 10.5592 13.4497 10.4419 13.5669C10.3247 13.6842 10.1658 13.75 10 13.75C9.83424 13.75 9.67527 13.6842 9.55806 13.5669C9.44085 13.4497 9.375 13.2908 9.375 13.125V10.625H6.875C6.70924 10.625 6.55027 10.5592 6.43306 10.4419C6.31585 10.3247 6.25 10.1658 6.25 10C6.25 9.83424 6.31585 9.67527 6.43306 9.55806C6.55027 9.44085 6.70924 9.375 6.875 9.375H9.375V6.875C9.375 6.70924 9.44085 6.55027 9.55806 6.43306C9.67527 6.31585 9.83424 6.25 10 6.25C10.1658 6.25 10.3247 6.31585 10.4419 6.43306C10.5592 6.55027 10.625 6.70924 10.625 6.875V9.375H13.125C13.2908 9.375 13.4497 9.44085 13.5669 9.55806C13.6842 9.67527 13.75 9.83424 13.75 10C13.75 10.1658 13.6842 10.3247 13.5669 10.4419C13.4497 10.5592 13.2908 10.625 13.125 10.625Z"
                      //     fill="currentColor" />
                      // </svg>}
                    />
                 
                ) : ""}

              </div>
              {(companyData.name && !requestData && sentStatus == false && !selectedCompanyName) &&
                <div>
                  <InfoBox
                    infoClass={`px-spaceBase py-spaceSmall`}
                    variant="standard"
                    infoText={
                      <div>
                        <div className="justify-start items-start gap-2 inline-flex">

                          <div className="text-green-500 mb-2 text-md font-black font-satoshi leading-[20px]">You have been invited by this Company Profile.</div>
                        </div>
                        <div className=" flex flex-wrap w-auto text-black text-sm font-medium font-satoshi leading-tight leading-[20px]">You can continue with this company profile or choose another from the list. If you can’t find your company, you can set up a new profile for your company.</div>
                      </div>
                    }
                  />

                </div>
              }
              {sentStatus === true &&
                <div>
                  <InfoBox
                    infoClass={`px-spaceBase py-spaceSmall`}
                    // className="p-spaceBase"
                    variant="warning"
                    infoText={
                      <div>
                        <div className="justify-start items-start gap-2 inline-flex">
                          <div className="w-5 h-5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                              <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM9.6875 5.625C9.87292 5.625 10.0542 5.67998 10.2084 5.783C10.3625 5.88601 10.4827 6.03243 10.5536 6.20373C10.6246 6.37504 10.6432 6.56354 10.607 6.7454C10.5708 6.92725 10.4815 7.0943 10.3504 7.22541C10.2193 7.35652 10.0523 7.44581 9.8704 7.48199C9.68854 7.51816 9.50004 7.49959 9.32874 7.42864C9.15743 7.35768 9.01101 7.23752 8.908 7.08335C8.80499 6.92918 8.75 6.74792 8.75 6.5625C8.75 6.31386 8.84878 6.0754 9.02459 5.89959C9.20041 5.72377 9.43886 5.625 9.6875 5.625ZM10.625 14.375C10.2935 14.375 9.97554 14.2433 9.74112 14.0089C9.5067 13.7745 9.375 13.4565 9.375 13.125V10C9.20924 10 9.05027 9.93415 8.93306 9.81694C8.81585 9.69973 8.75 9.54076 8.75 9.375C8.75 9.20924 8.81585 9.05027 8.93306 8.93306C9.05027 8.81585 9.20924 8.75 9.375 8.75C9.70652 8.75 10.0245 8.8817 10.2589 9.11612C10.4933 9.35054 10.625 9.66848 10.625 10V13.125C10.7908 13.125 10.9497 13.1908 11.0669 13.3081C11.1842 13.4253 11.25 13.5842 11.25 13.75C11.25 13.9158 11.1842 14.0747 11.0669 14.1919C10.9497 14.3092 10.7908 14.375 10.625 14.375Z" fill="#FFB941" />
                            </svg>
                          </div>
                          <div className="text-teal-950 text-sm font-black font-satoshi leading-tight">Waiting for Verification</div>
                        </div>
                        <div className=" flex flex-wrap w-auto text-black text-sm font-medium font-satoshi leading-tight">We have emailed the Admin of this Company profile to verify you. You can start posting opportunities as soon as your account is verified.</div>
                      </div>
                    }
                  />

                </div>
              }
              
             
              
            </div>
            <div className="xl:absolute xl:bottom-0 mt-spaceSLarge xl:mt-auto w-full">
            {!sentStatus === true &&
                <div >
                  {userData.status !== 31 ?
                    <Link>
                      <FillButtonV2
                        onClick={onSubmit}
                        className={`px-8 xl:mb-spaceSLarge`}

                        text='Continue to Verify'
                        disabled={(companyData.name || selectedCompanyId) ? false : true}
                        color="green"
                        textColor="white"
                      />
                    </Link>
                    :
                    ""
                  }


                </div>
              }
              </div>
          </div>

        </div>

      </div>

      {alertData && <Alert text={alertData} alertClose={closeAlert} time={2} />}
    </>
  );
}
