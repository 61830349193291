import React, { useState, useRef, useEffect } from "react";

const TimeRange = ({selectedTime}) => {
  const [circlePosition, setCirclePosition] = useState(0);
  const intervalMinutes = 30;
  const lineRef = useRef(null);
  const isMouseDown = useRef(false);


  const handleMouseDown = () => {
    isMouseDown.current = true;
  };

  const handleMouseUp = () => {
    isMouseDown.current = false;
  };

  const [isAM, setIsAM] = useState(true);

 

  const handleMouseMove = (e) => {
    if (isMouseDown.current) {
      const rect = lineRef.current.getBoundingClientRect();
      const mouseX = e.clientX - rect.left;
      let newPosition =
        Math.round((mouseX / rect.width) * 100 / (100 / ((24 * 60) / intervalMinutes))) *
        (100 / ((24 * 60) / intervalMinutes));
      newPosition = Math.max(0, Math.min(100, newPosition));
      setCirclePosition(newPosition);
    }
  };

  const handleScroll = () => {
    if (isMouseDown.current) {
      const scrollPosition =
        Math.max(
          0,
          Math.min(
            100,
            (window.scrollY /
              (document.body.scrollHeight - window.innerHeight)) *
              100
          )
        );
      setCirclePosition(scrollPosition);
    }
  };

  const getRoundedTime = (position) => {
    const roundedMinutes =
      Math.round(position / (100 / ((12 * 60) / intervalMinutes))) *
      intervalMinutes;
    return roundedMinutes >= 1440
      ? "00:00"
      : `${Math.floor(roundedMinutes / 60)}:${(roundedMinutes % 60)
          .toString()
          .padStart(2, "00")} ${isAM ? 'AM' : 'PM'}`;
  };

  const handleClick = () => {
   
    setIsAM(!isAM);
    getRoundedTime(circlePosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    // Log the time whenever circlePosition changes
    const time = getRoundedTime(circlePosition);
    console.log(time);
    // Pass the selected time to the parent component
    selectedTime(time);
  }, [circlePosition, selectedTime]);

  return (
    <div>
      <div ref={lineRef} className="w-max h-11 p-1 justify-center items-center gap-1 inline-flex">
        <div className="p-2 rounded-lg justify-start items-start gap-1 inline-flex">
          <div className="w-[16rem] relative">
            <div className="w-[16rem] h-1 bg-gray-300 rounded"></div>
            <div
              className="w-5 h-5 mt-[-10px] absolute bg-white rounded-xl border-4 border-emerald-500 cursor-pointer"
              style={{ left: `${circlePosition}%` }}
            />
          </div>
        </div>

        <div className="p-2 ml-8 bg-white rounded-lg border-2 border-emerald-500 justify-center items-center flex">
      <div
        className="w-max text-center text-emerald-500 text-sm font-black font-['Satoshi Variable'] leading-tight cursor-pointer"
        onClick={handleClick}
      >
        {getRoundedTime(circlePosition)}
      </div>
    </div>
      </div>
    </div>
  );
};

export default TimeRange;
