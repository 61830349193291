import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import BeepNavbar from "../../../components/navbar/navbar";


const PaymentSuccess = () => {

    // const [select, isSelect] = useState('');
    const paymentId = localStorage.getItem('paymentId')
    const navigate = useNavigate();

    useEffect(() => {
        // Check if the session flag is set
  

        const isRedirected = sessionStorage.getItem('isRedirected');
   
        try {
         const elements = document.getElementsByClassName("razorpay-container");
   
         elements[0].parentNode.removeChild(elements[0]);
        } catch (error) {
         
        }
        // If the flag is not set, redirect to another page
        if (!isRedirected) {
          navigate('/'); // You can redirect to any other page
        }
        if(sessionStorage.getItem("failureRefreshPending") == true){
         sessionStorage.removeItem('failureRefreshPending');
         window.location.reload();
        } else {
         sessionStorage.removeItem('isRedirected');
        
        }


      }, []); 

  return <div className="gradient-50 h-[100vh]">
   <BeepNavbar />
  <div className="max-w-[1024px] h-[100vh] m-auto pt-14 flex flex-col justify-between items-center bg-white">
   
  <div className="w-full h-auto my-16 flex flex-col justify-center items-center">
    <div className="w-full  h-auto flex flex-col justify-center items-center gap-6">
        <div className="w-[220px] h-[220px] flex justify-center items-center ">
        <img src="/PmIcon3.svg" alt="PmIcon1" width="256" height="256" className="shadow-blue " />
        <img src="/PmIcon3.svg" alt="PmIcon1" width="256" height="256" className="blur-[50px] ml-[-10rem] " />


        </div>
        <div className=" text-teal-950 text-5xl font-bold font-satoshi leading-[57.60px]">Thank You!</div>
        <div className="w-full  text-center text-slate-700 text-2xl font-medium font-satoshi leading-[28.80px]">Welcome to your Growth Plan.<br/>Your payment has been processed successfully</div>
        <div className="text-center text-gray-400 text-base font-medium font-satoshi leading-tight">Payment ID: {paymentId}</div>
    </div>
    <Link style={{zIndex: 5}} to="/">
    <div className="px-4 py-3 my-6 bg-emerald-500 rounded-xl justify-center items-center gap-2 inline-flex cursor-pointer" style={{zIndex: 5}}> 
        <div className="text-center text-white text-sm font-bold font-satoshi leading-tight">Go to Dashboard</div>
    </div>
    </Link>
    <div className="w-full h-auto flex flex-col justify-center items-center mt-[-6rem]">
    <div className="w-full flex justify-end items-end mb-[-9rem]">
    <img src="/PmIcon1.png" alt="PmIcon1" width="256" height="256" className="" />
  

   
    </div>
    <div className="w-full inline-flex gap-4 justify-center items-center flex-wrap">
    <div className="w-[226px]  h-[210px] px-5 py-10 bg-white rounded-xl shadow border border-gray-300 flex-col justify-start items-center gap-[15px] inline-flex">
    <div className="w-[30px] h-[30px] relative">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <g clip-path="url(#clip0_4951_23305)">
    <path d="M26.25 15.275C26.25 8.4125 20.925 3.75 15 3.75C9.1375 3.75 3.75 8.3125 3.75 15.35C3 15.775 2.5 16.575 2.5 17.5V20C2.5 21.375 3.625 22.5 5 22.5H6.25V14.875C6.25 10.0375 10.1625 6.125 15 6.125C19.8375 6.125 23.75 10.0375 23.75 14.875V23.75H13.75V26.25H23.75C25.125 26.25 26.25 25.125 26.25 23.75V22.225C26.9875 21.8375 27.5 21.075 27.5 20.175V17.3C27.5 16.425 26.9875 15.6625 26.25 15.275Z" fill="#002032"/>
    <path d="M11.25 17.5C11.9404 17.5 12.5 16.9404 12.5 16.25C12.5 15.5596 11.9404 15 11.25 15C10.5596 15 10 15.5596 10 16.25C10 16.9404 10.5596 17.5 11.25 17.5Z" fill="#002032"/>
    <path d="M18.75 17.5C19.4404 17.5 20 16.9404 20 16.25C20 15.5596 19.4404 15 18.75 15C18.0596 15 17.5 15.5596 17.5 16.25C17.5 16.9404 18.0596 17.5 18.75 17.5Z" fill="#002032"/>
    <path d="M22.4991 13.7875C21.8991 10.225 18.7991 7.5 15.0616 7.5C11.2741 7.5 7.19911 10.6375 7.52411 15.5625C10.6116 14.3 12.9366 11.55 13.5991 8.2C15.2366 11.4875 18.5991 13.75 22.4991 13.7875Z" fill="#002032"/>
  </g>
  <defs>
    <clipPath id="clip0_4951_23305">
      <rect width="30" height="30" fill="white"/>
    </clipPath>
  </defs>
</svg>
    </div>
    <div className="text-center text-teal-950 text-base font-medium font-satoshi leading-tight">Priority Support</div>
    <div className="w-[186px] text-center leading-[1px]"><span className="text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Having trouble with the app? </span><span className="text-slate-500 text-sm font-medium font-satoshi underline leading-[16.80px]">Click here</span><span className="text-slate-500 text-sm font-medium font-['Satoshi'] leading-[16.80px]"> to get started with our priority support.</span></div>
</div>
<div className="w-[226px] h-[210px] px-5 py-10 bg-white rounded-xl shadow border border-gray-300 flex-col justify-start items-center gap-[15px] inline-flex">
    <div className="w-[30px] h-[30px] relative">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <g clip-path="url(#clip0_4951_23318)">
    <path d="M13.34 4.49213C13.3502 4.42673 13.9832 4.42673 13.9933 4.49213C14.3623 6.86524 15.146 9.86451 16.8074 11.5259C18.4688 13.1874 21.4681 13.971 23.8412 14.34C23.9066 14.3502 23.9066 14.9832 23.8412 14.9933C21.4681 15.3623 18.4688 16.146 16.8074 17.8074C15.146 19.4688 14.3623 22.4681 13.9933 24.8412C13.9832 24.9066 13.3502 24.9066 13.34 24.8412C12.971 22.4681 12.1874 19.4688 10.5259 17.8074C8.86451 16.146 5.86524 15.3623 3.49213 14.9933C3.42673 14.9832 3.42673 14.3502 3.49213 14.34C5.86524 13.971 8.86451 13.1874 10.5259 11.5259C12.1874 9.86451 12.971 6.86524 13.34 4.49213Z" fill="#002032"/>
    <path d="M24.2244 4.42889C24.2321 4.39185 24.6791 4.39185 24.6867 4.42889C24.8693 5.31507 25.1955 6.28809 25.778 6.87057C26.3605 7.45305 27.3335 7.77929 28.2197 7.96186C28.2567 7.96949 28.2567 8.41648 28.2197 8.42411C27.3335 8.60668 26.3605 8.93292 25.778 9.5154C25.1955 10.0979 24.8693 11.0709 24.6867 11.9571C24.6791 11.9941 24.2321 11.9941 24.2244 11.9571C24.0419 11.0709 23.7156 10.0979 23.1331 9.5154C22.5507 8.93292 21.5776 8.60668 20.6915 8.42411C20.6544 8.41648 20.6544 7.96949 20.6915 7.96186C21.5776 7.77929 22.5507 7.45305 23.1331 6.87057C23.7156 6.28809 24.0419 5.31507 24.2244 4.42889Z" fill="#002032"/>
  </g>
  <defs>
    <clipPath id="clip0_4951_23318">
      <rect width="30" height="30" fill="white"/>
    </clipPath>
  </defs>
</svg>
    </div>
    <div className="text-center text-teal-950 text-base font-medium font-satoshi leading-tight">AI Improvements</div>
    <div className="w-[186px] text-center text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Leverage our AI power for smarter matchmaking and candidate sourcing.</div>
</div>
<div className="w-[226px] h-[210px] px-5 py-10 bg-white rounded-xl shadow border border-gray-300 flex-col justify-start items-center gap-[15px] inline-flex">
    <div className="w-[30px] h-[30px] relative">
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <g clip-path="url(#clip0_4951_23329)">
    <path d="M11.8693 18.5351L11.4775 18.9775C10.6908 19.7641 9.68853 20.2999 8.59738 20.5169C7.50624 20.734 6.37524 20.6226 5.34741 20.1968C4.31957 19.7711 3.44107 19.0501 2.82298 18.1251C2.2049 17.2001 1.875 16.1125 1.875 15C1.875 13.8875 2.2049 12.7999 2.82298 11.8749C3.44107 10.9499 4.31957 10.2289 5.34741 9.80318C6.37524 9.37744 7.50624 9.26604 8.59738 9.48309C9.68853 9.70013 10.6908 10.2359 11.4775 11.0225L18.5225 18.9775C19.3092 19.7641 20.3115 20.2999 21.4026 20.5169C22.4938 20.734 23.6248 20.6226 24.6526 20.1968C25.6804 19.7711 26.5589 19.0501 27.177 18.1251C27.7951 17.2001 28.125 16.1125 28.125 15C28.125 13.8875 27.7951 12.7999 27.177 11.8749C26.5589 10.9499 25.6804 10.2289 24.6526 9.80318C23.6248 9.37744 22.4938 9.26604 21.4026 9.48309C20.3115 9.70013 19.3092 10.2359 18.5225 11.0225L18.1307 11.4649" stroke="#002032" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_4951_23329">
      <rect width="30" height="30" fill="white"/>
    </clipPath>
  </defs>
</svg>
    </div>
    <div className="text-center text-teal-950 text-base font-medium font-satoshi leading-tight">Unlimited Access </div>
    <div className="w-[186px] text-center text-slate-500 text-sm font-medium font-satoshi leading-[16.80px]">Dive into unlimited job postings and searches for your next candidate.</div>
</div>
    </div>
    <div className="flex justify-start items-start w-full mt-[-9rem] md:mt-[-13rem]">
   
    <img src="/PmIcon2.png" alt="PmIcon1" width="239" height="239" />
    </div>
    </div>
</div>
<div className="w-full h-[92px] px-16 py-8 bg-gray-100 flex-col justify-start items-center gap-16 inline-flex">
    <div className="self-stretch justify-center items-center gap-8 inline-flex">
        <Link to="/tnc" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Terms of Use</div>
        </div>
        </Link>
        <Link to="/privacy" >
        <div className="p-1 rounded-xl justify-start items-center gap-1 flex cursor-pointer">
            <div className="text-slate-500 text-sm font-medium font-satoshi leading-tight">Privacy Policy</div>
        </div>
        </Link>
    </div>
</div>
  </div>
  </div>
};

export default PaymentSuccess;

