import React from "react";
import Parser from "html-react-parser";

const DescrptionCard = ({ jobDetails }) => {
  return (
    <div className="flex flex-col sm:flex-row mt-4 rounded-3xl bg-white py-6 px-4 sm:p-8 gap-4 sm:gap-8">
      <div className="OpeningDetails w-1/6 text-gray-900 text-[16px] font-bold leading-normal">
        Description
      </div>
      <div className="w-5/6 ">
        {/* <span className="mb-2 text-teal-950 text-xs sm:text-sm sm:text-opacity-60 font-medium leading-[18px]"> */}
        <div className="mb-2 text-teal-950 text-xs sm:text-sm sm:text-opacity-60 font-medium leading-[18px]">
                          <div
                  dangerouslySetInnerHTML={{
                    __html: jobDetails?.job_description,
                  }}
                />
                            {/* {formData.job_description} */}
                          </div>
          {/* {jobDetails?.job_description} */}
        {/* </span> */}
      </div>
    </div>
  );
};

export default DescrptionCard;
