import React, { useState, useRef, useEffect } from 'react'
import TextInput from './textInput'
import ButtonChild from '../../buttons/UI_Buttons_2/buttonChild'
import InfoBox from './infoBox'
import InputFieldComp from './InputFieldComp'
import InputOtp from './inputOtp'
import TextAreaBox from './textAreaBox'
import SingleSelectComp from '../../menu/singleSelectComp'
import MultiSelectComp from '../../menu/multiSelectComp'
import AddNotes from '../../../pages/job/application.jsx/modals/addNotes'
import LogOutToggle from '../../../pages/profile/modals/logOutToggle'
import OnSaveChangeToggle from '../../../pages/profile/modals/onSaveChangesToggle'
import InviteUser from '../../../pages/profile/modals/inviteUser'
import RevokeAdminAccess from '../../../pages/profile/modals/revokeAdminAccess'
import GrantAdminAccess from '../../../pages/profile/modals/grantAdminAccess'
import Calender from '../../calender/calender'

// import TextEditor from './TextBox'
// import CustomTextEditor from './TextBox'

const TestInputField = () => {

  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(false);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);
   

    // Check if the new value contains "@gmail.com" and update the error state accordingly
    if (!newValue.includes("@gmail.com")) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const Icon = [
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99984 16.3332C13.4976 16.3332 16.3332 13.4976 16.3332 9.99984C16.3332 6.50203 13.4976 3.6665 9.99984 3.6665C6.50203 3.6665 3.6665 6.50203 3.6665 9.99984C3.6665 13.4976 6.50203 16.3332 9.99984 16.3332ZM18.3332 9.99984C18.3332 14.6022 14.6022 18.3332 9.99984 18.3332C5.39746 18.3332 1.6665 14.6022 1.6665 9.99984C1.6665 5.39746 5.39746 1.6665 9.99984 1.6665C14.6022 1.6665 18.3332 5.39746 18.3332 9.99984Z"
        fill="currentColor" />
    </svg>
  ]
  const items = ['Apple', 'Banana', 'Cherry', 'Date', 'Elderberry', 'Fig', 'Grape', 'Honeydew'];

  const option=["abc", "def","ghi","jkl","mno","pqr","stu","vwx","yz"]
  return (
    <div>

      <span class="flex justify-center items-center py-8 text-[25px] font-bold text-red-500">Work in Progress.......</span>

      <b className='flex justify-center text-3xl'>InputField Components</b>
      <br />
      <b className='flex justify-center'>Text-Input</b><br />
      <div className='flex flex-col'>
      <div className='m-10 w-96'>
<h1>Selectors</h1>
<MultiSelectComp options={option} />
<SingleSelectComp />


</div>
        <div className='ml-16 border-r'>


          <b>Input-Box: Default / active/ filled</b><br />
          <div className='flex w-96 justify-start mx-10 my-5'>
            
            <TextInput
              
              textType="input"
              type="text"
              htmlFor="name"
              name="name"
              placeholder="Placeholder Text...."
              textColor="gray"
              leftText="ABC"
              rightText="123"
              leftIcon={Icon}
              rightIcon={Icon}
            />
          </div>
          <b>Disabled:</b><br />
          <div className='flex w-96 justify-start mx-10 my-5'>
            <TextInput
              textType="input"
              type="text"
              htmlFor="name"
              name="name"
              placeholder="Fixed text"
              textColor="gray"
              leftText="ABC"
              rightText="123"
              leftIcon={Icon}
              rightIcon={Icon}
              disabled={true}
            />
          </div>
          <hr />
          <b>Infor Box: Input</b><br />
          <div className='flex-col' >
            <div className='flex w-96 justify-start mx-10 my-5'>
              <InfoBox
                variant="standard"
                infoText="Information text"
                labelText="Label Text"
                inforText="Info Text"
                leftIcon={Icon}
                buttonText="Button"
                buttonLeftIcon={Icon}
              // buttonTextColor="green"


              />
            </div>
            <div className='flex w-96 justify-start mx-10 my-5'>
              <InfoBox
                variant="highlight"
                infoText="Information text"
                labelText="Label Text"
                inforText="Info Text"
                leftIcon={Icon}
                buttonText="Button"
                buttonLeftIcon={Icon}
              // buttonTextColor="green"


              />
            </div>
            <div className='flex w-96 justify-start mx-10 my-5'>
              <InfoBox
                variant="danger"
                infoText="Information text"
                labelText="Label Text"
                inforText="Info Text"
                leftIcon={Icon}
                buttonText="Button"
                buttonLeftIcon={Icon}
              // buttonTextColor="green"


              />
            </div>
            <div className='flex w-96 justify-start mx-10 my-5'>
              <InfoBox
                variant="warning"
                infoText="Information text"
                labelText="Label Text"
                inforText="Info Text"
                leftIcon={Icon}
                buttonText="Button"
                buttonLeftIcon={Icon}
              // buttonTextColor="green"


              />

            </div>
          </div>

          <hr />


        </div>
        <div className='ml-16'>
          <b>Component: Input Field</b>
          <div className='flex-col w-96 justify-start mx-5 my-5'>
            <InputFieldComp
              labelText="Label Text"
              inforText="Info Text"
              leftIcon={Icon}
              type="text"
              htmlFor="name"
              name="name"
              variant="standard"
              infoText="Information Text"
              infoLeftIcon={Icon}
              buttonText="Button"
              buttonLeftIcon={Icon}
              addInfo="This is additional information."
              errorMsg="This is an error Validation."
            />
          </div>
          <hr />
          <br />
          <div className=' w-96'>
            <TextInput
              type="text"
              htmlFor="name"
              name="name"
              labelText="Enter Your Name:"
              textType="input"
              placeholder="Full Name"
              textColor="gray"
            />
            <TextInput
              type="text"
              htmlFor="name"
              name="name"
              labelText="Website"
              textType="input"
              placeholder="website.com"
              rightIcon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10 1.875C8.39303 1.875 6.82214 2.35152 5.486 3.24431C4.14985 4.1371 3.10844 5.40605 2.49348 6.8907C1.87852 8.37535 1.71762 10.009 2.03112 11.5851C2.34463 13.1612 3.11846 14.6089 4.25476 15.7452C5.39106 16.8815 6.8388 17.6554 8.4149 17.9689C9.99099 18.2824 11.6247 18.1215 13.1093 17.5065C14.594 16.8916 15.8629 15.8502 16.7557 14.514C17.6485 13.1779 18.125 11.607 18.125 10C18.1227 7.84581 17.266 5.78051 15.7427 4.25727C14.2195 2.73403 12.1542 1.87727 10 1.875ZM16.8461 9.375H13.7344C13.6242 7.15156 12.932 5.04141 11.7719 3.35938C13.1306 3.72417 14.3452 4.49613 15.2523 5.57152C16.1594 6.64691 16.7156 7.97419 16.8461 9.375ZM7.51797 10.625H12.482C12.3508 13.0367 11.4664 15.2828 10 16.8664C8.53672 15.2828 7.64922 13.0367 7.51797 10.625ZM7.51797 9.375C7.64922 6.96328 8.5336 4.71719 10 3.13359C11.4633 4.71719 12.3508 6.96172 12.482 9.375H7.51797ZM11.7719 16.6406C12.932 14.9563 13.6242 12.8461 13.7344 10.625H16.8461C16.7156 12.0258 16.1594 13.3531 15.2523 14.4285C14.3452 15.5039 13.1306 16.2758 11.7719 16.6406Z"
                  fill="currentColor" />
              </svg>}
              textColor="gray"
            />

            <TextInput
              type="text"
              htmlFor="name"
              name="name"
              labelText="Mobile :"
              textType="input"
              placeholder="00000 00000"
              leftText="+91"
              textColor="gray"
            />

            <br />
            <br />
            <hr />
            <br />
            <b>Component OTP Field:</b>
            <br />
            <InputOtp
              placeholder="0"
            />
            {/* <InputOtp
              hasError={true}
              placeholder="0"
            /> */}
            {/* <InputOtp
              verified={true}
              placeholder="1"
            /> */}


          </div>
          <br />
          <hr />

{/* <AddNotes /> */}
{/* <LogOutToggle /> */}
{/* <OnSaveChangeToggle /> */}
{/* <InviteUser /> */}
{/* <RevokeAdminAccess /> */}
{/* <GrantAdminAccess  /> */}

        </div>

      </div>
      {/* <TextEditor />

      <div className='w-96 my-20 ml-16'>

        <TextAreaBox 
        addInfo="This is an additional Information."
        errorMsg="This is an error Validation."
        />

          
          </div> */}


<Calender />

    </div>
  )
}

export default TestInputField