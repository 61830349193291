import React, { useState, useEffect } from 'react'
import ChatBox from './modals/chatBox'
import Message from './modals/message'
import ChatMainScreen from './chat'
import BeepNavbar from '../../components/navbar/navbar'
import { Link } from 'react-router-dom'
import MessageComp from './modals/message1'
import "../../index.css"
import Loader from '../../components/Loader/loader'

const MyMessage = () => {

// State to track whether the user is in a conversation or not
const [inConversation, setInConversation] = useState(true);



// Function to handle going back to the chat list
const handleGoBack = () => {
  setInConversation(true);
};


const [Loading, setLoading] = useState(true); // State to track loading

// Your existing state and useEffect logic...

useEffect(() => {
  // Simulating an asynchronous action
  const fakeAsyncAction = async () => {
    try {
      // Simulate an API call or any asynchronous action
      await new Promise((resolve) => setTimeout(resolve, 1000));
    } finally {
      // Set isLoading to false once the asynchronous action is done
      setLoading(false);
    }
  };

  // Call the fakeAsyncAction when the component mounts
  fakeAsyncAction();
}, []); // Empty dependency array means this effect runs once on mount

// Render the loader if isLoading is true
if (Loading) {
  return <Loader />;
}
    return (
        <div>
            <BeepNavbar />

            <div
                className="4 w-screen h-screen flex-col justify-center items-center inline-flex overflow-hidden no-scrollbar"
                // style={{
                //     background:
                //         "linear-gradient(180deg, rgba(22, 192, 131, 0.10) 0%, rgba(255, 218, 69, 0.10) 50%, rgba(22, 192, 131, 0.10) 100%), #FFF",
                // }}
            >

    <div className='sm:hidden'>

    <Message />
    </div>
     

   
                
                <div className='hidden sm:flex '>
                    <ChatMainScreen />
                </div>
            </div>
        </div>
    )
}

export default MyMessage