import React from "react";
const CommonButtonWithIcon = ({ icon, text, className, ...props }) => {
  return (
    <button
      className={`ButtonMedium flex justify-between cursor-pointer w-full sx:w-fit h-11 p-3 bg-emerald-500  rounded-xl border  border-gray-900 border-opacity-10 justify-center items-center gap-2 inline-flex ${className}`}
      {...props}
    >
      {/* <div className="InputFieldIconsPluscircle  w-5 h-5 ">{icon}</div> */}
      <div className="ButtonText text-center text-white text-[14px] font-bold leading-tight w-max">
        {text}
      </div>
      <div className="InputFieldIconsPluscircle  w-5 h-5">
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="white">
<path d="M14.1922 10.4422L7.94219 16.6922C7.85478 16.7797 7.74337 16.8393 7.62207 16.8635C7.50076 16.8876 7.37502 16.8752 7.26076 16.8279C7.14649 16.7805 7.04884 16.7004 6.98017 16.5975C6.91151 16.4946 6.8749 16.3737 6.875 16.25V3.75C6.8749 3.62632 6.91151 3.50539 6.98017 3.40252C7.04884 3.29964 7.14649 3.21946 7.26076 3.17211C7.37502 3.12477 7.50076 3.11239 7.62207 3.13655C7.74337 3.1607 7.85478 3.22031 7.94219 3.30782L14.1922 9.55782C14.2503 9.61586 14.2964 9.68479 14.3279 9.76067C14.3593 9.83654 14.3755 9.91787 14.3755 10C14.3755 10.0821 14.3593 10.1635 14.3279 10.2393C14.2964 10.3152 14.2503 10.3841 14.1922 10.4422Z" 
fill=""/>
</svg>
      </div>

    </button>
  );
};

export default CommonButtonWithIcon;


// uses
// <CommonButtonWithIcon />
