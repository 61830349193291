import BeepNavbar from "../../components/navbar/navbar";
import { Link } from "react-router-dom";

const TNC = () => {
  return (
    <div>

    {/* <div className="sm:hidden">
            <BeepNavbar />
    
          </div> */}
    <div className="Menu w-full border-l border-gray-900 border-opacity-10 sm:w-[75vw] xl:w-[50vw] sm:pt-[3rem] sm:m-0 mb-8 overflow-scroll no-scrollbar grow shrink basis-0 self-stretch p-spaceBase sm:px-spaceSLarge sm:py-spaceLarge bg-white flex-col justify-start items-start inline-flex">
    {/* <Link to="/profile">
              <div className="sm:hidden mb-4  flex flex-row ">
                <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M5.80781 10.4427L12.0578 16.6927C12.1452 16.7802 12.2566 16.8398 12.3779 16.8639C12.4992 16.8881 12.625 16.8757 12.7392 16.8284C12.8535 16.781 12.9512 16.7009 13.0198 16.598C13.0885 16.4951 13.1251 16.3742 13.125 16.2505V3.75049C13.1251 3.62681 13.0885 3.50587 13.0198 3.403C12.9512 3.30013 12.8535 3.21995 12.7392 3.1726C12.625 3.12526 12.4992 3.11288 12.3779 3.13704C12.2566 3.16119 12.1452 3.2208 12.0578 3.3083L5.80781 9.5583C5.7497 9.61635 5.7036 9.68528 5.67215 9.76115C5.6407 9.83703 5.62451 9.91836 5.62451 10.0005C5.62451 10.0826 5.6407 10.164 5.67215 10.2398C5.7036 10.3157 5.7497 10.3846 5.80781 10.4427Z" fill="#002032" fill-opacity="0.6" />
                </svg>
                </div>
                <span className='text-gray-500 font-bold leading-tight'>Go Back</span>
              </div>
            </Link> */}
        <div className="text-teal-950 text-[32px] font-black font-['Satoshi Variable'] leading-[38.40px] mb-spaceXxLarge sm:mb-spaceSLarge">Terms of Use</div>
          <div className="p-2">
            <h4 className="heading font-bold ">1. Acceptance of Terms</h4>
            <div className="p-4">
              <p>
                By accessing or using the Beep Recruiter panel ("the Service"),
                you agree to comply with and be bound by these Terms of Service
                ("Terms"). If you do not agree to these Terms, please do not use
                the Service.{" "}
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">2. User Eligibility </h4>
            <div className="p-4">
              <p>
                You must be at least 18 years old and have the legal capacity to
                enter into this agreement. By using the Service, you represent
                and warrant that you meet these requirements.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">3. Account Registration </h4>
            <div className="p-4">
              <p>
                To access certain features of the Service, you must create an
                account and provide accurate information. You are responsible
                for maintaining the confidentiality of your account credentials
                and for all activities that occur under your account.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">4. Job Postings </h4>
            <div className="p-4">
              <p>
                Recruiters can create and manage job postings on the Service.
                You agree that your job postings will not contain false or
                misleading information and will comply with all applicable laws
                and regulations.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">5. Candidate Interactions </h4>
            <div className="p-4">
              <p>
                Recruiters can communicate with candidates through the Service.
                You agree to treat candidates respectfully and professionally.
                Discrimination, harassment, or any form of abusive behavior is
                strictly prohibited.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">6. AI-Based Tools </h4>
            <div className="p-4">
              <p>
                The Service may offer AI-based tools for hiring. These tools are
                provided as-is, and we do not guarantee their accuracy. You are
                responsible for verifying information and making hiring
                decisions.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">7. Data Privacy </h4>
            <div className="p-4">
              <p>
                Our Privacy Policy governs the collection, use, and disclosure
                of personal information on the Service. By using the Service,
                you consent to our data practices as described in the Privacy
                Policy.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">8. Pricing and Payments </h4>
            <div className="p-4">
              <p>
                Recruiters may be required to pay fees for certain features or
                services. Pricing details are available on the Service, and you
                agree to pay all applicable fees and taxes.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">9. Termination </h4>
            <div className="p-4">
              <p>
                We reserve the right to terminate or suspend your account at our
                discretion, with or without cause. You may terminate your
                account at any time by following the instructions on the
                Service.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">10. Changes to Terms </h4>
            <div className="p-4">
              <p>
                We may update these Terms from time to time. It is your
                responsibility to review them periodically. Continued use of the
                Service after changes constitute your acceptance of the revised
                Terms.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">
              11. Disclaimer of Warranties{" "}
            </h4>
            <div className="p-4">
              <p>
                The Service is provided "as is" without warranties of any kind.
                We make no guarantees regarding the accuracy, reliability, or
                availability of the Service.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">12. Limitation of Liability</h4>
            <div className="p-4">
              <p>
                To the extent permitted by law, we shall not be liable for any
                indirect, incidental, special, consequential, or punitive
                damages.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">13. Governing Law</h4>
            <div className="p-4">
              <p>
                To the extent permitted by law, we shall not be liable for any
                indirect, incidental, special, consequential, or punitive
                damages.
              </p>
            </div>
          </div>
          <div className="p-2">
            <h4 className="heading font-bold ">14. Contact Us </h4>
            <div className="p-4">
              <p>
                If you have any questions or concerns about these Terms, please
                contact us at{" "}
                <Link
                  to="#"
                  onClick={(e) => {
                    window.location.href = "mailto:contact@eventbeep.com";
                    e.preventDefault();
                  }}
                  className="ButtonText text-emerald-500 text-[14px] font-bold leading-tight mb-0 mt-auto"
                >
                  contact@eventbeep.com
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default TNC;
