import React from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "../pages/auth/login";
import { ForgotPassVerify } from "../pages/auth/forgotPass_verifyAccount";
import ResetPassword from "../pages/auth/resetPassword";
import { VerifyOTP } from "../pages/auth/verifyOtp";
import CreateCompany from "../pages/company/create_company";
import JobDashboard from "../pages/job/dashboard";
import CreateJob from "../pages/job/createJob";
import CandidateListing from "../pages/job/candidateList/candidateListing";
import Application from "../pages/job/application.jsx";
import OpeningDetails from "../pages/job/openingDetails";
import { CreateAccount } from "../pages/auth/createAccount";
import { ResetPasswordOTP } from "../pages/auth/forgot_password_otp_verify";
// import ChatMainScreen from "../pages/chat/chat";
import RedirectHome from "../pages/redirectpage";
import Profile from "../pages/profile/profile";
import EmailVerification from "../pages/verification/emailverification";
import EmailVerificationUser from "../pages/verification/emailverificationUser";
import TNC from "../pages/tnc/tnc";
import Privacy from "../pages/tnc/privacy";
import InterviewCard from "../pages/job/application.jsx/righsideInfo/interview";
// import InterviewCard from "../pages/job/application.jsx/righsideInfo/interview";

import MyCompany from "../pages/profile/profile/mycompany";
import ResetPass from "../pages/profile/profile/resetpass";
import HelpAndFedback from "../pages/profile/profile/helpAndFeedback";
// import ChatBox from "../pages/chat/modals/chatBox";
import MyMessage from "../pages/chat/myMessage";
// import Message from "../pages/chat/modals/message";
import MyMessage1 from "../pages/chat/myMessage1";
import GenerateResume from "../pages/job/candidateResume/generateResume";
import CommonButtonWithIcon from "../components/buttons/commonButtonWithIcon";
import TestButtons from "../components/buttons/UI_Buttons_2/testButtons";
import TestInputField from "../components/inputfields/UI_Inputs/testInputField";
import { SelectCompany } from "../pages/auth/selectCompany";
import CreateNewCompany from "../pages/company/createCompany";
import CompanyVerifyStatus from "../pages/auth/alert_notify_verify_window/companyVerifyStatus";
import RedirectSignIn from "../pages/redirectSignIn";
import NotificationsSetting from "../pages/profile/profile/notificationsSetting";
import BeepNavbar from "../components/navbar/navbar.jsx";
import NewDashboard from "../pages/job/Dashboard/newDashboard.jsx";
import CreateJobOpeningPage from "../pages/job/JobOpening/createJobOpeningPage.jsx";
import TagInput from "../components/calender/tag.jsx";
import Pricing from "../pages/profile/modals/pricing.jsx";
import PaymentSuccess from "../pages/profile/modals/paymentSuccess.jsx";
import Checkout from "../pages/profile/modals/checkout.jsx";
import PaymentFailure from "../pages/profile/modals/paymentFailure.jsx";
import { AboutUs } from "../pages/job/Dashboard/modals/aboutUs.jsx";
// import MyCompanyMob from "../pages/profile/LeftMenuSmall/mycompany.jsx";
// import MyProfile from "../pages/profile/LeftMenuSmall/myprofile.jsx"
// import ResetPassMob from "../pages/profile/LeftMenuSmall/resetpass.jsx";
// import NotificationsSettingMob from "../pages/profile/LeftMenuSmall/notificationsSetting.jsx";

const MainRoutes = () => {
  return (
    <div>
     
      <Routes>
     
      <Route path="/newdash" element={<JobDashboard />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/pricing/checkout/success" element={<PaymentSuccess />} />
      <Route path="/pricing/checkout" element={<Checkout />} />
      <Route path="/pricing/checkout/failure" element={<PaymentFailure />} />




      {/* <Route path="/opening" element={<CreateJobOpeningPage />} /> */}



      <Route path="/signin" element={<RedirectSignIn />} />
      <Route path="/login" element={<Login />} />
      
      <Route path="/" element={<RedirectHome />} />
        <Route path="test" element={<TestButtons />} />
        <Route path="testInput" element={<TestInputField />} />

        <Route path="button" element={<CommonButtonWithIcon />} />
      
        
        <Route path="create/account" element={<CreateAccount />} />
        <Route path="create/verify" element={<VerifyOTP />} />
        <Route path="create/selectcompany" element={<SelectCompany />} />
        <Route path="company/createnewcompany" element={<CreateNewCompany/>} />
        <Route path="company/verifystatus" element={<CompanyVerifyStatus/>} />


        <Route path="password/verifyaccount" element={<ForgotPassVerify />} />
        <Route path="password/reset" element={<ResetPassword />} />

        <Route path="resetpassword/otp" element={<ResetPasswordOTP />} />
        <Route path="company/create" element={<CreateCompany />} />
        <Route path="job/dashboard" element={<NewDashboard />} />
        <Route path="job/create" element={<CreateJobOpeningPage />} />
        <Route path="job/candidate/:id" element={<CandidateListing />} />
        <Route
          path="job/application/:jobId/:applicationNo"
          element={<Application />}
        />
        <Route path="job/opening/:id" element={<OpeningDetails />} />
        <Route path="chat" element={<MyMessage />} />
        <Route path="chat1" element={<MyMessage1/>} />
        <Route path="profile" element={<Profile profile={true} />} />
        <Route path="transaction" element={<Profile transaction={true} />} />
        <Route path="leaderboard" element={<Profile leaderboard={true} />} />


        <Route
          path="recruiter/verify_email_token"
          element={<EmailVerification />}
        />
        <Route
          path="user/verify_email_token"
          element={<EmailVerificationUser />}
        />
        <Route path="terms-of-use" element={<TNC />} />
        <Route path="privacy-policy" element={<Privacy />} />

        <Route path="*" element={<RedirectHome />} />
  
      <Route path="tnc" element={<Profile tnc={true} />} />
      <Route path="privacy" element={<Profile privacy={true} />} />


       
        
        <Route path="/resume" element={<GenerateResume />} />
        <Route path="/about-us" element={<AboutUs />} />

        <Route
          path="/job/application/:jobId/:applicationNo/resume"
          element={<GenerateResume />}
        />     
      </Routes>
    </div>
  );
};

export default MainRoutes;
