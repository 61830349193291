import React from "react";
import RightSideExperience from "./righsideInfo/experience";
import QuestionsCandidateAnswer from "./righsideInfo/questions";
import OfferForCandidate from "./righsideInfo/offer";
import AssignemtsListCard from "./righsideInfo/assignments";
import CertificationsCard from "./righsideInfo/certification";
import AwardsAndHonors from "./righsideInfo/awards";
import InterviewCard from "./righsideInfo/interview";
import EducationCard from "./righsideInfo/education";
import DisplayNotes from "./righsideInfo/notes";

const RightSideInfo = ({
  className,
  userInfo,
  cancelInterview,
  setshowScheduleInterview,
  acceptInterview,
  notes,
}) => {
  return (
    <div
      className={"bg-slate-100 px-4 py-2 overflow-scroll no-scrollbar w-full mb-8 " + className}
    >
      {userInfo?.interview && (
        <InterviewCard
          userInfo={userInfo}
          cancelInterview={cancelInterview}
          setshowScheduleInterview={setshowScheduleInterview}
          acceptInterview={acceptInterview}
        />
      )}
      {notes?.length != 0 && <DisplayNotes notes={notes} />}
      {/* <OfferForCandidate userInfo={userInfo} /> */}
      {userInfo?.assignment && <AssignemtsListCard userInfo={userInfo} />}
      {userInfo?.questions?.length > 0 && <QuestionsCandidateAnswer userInfo={userInfo} />}
      {userInfo?.applicant_info?.work_profile?.experience && (
        <RightSideExperience userInfo={userInfo} />
      )}
      {userInfo?.applicant_info?.work_profile?.education && (
        <EducationCard userInfo={userInfo} />
      )}
      {userInfo?.applicant_info?.work_profile?.certifications && (
        <CertificationsCard userInfo={userInfo} />
      )}

      {userInfo?.awards && <AwardsAndHonors userInfo={userInfo} />}

      {!(
        userInfo?.interview ||
        userInfo?.assignment ||
        userInfo?.questions ||
        userInfo?.applicant_info?.work_profile?.experience ||
        userInfo?.applicant_info?.work_profile?.education ||
        userInfo?.applicant_info?.work_profile?.certifications ||
        userInfo?.awards ||
        notes?.length != 0
      ) && (
        <div className="w-full bg-white mx-auto p-4 text-center rounded-3xl shadow-sm">
          No data
        </div>
      )}
    </div>
  );
};

export default RightSideInfo;
