import React, { useEffect, useState } from 'react'
import ButtonChild from '../../../components/buttons/UI_Buttons_2/buttonChild'
import { useParams, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

const CompanyVerifyStatus = () => {

const [searchParams] = useSearchParams();
let userId = searchParams.get("userId")
console.log(userId)

const navigate = useNavigate();
// useEffect(() => {
//     // Get user data from the location state
//     const authToken = localStorage.getItem("auth") || location.state?.authToken;
//     // const authToken = location.state?.authToken;
//     console.log(authToken)
//     if (authToken) {
//       // If user data is not available in the location state, you can make an API request here
//       const config = {
//         headers: {
//           Authorization: `Bearer ${authToken}`, // Include the auth-token in the request headers
//         },
//       };
//       axios
//         .get(`${process.env.REACT_APP_baseURL}/recruiter/verify`, config)
//         .then((response) => {

//           console.log(response.data)
//           setCompanyData(response.data.userId)

//         })
//         .catch((error) => {
//           console.error('Error fetching user data:', error);

//         });
//     }

//   }, []);

useEffect(()=>{
  const authToken = localStorage.getItem('auth')
  if(!authToken){
    navigate('/signin')
  } 
},[])

const [response, setResponse] = useState('')
  const accepOrReject = async () => {
    try {
      // Assuming you have an authentication token in a variable called authToken
      const authToken = localStorage.getItem('auth');
  
      // Make an API request to send an email invitation with the token in the headers
      const response = await axios.patch(
        `${process.env.REACT_APP_baseURL}/company/updateverification/${userId}`,
        {
          "verifyStatus": "Accepted",
        },
        {
          headers: {
            'Authorization': `Bearer ${authToken}`, // Adjust the header format as per your API's requirements
          },
          
        }
      );
        console.log(response)
        setResponse(response);
      // Handle the API respons
    } catch (error) {
      console.error('Error sending invitation:', error);
     console.log('Error updating updation. Please try again later.');
    }
  };
  
  

  return (
    <div className="flex flex-col w-screen overflow-auto no-scrollbar justify-center items-center gradient sm:p-spaceSLarge min-h-screen
    xl:flex-row xl:h-[100vh] xl:p-0 xl:m-0  ">
        <div className="w-full h-full sx:w-[420px] sx:h-[460px] 2xl:w-[520px] 2xl:h-[560px] p-8 sm:p-16 bg-white sx:rounded-[76px] flex-col justify-center items-center gap-8 inline-flex">
    <div className="sx:w-64 sx:h-64 relative">
      <img src="/ill.png"/>
    </div>
    <div className="self-stretch h-auto flex-col justify-start items-start gap-4 flex">
        <div className="self-stretch text-center text-black text-[20px] sx:text-[25px] 2xl:text-[32px] font-black font-satoshi leading-[38.40px]">New Profile Verified!</div>
        {/* <div className="self-stretch text-center text-black text-[20px] sx:text-[25px] sm:text-[32px] font-black font-satoshi leading-[38.40px]">Link Expired</div> */}

        <div className=" self-stretch text-center text-black text-sm font-medium font-satoshi leading-tight">A new Beep Profile has been verified for your Company. Please accept the request</div>
        {/* <div className=" self-stretch text-center text-black text-sm font-medium font-satoshi leading-tight">Please request a new link to verify.</div> */}

    </div>
    <div className="p-1 self-stretch rounded-xl justify-center items-center gap-1 inline-flex" onClick={accepOrReject}>
        <ButtonChild 
        textColor="green"
        text={response ? 'Verified' : "Accept"}
        textClass={`text-xl`}
        />
       
    </div>
</div>
    </div>
  )
}

export default CompanyVerifyStatus