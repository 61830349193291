import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

// This is for the users to verify email that sent through the email from my profile screen

const EmailVerificationUser = () => {
  const [verified, setVerified] = useState(null);
  // null - waiting for verification, true, false - dependnds on the token result

  const navigator = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const token = queryParams.get("token");

  const verifyToken = async () => {
    console.log("token", token);

    const url = `${process.env.REACT_APP_baseURL}/user/verify_email_token?token=${token}`;

    const response = await axios
      .get(url, {
        headers: {},
      })
      .catch((error) => {
        setVerified(false);
      });

    if (response?.data) {
      console.log(response?.data);
      setVerified(true);
    }
  };

  useEffect(verifyToken, []);
  return (
    <div>
      <div
        className="EmailVerified w-screen pt-32 h-screen pb-[156px to-white flex-col justify-start items-center gap-[100px] inline-flex"
        style={{
          background:
            "linear-gradient(180deg, rgba(22, 192, 131, 0.10) 0%, rgba(255, 218, 69, 0.10) 50%, rgba(255, 255, 255, 0.10) 100%), #FFF",
        }}
      >
        {/* Token verified successfully */}
        {verified === true && (
          <div className="FullCard w-96 h-[508px]   flex-col justify-start items-start inline-flex">
            <div className="IllTxtButton h-[424px] shadow rounded-t-[44px] p-8 bg-white flex-col justify-start items-center gap-8 flex">
              <div className="Ill w-64 h-64 relative">
                <img
                  className=" w-48 h-48  absolute origin-top-left"
                  src="/48.png"
                />

                <img
                  className="w-32 h-32 left-[107.50px]  absolute"
                  src="/28 1.png"
                />
              </div>
              <div className="Txt self-stretch h-[72px] flex-col justify-start items-center gap-2 flex">
                <div className="GreatYourEmailIsVerified text-center text-teal-950 text-base font-bold leading-normal">
                  Great! Your Email is Verified
                </div>
                <div className="NowYouCanApplyAndStartHearingFromRecruitersOnYourEmailDirectly self-stretch text-center text-teal-950 text-sm font-medium leading-tight">
                  Now you can apply and start hearing from recruiters on your
                  email directly.
                </div>
              </div>
            </div>
            <div className="IllTxtButton self-stretch h-[84px] rounded-b-[44px] shadow  p-8 bg-emerald-500 bg-opacity-10 border-t border-emerald-500 flex-col justify-start items-center gap-8 flex">
              <div className="UseTheBeepAppToStartApplying self-stretch text-center text-teal-950 text-opacity-60 text-sm font-medium leading-tight">
                Use the Beep app to start applying!
              </div>
            </div>
          </div>
        )}

        {/* Invalid token or Expired */}
        {verified === false && (
          <div className="FullCard w-96 h-[508px] rounded-[44px]  bg-white  shadow flex-col justify-start items-start inline-flex">
            <div className="IllTxtButton h-[424px] p-8 flex-col justify-start items-center gap-8 flex">
              <div className="Ill w-64 h-64 relative">
                <img
                  className="1 w-48 h-48 left-[32px] top-[32px] absolute"
                  src="/26 1.png"
                />
              </div>
              <div className="Txt self-stretch h-[72px] flex-col justify-start items-center gap-2 flex">
                <div className="OopsTheLinkHasExpired self-stretch text-center text-teal-950 text-base font-bold leading-normal">
                  Oops! The link has Expired.
                </div>
                <div className="ResendAnEmailFromAppSettingsToFinishVerification self-stretch text-center text-teal-950 text-sm font-medium leading-tight">
                  Resend an email from app settings to finish verification.
                </div>
              </div>
            </div>
            <div className="IllTxtButton self-stretch h-[84px] rounded-b-[44px] p-8 bg-amber-300 bg-opacity-10 border-t border-amber-300 flex-col justify-start items-center gap-8 flex">
              <div className="SendNewLinkFromYourBeepApp self-stretch text-center text-teal-950 text-opacity-60 text-sm font-medium leading-tight">
                Send new link from your Beep App.
              </div>
            </div>
          </div>
        )}

        {/* Loader until getting response */}
        {verified === null && (
          <div className="mx-auto">
            <div class="absolute right-1/2 bottom-1/2  transform translate-x-1/2 translate-y-1/2 ">
              <div class="border-t-transparent border-solid animate-spin  rounded-full border-emerald-500 border-8 h-12 w-12"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationUser;
