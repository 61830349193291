import React, { useState } from "react";

const QuestionsCandidateAnswer = ({ userInfo }) => {
  console.log(userInfo?.job_info?.questions?.map((index)=>index.question))
  const questions = [
    {
      questions: "Why should you be selected for the role?",
      answer:
        "I believe I am a strong candidate for this role because of my passion for social media and my previous experience in social media management and content creation. I am creative, proactive, and able to think outside the box to come up with innovative social media strategies to engage with the audience. I have a good understanding of social media platforms and tools, and I'm always eager to learn and stay updated with the latest social media trends and best practices. I am a team player who is comfortable collaborating with others to achieve common goals. I am excited about the opportunity to work with Swiggy and contribute to its social media presence.",
    },

    {
      questions: "Most exciting appllication you worked",
      answer: "Chat application",
    },
  ];
  return (
    <div className="bg-white rounded-lg p-4 my-2">
      <div className="flex flex-col sm:flex-row">
        <div className="w-3/12 font-bold ">Application Answers: </div>
        <div className="w-9/12">
        {userInfo?.job_info?.questions?.map((question, index) => (
  <div key={index} className="mb-2">
    <div className="font-semibold text-slate-800 mb-1 flex  gap-2">
     <h3 >Que:</h3> 
     {question.question}
    </div>
    <div className="text-sm text-slate-500 text-justify flex gap-1">
    <h3 className="text-black font-bold">Ans:</h3>  
    
    {question.questionType === 1 && question.options && 
  (userInfo.questions[index]?.selectOption !== undefined || userInfo.questions[index]?.selectedOption !== undefined)
  ? question.options[
    userInfo.questions[index]?.selectOption !== undefined 
    ? userInfo.questions[index]?.selectOption 
    : userInfo.questions[index]?.selectedOption
  ]
  : question.questionType === 2 && question.options && 
    (userInfo.questions[index]?.selectOptions || userInfo.questions[index]?.selectedOptions)
    ? (userInfo.questions[index]?.selectOptions || userInfo.questions[index]?.selectedOptions)
        .map(optionIndex => question.options[optionIndex])
        .join(', ') 
    : question.questionType === 3 && userInfo.questions[index]?.url
      ? <a target="_blank" className="font-bold text-blue-500" href={userInfo.questions[index]?.url}>View File</a>
      : userInfo.questions[index]?.answer || ''
}

    </div>
  </div>
))}


        </div>
      </div>
    </div>
  );
};

export default QuestionsCandidateAnswer;
